<div class="card flex-column {{ size }}" attr.data-sku="{{ product?.coreProduct?.sku }}">
  <div class="text text-primary no-wrap text-ellipsis product-name">
    <label class="text text-primary text-ellipsis overflow-class" [tooltip]="product?.name">
      {{ product?.name }}
    </label>
  </div>
  <div class="manufacturer flex-row pb-small mb-small" *ngIf="product?.coreProduct?.manufacturer">
    <label class="text mr-auto">
      {{ product?.coreProduct?.manufacturer?.name }}
    </label>
    <label class="text sku">
      #{{ product?.coreProduct?.sku }}
    </label>
  </div>

  <div class="flex-row no-wrap">
    <div *ngIf="(product?.supplierProductTags?.length ?? 0)" class="flex-row" style="position: absolute;">
      <app-product-tag *ngFor="let t of product?.supplierProductTags" [color]="t.c" [background]="t.b"
        [text]="t.t"></app-product-tag>
    </div>
    <div *ngIf="(product?.coreProduct?.listing?.productCount ?? 0) > 1"
      class="listing-count listing-space variations-icon flex-row no-wrap ml-auto">
      <span class="mr-small">{{ product?.coreProduct?.listing?.productCount}}</span>
      <img src="https://cdn-icons-png.flaticon.com/128/5322/5322871.png" style="height: 20px; width: 20px;" />
    </div>
    <div class="flex-row listing-space" *ngIf="getMode() === 'add'">
      &nbsp;
    </div>
  </div>

  <div class="image" [ngStyle]="{ 'background-image': 'url(' + product?.imageUrl + ')' }"></div>

  <div class="supplier-details">{{ product?.supplier?.name }}: {{ product?.sku }}</div>

  <app-add-to-cart [id]="getATCId()" *ngIf="displayAddToCart" [coreProduct]="product?.coreProduct!"
    [supplierProduct]="product" class="mb-small ml-auto mr-auto" [mode]="getMode()" [allowVariations]="true"
    [hideRemove]="true" [showCurrentQuantity]="false">
  </app-add-to-cart>

  <div class="bottom flex-column">
    <div class="flex-row">
      <div class="text price mr-auto">
        {{ product?.price ?? '&nbsp;'}}
      </div>
      <div *ngIf="getMode() === 'add'" class="text text-300">
        <a class="link">
          &nbsp;
        </a>
      </div>
      <div *ngIf="getMode() === 'add-to'" class="text text-300">
        <a [class]="'view-variations-' + product?.coreProduct?.sku + ' link'"
          (click)="openModal(getModalId()); show_variations.openVariations()">
          Show Variations
        </a>
        <app-modal [confirmClose]="true" [id]="getModalId()" size="large"
          [heading]="'Variations of ' + product?.coreProduct?.listing?.name" title="Variations">
          <app-product-variations #show_variations [modalId]="getModalId()" [isListing]="true" [showAll]="true"
            [product]="product?.coreProduct" [mainImageUrl]="product?.imageUrl"></app-product-variations>
        </app-modal>
      </div>
    </div>
    <div *ngIf="product?.lastPurchaseDate" class="flex-column">
      Purchased {{ product?.lastPurchaseQuantity | number }} on {{ product?.lastPurchaseDate | date:'longDate' }}
    </div>
    <div *ngIf="!product?.lastPurchaseDate" class="flex-column">
      &nbsp;
    </div>
  </div>

</div>