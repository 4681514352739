import { environment } from '../../../environments/environment';

/**
 * Base API configuration file
 */
export class BudgetPeriodApiConfig {
    static path: string = environment.api.budget.path;
    static version: string | number | null = environment.api.budget.version;

    public static setApiVersion(version: string = 'api'): void {
        BudgetPeriodApiConfig.version = version;
    }

    public static getApiVersion(): string | number | null {
        return BudgetPeriodApiConfig.version;
    }

    public static getPath(): string {
        return BudgetPeriodApiConfig.path;
    }

    public static getFullUrl(path: string): string {
        if (BudgetPeriodApiConfig.getApiVersion()) {
            return [BudgetPeriodApiConfig.getPath(), BudgetPeriodApiConfig.getApiVersion(), path].join('/');
        } else {
            return [BudgetPeriodApiConfig.getPath(), path].join('/');
        }
    }
}
