<app-progress [splash]="true" [value]="working">
    <div class="header flex-row rap justify-between">
        <H3>Practice Formulary</H3>
        <dx-button [disabled]="!allowlNavigateFormulary" (click)="gotToFormulary()" class="mt-auto" [useSubmitBehavior]="false"
            text="Manage Formulary">
        </dx-button>
        <label *ngIf="!(parent?.allowManageFormulary ?? true)" class="text text-rubik text-500 mt-auto mb-small">
            *The parent organization has disabled formulary management on this account*
        </label>
    </div>
    <form (ngSubmit)="formSubmit($event)">
        <dx-form [formData]="formSource" (onFieldDataChanged)="onFieldDataChanged($event)">
            <dxi-item itemType="group">
                <dxi-item dataField="practice.inheritPublicFormulary" [visible]="!hideCheckbox" editorType="dxCheckBox">
                    <dxo-label template="Inherit formulary from parents"></dxo-label>
                </dxi-item>

                <dxi-item editorType="dxSelectBox" dataField="publicFormularyId"
                    [editorOptions]="{ dataSource: formularies | sort:'id', displayExpr: 'name', valueExpr: 'id', showClearButton: 'true' }">
                    <dxo-label template="Public Formulary"></dxo-label>
                    <!-- <dxi-validation-rule type="required" message="Formulary is required"></dxi-validation-rule> -->
                </dxi-item>

                <dxi-item [visible]="parent?.allowManageFormulary" dataField="allowManageFormulary"
                    editorType="dxCheckBox">
                    <dxo-label template="Allow practice to manage formulary"></dxo-label>
                </dxi-item>
                <dxi-item [visible]="parent?.allowOrderHistory" dataField="allowOrderHistory" editorType="dxCheckBox">
                    <dxo-label template="Allow practice to purchase from order history"></dxo-label>
                </dxi-item>
                <dxi-item [visible]="parent?.allowDentalCity" dataField="allowDentalCity" editorType="dxCheckBox">
                    <dxo-label template="Allow practice to order from Dental City"></dxo-label>
                </dxi-item>
            </dxi-item>
            <dxi-item>
                <dx-button [disabled]="!isValid" class="mt-large" [useSubmitBehavior]="true" text="Save"></dx-button>
            </dxi-item>
        </dx-form>
    </form>
</app-progress>