<app-progress [splash]="true" [value]="working">
    <div class="account-edit flex-column">
        <div class="header flex-row no-wrap justify-between">
            <div class="flex-column">
                <h3 *ngIf="agency?.id; else new">{{agency?.name}}</h3>
                <label>{{agencyType}}</label>
            </div>
            <ng-template #new>
                <h3>New Agency Form</h3>
            </ng-template>
            <div class="flex-row">
                <button class="btn secondary text text-rubik ml-medium " (click)="returnToAgencyList()">Return to Agency List</button>
                <button class="btn secondary text text-rubik ml-medium" *ngIf="showBackButton" (click)="goBack()">Return to Accounts</button>
            </div>
        </div>


        <div class="body">
            <div class="info">

                <div #navbar *ngIf="agency" class="sidebar mr-medium mb-auto">
                    <app-access  *ngFor="let nav of appAccessNavigationOptions" [platformFeature]="nav.feature" [ngClass]="{'disabled' : !agency!.id}" routerLinkActive="active" [displayIfNoAccess]="false">
                        <a id="nav.aTagId" (click)="nav.action()" class="content text text-500 black">{{nav.aTagText}}</a>
                    </app-access>
                </div>
                <div class="ml-small">
                    <router-outlet (activate)='onActivate($event)'></router-outlet>
                </div>
            </div>
            <app-audit-info [auditInfo]="agency"></app-audit-info>

        </div>
    </div>
</app-progress>