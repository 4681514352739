import { AfterViewInit, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NameIdDescription, iNameId, Agent, Agency } from 'src/app/shared/models';
import { LoginService, ModalService, MonitorService, NavigationService } from 'src/app/shared/services';
import { DxFormComponent, } from 'devextreme-angular';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { ToolbarItem } from 'devextreme/ui/html_editor';
import { FormCanDeactivate as FormCanDeactivate } from 'src/app/shared/utils/form.candeactivate';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-agency-edit-details',
  templateUrl: './agency-edit-details.component.html',
  styleUrls: ['./agency-edit-details.component.scss'],
})
export class AgencyEditDetailsComponent extends FormCanDeactivate implements AfterViewInit {
  submitMessage: string = 'Error updating agency';
  validate(): boolean {
    return (this.form && this.agency && this.isValid) ?? false;
  }
  postExecute(): void {
    if (this.isNew) {
      this.navigationService.backoffice().agency.details(this.agency!.id);
    } else {
      super.postExecute();
    }
  }

  @ViewChild(DxFormComponent, { static: false })
  form: DxFormComponent | undefined;

  agency: Agency = {} as Agency;

  editorContent: string | undefined;

  @ViewChild('htmlEditor', { static: false }) htmlEditor: any;

  toolbarItems: ToolbarItem[] = [
    { name: 'undo' },
    { name: 'redo' },
    { name: 'separator' },
    { name: 'header', acceptedValues: [1, 2, 3, 4, 5] },
    { name: 'separator' },
    { name: 'bold' },
    { name: 'italic' },
    { name: 'underline' },
    { name: 'strike' },
    { name: 'separator' },
    { name: 'alignLeft' },
    { name: 'alignCenter' },
    { name: 'alignRight' },
    { name: 'alignJustify' }
  ];

  toolbar = {
    items: this.toolbarItems
  };

  @Output()
  sendUpdatedAgency = new EventEmitter<Agency>();

  creating = true; // Used to disable features that are unique to updating current customers
  formGroup!: UntypedFormGroup;
  working = 0; // Set to true when waiting for async queries to complete. Prevents bad requests
  accountId: number | undefined = undefined; // 0 is LIST, so can't use 0
  parentId = 0;
  message = '';
  confirmMessage = ''; // Message presented by the current confirm modal

  selectedUser: Agent | undefined; // Current selected agent to be added to the account
  recommendations: NameIdDescription[] = []; // recommendation options
  allUsers: Agent[] = [];
  theSuppliers: iNameId[] = [];
  inviteMessage?: string;
  shippingEqualsBilling = true;

  editUserFormGroup!: UntypedFormGroup; // form group for inviting new agent
  agent: Agent | undefined;
  theUsers: iNameId[] = [];
  isNew = false;
  isLive = false;

  constructor(
    monitor: MonitorService,
    login: LoginService,
    private agencyService: AgencyService,
    router: Router,
    private activatedRoute: ActivatedRoute,
    private modals: ModalService,
    private navigationService: NavigationService
  ) {
    super(monitor, login, router);

    this.editorContent = this.agency?.description;
    this.formGroup = new UntypedFormGroup({});
  }

  ngAfterViewInit() {
    this.activatedRoute.parent?.paramMap.subscribe(async (result) => {
      let routeParameter = result.get('agencyId');

      if (routeParameter === "new") {
        this.isNew = true;
      }

      else {
        let agencyId = parseInt(routeParameter ?? '0');

        if(agencyId > 0) {
          this.agency = await this.agencyService.getAgencyById(agencyId, { accounts: true, agents: true, formulary: true });
  
          this.editorContent = this.agency?.description;
          this.isNew = !this.agency.id;
          if(this.agency?.live) {
            this.isLive = true;
            const live = this.form!.instance.getEditor("live");
            live?.option("disabled", true);
          }
      
          if (!this.isNew) {
            const platformAgency = this.form!.instance.getEditor("platformAgency");
            platformAgency?.option("disabled", true);
          }

        } else{
          this.monitor.logException('Invalid agency id provided');
          this.monitor.handleError('Invalid agency id provided');
        }

      }
      
    });
  }


  onContentChanged(event: any) {
    this.editorContent = event.html;
  }


  async submit(): Promise<boolean> {
    const formData: Agency = this.form!.instance.option('formData');
  
    const handleFormSubmission = async (): Promise<boolean> => {
  
      if (this.isNew) {
        this.agency = await this.agencyService.postAgency(formData);
        if(this.agency.live){
          this.isLive = true;
          const live = this.form!.instance.getEditor("live");
          live?.option("disabled", true);
          const platformAgency = this.form!.instance.getEditor("platformAgency");
          platformAgency?.option("disabled", true);
        }
        return true;
      } else {
        this.agency = await this.agencyService.updateAgency(formData.id, formData);
        if(this.agency.live) {
          this.isLive = true;
          const live = this.form!.instance.getEditor("live");
          live?.option("disabled", true);
          const platformAgency = this.form!.instance.getEditor("platformAgency");
        platformAgency?.option("disabled", true);
        }
        this.monitor.handleSuccess("Sucessfully updated Agency.");
        return true;
      }
    };
  
    if (formData.live && !this.isLive) {
      // Open modal and provide a callback
      await new Promise<void>((resolve) => {
        this.modals.confirm('confirmGoLive', (confirmation) => {
          // If the user confirms, continue with the form submission
          if (confirmation) {
            resolve();
          } else {
            // Reject the promise if the user doesn't confirm
            resolve(Promise.reject());
          }
        });
      });
  
      return await handleFormSubmission(); // Return the result after confirmation
    } else {
      return await handleFormSubmission(); // Return the result without confirmation
    }
  }
}
