<div class="standard-background">
    <app-progress [value]="loading" [splash]="true">
        <div class="page-content">
	        <div class="tab-container left">
		        <div class="header justify-between no-wrap">
			        <div class="left-panel">
				        <img src="assets/images/reports/ReportsIcon.png" class="img"/><span class="title">Reports</span>
			        </div>
		        </div>
		        <div class="body">
			        <ng-template class="tab-list" ngFor [ngForOf]="reportCategory" let-section>
				        <p class="tab section-name">{{section}}</p>
				        <ng-template ngFor [ngForOf]="reports" let-report>
							<div class="tab">
								<a class="menu-name selected" *ngIf="report.reportMenuSectionName === section && report.reportId === selectedReport.reportId" (click)="loadTab(report)">{{report.reportMenuName}}</a>
								<a class="menu-name" *ngIf="report.reportMenuSectionName === section && report.reportId !== selectedReport.reportId" (click)="loadTab(report)">{{report.reportMenuName}}</a>
							</div>
				        </ng-template>
			        </ng-template>
		        </div>
	        </div>

			<div class="tab-container right" *ngIf="selectedReportData && !noContent">
				<div class="header justify-between no-wrap">
					<div class="report-title"><h4>{{selectedReport.reportTitle}}</h4></div>
					<div class="report-sub-title">{{selectedReport.reportSubTitle}}</div>
				</div>
				<div class="body" style="padding-top: 30px">
					<div style="clear: both; float: right; display: block;">
						<app-generic-filters [filters]="budgetFilters" [submit]="filterSubmit" [isGeneric]="isGeneric" [renderFilters]="renderFilters"></app-generic-filters>
					</div>
					<div style="clear: both; display: block;">
						<app-generic-report [reportData]="selectedReportData" [reportType]="selectedReport.reportDisplayTypeId" [columnsMetadata]="columnsMetadata" [renderReport]="renderReport"></app-generic-report>
					</div>
				</div>
			</div>
            <div class="tab-alternate-content" *ngIf="noContent">
                No report data could be retrieved. 
            </div>
        </div>
    </app-progress>
</div>
