<style>
    .auth0-lock {
        font-family: "Rubik", serif !important;
        font-style: normal;
        font-weight: 500;
    }

    .auth0-lock.auth0-lock-opened {
        background-image: url('../../../../assets/images/sherpa-bg.jpg');
        background-size: cover;
    }

    .auth0-lock.auth0-lock.auth0-lock-opened .auth0-lock-overlay {
        opacity: 0.0 !important;
    }

    .auth0-lock .auth0-lock-header {
        background: #F26363 !important;
    }

    .auth0-lock .auth0-lock-header-bg.auth0-lock-blur-support {
        display: none;
    }

    .auth0-lock .auth0-lock-name {
        color: #000;
    }

    .auth0-lock .auth0-lock-header-welcome {
        filter: invert(1);
    }
</style>
<script src="https://cdn.auth0.com/js/lock/11.32/lock.min.js"></script>
<div id="auth0-lock-container-1" class="auth0-lock-container">
    <div data-reactroot="" class="auth0-lock auth0-lock-opened" lang="en" style="font-size: 1rem;">
        <div class="auth0-lock-overlay"></div>
        <div class="auth0-lock-center">
            <form class="auth0-lock-widget" method="post" novalidate="" [formGroup]="formGroup" (ngSubmit)="reset()">
                <div class="auth0-lock-widget-container">
                    <div class="auth0-lock-cred-pane auth0-lock-quiet">
                        <div class="auth0-lock-cred-pane-internal-wrapper">
                            <div class="auth0-lock-content-wrapper">
                                <div class="auth0-lock-header">
                                    <span id="undefined-back-button" role="button" (click)="login()"
                                        tabindex="0" class="auth0-lock-back-button" aria-label="back"><svg
                                            aria-hidden="true" focusable="false" enable-background="new 0 0 24 24"
                                            version="1.0" viewBox="0 0 24 24" xml:space="preserve"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"><!-- react-text: 69 -->
                                            <!-- /react-text -->
                                            <polyline fill="none" points="12.5,21 3.5,12 12.5,3 " stroke="#000000"
                                                stroke-miterlimit="10" stroke-width="2"></polyline>
                                            <!-- react-text: 71 --> <!-- /react-text -->
                                            <line fill="none" stroke="#000000" stroke-miterlimit="10" stroke-width="2"
                                                x1="22" x2="3.5" y1="12" y2="12"></line><!-- react-text: 73 -->
                                            <!-- /react-text -->
                                        </svg>
                                    </span>
                                    <div class="auth0-lock-header-bg auth0-lock-blur-support">
                                        <div class="auth0-lock-header-bg-blur"
                                            style="background-image: url(&quot;assets/images/icons/yak@3x.png&quot;);">
                                        </div>
                                        <div class="auth0-lock-header-bg-solid"
                                            style="background-color: rgb(242, 99, 99);"></div>
                                    </div>
                                    <div class="auth0-lock-header-welcome"><img alt="" class="auth0-lock-header-logo"
                                            src="assets/images/icons/yak@3x.png">
                                        <div class="auth0-lock-name" title="Reset your password">Reset your password
                                        </div>
                                    </div>
                                </div>
                                <div class="auth0-lock-content-body-wrapper" style="margin-top: 0px;">
                                    <div>
                                        <div></div>
                                    </div>
                                    <div style="position: relative;"><span>
                                            <div class="" style="">
                                                <div style="visibility: inherit;">
                                                    <div class="auth0-lock-view-content">
                                                        <div style="position: relative;">
                                                            <div class="auth0-lock-body-content">
                                                                <div class="auth0-lock-content">
                                                                    <div class="auth0-lock-form">
                                                                        <div>
                                                                            <div>
                                                                                <div>
                                                                                    <p><span>Please enter your email
                                                                                            address. We will send you an
                                                                                            email to reset your
                                                                                            password.</span></p>
                                                                                    <div
                                                                                        class="auth0-lock-input-block auth0-lock-input-username">
                                                                                        <div
                                                                                            class="auth0-lock-input-wrap auth0-lock-input-wrap-with-icon">
                                                                                            <svg aria-hidden="true"
                                                                                                focusable="false"
                                                                                                width="13px"
                                                                                                height="14px"
                                                                                                viewBox="0 0 15 16"
                                                                                                version="1.1"
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                                                class="auth0-lock-icon auth0-lock-icon-box">
                                                                                                <g stroke="none"
                                                                                                    stroke-width="1"
                                                                                                    fill="none"
                                                                                                    fill-rule="evenodd">
                                                                                                    <g transform="translate(-11.000000, -1471.000000)"
                                                                                                        fill="#888888">
                                                                                                        <path
                                                                                                            d="M25.552,1486.998 L11.449,1486.998 C10.667,1485.799 10.984,1483.399 11.766,1482.6 C12.139,1482.219 14.931,1481.5 16.267,1481.172 C14.856,1480.076 13.995,1478.042 13.995,1476.103 C13.995,1473.284 14.813,1470.999 18.498,1470.999 C22.182,1470.999 23,1473.284 23,1476.103 C23,1478.037 22.145,1480.065 20.74,1481.163 C22.046,1481.489 24.88,1482.228 25.241,1482.601 C26.019,1483.399 26.328,1485.799 25.552,1486.998 L25.552,1486.998 Z M24.6,1483.443 C24.087,1483.169 21.881,1482.548 20,1482.097 L20,1480.513 C21.254,1479.659 21.997,1477.806 21.997,1476.12 C21.997,1473.841 21.414,1471.993 18.499,1471.993 C15.583,1471.993 15,1473.841 15,1476.12 C15,1477.807 15.744,1479.662 17,1480.515 L17,1482.112 C15.109,1482.556 12.914,1483.166 12.409,1483.442 C12.082,1483.854 11.797,1485.173 12,1486 L25,1486 C25.201,1485.174 24.922,1483.858 24.6,1483.443 L24.6,1483.443 Z">
                                                                                                        </path>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </svg><input type="text"
                                                                                                formControlName="username"
                                                                                                name="username"
                                                                                                class="auth0-lock-input"
                                                                                                placeholder="username/email"
                                                                                                autocomplete="off"
                                                                                                autocapitalize="off"
                                                                                                spellcheck="off"
                                                                                                autocorrect="off"
                                                                                                aria-label="User name"
                                                                                                aria-invalid="false">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </span></div>
                                </div>
                            </div><button id="1-submit" class="auth0-lock-submit" name="submit" type="submit"
                                aria-label="Send Email" style="background-color: rgb(242, 99, 99); display: block;">
                                <div class="auth0-loading-container">
                                    <div class="auth0-loading"></div>
                                </div>
                                <span class="auth0-label-submit">
                                    <!-- react-text: 34 -->{{ sent ?? 'Send Email' }}<!-- /react-text -->
                                    <svg aria-hidden="true" focusable="false" class="icon-text"
                                        width="8px" height="12px" viewBox="0 0 8 12" version="1.1"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g id="Web/Submit/Active" transform="translate(-148.000000, -32.000000)"
                                                fill="#FFFFFF">
                                                <polygon id="Shape"
                                                    points="148 33.4 149.4 32 155.4 38 149.4 44 148 42.6 152.6 38">
                                                </polygon>
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>