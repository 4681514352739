<div class="form-wrapper confirm-dialog" *ngIf="!errorMessage">
    <div class="order-approvers-section" *ngIf="approvalDetails?.hasManualApproval && approvalDetails!.manualApprovalDetails!.levelApprovers">
        <h4>Requisition Approvers</h4>
        <app-display-approver-list [levelApprovers]="approvalDetails!.manualApprovalDetails!.levelApprovers"></app-display-approver-list>
    </div>
    <div class="deadline-section" *ngIf="approvalDetails?.hasManualApproval">
        <h4>Deadline <span class="bold" *ngIf="!approvalDetails?.manualApprovalDetails?.isDeadlineOptional">*</span></h4>
        <dx-date-box [width]="200"></dx-date-box>
    </div>
	<div class="comments-section" *ngIf="approvalDetails?.hasManualApproval">
		<h4>Comments for Approvers</h4>
		<dx-text-area #commentBox
		              [(value)]="commentBoxValue"
		              placeholder="These comments will be showing to the approvers listed above"
		></dx-text-area>
	</div>
	<div style="text-align: left; margin-top: 30px; margin-bottom: 30px; font-weight: 400; font-size: 18px;" *ngIf="!approvalDetails?.hasManualApproval">
        <h4>This requisition will be automatically approved.</h4>
	</div>
	<div class="cc-section">
		<h4>CC: Additional Contacts</h4>
		<dx-text-area #ccBox
		              [(value)]="ccBoxValue"
		              placeholder="Enter one email address per line"
		></dx-text-area>
	</div>
    <div class="disclaimer">
        <div class="disclaimer-image">
            <img width="20px" height="20px" src="assets/images/icons/info.png">
        </div>
        <div class="disclaimer-text">This is a price estimate, it does not include tax, shipping or other price adjustments by supplier.</div>
    </div>
    <div class="buttons-container">
        <dx-button class="btn primary" (onClick)="verifySubmit()">Submit</dx-button>
        <dx-button class="btn" (onClick)="closeModal('approval-modal')">Close</dx-button>
    </div>
</div>
<div class="error-message-wrapper" *ngIf="errorMessage">
    <p>{{errorMessage}}</p>
</div>