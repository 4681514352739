import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, lastValueFrom } from "rxjs";
import { FormularyApiConfig } from "../api";
import { Formulary, FormularyProduct, FormularyProductAction } from "../models";

export class FormularyLoadOptions {
  public products?: boolean | undefined = undefined;
  public supplier?: boolean | undefined = undefined;
  public coreProducts?: boolean | undefined = undefined;
  public agencyId?: number | undefined = undefined;
  public formularyType?: string | undefined = undefined;
  public practice?: boolean | undefined = undefined;
}

@Injectable()
export class FormularyService {

  constructor(private http: HttpClient) { }

  private buildParams(options: FormularyLoadOptions | undefined): HttpParams {
    let params = new HttpParams();

    if (options) {
      if (options.products) {
        params = params.append('includeproducts', true);
      }
      if (options.supplier) {
        params = params.append('includesupplier', true);
      }
      if (options.coreProducts) {
        params = params.append('includecoreproduct', true);
      }
      if (options.agencyId) {
        params = params.append('agencyId', options.agencyId);
      }
      if (options.formularyType !== undefined) {
        params = params.append('formularyType', options.formularyType);
      }
      if (options.practice) {
        params = params.append('includepractice', true);
      }
    }
    return params;
  }

  public getFormularies(options: FormularyLoadOptions | undefined): Promise<Formulary[]> {
    const params = this.buildParams(options);
    return lastValueFrom(this.http.get<Formulary[]>(FormularyApiConfig.getFullUrl(''), { params }));
  }
  public getFormulary(id: number, options: FormularyLoadOptions | undefined): Promise<Formulary> {
    const params = this.buildParams(options);
    return lastValueFrom(this.http.get<Formulary>(FormularyApiConfig.getFullUrl(`${id}`), { params }));
  }

  public updateFormulary(id: number, value: Formulary): Promise<Formulary> {
    return lastValueFrom(this.http.put<Formulary>(FormularyApiConfig.getFullUrl(`${id}`), value));
  }

  public createFormulary(value: Formulary): Promise<Formulary> {
    return lastValueFrom(this.http.post<Formulary>(FormularyApiConfig.getFullUrl(''), value));
  }

  public updateFormularyProducts(id: number, values: FormularyProductAction[]): Promise<Formulary> {
    return lastValueFrom(this.http.patch<Formulary>(FormularyApiConfig.getFullUrl(`${id}/products`), values));
  }

  public searchFormulary(id: number, params: HttpParams): Promise<any> {    
    return lastValueFrom(this.http.get<any>(FormularyApiConfig.getFullUrl(`list/${id}`), { params }));
  }
  public searchFormularyComposite(id: number, params: HttpParams): Promise<any> {    
    return lastValueFrom(this.http.get<any>(FormularyApiConfig.getFullUrl(`list/account/${id}/product`), { params }));
  }
  public exportFormularyProducts(id: number, params: HttpParams): Promise<any> {    
    return lastValueFrom(this.http.get<any>(FormularyApiConfig.getFullUrl(`export/${id}`), { params }));
  }

}