<app-progress [value]="working" [splash]="false">

    <dx-tree-list [dataSource]="accounts" [columnAutoWidth]="true" keyExpr="id" class="mb-large" parentIdExpr="parentId"
        [wordWrapEnabled]="true" [showBorders]="true" [showColumnLines]="true" (onCellPrepared)="onCellPrepared($event)"
        (onContentReady)="onContentReady($event)" (onCellClick)="selectAccount($event)"
        [disabled]="!!working">


        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-filter-row [visible]="true" [applyFilter]="'auto'"></dxo-filter-row>

        <dxi-column sortOrder="asc" dataField="name" dataType="string"
            caption="Account Name"></dxi-column>
    </dx-tree-list>
</app-progress>