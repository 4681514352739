import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { RequisitionApiConfig } from '../api/requisition.api.config';
import { IOptimizeOrderLine } from '../models/optimize.order.line.model';
import IGenericApiResponse from '../models/generic-response.model';
import { ISubmitRequisitionRequest } from '../models/submit.requisition.request.model';

@Injectable({ providedIn: 'root' })
export class RequisitionService {
  constructor(
    private http: HttpClient
  ) {

  }

  public GetOrderOptimization(accountId: number, requisitionId: number): Promise<IGenericApiResponse> {
    return lastValueFrom(this.http.get<IGenericApiResponse>(RequisitionApiConfig.getFullUrl(`account/${accountId}/optimization/requisition/${requisitionId}`)));
  }

  public SwapSupplierProduct(accountId: number, requisitionId: number, lineId: number, supplierProductId: number): Promise<IGenericApiResponse> {
    let body = 
    {
      requisitionId: requisitionId,
      requisitionLineId: lineId,
      supplierProductId: supplierProductId
    }

    return lastValueFrom(this.http.patch<IGenericApiResponse>(RequisitionApiConfig.getFullUrl(`account/${accountId}/optimization/swap`), body));
  }

  public GetRequisitionApprovalDetails(accountId: number, requisitionId: number): Promise<IGenericApiResponse> {
    return lastValueFrom(this.http.get<IGenericApiResponse>(RequisitionApiConfig.getFullUrl(`account/${accountId}/optimization/requsition/${requisitionId}/submit`), {}));
  }

  public SubmitRequisition(requisitionSubmission: ISubmitRequisitionRequest, accountId: number): Promise<IGenericApiResponse> {

    return lastValueFrom(this.http.patch<IGenericApiResponse>(RequisitionApiConfig.getFullUrl(`account/${accountId}/optimization/requsition/${requisitionSubmission.requisitionId}/submit`), requisitionSubmission));
  }

  public getApprovalDetails(accountId: number, requisitionId: number): Promise<IGenericApiResponse> {

    return lastValueFrom(this.http.get<IGenericApiResponse>(RequisitionApiConfig.getFullUrl(`account/${accountId}/requsition/${requisitionId}/approval`)));
  }

  public submitApproval(accountId: number, requisitionId: number, approved: boolean, comments: string = "", requisitionJson: string = ""): Promise<IGenericApiResponse> {
    let body = {
      approve: approved,
      comments: comments,
      requisitionJson: requisitionJson
    }

    return lastValueFrom(this.http.post<IGenericApiResponse>(RequisitionApiConfig.getFullUrl(`account/${accountId}/requsition/${requisitionId}/approval`), body));
  }
}