import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IAccount, Cart, CartLine,  } from '../models';
import { MonitorService } from './monitor.service';
import { OrderService } from './order.service';
import { AgentService } from './agent.service';
import { AccountType } from "../models/account.model";
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class CartService
{
	_cart: Cart;
	_account: IAccount;
	_currentLineId: number | undefined;
	allowUpdate: boolean = true;

	// public working = 0;
	refreshTimer: any;
	refreshInterval = 30000;

	private pendingApprovalsValue: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	public pendingApprovals: Observable<number> = this.pendingApprovalsValue.asObservable();

	public async incrementPendingApporval(){
		this.pendingApprovalsValue.next(this.pendingApprovalsValue.getValue() + 1);
	}

	constructor(
		private monitor: MonitorService,
		private orderService: OrderService,
		private agentService: AgentService,
		private activatedRoute: ActivatedRoute
	)
	{
		this.resetRefresh();
		this._account = this.agentService.currentAccount!;
		this._cart = new Cart();
	}

	private cartChangeSubject: Subject<Cart> = new BehaviorSubject<Cart>(this.cart);
	public cartChangedObservable$: Observable<Cart> = this.cartChangeSubject.asObservable();

	getCurrentLineId(): number
	{
		return this._currentLineId!;
	}

	setAllowUpdateBool(bool: boolean): void
	{
		this.allowUpdate = bool
	}

	getAllowUpdateBool(): boolean
	{
		return this.allowUpdate!;
	}

	cartForceUpdate(cart: Cart): void
	{
		this.setAllowUpdateBool(false)
		this.cartChangeSubject.next(cart);
		this.setAllowUpdateBool(true);
	}

	public get cart(): Cart
	{
		return this._cart;
	}

	public set cart(value: Cart)
	{
		this._cart = value;
		value.practiceId = this._account!.id;
		this.cartChangeSubject.next(value);
		this.refreshCart();
		this.resetRefresh();
	}

	public async addLine(value: CartLine): Promise<CartLine | undefined>
	{

		const cart = this.cart;

		if (cart)
		{
			const newLines = await this.orderService.createPurchaseRequisitionLines(this.account!.id, [
				{
					id: value.id,
					purchaseRequisitionId: this._cart!.id,
					coreProductId: value.coreProductId,
					price: undefined,
					quantity: value.quantity,
					supplierProductId: value.supplierProductId
				}
			]);

			if (!cart.id)
			{
				cart.id = newLines[0].purchaseRequisitionId
			}
			newLines.forEach(l =>
			{
				const line = cart.lines.find(cl => cl.id === l.id);
				if (line)
				{
					line.coreProduct = l.coreProduct;
					line.coreProductId = l.coreProductId;
					line.coreProductName = l.coreProduct?.name;
					line.modified = l.modified;
					line.modifiedBy = l.modifiedBy;
					line.price = l.price;
					line.productListing = l.coreProduct?.listing?.name;
					line.quantity = l.quantity ?? 0;
					line.supplierProduct = l.supplierProduct;
					line.supplierProductId = l.supplierProductId;
					line.supplierProductName = l.supplierProduct?.name;
				}
				else
				{
					cart.lines.push({
						id: l.id,
						quantity: l.quantity ?? 0,
						uom: 'EA',
						coreProduct: l.coreProduct,
						coreProductId: l.coreProductId,
						coreProductName: l.coreProduct?.name,
						created: l.created,
						createdBy: l.createdBy,
						modified: l.modified,
						modifiedBy: l.modifiedBy,
						price: l.price,
						productListing: l.coreProduct?.listing?.name,
						supplierProduct: l.supplierProduct,
						supplierProductId: l.supplierProductId,
						supplierProductName: l.supplierProduct?.name
					} as CartLine);
				}
			});
			this._currentLineId = value.id;
			this.setAllowUpdateBool(false);

			this.cartChangeSubject.next(cart);

			this.setAllowUpdateBool(true);
			this._currentLineId = undefined;

			this.resetRefresh();
		}
		else
		{
			this.monitor.logException('No active cart');
		}

		return cart?.lines.find(l => l.coreProductId === value.coreProductId && l.supplierProductId === value.supplierProductId);
	}

	public async removeLine(value: CartLine): Promise<any>
	{
		const cart = this.cart;
		if (cart)
		{
			for (let i = 0; i < cart.lines.length; ++i)
			{
				await this.orderService.removePurchaseRequisitionLines(this.account!.id, value.id);
				if (cart.lines[i].id === value.id)
				{
					cart.lines.splice(i, 1);
					this.cartChangeSubject.next(cart);
					break;
				}
			}
		}
		else
		{
			this.monitor.logException('No active cart');
		}
	}

	public async updateLine(value: CartLine): Promise<CartLine | undefined>
	{
		const cart = this.cart;
		if (cart)
		{
			const lines = await this.orderService.updatePurchaseRequisitionLines(this.account!.id, [
				{
					id: value.id,
					purchaseRequisitionId: this._cart!.id,
					coreProductId: value.coreProductId,
					price: value.price,
					quantity: value.quantity,
					supplierProductId: value.supplierProductId,
				}
			]);

			lines.forEach(l =>
			{
				const line = cart.lines.find(cl => cl.id === l.id);
				if (line)
				{
					line.coreProduct = l.coreProduct;
					line.coreProductId = l.coreProductId;
					line.coreProductName = l.coreProduct?.name;
					line.modified = l.modified;
					line.modifiedBy = l.modifiedBy;
					line.price = l.price;
					line.productListing = l.coreProduct?.listing?.name;
					line.quantity = l.quantity ?? 0;
					line.supplierProduct = l.supplierProduct;
					line.supplierProductId = l.supplierProductId;
					line.supplierProductName = l.supplierProduct?.name;
				}
				else
				{
					cart.lines.push({
						id: l.id,
						coreProduct: l.coreProduct,
						coreProductId: l.coreProductId,
						coreProductName: l.coreProduct?.name,
						modified: l.modified,
						modifiedBy: l.modifiedBy,
						price: l.price,
						productListing: l.coreProduct?.listing?.name,
						quantity: l.quantity ?? 0,
						supplierProduct: l.supplierProduct,
						supplierProductId: l.supplierProductId,
						supplierProductName: l.supplierProduct?.name,
						uom: 'EA'
					} as CartLine);
				}
			});
			this._currentLineId = value.id;
			this.setAllowUpdateBool(false);

			this.cartChangeSubject.next(cart);

			this.setAllowUpdateBool(true);
			this._currentLineId = undefined;

			// } else {
			//     this.monitor.logException('Line not found');
			// }
		}
		else
		{
			this.monitor.logException('No cart active');
		}
		this.resetRefresh();

		return cart?.lines.find(l => l.coreProductId === value.coreProductId && l.supplierProductId === value.supplierProductId);
	}

	public async emptyCart(): Promise<any>
	{
		const cart = this.cart;
		const account = this.agentService.currentAccount;
		if (cart && account)
		{
			await this.orderService.updatePurchaseRequisitionLines(account.id, []);
			cart.lines = [];
			this.resetRefresh();
		}
		else
		{
			this.monitor.logException('No cart active');
		}
	}

	public get account(): IAccount | undefined
	{
		return this._account;
	}


	public set account(value: IAccount | undefined)
	{
		if (value)
		{ // don't clear on an undefined value, just leave as is
			if (this._account?.id !== value.id)
			{
				this._account = value;
				if (this._account?.id)
				{
					this._cart!.lines = []; // clear it so it never looks like the old account
					this._cart!.id = 0;
					this._cart!.modifier = 0;

					setTimeout(async() =>
					{
						await this.refreshCart();
					}, 1);

					this.resetRefresh();
				}
				else
				{
					this.cart = new Cart();
					this.cart.id = 0;
					this.cart.practiceId = 0;
					this.cart.lines = [];
				}
				this.cartChangeSubject.next(this.cart);
			}
		}
	}

	async refreshCart()
	{
		if (!this._account)
		{
			this._account = this.agentService.currentAccount!;
		}

		if (this._account && this._account.accountType === AccountType.PRACTICE)
		{
			try
			{
				const req = await this.orderService.refreshOpenPurchaseRequisition(this._account!.id, this._cart?.id, this._cart?.modifier);

				this.pendingApprovalsValue.next(req.pendingApprovalsCount);

				if (req.practiceId !== this._account.id)
				{
					// value flipped whil loading, so abort
					return;
				}

				// if the cart has changed, update the cart observable
				if (!this._cart || this._cart.id !== req.id || this._cart.modifier !== req.modifier)
				{
					this._cart = req;
					this.cartChangeSubject.next(this._cart);
				}
			}
			catch (err)
			{
				this.monitor.handleError(err, 'Error refreshing cart');
			}
			finally
			{
				// this.working = 0;
			}
		}
	}

	resetRefresh(): void
	{
		if (this.refreshTimer)
		{
			clearInterval(this.refreshTimer);
		}

		this.refreshTimer = setInterval(async () =>
		{
			await this.refreshCart();
		}, this.refreshInterval);
	}
}