import { Component, Input } from '@angular/core';

export class SplashOptions {
  title: string | undefined = undefined;
  message: string | undefined = undefined;
}

@Component({
  selector: 'app-progress',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {

  @Input()
  splash = false;

  @Input()
  splashOptions: SplashOptions | undefined = undefined;

  @Input()
  speed: 'slow' | 'medium' | 'fast' = 'medium';

  @Input()
  type: 'spinner' | 'bar' = 'bar';

  @Input()
  fit = false;

  @Input()
  get value(): number | boolean | undefined {
    return this._value;
  }
  set value(val: number | boolean | undefined) {
    let startNew = false;
    if (!this._value) {
      startNew = true;
    }

    this._value = val;
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
      this.startHide = false;
    }

    if (this.display !== this.show()) {
      if (this.display) { // flipped to hide
        if (this.splash) {
          this.startHide = true;
          this.timer = setTimeout(() => {
            this.display = false;
          }, 150);
        } else {
          this.display = false;
        }
      } else { // flipped to show
        if (this.splash) {
          this.timer = setTimeout(() => {
            this.display = true;
          }, 200);
        } else {
          this.display = true;
          if (startNew) {
            this._value = 0;
            this.timer = setTimeout(() => {
              this._value = val;
            }, 200);
          }
        }
      }
    }
  }

  // dots: string[] = [];

  private timer: any | undefined;

  private _value: number | boolean | undefined = undefined;
  display = false;
  startHide = false;

  constructor() {
    // this.dots = ['.'];
  }

  show(): boolean {
    return !!this.value;
  }

  // updateDots(): void {
  //   if (this.maxDots > 1) {
  //     this.dots.push('.');
  //     if (this.dots.length > this.maxDots) {
  //       this.dots = ['.'];
  //     }
  //   }
  // }
}
