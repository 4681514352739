import { Component, Inject, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { User } from '@auth0/auth0-angular';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AccountType, Agent, IAccount, Profile } from './shared/models';
import { LoginService, ModalService, AgentService, CartService, CatalogService, NavigationService } from './shared/services';
import { MonitorService } from './shared/services/monitor.service';
import { DropdownContent } from './shared/user-type';
import packageJson from 'package.json';
import { Feature, FeatureService } from './shared/services/feature.service';
import { DOCUMENT } from '@angular/common';
import { AccountService } from './shared/services/account.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  user: User | undefined;
  dropdownContent: DropdownContent[] = [];
  myAccount: DropdownContent | undefined;
  agent: Agent | undefined = undefined;
  working = false;
  nochromeMode = false;
  loadingMessage = '';
  mobileexpanded = false;
  accountType = AccountType;
  public version: string = packageJson.version;
  feature = Feature;

  profile = Profile;
  selectedProfile: Profile | undefined;
  selectedAccount: IAccount | undefined;
  private subscriptions: Subscription[] = [];

  private reservedPaths: string[] = ['/home', '/recover-password', '/login'];

  constructor(
    private loginService: LoginService,
    private agentService: AgentService,
    private accountService: AccountService,
    private router: Router,
    private gtm: GoogleTagManagerService,
    private monitor: MonitorService,
    private titleService: Title,
    public modals: ModalService,
    public featureService: FeatureService,
    private cartService: CartService,
    private navigationService: NavigationService,
    @Inject(DOCUMENT) public document: Document
  ) {
    // check app version
    if (localStorage.getItem('version') !== this.version) {
      const loggedIn = !!loginService.authenticatedUser;
      localStorage.clear();
      sessionStorage.clear();
      var cookies = document.cookie.split(';');
      // set past expiry to all cookies
      for (var i = 0; i < cookies.length; i++) {
        document.cookie = cookies[i] + "=; expires=" + new Date(0).toUTCString();
      }
      localStorage.setItem('version', this.version);
      if (loggedIn) {
        document.location = `${document.location.origin}/home`;
      }
    }

    this.user = this.loginService.authenticatedUser;
    this.agent = this.agentService.authenticatedAgent;

    if (!this.agent) {
      this.preservePath();
    }

    this.subscriptions.push(this.agentService.authenticated$.subscribe(async u => {
      try {
        this.working = true;
        this.agent = u;
        this.user = this.loginService.authenticatedUser;
        document.body.setAttribute('user-id', this.user?.sub ?? 'null');
        this.agent = this.agentService.authenticatedAgent;
        this.myAccount = {
          name: 'My Actions',
          claims: [],
          children: [
          ],
        };
        this.monitor.logEvent('login', { name: this.user?.name });
      } catch (error) {
        this.monitor.handleError(error, 'Error authenticating');
      } finally {
        this.working = false;
      }
    }));

    this.subscriptions.push(
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map(async () => {
          this.working = true;
          let route: ActivatedRoute = this.router.routerState.root;

          if(!this.reservedPaths.some(p => window.location.pathname.includes(p))){

            let profile = this.agentService.currentProfile;
            let account = this.agentService.currentAccount;
  
            if(profile != this.selectedProfile) {
              this.selectedProfile = profile;
              if (this.selectedProfile === Profile.Platform) {
                monitor.handleInfo('Switched to Sherpa Profile', undefined, '');
              } else if (this.selectedProfile === Profile.Practice && account) {
                monitor.handleInfo(account.name, undefined, 'Switched to Practice Profile');
                await this.cartService.refreshCart();
              } else if (this.selectedProfile === Profile.DSO && account) {
                monitor.handleInfo(account.name, undefined, 'Switched to DSO Profile');
              } else if (this.selectedProfile === Profile.GPO && account) {
                monitor.handleInfo(account.name, undefined, 'Switched to GPO Profile');
              } else {
                this.navigationService.public().message.invalidRoute(); //Not a valid profile route. Redirect to public
              }
              await agentService.trackProfileSwitch(account);
            }
  
            if(account && (!this.selectedAccount || account.id != this.selectedAccount.id)) {
              this.agentService.addAuthorizedAccount(account);
              this.accountService.loadCustomization(account.id);
              this.selectedAccount = account;
            }else if(this.selectedProfile == Profile.Platform){
              this.accountService.clearCustomizations();
              this.selectedAccount = undefined;
            } else if(!account && this.selectedProfile != Profile.Public) {
              this.accountService.clearCustomizations();
              this.navigationService.public().message.accountAccessInvalid();
            }
          }

          let routeTitle = '';
          while (route!.firstChild) {
            route = route.firstChild;
          }
          if (route.snapshot.data['title']) {
            routeTitle = route!.snapshot.data['title'];
          }

          if (routeTitle.indexOf(':') >= 0) {
            const r = route.snapshot.url.map((u) => u.path);
            r.splice(0, 1); // strip off the main route?

            routeTitle = `${routeTitle.substring(
              0,
              routeTitle.indexOf(':')
            )}${r.join('/')}`;
          }

          this.titleService.setTitle(routeTitle);
          const gtmTag = {
            event: 'page',
            pageName: route.snapshot.url,
          };
          this.gtm.pushTag(gtmTag);

          route.queryParams.subscribe(params => {
            if (params.nochrome === 'true') {
              document.body.classList.add('no-chrome');
            }
          });

          window.scrollTo({ top: 0, left: 0 });

          this.working = false;

          return routeTitle;
        })
      ).subscribe({
        next: async (title: Promise<string>) => {
          let newTitle = await title;
          if (newTitle) {
            this.titleService.setTitle(newTitle);
          }
        },
        error: (err) => {
          this.monitor.handleError(err, 'Error routing to page');
        },
      })
    );

    this.subscriptions.push(
      this.loginService.loggedOutObservable$.subscribe(lo => {
        if (lo) {
          this.agentService.unauthenticate();
          this.navigationService.root().login();
        }
      }));
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  preservePath() {
    const r = this.reservedPaths.find(p => p === window.location.pathname.toLowerCase());
    if (!r) {
      this.loginService.initialize(window.location.pathname);
    }
  }

  async login(): Promise<any> {
    try {
      this.working = true;
      await this.loginService.login(this.loginService.initialUrl() ?? '/public/login');
    } catch (error) {
      this.monitor.handleError(error, 'Error during login');
    } finally {
      this.working = false;
    }
  }

  reauthenticate(): void {
    this.loginService.reauthenticate();
  }

  logout(): void {
    this.loginService.logout();
  }

  changeLogin(): void {
    this.loginService.logout();
  }

  toggleMenu(value: boolean): void {
    this.mobileexpanded = value;
  }

  get copyright(): Date {
    return new Date();
  }

  toReleaseNotes() {
    this.navigationService.root().releaseNotes();
  }
}
