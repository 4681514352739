import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Agency, Formulary, FormularyType } from 'src/app/shared/models';
import { AgentService, LoginService, MonitorService, NavigationService } from 'src/app/shared/services';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { Feature } from 'src/app/shared/services/feature.service';
import { FormularyService } from 'src/app/shared/services/formulary.service';
import { GenericComponent } from 'src/app/shared/utils/generic.component';

@Component({
  selector: 'app-formulary-edit',
  templateUrl: './formulary-edit.component.html',
  styleUrls: ['./formulary-edit.component.scss']
})
export class FormularyEditComponent extends GenericComponent implements OnInit, OnDestroy {

  formulary: Formulary | undefined;

  showBackButton: boolean = false;

  feature = Feature;
  subscriptions: Subscription[] = [];
  formularyName: string | undefined;

  constructor(
    private agencyService: AgencyService,
    private agentService: AgentService,
    private formularyService: FormularyService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    monitor: MonitorService,
    login: LoginService
  ) {
    super(monitor, login);
  }

  appAccessNavigationOptions: { feature: Feature, aTagId: string, aTagText: string, action: Function }[] = [
    {
      feature: Feature.AgencyUpdate,
      aTagId: 'details',
      aTagText: 'Details',
      action: () => this.navigationService.backoffice().formulary.details(this.formulary!.id)
    },
    {
      feature: Feature.AgentUpdate,
      aTagId: 'products',
      aTagText: 'Products',
      action: () => this.navigationService.backoffice().formulary.products(this.formulary!.id)
    }
  ]

  ngOnInit() {
    const state = history.state;
    this.showBackButton = state && state.fromSpecificPage;

    this.subscriptions.push(this.route.params.subscribe(async (params) => {
      // If a agency Id was supplied in the route populate the form fields with current values
      this.working = 1;
      try {
        if (params.formularyId !== undefined && params.formularyId !== 'new') {
          this.formulary = await this.formularyService.getFormulary(params.formularyId, {});
          this.formularyName = this.formulary?.name?.toLowerCase().includes("formulary") ? this.formulary.name : this.formulary.name + " Formulary";
        } else {
          this.formulary = {
            active: true,
            id: 0,
            name: '',
            agencyId: this.agentService.authenticatedAgent!.agency!.id,
            formularyType: FormularyType.Public // agency and custom formularies are created by default processing on those entities
          };
        }
      } catch (exception) {
        this.monitor.logException(exception);
        
        this.navigationService.backoffice().message.invalidRoute();
      } finally {
        this.working = 0;
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  async loadAgency(id: number): Promise<Agency | undefined> {
    var a: Agency | undefined = await this.agencyService.getAgencyById(id, { accounts: true, agents: true });
    a.agents?.forEach(ag => {
      if (ag.lastLogin) {
        ag.lastLogin = ag.lastLogin;
      }
    });
    return a;
  }

  onActivate(component: any) {
    component.formulary = this.formulary;
  }

  backToFormularies(){
    this.navigationService.backoffice().formulary.list();
  }

  goBack() {
    window.history.back();
  }
}
