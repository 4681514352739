<app-access [platformFeature]="feature.AccountUpdate" [redirectOnFailure]="true">
    <app-progress [splash]="true" [value]="working">
      <div class="account-edit flex-column">
        <div class="header flex-row no-wrap justify-between">
          <H3>Agencies</H3>
        </div>
        <div class="body info">
          <dx-data-grid [showBorders]="true" [showColumnLines]="true" [dataSource]="dataSource" [wordWrapEnabled]="false" (onContentReady)="onContentReady($event)" (onCellClick)="onCellClick($event)">
            <dxo-search-panel [visible]="true" [width]="240"></dxo-search-panel>
            <dxo-paging [pageSize]="10" [pageIndex]="0"></dxo-paging>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-filter-row [visible]="true" [applyFilter]="'auto'"></dxo-filter-row>
  
            <dxi-column [width]="400" id="agency" dataField="name" sortOrder="asc" [allowHeaderFiltering]="true"
              caption="Agency"></dxi-column>
            <dxi-column [alignment]="'center'" dataField="accounts.length" dataType="number" [width]="180"
              [allowHeaderFiltering]="true" caption="Accounts"></dxi-column>
            <dxi-column [alignment]="'center'" dataField="agents.length" dataType="number" [width]="180"
              [allowHeaderFiltering]="true" caption="Agents"></dxi-column>
            <dxi-column dataField="platformAgency" [width]="180" dataType="boolean" [falseText]="'No'" [trueText]="'Yes'"
              [allowHeaderFiltering]="true" caption="Platform Agency"></dxi-column>
            <dxi-column dataField="active" dataType="boolean" [alignment]="'left'" [falseText]="'No'"
              [trueText]="'Yes'" [allowHeaderFiltering]="false" [allowFiltering]="true" caption="Active">
            </dxi-column>
          </dx-data-grid>
        </div>
      </div>
    </app-progress>
  </app-access>
