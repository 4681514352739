import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { DropdownContent, DropdownItem } from '../../user-type';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NavigationService } from '../../services';

@Component({
  selector: 'app-drop-down-navigate',
  templateUrl: './drop-down-navigate.component.html',
  styleUrls: ['./drop-down-navigate.component.scss']
})
export class DropDownNavigateComponent  {

  expanded = false;

  @ViewChild('dropdown') dropdown?: ElementRef;
  @Input()
  content?: DropdownContent;
  @Input()
  buttonClass?: string;
  @Input()
  linkClass?: string;
  @Input()
  borderClass?: string;

  activeRoute?: string;

  @Output()
  selected: EventEmitter<DropdownItem> = new EventEmitter<DropdownItem>();

  expandedSubMenu: boolean = false;

  constructor(
    private renderer: Renderer2,
    private navigationService: NavigationService
  ) {

    this.navigationService.activeRoute$.subscribe(activeRoute => {
      this.activeRoute = activeRoute;
    });

    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.expanded && this.dropdown?.nativeElement) {
        var target: any = e.target;
        let found = false;
        while (target && target.type !== 'app-drop-down') {
          if (target !== this.dropdown.nativeElement) {
            target = target.parentElement;
          } else {
            found = true;
            break;
          }
        }
        if (!found) {
          this.expanded = false;
        }
      }
    });
  }

  click(item: DropdownItem): void {
    this.expanded = false;
    if (item.action) {
      item.action();
    }
    this.selected.emit(item);

  }

  toggleSubmenu(item: DropdownItem): void {
    item.expanded = !item.expanded;
  }

}
