<dx-tree-list id="BudgetReportGrid" *ngIf="reportType === reportTypes.TreeList && renderReport"
              [dataSource]="reportData" [noDataText]="_noDataMessage"
              [showBorders]="true" [showColumnLines]="true" [allowColumnResizing]="true" [wordWrapEnabled]="false">

    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-filter-row [visible]="true" [applyFilter]="'auto'"></dxo-filter-row>

    <dxi-column dataField="accountName" dataType="string" caption="Account" width="400px"></dxi-column>
    <dxi-column dataField="period" dataType="string" width="300px" cellTemplate="periodCellTemplate"></dxi-column>
    <dxi-column dataField="totalSpent" caption="Spent ($)" width="120px" alignment="right" format="currency"></dxi-column>
    <dxi-column dataField="budgetSpentPercent" caption="Spent (%)" width="120px" alignment="right"></dxi-column>
    <dxi-column dataField="purchaseOrdersCount" dataType="number" caption="Orders" width="120px" alignment="right"></dxi-column>
    <dxi-column dataField="totalPendingApproval" dataType="number" caption="Pending Approval" width="190px" alignment="right"></dxi-column>
	<dxi-column dataField="lastPurchaseOrderDate" dataType="date" [format]="'shortDate'" caption="Last Purchase"></dxi-column>

    <dxo-pager [visible]="true"
               [displayMode]="'full'"
               [showPageSizeSelector]="false"
               [showInfo]="true"
               [showNavigationButtons]="true">
    </dxo-pager>

    <div *dxTemplate="let cell of 'periodCellTemplate'">
        <div *ngIf="cell.data.period">
            {{ cell.data.period }}
            <br />
            <span style="color: #aaa">
                {{ cell.data.periodStart }} - {{ cell.data.periodEnd }}
            </span>
        </div>
    </div>

</dx-tree-list>

<dx-data-grid id="GenericReportGrid" *ngIf="reportType === reportTypes.DataGrid && renderReport"
              keyExpr="id"
              [dataSource]="reportData"
              [allowColumnReordering]="true"
              [allowColumnResizing]="true"
              [showColumnLines]="true"
              [wordWrapEnabled]="false"
              [showBorders]="true"
              [width]="'100%'"
              (onContentReady)="contentReady($event)">
    <dxo-filter-row [visible]="true" [applyFilter]="'auto'"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-search-panel [visible]="true"
                      [width]="240"
                      placeholder="Search..."></dxo-search-panel>
    <dxo-paging [pageSize]="100" [pageIndex]="0"></dxo-paging>
    <dxo-pager [visible]="true"
               [allowedPageSizes]="allowedPageSizes"
               [displayMode]="'full'"
               [showPageSizeSelector]="true"
               [showInfo]="true"
               [showNavigationButtons]="true">
    </dxo-pager>
</dx-data-grid>
