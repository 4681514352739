import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '@auth0/auth0-angular';
import { Agent, Profile } from 'src/app/shared/models';
import { AgentService, LoginService, NavigationService } from 'src/app/shared/services';
@Component({
  selector: 'app-generic-nav',
  templateUrl: './generic-nav.component.html',
  styleUrls: ['./generic-nav.component.scss']
})
export class GenericNavComponent {
  @Input()
  agent: Agent | undefined = undefined;
  @Input()
  user: User | undefined;
  @Input()
  nochromeMode = false;
  @Input()
  mobileexpanded = false;
  @Input()
  profile: Profile | undefined
  @Output()
  login: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(private loginService: LoginService, private navigationService: NavigationService, private agentService: AgentService, private activatedRoute: ActivatedRoute) { }
  async requestLogin(): Promise<any> {
    this.login.next(true);
  }
  toggleMenu(value: boolean): void {
    this.mobileexpanded = value;
  }
  logout(): void {
    this.loginService.logout();
  }
  toHome() {
    this.navigationService.root().home();
  }
}