import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { NameId, PlacePurchaseOrder, PurchaseOrder, PurchaseRequisition, PurchaseRequisitionLine, PurchaseRequisitionListView, RequisitionMessage, Submission, SubmissionMethodStatus, Cart } from '../models';
import { OrderApiConfig } from '../api/order.api.config';
import { PurchaseRequisitionWithApprovalsListView } from "../models/purchase.requisition.model";
import { PurchaseOrderListRecord } from "../models/purchase.order.model";

export class PurchaseOrderLoadOptions
{
	public submission?: boolean | undefined = undefined;
	public supplier?: boolean | undefined = undefined;
	public supplierProduct?: boolean | undefined = undefined;
}

export class PurchaseRequisitionLoadOptions
{
	public practice?: boolean | undefined = undefined;
	public coreProduct?: boolean | undefined = undefined;
	public supplierProduct?: boolean | undefined = undefined;
	public purchaseOrder?: boolean | undefined = undefined;
	public modified?: boolean | undefined = undefined;
}

@Injectable({ providedIn: 'root' })
export class OrderService
{
	constructor(
		private http: HttpClient
	)
	{
	}

	private buildOrderParams(options: PurchaseOrderLoadOptions | undefined): HttpParams
	{
		let params = new HttpParams();

		if (options)
		{
			if (options.submission)
			{
				params = params.append('includesubmission', true);
			}
			if (options.supplier)
			{
				params = params.append('includesupplier', true);
			}
			if (options.supplierProduct)
			{
				params = params.append('includesupplierproduct', true);
			}
		}
		return params;
	}

	private buildRequisitionParams(options: PurchaseRequisitionLoadOptions | undefined): HttpParams
	{
		let params = new HttpParams();

		if (options)
		{
			if (options.practice)
			{
				params = params.append('includepractice', true);
			}
			if (options.coreProduct)
			{
				params = params.append('includecoreproduct', true);
			}
			if (options.supplierProduct)
			{
				params = params.append('includesupplierproduct', true);
			}
			if (options.purchaseOrder)
			{
				params = params.append('includepurchaseorder', true);
			}
			if (options.modified)
			{
				params = params.append('includemodified', true);
			}
		}
		return params;
	}

	// get list of purchase requisitions
	public getPracticePurchaseRequisition(accountId: number, options: PurchaseRequisitionLoadOptions | undefined): Promise<PurchaseRequisitionListView[]>
	{
		const params = this.buildRequisitionParams(options);
		return lastValueFrom(this.http.get<PurchaseRequisitionListView[]>(OrderApiConfig.getFullUrl(`account/${accountId}/requisition`), { params }));
	}

	// get list of purchase requisitions
	public getPracticePurchaseRequisitionWithApprovals(accountId: number | undefined): Promise<PurchaseRequisitionWithApprovalsListView[]>
	{
		const params = {};

		return lastValueFrom(this.http.get<PurchaseRequisitionWithApprovalsListView[]>(OrderApiConfig.getFullUrl(`account/${accountId}/requisition-approvals`), { params }));
	}

	public getPendingPracticePurchaseRequisitionWithApprovals(accountId: number | undefined): Promise<PurchaseRequisitionWithApprovalsListView[]>
	{
		const params = {};

		return lastValueFrom(this.http.get<PurchaseRequisitionWithApprovalsListView[]>(OrderApiConfig.getFullUrl(`account/${accountId}/requisition-approvals/pending`), { params }));
	}

	public getRequisition(accountId: number, id: number, options: PurchaseRequisitionLoadOptions | undefined): Promise<PurchaseRequisition>
	{
		const params = this.buildRequisitionParams(options);
		return lastValueFrom(this.http.get<PurchaseRequisition>(OrderApiConfig.getFullUrl(`account/${accountId}/requisition/${id}`), { params }));
	}

	// refresh open requisition
	public refreshOpenPurchaseRequisition(accountId: number, requisitionId: number | undefined, modified: number | undefined = undefined): Promise<Cart>
	{
		let params: HttpParams = new HttpParams();
		if (modified)
		{
			params = params.append('lastModified', modified.toString());
		}
		return lastValueFrom(this.http.get<Cart>(OrderApiConfig.getFullUrl(`account/${accountId}/requisition/${requisitionId ?? 0}/refresh`), { params }));
	}

	// get currently open requisition
	public getOpenPurchaseRequisition(accountId: number, options: PurchaseRequisitionLoadOptions | undefined): Promise<PurchaseRequisition>
	{
		const params = this.buildRequisitionParams(options);
		return lastValueFrom(this.http.get<PurchaseRequisition>(OrderApiConfig.getFullUrl(`account/${accountId}/requisition/open`), { params }));
	}

	// update/create open requisition
	public updatePurchaseRequisition(accountId: number, requisition: PurchaseRequisitionLine[]): Promise<PurchaseRequisitionLine[]>
	{
		return lastValueFrom(this.http.post<PurchaseRequisitionLine[]>(OrderApiConfig.getFullUrl(`account/${accountId}/requisition/open`), requisition));
	}

	public createPurchaseRequisitionLines(accountId: number, requisitionLines: PurchaseRequisitionLine[]): Promise<PurchaseRequisitionLine[]>
	{
		return lastValueFrom(this.http.put<PurchaseRequisitionLine[]>(OrderApiConfig.getFullUrl(`account/${accountId}/requisition/open`), requisitionLines));
	}

	public updatePurchaseRequisitionLines(accountId: number, requisitionLines: PurchaseRequisitionLine[]): Promise<PurchaseRequisitionLine[]>
	{
		return lastValueFrom(this.http.post<PurchaseRequisitionLine[]>(OrderApiConfig.getFullUrl(`account/${accountId}/requisition/open`), requisitionLines));
	}

	public emptyOpenPurchaseRequisition(accountId: number): Promise<any>
	{
		return lastValueFrom(this.http.delete(OrderApiConfig.getFullUrl(`account/${accountId}/requisition/open/empty`), {}));
	}

	public removePurchaseRequisitionLines(accountId: number, lineId: number): Promise<any>
	{
		return lastValueFrom(this.http.delete(OrderApiConfig.getFullUrl(`account/${accountId}/requisition/open/${lineId}`), {}));
	}

	public submitPurchaseRequisition(accountId: number, id: number, value: PurchaseRequisition): Promise<PlacePurchaseOrder>
	{
		return lastValueFrom(this.http.post<PlacePurchaseOrder>(OrderApiConfig.getFullUrl(`account/${accountId}/requisition/${id}/requisition/submit`), value));
	}

	// get list of purchase orders
	public getPurchaseOrders(accountId: number, options: PurchaseOrderLoadOptions | undefined): Promise<PurchaseOrder[]>
	{
		const params = this.buildOrderParams(options);
		return lastValueFrom(this.http.get<PurchaseOrder[]>(OrderApiConfig.getFullUrl(`account/${accountId}/order`), { params }));
	}

	public getPurchaseOrderList(accountId: number): Promise<PurchaseOrderListRecord[]>
	{
		const params = {};

		return lastValueFrom(this.http.get<PurchaseOrderListRecord[]>(OrderApiConfig.getFullUrl(`account/${accountId}/order-list`), { params }));
	}

	public resendOrder(accountId: number, purchaseOrderId: number, supplierId: number): Promise<Submission>
	{
		let params = new HttpParams();
		params = params.append('supplierId', supplierId);
		return lastValueFrom(this.http.post<Submission>(OrderApiConfig.getFullUrl(`account/${accountId}/order/${purchaseOrderId}/send`), null, { params }));
	}

	//set order status
	public updateOrderStatus(accountId: number, purchaseOrderId: number, submissionId: number, status: string): Promise<Submission>
	{
		let params = new HttpParams();
		params = params.append('status', status);
		return lastValueFrom(this.http.post<Submission>(OrderApiConfig.getFullUrl(`account/${accountId}/order/${purchaseOrderId}/submission/${submissionId}/status`), null, { params }));
	}

	public getSubmissionStatus(accountId: number)
	{
		return lastValueFrom(this.http.get<SubmissionMethodStatus[]>(OrderApiConfig.getFullUrl(`account/${accountId}/submission/status`)));
	}

	public markRead(accountId: number, purchaseRequisitionId: number, message: RequisitionMessage): Promise<any>
	{
		return lastValueFrom(this.http.post(OrderApiConfig.getFullUrl(`account/${accountId}/requisition/${purchaseRequisitionId}/message/${message.id}`), message));
	}
}