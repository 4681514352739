<app-progress [ngClass]="{'hide': hasPendingApproval}" *ngIf="supplierProduct?.canPurchase ?? coreProduct?.canPurchase" [splash]="false" [type]="'spinner'" [value]="working" [fit]="true">
    <div class="flex-row no-wrap" *ngIf="mode === 'add'" [ngClass]="{ 'col-3': layout === 'row', 'col-2': layout === 'wrap' }">
        <div class="flex-row no-wrap">
            <i [class]="'create-decrement-' + (coreProduct ? coreProduct.sku : supplierProduct!.sku) + ' minus mr-small mt-auto mb-auto'" (click)="decrement($event)"></i>
            <input #quantityInput [(ngModel)]="quantity" inputmode="numeric" type="number" [ngModelOptions]="{updateOn: 'blur'}"
                   (blur)="checkVal()" [class]="'create-line-quantity-' + (supplierProduct ? supplierProduct!.sku : coreProduct!.sku) + ' input cart-quantity mt-auto mb-auto mr-small'" min="1" max="1000" step="1" />
            <i [class]="'create-increment-' + (coreProduct ? coreProduct.sku : supplierProduct!.sku) + ' add mt-auto mb-auto'" (click)="increment($event)"></i>
        </div>
        <button type="button" [ngClass]="{ 'in-cart': inCart && !showCurrentQuantity }" [attr.data-content]="inCartDisplay"
                [class]="'create-line-' + (coreProduct ? coreProduct.sku : supplierProduct!.sku) + ' btn text secondary border-secondary-accent add-to-cart mt-auto mb-auto mr-small'"
                (click)="createLine($event)">
            Add
        </button>
        <button type="button" [class]="'create-line-' + (coreProduct ? coreProduct.sku : supplierProduct!.sku) + ' btn text text-right border-secondary-accent mt-auto mr-small mb-auto not-button'"
                [ngStyle]="{ 'grid-column': layout === 'wrap' ? '1 / span 2' : '2' }" style="margin-left: auto; margin-right: 8px;"
                (click)="openModal('remove' + coreProduct.id + getModalId())" *ngIf="(inCart && !hideRemove)">
            Remove
            <!-- <i class="add ml-small" (click)="increment($event)"></i> -->
        </button>
    </div>
    <div class="flex-row no-wrap" *ngIf="mode === 'update'" [ngClass]="{ 'col-3': layout === 'row', 'col-2': layout === 'wrap' }">
        <div class="flex-row no-wrap">
            <i class="minus mr-small mt-auto mb-auto" (click)="decrement($event)"></i>
            <input #quantityInput [(ngModel)]="quantity" inputmode="numeric" type="number" [ngModelOptions]="{updateOn: 'blur'}"
                   (blur)="checkVal()" class="input cart-quantity mt-auto mb-auto mr-small" min="1" max="1000" step="1" />
            <i class="add mt-auto mb-auto" (click)="increment($event)"></i>
        </div>
        <button type="button" [ngClass]="{ 'in-cart': inCart && !showCurrentQuantity }" [attr.data-content]="inCartDisplay"
                class="btn text secondary border-secondary-accent add-to-cart mt-auto mb-auto mr-small"
                (click)="createLine($event)">
            Update
        </button>
        <button type="button" class="btn text border-secondary-accent mt-auto mb-auto" [ngClass]="{ 'not-button': layout === 'wrap' }"
                [ngStyle]="{ 'grid-column': layout === 'wrap' ? '1 / span 2' : '1' }"
                (click)="openModal('remove' + coreProduct.id + getModalId())" *ngIf="(inCart || !hideRemove)">
            Remove
        </button>
    </div>
    <div class="flex-row no-wrap col-2" *ngIf="mode === 'add-to'">
        <div class="flex-row no-wrap">
            <i class="minus mr-small mt-auto mb-auto" (click)="decrement($event)"></i>
            <input #quantityInput [(ngModel)]="quantity" inputmode="numeric" type="number" [ngModelOptions]="{updateOn: 'blur'}"
                   (blur)="checkVal()" class="input cart-quantity mt-auto mb-auto mr-small" min="1" max="1000" step="1" />
            <i class="add mt-auto mb-auto" (click)="increment($event)"></i>
        </div>
        <!-- remove button based on show remove and the product in the cart -->
        <div class="text text-300" [ngClass]="{ 'hidden': !coreProduct.listing.productCount }">
            <button (click)="openVariations(getModalId(), show_variations)"
                    [ngClass]="{ 'in-cart': inCart && !showCurrentQuantity }" [attr.data-content]="inCartDisplay"
                    class="btn text secondary border-secondary-accent add-to-cart mt-auto mb-auto">
                Add
            </button>

            <app-modal [id]="getModalId()" [confirmClose]="true" size="large" heading="Confirm add to requisition"
                       title="Variations">
                <app-product-variations [modalId]="getModalId()" #show_variations [isListing]="false" [mainImageUrl]="supplierProduct?.imageUrl ?? coreProduct.imageUrl"
                                        [product]="coreProduct" [supplierProduct]="supplierProduct"></app-product-variations>
            </app-modal>
        </div>
    </div>

</app-progress>

<div *ngIf="hasPendingApproval" class="purchasing-disabled">
    Purchasing is disabled, approval in progress
</div>

<app-modal *ngIf="coreProduct" [id]="('remove' + coreProduct.id + getModalId())" heading="Confirm to remove">
    <div>
        <div class="text text-300 rubik overflow-hidden mb-medium">Are you sure you want to remove {{coreProduct.name }} from your requisition?</div>
        <div class="flex-row justify-even">
            <button class="btn text border-secondary-accent mt-auto mb-auto ml-small"
                    (click)="removeLine($event, 'remove' + coreProduct.id + getModalId())">
                Remove
            </button>
            <button class="btn text border-secondary-accent mt-auto mb-auto ml-small"
                    (click)="closeModal('remove' + coreProduct.id + getModalId())">
                Cancel
            </button>
        </div>
    </div>
</app-modal>

<div *ngIf="!(supplierProduct?.canPurchase ?? coreProduct?.canPurchase)" class="can-not-purchase text">
    This product is not enabled for purchase
</div>

<div>

</div>