<div class="mt-large flex-row justify-even">
    <div>
      <label>ID: {{auditInfo?.id}}</label>
    </div>
    <div>
      <label>Create Date: {{auditInfo?.created | date: 'dd/MM/yyyy h:mm a' }} <span *ngIf="!auditInfo?.created">UNAVAILABLE</span></label>
    </div>
    <div>
      <label>Created By: {{createdByUsername}}  <span *ngIf="!createdByUsername">UNAVAILABLE</span></label> 
    </div>
    <div>
      <label>Last Modified Date: {{auditInfo?.modified | date: 'dd/MM/yyyy h:mm a' }} <span *ngIf="!auditInfo?.modified">UNAVAILABLE</span></label>
    </div>
    <div>
      <label>Last Modified By: {{modifiedByUsername}} <span *ngIf="!modifiedByUsername">UNAVAILABLE</span></label> 
    </div>

  </div>
