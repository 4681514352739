import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { IAccount, AccountType, Formulary } from 'src/app/shared/models';
import { LoginService, MonitorService, NavigationService } from 'src/app/shared/services';
import { AccountService } from 'src/app/shared/services/account.service';
import { Feature } from 'src/app/shared/services/feature.service';
import { FormularyService } from 'src/app/shared/services/formulary.service';
import { GenericComponent } from 'src/app/shared/utils/generic.component';

@Component({
  selector: 'app-account-edit-formulary',
  templateUrl: './account-edit-formulary.component.html',
  styleUrls: ['./account-edit-formulary.component.scss']
})
export class AccountEditFormularyComponent extends GenericComponent implements OnInit {
  account: IAccount | undefined;
  parentAccount: IAccount | undefined;
  formulary: Formulary | undefined;
  showBackButton: boolean = false;
  feature = Feature;
  accountType = AccountType;
  anyNotAllowed = false;

  appAccessNavigationOptions: { feature: Feature, aTagId: string, aTagText: string, action: Function }[] = [
    {
      feature: Feature.AccountUpdate,
      aTagId: 'configuration',
      aTagText: 'Configuration',
      action: () => this.navigationService.backoffice().account.formulary.configurePractice(this.account!.id, this.activatedRoute.parent!, true)
    },
    {
      feature: Feature.AccountUpdate,
      aTagId: 'formulary',
      aTagText: 'Formulary',
      action: () => this.navigationService.backoffice().account.formulary.formulary(this.account!.id, this.account!.practice!.customFormularyId || 0, this.activatedRoute, true)
    },
    {
      feature: Feature.AccountUpdate,
      aTagId: 'products',
      aTagText: 'Products',
      action: () => this.navigationService.backoffice().account.formulary.products(this.account!.id, this.activatedRoute, true)
    }
  ]

  constructor(
    private accountService: AccountService,
    monitor: MonitorService,
    login: LoginService,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService,
    private formularyService: FormularyService
  ) {
      super(monitor, login);
  
    }
  
  ngOnInit() {

    this.activatedRoute.parent!.paramMap.subscribe(async params => {

      await this.execute(async () => {
        this.working = 50;

        const accountId = params.get('accountId');
      
        if (accountId) {
          this.account = await this.accountService.getAccountById(parseInt(accountId), { formulary: true, parent: true });
        }

        if (this.account && this.account.parentId) {
          this.parentAccount = await this.accountService.getAccountById(this.account.parentId, { });

          if (!this.parentAccount?.allowManageFormulary) {
            this.account!.allowManageFormulary = false;
          }
          if (!this.parentAccount?.allowDentalCity) {
            this.account!.allowDentalCity = false;
          }
          if (!this.parentAccount?.allowOrderHistory) {
            this.account!.allowOrderHistory = false;
          }
    
          this.anyNotAllowed = !this.account.allowManageFormulary;
          if (this.account && this.account.parentId) {
            let p = this.account.parentId;
            while (p) {
              const pa = await this.accountService.getAccountById(p, {});
              this.anyNotAllowed = this.anyNotAllowed || !pa.allowManageFormulary;    
              p = pa.parentId;        
            }
          }
    
        }  

        if(this.account?.practice?.customFormularyId){
          this.formulary = await this.formularyService.getFormulary(this.account.practice.customFormularyId, {});
        }

        this.working = 0;

        const state = history.state;
        this.showBackButton = state && state.fromSpecificPage;

        return true;
      }, 'Error loading formulary');
      
    });
  }

}
