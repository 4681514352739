<app-progress [splash]="true" [value]="working">
    <form (submit)="formSubmit($event)" [formGroup]="formGroup">
        <dx-form id="form" [formData]="agency" labelLocation="top" [showColonAfterLabel]="false"
            (onFieldDataChanged)="onFieldDataChanged($event)">
            <dxi-item itemType="group" [colCount]="1">
                <dxi-item dataField="name" [isRequired]="true">
                    <dxo-label template="Name"></dxo-label>
                    <dxi-validation-rule type="required" message="Agency Name is required"></dxi-validation-rule>
                </dxi-item>

                <dxi-item editorType="dxHtmlEditor" dataField="description" [editorOptions]="{ toolbar: toolbar }">
                    <dxo-label template="Description"></dxo-label>
                </dxi-item>

                <dxi-item itemType="simple" dataField="platformAgency" editorType="dxCheckBox">
                    <dxo-label template="Platform Agency"></dxo-label>
                </dxi-item>
                <dxi-item itemType="simple" dataField="active" editorType="dxCheckBox">
                    <dxo-label template="Active"></dxo-label>
                </dxi-item>
                <dxi-item itemType="simple" dataField="live" editorType="dxCheckBox">
                    <dxo-label template="Go Live"></dxo-label>
                </dxi-item>

            </dxi-item>
        </dx-form>
        <dx-button [disabled]="!isValid" text="Save" [useSubmitBehavior]="true"></dx-button>
    </form>
</app-progress>


<app-modal id="confirmGoLive" [confirmClose]="false" size="medium" heading="Confirm go live" [confirm]="true">
    <p class="text text-center">You have enabled the agency's 'go live' feature. All agents will be sent an invitation email.</p>
    <p class="text text-center">Do you want to continue?</p>
</app-modal>