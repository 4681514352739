import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { IAccount, Cart, CartLine, CoreProduct, RequisitionMessage, SupplierProduct } from 'src/app/shared/models';
import { AgentService, CartService, CatalogLoadOptions, CatalogService, ModalService, MonitorService, NavigationService, OrderService } from 'src/app/shared/services';
import { AddToCartComponent } from '../add-to-cart/add-to-cart.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cart-quick-view',
  templateUrl: './cart-quick-view.component.html',
  styleUrls: ['./cart-quick-view.component.scss']
})
export class CartQuickViewComponent implements OnInit, OnDestroy {
  @ViewChild('cartcontents') cartcontents?: ElementRef;

  expanded = false;
  coreProducts: any = {};
  supplierProducts: any = {};
  account?: IAccount;
  cart?: Cart;
  private subscriptions: Subscription[] = [];
  private _internalCount = 0;

  protected total: number | undefined = undefined;
  hasPendingApproval: boolean = true;

  constructor(
    public cartService: CartService,
    private catalogService: CatalogService,
    private modalService: ModalService,
    private renderer: Renderer2,
    private orderService: OrderService,
    private agentService: AgentService,
    private navigationService: NavigationService,
    private monitor: MonitorService
  ) {
    this._internalCount = AddToCartComponent._counter++;
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.expanded && this.cartcontents?.nativeElement) {
        var target: any = e.target;
        let found = false;
        while (target) {
          if (target !== this.cartcontents.nativeElement) {
            target = target.parentElement;
          } else {
            found = true;
            break;
          }
        }
        if (!found) {
          this.expanded = false;
        }
      }
    });

    this.cartService.pendingApprovals.subscribe(pendingApprovalCount => {
      this.hasPendingApproval = pendingApprovalCount > 0;
    });

    this.subscriptions.push(this.cartService.cartChangedObservable$.subscribe((value: Cart | undefined) => {
      this.cart = value;
      if(this.cart){
        this.calcTotal();
      }
    }));
  }

  goToOptimizeAndOrder() {
    this.expanded = false;
    this.navigationService.practice(this.agentService.currentAccount?.id!).requisition.review(this.cart!.id);
  }
  //for returning unique id for each modal
  getModalId(): string {
    return `add-to-cart-${this._internalCount}`;
  }

  getATCId(): string {
    return `atc-${this._internalCount}`;
  }

  async ngOnInit(): Promise<any> {
    await this.cartService.refreshCart();
    this.calcTotal();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  openModal(id: string): void {
    this.modalService.open(id);
  }

  /**
   * Author: @dcphillips
   * @description Calculates the total price of the cart
   * 
   * if any product does not have a supplier, set the price as NaN
   * @returns the total price of the cart
   */
  calcTotal(): number | undefined {
    let total: number | undefined = 0;
    let tbd = false;
    if (this.cart) {
      this.cart.lines.forEach((cl: CartLine) => {
        if (cl.supplierProduct && cl.supplierProduct.supplier && cl.supplierProduct.price && cl.supplierProduct.price !== '$--') {
          let itemPrice = Number(cl.supplierProduct.price?.charAt(0) === '$' ? cl.supplierProduct.price?.substring(1) : cl.supplierProduct.price);
          total! += cl.quantity * ((!isNaN(itemPrice)) ? itemPrice : 0);
        } else {
          tbd = true;
        }
      });
    }
    if (tbd) {
      this.total = undefined;
      total = undefined;
    } else {
      this.total = total;
    }
    return total;
  }

  async dismiss(message: RequisitionMessage, e: Event): Promise<any> {
    e.preventDefault();
    message.dismissed = true;
    setTimeout(() => {
      this.cart!.messages = this.cart!.messages!.filter(m => m.id !== message.id);      
    }, 600);
    try {
      message.dismissedBy = this.agentService.authenticatedAgent?.id ?? 0;
      await this.orderService.markRead(this.cart!.practiceId, this.cart!.id, message);
    } catch (error) {
      this.monitor.handleError(error, 'There was a problem dismissing the message');
    }
  }

  async openVariation(id: string, line: CartLine, show_variations: any): Promise<any> {
    if (!line.coreProduct) {
      let options = { accountId: this.cart!.practiceId, coreProducts: true, includeListing: true } as CatalogLoadOptions;

      line.supplierProduct = await this.catalogService.getSupplierProduct(line.supplierProductId!, options);
      line.coreProduct = line.supplierProduct.coreProduct;
    }

    this.openModal(id);

    // don't do right away,otherwise core inputs won't be set correctly
    setTimeout(() => {
      show_variations.openVariations();      
    }, 100);
  }
}
