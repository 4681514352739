import { environment } from '../../../environments/environment';

/**
 * Base API configuration file
 */
export class AgencyApiConfig {
    static path: string = environment.api.agency.path;
    static version: string | number | null = environment.api.agency.version;

    public static setApiVersion(version: string = 'api'): void {
        AgencyApiConfig.version = version;
    }

    public static getApiVersion(): string | number | null {
        return AgencyApiConfig.version;
    }

    public static getPath(): string {
        return AgencyApiConfig.path;
    }

    public static getFullUrl(path: string): string {
        if (AgencyApiConfig.getApiVersion()) {
            return [AgencyApiConfig.getPath(), AgencyApiConfig.getApiVersion(), path].join('/');
        } else {
            return [AgencyApiConfig.getPath(), path].join('/');
        }
    }
}
