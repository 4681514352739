import { IOptimizeOrderLine, OptimizeOrderLine } from "./optimize.order.line.model";
import { CoreProduct } from "./product.core.model";
import { SupplierProduct } from "./product.supplier.model";
import { RequisitionMessage } from "./purchase.requisition.model";

export interface ICart {
    id: number;
    practiceId: number;
    lines: CartLine[];
    createdBy?: number;
    created?: Date;
    modifiedBy?: number;
    modified?: Date;
    messages?: RequisitionMessage[] | undefined;
    modifier?: number | undefined;
    pendingApprovalsCount: number;
}
export class Cart implements ICart {
    public id!: number;
    public practiceId!: number;
    public lines: CartLine[] = [];
    public messages?: RequisitionMessage[] | undefined = undefined;
    public modifier?: number | undefined = undefined;
    public pendingApprovalsCount!: number;

    public fromOptimizeOrder(requisitionId: number, practiceId: number, lines: OptimizeOrderLine[]): void {
        this.id = requisitionId;
        this.practiceId = practiceId;
        this.lines = lines.map((line) => {
            let cartLine = new CartLine();
            cartLine.fromOptimizeOrderLine(line);
            return cartLine;
        });
    }
}

export interface ICartLine {
    id: number;
    coreProductId?: number;   
    coreProductName?: string;
    productListing?: string;
    supplierProductId?: number;
    supplierProductName?: string;
    uom: string;
    quantity: number;
    price?: string;
    createdBy?: number;
    created?: Date;
    modifiedBy?: number;
    modified?: Date;
    coreProduct?: CoreProduct;
    supplierProduct?: SupplierProduct;
}
export class CartLine implements ICartLine {
    public id!: number;
    public coreProductId?: number;   
    public coreProductName?: string;
    public productListing?: string;
    public supplierProductId?: number;
    public supplierProductName?: string;
    public uom!: string;
    public quantity!: number;
    public price?: string;
    public createdBy?: number;
    public created?: Date;
    public modifiedBy?: number;
    public modified?: Date;

    public coreProduct?: CoreProduct;
    public supplierProduct?: SupplierProduct;

    public fromOptimizeOrderLine(line: OptimizeOrderLine): void {
        this.id = line.purchaseRequisitionLineId;
        this.coreProductId = line.coreProductId;
        this.coreProductName = line.coreProduct;
        this.productListing = line.productListing;
        this.supplierProductId = line.supplierProductId;
        this.supplierProductName = line.supplierProduct;
        this.uom = "";
        this.quantity = line.purchaseRequisitionLineQuantity;
        this.price = line.price;
        this.supplierProduct = new SupplierProduct();
        this.supplierProduct.imageUrl = line.relativeImageUrl;
        this.supplierProduct.sku = line.supplierProductSKU;
        this.supplierProduct.name = line.supplierProduct;
        this.coreProduct = new CoreProduct();
        this.coreProduct.name = line.coreProduct;
        this.coreProduct.coreProductTags = line.coreProductTags;
        this.coreProduct.id = line.coreProductId;
    }
}
