import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService, ModalService, AgentService, ToastService, AgentLoadOptions, MonitorService, CartService, NavigationService } from '../../shared/services';
import { IAccount, AccountType, Agent, Profile } from '../../shared/models';
import { User } from '@auth0/auth0-angular';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/shared/services/account.service';
import { Feature, FeatureService } from 'src/app/shared/services/feature.service';

@Component({
  selector: 'app-account-card',
  templateUrl: './account-card.component.html',
  styleUrls: ['./account-card.component.scss']
})
export class AccountCardComponent implements OnInit, OnDestroy {

  @ViewChild('customerAccountLink') card?: ElementRef;

  agent: Agent | undefined;
  user: User | undefined;

  @Input()
  profile: Profile | undefined;

  name: string | undefined = '';
  initials: string | undefined = '';
  accountCardOpen: boolean = false;
  accountType = AccountType;
  accounts: IAccount[] = [];

  profileSelector = Profile;
  feature = Feature;
  subscriptions: Subscription[] = [];
  recentAccounts: IAccount[] = [];
  working = 0;
  disableSubmit: boolean = false;

  @Input()
  account: IAccount | undefined;

  constructor(
    private loginService: LoginService,
    private agentService: AgentService,
    public accountService: AccountService,
    public featureService: FeatureService,
    private cartService: CartService,
    private monitorService: MonitorService,
    private navigationService: NavigationService,
    public modals: ModalService,
    private renderer: Renderer2
  ) {

    this.agent = agentService.authenticatedAgent;
    this.user = loginService.authenticatedUser;

    this.subscriptions.push(this.agentService.authenticated$.subscribe(async a => {
      this.agent = a;
      this.user = this.loginService.authenticatedUser;
      if (this.user) {
        this.name = this.agent ? `${this.agent?.firstName} ${this.agent?.lastName}` : this.user?.name;
        let given_name = this.user?.given_name || "";
        let family_name = this.user?.family_name || "";
        let initials = given_name.substring(0, 1) + family_name.substring(0, 1)
        this.initials = initials && initials != "" ? initials : this.user?.name?.substring(0, 2).toLocaleUpperCase();
      }
      else {
        this.name = '';
        this.initials = '';
      }

      if (this.agent) {
        this.accounts = await this.agentService.getAgentAccounts(this.agent.id!, { accounts: true });       
      }

    }));

    this.subscriptions.push(this.agentService.profileChange$.subscribe(async a => {
      
      modals.close('accountSwitchModal');
      setTimeout(async () => {
        if (this.agent?.id) {
          try {
            this.working = 60;
            this.recentAccounts = [];
            (await this.agentService.getRecentAgentAccount(this.agent.id, 5, { accounts: true, activities: true })).map(a => {
              if (a.id !== this.agentService.currentAccount!.id) {
                this.recentAccounts.push(a);
              }
            });
            this.working = 80;
            this.accounts = await this.agentService.getAgentAccounts(this.agent.id, { accounts: true });
          } catch(e) {
            this.monitorService.handleError(e);
          } finally {
            this.working = 0;
          }
        }
      }, 1500);
    }));

    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.accountCardOpen && this.card?.nativeElement) {
        var target: any = e.target;
        let found = false;
        while (target) {
          if (target !== this.card.nativeElement) {
            target = target.parentElement;
          } else {
            found = true;
            break;
          }
        }
        if (!found) {
          this.accountCardOpen = false;
        }
      }
    });

  }

  async ngOnInit(): Promise<any> {
    if (this.user) {
      this.name = this.agent ? `${this.agent?.firstName} ${this.agent?.lastName}` : this.user?.name;
      let given_name = this.user?.given_name || "";
      let family_name = this.user?.family_name || "";
      let initials = given_name.substring(0, 1) + family_name.substring(0, 1)
      this.initials = initials && initials != "" ? initials : this.user?.name?.substring(0, 2).toLocaleUpperCase();

      if (this.agent?.id) {
        try {
          this.working = 10;
          this.recentAccounts = [];
          let account = this.agentService.currentAccount
          if (account && this.profile != Profile.Platform) {
            await this.accountService.loadCustomization(account!.id);            
          }
          (await this.agentService.getRecentAgentAccount(this.agent.id, 5, { accounts: true, activities: true})).map(async a => {
            if (a.id !== account?.id) {
              this.recentAccounts.push(a);
            }
          });
          this.working = 80;
          this.accounts = await this.agentService.getAgentAccounts(this.agent.id, { accounts: true });
        } catch(e) {
          this.monitorService.handleError(e);
        } finally {
          this.working = 0;
        }
      }
    }
    else {
      this.name = "";
      this.initials = "";
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  toggleAccountCard() {
    this.accountCardOpen = !this.accountCardOpen;
  }

  logout(): void {
    this.loginService.logout();
  }

  async sendResetLink(id: string): Promise<any> {
    this.disableSubmit = true;

    try {
      if (this.agent) {
        var resetParam = this.agent.customUsername ? this.agent.userName! : this.agent.email;
        var x = await this.agentService.requestReset(resetParam);
        this.monitorService.handleSuccess(x.message);
      } 
    }
    catch (e) {
      this.monitorService.handleError(e);
    }
    finally {
      this.closeModal(id);
      this.disableSubmit = false;
    }

  }

  openModal(id: string): void {
    this.modals.open(id);
  }
  closeModal(id: string): void {
    this.modals.close(id);
  }

  switchProfile(value: number) {
    this.toggleAccountCard();  
    this.accountService.clearCustomizations();

    this.navigationService.backoffice().dashboard();
  }

  async switchAccount(value: IAccount) {
    switch (value.accountType) {
      case AccountType.PRACTICE:
        this.navigationService.practice(value.id).dashboard();
        break;
      case AccountType.DSO:
        this.navigationService.dso(value.id).dashboard();
        break;
      case AccountType.GPO:
        this.navigationService.gpo(value.id).dashboard();
        break;
      default:
        this.monitorService.handleError('Invalid account type');
        break;
    }

    this.agentService.addAuthorizedAccount(value);
    this.cartService.account = value;
    try {
      this.working = 50;
      await this.accountService.loadCustomization(value.id);
      this.working = 75;
      await this.cartService.refreshCart();
      this.toggleAccountCard();  
    } finally {
      this.working = 0;
    }
  }
}
