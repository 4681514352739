import { environment } from '../../../environments/environment';

export class ReportingApiConfig {
    static readonly path: string = environment.api.reporting.path;
    static version: string | number | null = environment.api.reporting.version;

    public static setApiVersion(version: string = 'api'): void {
        ReportingApiConfig.version = version;
    }

    public static getApiVersion(): string | number | null {
        return ReportingApiConfig.version;
    }

    public static getPath(): string {
        return ReportingApiConfig.path;
    }

    public static getFullUrl(path: string): string {
        if (ReportingApiConfig.getApiVersion()) {
            return [ReportingApiConfig.getPath(), ReportingApiConfig.getApiVersion(), path].join('/');
        } else {
            return [ReportingApiConfig.getPath(), path].join('/');
        }
    }
}