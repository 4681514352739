<app-progress [splash]="true" [value]="working">

    <form (submit)="formSubmit($event)" [formGroup]="formGroup">
        <dx-form id="form" [formData]="formulary" labelLocation="top" [showColonAfterLabel]="false"
            (onFieldDataChanged)="onFieldDataChanged($event)">
            <dxi-item itemType="group" [colCount]="1">
                <dxi-item dataField="name" [isRequired]="true">
                    <dxo-label template="Name"></dxo-label>
                    <dxi-validation-rule type="required" message="Formulary Name is required"></dxi-validation-rule>
                    <dxi-validation-rule type="stringLength" message="Maximum length of name is 250" dataField="name"
                    [max]="250"></dxi-validation-rule>
                </dxi-item>

                <dxi-item editorType="dxHtmlEditor" dataField="description" [editorOptions]="{ toolbar: toolbar }">
                    <dxo-label template="Description"></dxo-label>
                    <dxi-validation-rule type="stringLength" message="Maximum length of description is 4000" dataField="description"
                    [max]="4000"></dxi-validation-rule>
                </dxi-item>

                <dxi-item itemType="simple" dataField="active" editorType="dxCheckBox" [visible]="!isNew">
                    <dxo-label template="Active"></dxo-label>
                </dxi-item>

            </dxi-item>
        </dx-form>
        <dx-button [disabled]="!isValid" text="Save" [useSubmitBehavior]="true"></dx-button>
    </form>
</app-progress>