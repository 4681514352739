import { Component, ViewChild, Input, Renderer2, Output, EventEmitter } from '@angular/core';
import { DxDropDownBoxComponent, DxFormComponent, DxListComponent, DxTextBoxComponent } from 'devextreme-angular';
import { AgentService, CartService, CatalogLoadOptions, CatalogService, LoginService, MonitorService } from 'src/app/shared/services';
import { CatalogApiConfig } from 'src/app/shared/api';
import { ActivatedRoute, Router } from '@angular/router';
import { FormCanDeactivate } from 'src/app/shared/utils/form.candeactivate';
import CustomStore from 'devextreme/data/custom_store';
import { SupplierService } from 'src/app/shared/services/supplier.service';
import { PracticeSupplier } from 'src/app/shared/models/practice.supplier.model';

@Component({
  selector: 'app-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.scss']
})

export class ProductSearchComponent extends FormCanDeactivate {
  async submit(): Promise<boolean> {
    const cp = this.tab?.value === 'all' || this.tab?.value === 'rec';
    this.search(this.url, cp, this.supplierSelection === -1 ? undefined : this.supplierSelection);
    return true;
  }
  validate(): boolean {
    return true;
  }
  submitMessage: string = "Searching for products.";

  @ViewChild(DxListComponent, { static: false })
  list: DxListComponent | undefined;

  @ViewChild(DxFormComponent, { static: false })
  form: DxFormComponent | undefined;

  @ViewChild(DxTextBoxComponent, { static: false })
  searchBox: DxTextBoxComponent | undefined;

  @ViewChild(DxDropDownBoxComponent, { static: false })
  supplierDropdown: DxDropDownBoxComponent | undefined;

  showDropdown: boolean = false;

  products: any[] = [];
  dataSource: any;
  @Input() tab?: {text: string, value: string};
  @Input() direction: 'horizontal' | 'vertical' = 'vertical';
  @Input() limit: boolean  = false;
  @Input() pageSize: number = 10;
  @Input() allowSearch:boolean = true;
  @Input() isHomePage:boolean = false;

  @Output()
  display: EventEmitter<boolean> = new EventEmitter<boolean>();

  //using -1 as the supplierId for All Suppliers and checking for this value when we call configureDatasource (aka ignoring it)
  supplierList: PracticeSupplier[] = [];
  supplierSelection: number = -1; //the id of the selection from dropdown

  url: string = ''; //CatalogApiConfig.getFullUrl('search');
  showScrollToTop =false;
  displayCoreProducts = true;

  onListScrollEvent(e: any) {
    if (this.direction === 'vertical') {
      const scrollTop = this.list?.instance?.scrollTop();
      if (!this.showScrollToTop && scrollTop && scrollTop > 400) {
        this.showScrollToTop = true;
      } else if (this.showScrollToTop && scrollTop && scrollTop < 400 ) {
        this.showScrollToTop = false;
      }
    }
  }


  constructor(
    private agentService: AgentService,
    private catalogService: CatalogService,
    private supplierService: SupplierService,
    private cartService: CartService,
    private activatedRoute: ActivatedRoute,
    monitor: MonitorService, 
    login: LoginService,
    private renderer: Renderer2,
    router: Router) {
    super(monitor, login, router);
  }


  async onContentReady(e: any) {
    let element = e.element.querySelector(".dx-icon-clear");
    
      element.addEventListener('click', async () => {
        const cp = this.tab?.value === 'all' || this.tab?.value === 'rec';
        await this.configureDatasource("", cp, (this.supplierSelection === -1 ? undefined : this.supplierSelection));
      });
  }

  onDropdownSelection(e: any) {
    let supplierId = e.value; 
    this.supplierSelection = supplierId;

    const searchText = this.searchBox?.text ?? '';
    this.configureDatasource(searchText, false, supplierId === -1 ? undefined : supplierId);
  }
  
  async ngOnInit() {
    this.url = CatalogApiConfig.getFullUrl('search');
    const cp = this.tab?.value === 'all' || this.tab?.value === 'rec';

    this.supplierList = [{ practiceId: this.agentService.currentAccount!.id, supplierId: -1, supplierName: 'All Suppliers' }];

    if (this.tab && this.tab.value === 'supplier') {
      try {
        let practiceSuppliers = await this.supplierService.getPracticeSuppliers(this.agentService.currentAccount!.id);
        this.showDropdown = true;
        
        if (practiceSuppliers) {
          this.supplierList = [...this.supplierList, ...practiceSuppliers];
        }
        
      } catch (error: any) {
        this.monitor.handleError(error, 'Unable to load suppliers');
      }
    }
    await this.configureDatasource("", cp); //not including supplierId here because we want to get all suppliers
  }

  search(url: string, coreProducts: boolean | undefined, supplierId?: number) {
    const searchText = this.searchBox?.text ?? '';
    this.url = url;

    this.configureDatasource(searchText, coreProducts, (supplierId === -1 ? undefined : supplierId));
  }

  async configureDatasource(searchText: string, coreProducts: boolean | undefined, supplierId?:number): Promise<void> {

    const loadOptions: CatalogLoadOptions = {
      accountId: this.agentService.currentAccount!.id,
      pageNumber: 0,
      pageSize: this.pageSize,
      includeListing: true,
      isHomePage: this.isHomePage
    };

    if (supplierId) {
      loadOptions.supplierId = supplierId;
    }

    if (this.tab!.value === 'rec') {   
      loadOptions.recommendedProduct = true;
      loadOptions.coreProducts = true;
    } else if (this.tab!.value === 'recent') {
      loadOptions.recentProduct = true;
      loadOptions.coreProducts = false;
    } else if (this.tab!.value === 'freq') {     
      loadOptions.mostOrderedProduct = true;
      loadOptions.coreProducts = false;
    } else if (this.tab!.value === 'supplier') {
      loadOptions.coreProducts = false;
      loadOptions.mostOrderedProduct = false;
      loadOptions.recentProduct = false;
      loadOptions.recommendedProduct = false;
    }
    loadOptions.coreProducts = coreProducts ?? loadOptions.coreProducts;
    loadOptions.includeListing = true;

    this.displayCoreProducts = loadOptions.coreProducts ?? false;

    const products  = await this.catalogService.get(searchText, loadOptions);
    this.dataSource = new CustomStore({
      loadMode: 'raw',
      load: () => {
        return products;
      },
    });

    if (this.limit && products.length > 0) {
      this.display.emit();
    }
  }
 
  scrollTop(): void {
    window.scrollTo({ top: 0, left: 0 });
   this.list?.instance.scrollTo(0);
   this.showScrollToTop = false;
  }
  
}
