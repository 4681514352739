<div #customerAccountLink>
  <div id="customer-account-link" (click)="toggleAccountCard()" class="text text-500 rubik mr-small mt-auto mb-auto">
    <div class="flex-row ml-auto">
      <div *ngIf="profile === profileSelector.Agent">
        <app-progress [splash]="false" [value]="99">
          Hold on while we find your profile...
        </app-progress>
      </div>
      <div
        *ngIf="profile === profileSelector.Practice"
        [innerHTML]="accountService.practiceIconSafe()">
        practice
      </div>
      <div *ngIf="profile === profileSelector.DSO"
        [innerHTML]="accountService.dsoIconSafe()">
        dso
      </div>
      <div *ngIf="profile === profileSelector.GPO"
        [innerHTML]="accountService.gpoIconSafe()">
        gpo
      </div>
      <div *ngIf="profile === profileSelector.GPO || profile === profileSelector.DSO || profile === profileSelector.Practice || profile === profileSelector.Public"
        class="account-name text text-500 ml-small mt-auto mb-auto mr-small max-text-size" [tooltip]="account?.name ?? 'Switch Accounts'">
        {{ account?.name ?? "Switch Accounts" }}
      </div>

      <div *ngIf="profile === profileSelector.Platform"
        class="account-name text text-800 ml-small mt-auto mb-auto mr-small">
        Sherpa Profile
      </div>

      <div *ngIf="profile === profileSelector.Unauthorized"
        class="account-name text text-800 ml-small mt-auto mb-auto mr-small">
        Agent Profile
      </div>
      <app-menu-bar [expanded]="accountCardOpen" [isArrow]="true"></app-menu-bar>
    </div>
    <div class="agent-name text text-300 ml-auto">
      Logged in as {{ name }}
    </div>
  </div>
  <div [ngClass]="accountCardOpen ? 'open' : 'closed'" class="account-card">
    <div class="flex-column">
        <button id="close-card" (click)="toggleAccountCard()">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.8285 9.99999L19.8996 2.92892L17.0711 0.100494L10.0001 7.17156L2.92901 0.100494L0.100586 2.92892L7.17165 9.99999L0.100586 17.0711L2.92901 19.8995L10.0001 12.8284L17.0711 19.8995L19.8996 17.0711L12.8285 9.99999Z" fill="black"/>
          </svg>
        </button>
    </div>

    <app-progress [splash]="false" [value]="working" class="mb-small">

      <div *ngIf="recentAccounts.length && (profile !== profileSelector.Unauthorized)" >
        <div class="text text-400 rubik card-header">
          Recent Accounts
        </div>
        <a *ngFor="let a of recentAccounts" (click)="switchAccount(a)" class="flex-row no-wrap recents">
          <div class="mr-small" *ngIf="a.accountType === accountType.PRACTICE"
            [innerHTML]="accountService.practiceIconSafe()"></div>
          <div class="mr-small" *ngIf="a.accountType === accountType.GPO" [innerHTML]="accountService.gpoIconSafe()">
          </div>
          <div class="mr-small" *ngIf="a.accountType === accountType.DSO" [innerHTML]="accountService.dsoIconSafe()">
          </div>
          <div class="text text-400 rubik mt-auto mb-auto ml-small recent-account text-ellipsis">{{a.name}}</div>
        </a>
      </div>
    </app-progress>

    <button *ngIf="accounts.length && accounts.length > 1 && (profile !== profileSelector.Unauthorized)" type="button" class="btn grey text text-upper text-400" (click)="openModal('accountSwitchModal')">Switch Account</button>
    

    <button class="btn grey black text text-upper text-400" type="button" (click)="switchProfile(profileSelector.Platform)"
      *ngIf="accountCardOpen && agent?.agency?.platformAgency && profile !== profileSelector.Platform && (featureService.check(feature.AgencyRead, agent?.features ?? feature.None) || featureService.check(feature.AgentRead, agent?.features ?? feature.None) || featureService.check(feature.AccountRead, agent?.features ?? feature.None))">
      Switch to Sherpa Profile
      <img src="assets/images/icons/yak-red.png" height="20px" />
    </button>



    <button *ngIf="agent?.active && accountCardOpen" class="btn grey black text text-upper text-400" type="button " (click)="openModal('change-password')">
      Change Password
    </button>

    <button *ngIf="accountCardOpen" class="btn grey text text-400 text-upper" type="button" aria-label="Logout" (click)="logout()">
      Logout
      <svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M25.1692 11.9289H1.99978C1.4694 11.9289 0.960752 12.1296 0.585721 12.4867C0.210691 12.8439 0 13.3283 0 13.8333C0 14.3384 0.210691 14.8228 0.585721 15.18C0.960752 15.5371 1.4694 15.7378 1.99978 15.7378H25.1692L16.5821 23.9115C16.2066 24.2691 15.9957 24.7541 15.9957 25.2598C15.9957 25.7656 16.2066 26.2506 16.5821 26.6082C16.9577 26.9658 17.4669 27.1667 17.998 27.1667C18.529 27.1667 19.0383 26.9658 19.4138 26.6082L31.4125 15.1817C31.5987 15.0048 31.7465 14.7946 31.8473 14.5632C31.9481 14.3319 32 14.0838 32 13.8333C32 13.5828 31.9481 13.3348 31.8473 13.1034C31.7465 12.8721 31.5987 12.6619 31.4125 12.485L19.4138 1.0585C19.0383 0.700897 18.529 0.5 17.998 0.5C17.4669 0.5 16.9577 0.700897 16.5821 1.0585C16.2066 1.4161 15.9957 1.9011 15.9957 2.40682C15.9957 2.91255 16.2066 3.39755 16.5821 3.75515L25.1692 11.9289Z"
           />
      </svg>
    </button>
  </div>
</div>

<app-modal id="accountSwitchModal" size="large" [showOverflow]="true" [title]="account?.name">
  <div class="mb-medium">
    <app-account-selector [agent]="agent!" class="mr-small"
      (selected)="modals.close('accountSwitchModal')"></app-account-selector>
  </div>

  <div class="flex-row">
    <button type="button" class="btn secondary mr-auto ml-auto text rubik"
      (click)="modals.close('accountSwitchModal')">Close</button>
  </div>
</app-modal>


<app-modal id="change-password" size="large" [showOverflow]="false">
  <div class="head mb-large">
    <h5 class="text text-center" style="text-transform:none;">Are you sure you want to reset your password?</h5>
  </div>
  <form>
    <div class="flex-row justify-around">
      <dx-button class="btn" class="btn gold" [disabled]="disableSubmit" (onClick)="sendResetLink('change-password')">Yes</dx-button>

      <button type="button" class="btn gray" (click)="closeModal('change-password')">No</button>
    </div>
  </form>

</app-modal>
