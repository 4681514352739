import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { DxTreeViewComponent } from 'devextreme-angular';
import { Subscription } from 'rxjs';
import { IAccount, AccountAccess, AccountType, Agent, Profile } from 'src/app/shared/models';
import { AgentLoadOptions, AgentService, CartService, MonitorService, NavigationService } from 'src/app/shared/services';
import { AccountService } from 'src/app/shared/services/account.service';
import { Feature, FeatureService } from 'src/app/shared/services/feature.service';
import { flattenDiagnosticMessageText } from 'typescript';

@Component({
  selector: 'app-account-selector',
  templateUrl: './account-selector.component.html',
  styleUrls: ['./account-selector.component.scss']
})
export class AccountSelectorComponent implements OnInit, OnDestroy {
  // @ViewChild('dropdown')
  // dropdown?: ElementRef;

  @ViewChild(DxTreeViewComponent, { static: false })
  treeView!: DxTreeViewComponent;

  @Output()
  selected: EventEmitter<IAccount> = new EventEmitter<IAccount>();

  accounts: AccountAccess[] = [];

  @Input()
  agent!: Agent;

  expanded = false;
  subscriptions: Subscription[] = [];
  treeBoxValue!: number;
  isTreeBoxOpened: boolean = false;
  working = 0;

  constructor(
    // private renderer: Renderer2,
    private features: FeatureService,
    private ref: ChangeDetectorRef,
    private monitor: MonitorService,
    private cart: CartService,
    private accountService: AccountService,
    private agentService: AgentService,
    private navigationService: NavigationService
  ) {

  }

  async ngOnInit(): Promise<any> {
    try {
      this.working = 20;
      this.accounts = [];
      const as = await this.agentService.getAgentAccounts(this.agent.id!, AgentLoadOptions.empty);
      as.forEach((a: IAccount) => {
        this.accounts.push({
          id: a.id,
          accountNumber: a.accountNumber,
          accountType: a.accountType,
          active: a.active,
          name: a.name,
          parentId: a.parentId,
          requistionApprover: false,
          role: { name: 'None', id: 0 }.id,
          enabled: true,
          changed: false,
          include: true,
          inherit: false,
          practice: a.practice,
          organization: a.organization
        });
      });

      this.accounts.forEach((acc: AccountAccess) => {
        this.working != 10;
        if (acc.parentId && !this.accounts.find(a => acc.parentId == a.id)) {
          acc.parentId = undefined;
        }
      });

    } catch (error: any) {
      this.monitor.handleError(error,  error?.error?.message ?? error.message);
    } finally {
      this.working = 0;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  select(a: IAccount, e: Event): void {

    e.stopPropagation();
    if (this.canView(a.id)) {
      this.expanded = false;
      this.agentService.addAuthorizedAccount(a);
      this.navigationService.practice(a.id).dashboard();
      this.selected.next(a);
    }
  }

  toggle(): void {
    this.expanded = !this.expanded;
  }

  canView(accountId: number): boolean {
    if (this.agent) {

    } else {
      return false;
    }
    return this.features.check(Feature.AccountRead, this.agent!.accountFeatures[accountId.toString()]);
  }

  onContentReady(e: any) {
    const rows = e.component.getVisibleRows();
    rows.forEach((row: any) => {
      const rowKey = e.component.getKeyByRowIndex(row.rowIndex);
      const rowData = row.data;
      const rowIndex = e.component.getRowIndexByKey(rowKey);
      const rowElement = e.component.getRowElement(rowIndex);
      if (rowData.active === false) {
        for (let i = 0; i < rowElement[0].children.length; ++i) {
          const element = rowElement[0].children[i];
          element.classList.add('inactive');
        }
      }
      if (rowData.include === false) {
        for (let i = 0; i < rowElement[0].children.length; ++i) {
          const element = rowElement[0].children[i];
          element.classList.add('not-included');
        };
      }
    });
  }

  onRowClick(s: any, e: any) {
    if (e.data.active === false || e.data.include === false) {
      e.cancel = true;
      s.SetFocusedRowIndex(e.rowIndex);
    }

  }

  onCellPrepared(e: any) {
    if (!e.row?.data?.enabled) {
      e.cellElement.classList.add('readonly');
    }

    if (e.rowIndex !== undefined && e.column.dataField === 'name') {
      const index = e.cellElement.innerHTML.indexOf('<div class="dx-treelist-text-content">');

      let icon: string = '';
      switch (e.data.accountType) {
        case (AccountType.GPO):
          icon = this.accountService.gpoIcon();
          break;
        case (AccountType.DSO):
          icon = this.accountService.dsoIcon();
          break;
        case (AccountType.PRACTICE):
          icon = this.accountService.practiceIcon();
          break;
        default:
          icon = this.accountService.practiceIcon('#ff1111');
          break;
      }

      e.cellElement.innerHTML = [
        '<div class="flex-row no-wrap">',
        e.cellElement.innerHTML.slice(0, index),
        '<div class="mr-small">',
        icon,
        '</div>',
        e.cellElement.innerHTML.slice(index),
        '</div>'].join('');
    }
  }

  // drop down funcationality
  syncTreeViewSelection(e: any) {
    if (!this.treeView) return;

    if (!this.treeBoxValue) {
      this.treeView.instance.unselectAll();

    } else {
      this.treeView.instance.selectItem(this.treeBoxValue);
    }
  }

  treeView_itemSelectionChanged(e: any) {
    this.treeBoxValue = e.itemData.id;
    this.selected.next(e.itemData);
  }

  onTreeBoxOptionChanged(e: any) {
    if (e.name === 'value') {
      this.isTreeBoxOpened = false;
      this.ref.detectChanges();
    }
  }

  async selectAccount(e: any): Promise<void> {
    const account = e.data;
    if (account?.include && account?.active && e.column.dataField === 'name' && e.event.target.classList.contains('dx-treelist-text-content')) {
      this.agentService.addAuthorizedAccount(account);
      this.selected.next(account);
      this.cart.account = account;
      await this.cart.refreshCart();

      switch (account.accountType) {
        case AccountType.PRACTICE:
          this.navigationService.practice(account.id).dashboard();
          break;
        case AccountType.DSO:
          this.navigationService.dso(account.id).dashboard();
          break;
        case AccountType.GPO:
          this.navigationService.gpo(account.id).dashboard();
          break;
        default:
          this.navigationService.public().message.invalidRoute();
          break;
      }
    }
  }
}
