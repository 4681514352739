import { environment } from '../../../environments/environment';

/**
 * Base API configuration file
 */
export class OrderApiConfig {
    static path: string = environment.api.order.path;
    static version: string | number | null = environment.api.order.version;

    public static setApiVersion(version: string = 'api'): void {
        OrderApiConfig.version = version;
    }

    public static getApiVersion(): string | number | null {
        return OrderApiConfig.version;
    }

    public static getPath(): string {
        return OrderApiConfig.path;
    }

    public static getFullUrl(path: string): string {
        if (OrderApiConfig.getApiVersion()) {
            return [OrderApiConfig.getPath(), OrderApiConfig.getApiVersion(), path].join('/');
        } else {
            return [OrderApiConfig.getPath(), path].join('/');
        }
    }
}