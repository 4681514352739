<div class="card flex-column {{ size }}">
  <div class="text text-primary no-wrap text-ellipsis product-name" [tooltip]="product?.name">
    {{ product?.name }}
  </div>
  <div class="manufacturer flex-row pb-small mb-small" *ngIf="product?.manufacturer">
    <label class="text mr-auto">
      {{ product?.manufacturer?.name }}
    </label>
    <label class="text sku">
      #{{ product?.sku }}
    </label>
  </div>
  <div class="flex-row no-wrap">
    <div class="flex-row" style="height: 28px;">
      <app-product-tag *ngFor="let t of product?.coreProductTags" [color]="t.c" [background]="t.b" [text]="t.t"></app-product-tag>
    </div>
    
    <div *ngIf="(product?.listing?.productCount ?? 0) > 1" 
      class="listing-count listing-space flex-row no-wrap variations-icon ml-auto">
      <span class="mr-small">{{ product?.listing?.productCount}}</span>
      <img src="https://cdn-icons-png.flaticon.com/128/5322/5322871.png" class="ml-auto mr-small" style="height: 20px; width: 20px;" />
    </div>  
  </div>

  <div class="image" [ngStyle]="{ 'background-image': 'url(' + product?.imageUrl + ')' }"></div>

  <app-add-to-cart id="getATCId()" [coreProduct]="product!" [mode]="(product?.listing?.productCount ?? 0) > 1 ? 'add-to' : 'add'" [hideRemove]="true" class="mb-small ml-auto mr-auto"></app-add-to-cart>
  <div class="bottom flex-column">
    <div class="flex-row">
      <div class="text price mr-auto">
        {{ product?.price}}
      </div>
      <div *ngIf="(product?.listing?.productCount ?? 0) > 1" class="text text-300">
        <a [class]="'view-variations-' + product?.sku + ' link'" (click)="openModal(getModalId()); show_variations.openVariations()">
          Show Variations
        </a>
        <app-modal [confirmClose]="true" [id]="getModalId()" size="large" [heading]="'Variations of ' + product?.listing?.name"  title="Variations">
          <app-product-variations #show_variations [modalId]="getModalId()" [isListing]="true" [product]="product" [showAll]="true"  [mainImageUrl]="product?.imageUrl"></app-product-variations>
        </app-modal>
      </div>
    </div>
    <div *ngIf="product?.lastPurchaseDate" class="flex-column">
      Purchased {{ product?.lastPurchaseQuantity | number }} on {{ product?.lastPurchaseDate | date:'longDate' }}
    </div>
    <div *ngIf="!product?.lastPurchaseDate" class="flex-column">
      &nbsp;
    </div>
  </div>
</div>
