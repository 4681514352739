import { Component } from '@angular/core';
import { User } from '@auth0/auth0-angular';
import { Subscription } from 'rxjs';
import { Agent, AccountType, Profile } from '../../models';
import { Feature, FeatureService } from '../../services/feature.service';
import { DropdownContent } from '../../user-type';
import { AgentService, CartService, LoginService, ModalService, MonitorService, NavigationService } from '../../services';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss']
})



export class ReleaseNotesComponent {
  user: User | undefined;
  dropdownContent: DropdownContent[] = [];
  myAccount: DropdownContent | undefined;
  agent: Agent | undefined = undefined;
  working = false;
  nochromeMode = false;
  loadingMessage = '';
  mobileexpanded = false;
  accountType = AccountType;
 // public version: string = packageJson.version;
  feature = Feature;

  profile = Profile;
  private subscriptions: Subscription[] = [];

  constructor(
    private loginService: LoginService,
    private agentService: AgentService,
    private navigationService: NavigationService,
    private gtm: GoogleTagManagerService,
    private monitor: MonitorService,
    private titleService: Title,
    public modals: ModalService,
    public featureService: FeatureService,
    private cartService: CartService,
    private activatedRoute: ActivatedRoute
  ) {
    this.user = this.loginService.authenticatedUser;
    this.agent = this.agentService.authenticatedAgent;


    this.subscriptions.push(this.agentService.authenticated$.subscribe(async u => {
      try {
        this.working = true;
        this.agent = u;
        this.user = this.loginService.authenticatedUser;
        this.agent = await this.agentService.authenticate();
        this.agent = this.agentService.authenticatedAgent;
        this.myAccount = {
          name: 'My Actions',
          claims: [],
          children: [
          ],
        };
        this.monitor.logEvent('login', { name: this.user?.name });
      } catch (error) {
        this.monitor.handleError(error, 'Error authenticating');
      } finally {
        this.working = false;
      }
    }));

    this.subscriptions.push(this.agentService.profileChange$.subscribe(async (a: Agent) => {

      let account = this.agentService.currentAccount;

      await agentService.trackProfileSwitch(account);
    }));

  }
}
