import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from '@auth0/auth0-angular';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { QuillModule } from 'ngx-quill';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminAgentEditComponent } from './components/admin/admin-agent-edit/admin-agent-edit.component';
import { AdminAgentViewComponent } from './components/admin/admin-agent-view/admin-agent-view.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { AgentDropDownComponent } from './components/agent-drop-down/agent-drop-down.component';
import { AuthInterceptor } from './interceptors';
import { ConfirmComponent } from './shared/components/confirm/confirm.component';
import { DropDownFormControlComponent } from './shared/components/drop-down-form-control/drop-down-form-control.component';
import { DropDownNavigateComponent } from './shared/components/drop-down-navigate/drop-down-navigate.component';
import { DropListFormControlComponent } from './shared/components/drop-list-form-control/drop-list-form-control.component';
import { ModalComponent } from './shared/components/modal/modal.component';
import { MultiOptionSelectComponent } from './shared/components/multi-option-select/multi-option-select.component';
import { CardComponent } from './shared/components/card/card.component';
import { ArrayDoubleSortPipe, ArraySortPipe, NameIdFilterPipe,} from './shared/pipes';
import { SplitPipe } from './shared/pipes/split.pipe';
import { ErrorHandlerService, FormatNumberService, LoginService, ModalService, MonitorService, AgentService, CartService, ClipboardService, CatalogService, OrderService} from './shared/services';
import { DashboardComponent } from './components/dashboard/main/dashboard.component';
import { PagingComponent } from './shared/components/paging/paging.component';
import { ProgressBarComponent } from './shared/components/progress-bar/progress-bar.component';
import { SortBaseComponent } from './shared/components/sort-base/sort-base.component';
import { PhoneMaskDirective } from './shared/directives/phone-mask.directive';
import { AccountCardComponent } from './components/account-card/account-card.component';
import { TooltipDirective } from './shared/directives/tooltip.directive';
import { AlertIconComponent } from './shared/components/icons/alert-icon/alert-icon.component';
import { CheckIconComponent } from './shared/components/icons/check-icon/check-icon.component';
import { CurrencyMaskDirective } from './shared/directives/currency.directive';
import { MaterialModule } from '../material.module';
import { MenuBarComponent } from './shared/components/menu-bar/menu-bar.component';
import { AgencyEditComponent } from './components/agency/edit/agency-edit/agency-edit.component';
import { AgencyListViewComponent } from './components/agency/agency-list-view/agency-list-view.component';
import { AgencyEditDetailsComponent } from './components/agency/edit/agency-edit-details/agency-edit-details.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DxButtonModule, DxCheckBoxModule, DxDataGridModule, DxFormModule, DxHtmlEditorModule, DxNumberBoxModule, DxSelectBoxModule, DxTreeListModule, DxValidationGroupModule, DxValidationSummaryModule,
  DxValidatorModule, DxTabsModule, DxDropDownBoxModule, DxTreeViewModule, DxPopupModule, DxFileUploaderModule, DxListModule, 
  DxButtonGroupModule, DxTextBoxModule, DxTabPanelModule, DxDateBoxModule, DxDateRangeBoxModule, 
  DxTextAreaModule} from 'devextreme-angular';
import { PlatformNavComponent } from './components/nav/platform-nav/platform-nav.component';
import { RecoverPasswordComponent } from './components/auth/recover-password/recover-password.component';
import { AgencyEditAgentsComponent } from './components/agency/edit/agency-edit-agents/agency-edit-agents.component';
import { AgencyEditAccessComponent } from './components/agency/edit/agency-edit-access/agency-edit-access.component';
import { AgencyEditAccountsComponent } from './components/agency/edit/agency-edit-accounts/agency-edit-accounts.component';
import { PageNotFoundComponent } from './components/nav/page-not-found/page-not-found.component';
import { AccountNavComponent } from './components/nav/account-nav/account-nav.component';
import { AgencyEditAgentDetailsComponent } from './components/agency/edit/agency-edit-agent-details/agency-edit-agent-details.component';
import { AccountEditComponent } from './components/account/edit/account-edit/account-edit.component';
import { AccountListViewComponent } from './components/account/account-list-view/account-list-view.component';
import { AccessComponent } from './shared/components/access/access.component';
import { FeatureService } from './shared/services/feature.service';
import { UnauthorizedComponent } from './components/nav/unauthorized/unauthorized.component';
import { AccountEditDetailsComponent } from './components/account/edit/account-edit-details/account-edit-details.component';
import { AccountEditAgenciesComponent } from './components/account/edit/account-edit-agencies/account-edit-agencies.component';
import { AccountSelectorComponent } from './components/account/account-selector/account-selector.component';
import { DsoNavComponent } from './components/nav/dso-nav/dso-nav.component';
import { GpoNavComponent } from './components/nav/gpo-nav/gpo-nav.component';
import { PracticeNavComponent } from './components/nav/practice-nav/practice-nav.component';
import { DsoDashboardComponent } from './components/dashboard/dso-dashboard/dso-dashboard.component';
import { GpoDashboardComponent } from './components/dashboard/gpo-dashboard/gpo-dashboard.component';
import { PracticeDashboardComponent } from './components/dashboard/practice-dashboard/practice-dashboard.component';
import { PlatformDashboardComponent } from './components/dashboard/platform-dashboard/platform-dashboard.component';
import { CartQuickViewComponent } from './components/cart/cart-quick-view/cart-quick-view.component';
import { GenericAgentDashboardComponent } from './components/dashboard/generic-agent-dashboard/generic-agent-dashboard.component';
import { InactiveAgentDashboardComponent } from './components/dashboard/inactive-agent-dashboard/inactive-agent-dashboard.component';
import { AgentAuthorizationGuard } from './shared/guard/agentAuthorization.guard';
import { AuditInfoComponent } from './shared/components/audit-info/audit-info.component';
import { AccountEditAddressComponent } from './components/account/edit/account-edit-address/account-edit-address.component';
import { AccountEditSuppliersComponent } from './components/account/edit/account-edit-suppliers/account-edit-suppliers.component';
import { AccountEditSupplierDetailsComponent } from './components/account/edit/account-edit-supplier-details/account-edit-supplier-details.component';
import { FormularyListViewComponent } from './components/formulary/formulary-list-view/formulary-list-view.component';
import { FormularyEditComponent } from './components/formulary/edit/formulary-edit/formulary-edit.component';
import { FormularyEditDetailsComponent } from './components/formulary/edit/formulary-edit-details/formulary-edit-details.component';
import { FormularyEditProductsComponent } from './components/formulary/edit/formulary-edit-products/formulary-edit-products.component';
import { FormularyService } from './shared/services/formulary.service';
import { AccountEditFormularyComponent } from './components/account/edit/account-edit-formulary/account-edit-formulary.component';
import { CatalogSearchComponent } from './components/catalog/catalog-search/catalog-search.component';
import { CoreProductComponent } from './components/products/core-product/core-product.component';
import { SupplierProductComponent } from './components/products/supplier-product/supplier-product.component';
import { SupplierProductCardComponent } from './components/catalog/supplier-product-card/supplier-product-card.component';
import { CoreProductCardComponent } from './components/catalog/core-product-card/core-product-card.component';
import { OrganizationEditFormularyConfigurationComponent } from './components/account/edit/organization-edit-formulary-configuration/organization-edit-formulary-configuration.component';
import { PracticeEditFormularyConfigurationComponent } from './components/account/edit/practice-edit-formulary-configuration/practice-edit-formulary-configuration.component';
import { ProductSearchComponent } from './components/catalog/product-search/product-search.component';

import { PurchaseRequisitionListComponent } from './components/account/purchase-requisition/purchase-requisition-list/purchase-requisition-list.component';
import { AccountEditFormularyProductsCompositeComponent } from './components/account/edit/account-edit-formulary-products-composite/account-edit-formulary-products-composite.component';
import { AddToCartComponent } from './components/cart/add-to-cart/add-to-cart.component';
import { ReviewCartComponent } from './components/cart/review-cart/review-cart.component';
import { ReviewOrderComponent } from './components/order/review-order/review-order.component';
import { OrderListViewComponent } from './components/order/order-list-view/order-list-view.component';
import { OrderDetailsComponent } from './components/order/order-details/order-details.component';
import { ProductVariationsComponent } from './shared/components/product-variations/product-variations.component';
import { AboutComponent } from './shared/components/about/about.component';
import { ContactComponent } from './shared/components/contact/contact.component';
import { PrivacyPolicyComponent } from './shared/components/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './shared/components/terms-of-use/terms-of-use.component';
import { FAQComponent } from './shared/components/faq/faq.component';
import { PurchaseRequisitionComponent } from './components/purchase-requisition/purchase-requisition/purchase-requisition.component';
import { ProductDetailsIconComponent } from './shared/components/product-details-icon/product-details-icon.component';
import { ReleaseNotesComponent } from './shared/components/release-notes/release-notes.component';
import { ProductTagComponent } from './components/products/product-tag/product-tag.component';
import { ProductLineTotalComponent } from './shared/components/product-line/product-line-total/product-line-total.component';
import { OutOfStockTagComponent } from './components/products/out-of-stock-tag/out-of-stock-tag.component';
import { RecommendedTagComponent } from './components/products/recommended-tag/recommended-tag.component';
import { NavToLoginComponent } from './components/login/nav-to-login/nav-to-login.component';
import { DisplayMessageComponent } from './components/display-message/display-message.component';
import { GenericNavComponent } from './components/nav/generic-nav/generic-nav.component';
import { BreadCrumbComponent } from './shared/components/bread-crumb/bread-crumb.component';
import { OptimizeOrderRowComponent } from './components/cart/review-cart/optimize-order-row/optimize-order-row.component';
import { BudgetPreviewComponent } from './shared/components/budget/budget-preview/budget-preview.component';
import { AccountService } from './shared/services/account.service';
import { AgencyService } from './shared/services/agency.service';
import { BudgetService } from './shared/services/budget.service';
import { SupplierService } from './shared/services/supplier.service';
import { ReportingDashboardComponent } from './components/dashboard/reporting-dashboard/reporting-dashboard.component';
import { GenericReportComponent } from './shared/components/generic-report/generic-report.component';
import { GenericFiltersComponent } from './shared/components/generic-filters/generic-filters.component';
import { PendingApprovalsListViewComponent } from './components/pending-approvals/pending-approvals-list-view/pending-approvals-list-view.component';
import { SubmissionDetailsFormComponent } from './components/order/submission-details-form/submission-details-form.component';
import { DisplayApproverListComponent } from './shared/components/display-approver-list/display-approver-list.component';
import { ApprovalsOverviewComponent } from './shared/components/approvals-overview/approvals-overview.component';
import { ApprovalsApproversComponent } from './shared/components/approvals-approvers/approvals-approvers.component';
import { ApprovalsTimelineComponent } from './shared/components/approvals-timeline/approvals-timeline.component';
import { ApprovalSubmitComponent } from './shared/components/approval-submit/approval-submit.component';
import { PurchaseOrderService } from './shared/services/purchaseOrder.service';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';


@NgModule({
  declarations: [
    AppComponent,
    ArraySortPipe,
    ArrayDoubleSortPipe,
    SplitPipe,
    NameIdFilterPipe,
    AdminAgentEditComponent,
    AdminAgentViewComponent,
    ModalComponent,
    ConfirmComponent,
    AgentDropDownComponent,
    LoginComponent,
    HomeComponent,
    DropDownNavigateComponent,
    DropDownFormControlComponent,
    DropListFormControlComponent,
    MultiOptionSelectComponent,
    CardComponent,
    DashboardComponent,
    ProgressBarComponent,
    PagingComponent,
    SortBaseComponent,
    CurrencyMaskDirective,
    PhoneMaskDirective,
    AccountCardComponent,
    TooltipDirective,
    AlertIconComponent,
    CheckIconComponent,
    MenuBarComponent,
    AgencyEditComponent,
    AgencyListViewComponent,
    AgencyEditDetailsComponent,
    PlatformNavComponent,
    RecoverPasswordComponent,
    AgencyEditAgentsComponent,
    AgencyEditAccessComponent,
    AgencyEditAccountsComponent,
    PageNotFoundComponent,
    AccountNavComponent,
    AgencyEditAgentDetailsComponent,
    AccountListViewComponent,
    AccountEditComponent,
    AccountEditDetailsComponent,
    AccessComponent,
    UnauthorizedComponent,
    AccountEditAgenciesComponent,
    AccountSelectorComponent,
    DsoNavComponent,
    GpoNavComponent,
    PracticeNavComponent,
    DsoDashboardComponent,
    GpoDashboardComponent,
    PracticeDashboardComponent,
    PlatformDashboardComponent,
    CartQuickViewComponent,
    GenericAgentDashboardComponent,
    InactiveAgentDashboardComponent,
    AuditInfoComponent,
    AccountEditSuppliersComponent,
    AccountEditSupplierDetailsComponent,
    AccountEditAddressComponent,
    FormularyListViewComponent,
    FormularyEditComponent,
    FormularyEditDetailsComponent,
    FormularyEditProductsComponent,
    AccountEditFormularyComponent,
    CatalogSearchComponent,
    CoreProductComponent,
    SupplierProductComponent,
    CardComponent,
    SupplierProductCardComponent,
    CoreProductCardComponent,
    OrganizationEditFormularyConfigurationComponent,
    PracticeEditFormularyConfigurationComponent,
    PurchaseRequisitionListComponent,
    AccountEditFormularyProductsCompositeComponent,
    ProductSearchComponent,
    AddToCartComponent,
    ReviewCartComponent,
    ReviewOrderComponent,
    OrderListViewComponent,
    OrderDetailsComponent,
    ProductVariationsComponent,
    FAQComponent,
    AboutComponent,
    ContactComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    PurchaseRequisitionComponent,
    ProductVariationsComponent,
    ProductDetailsIconComponent,
    ReleaseNotesComponent,
    ProductTagComponent,
    ProductLineTotalComponent,
    OutOfStockTagComponent,
    RecommendedTagComponent,
    NavToLoginComponent,
    DisplayMessageComponent,
    GenericNavComponent,
    BreadCrumbComponent,
    OptimizeOrderRowComponent,
    BudgetPreviewComponent,
    ReportingDashboardComponent,
    GenericReportComponent,
    GenericFiltersComponent,
    PendingApprovalsListViewComponent,
    SubmissionDetailsFormComponent,
    DisplayApproverListComponent,
    ApprovalsOverviewComponent,
    ApprovalsApproversComponent,
    ApprovalsTimelineComponent,
    ApprovalSubmitComponent,
    ConfirmDialogComponent,
  ],
  imports: [
    AppRoutingModule,
    CommonModule,
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    QuillModule.forRoot(),
    BrowserAnimationsModule,
    NgxChartsModule,
    NgxDatatableModule,
    DxButtonModule,
    DxTabsModule,
    DxDataGridModule,
    DxTextBoxModule,
    DxCheckBoxModule,
    DxSelectBoxModule,
    DxNumberBoxModule,
    DxTextAreaModule,
    DxTreeListModule,
    DxFormModule,
    DxHtmlEditorModule,
    DxDateBoxModule,
	DxDateRangeBoxModule,
    DxSelectBoxModule,
    DxValidatorModule,
    DxValidationGroupModule,
    DxValidationSummaryModule,
    DxDropDownBoxModule,
    DxTreeViewModule,
    DxFileUploaderModule,
    DxPopupModule,
    DxButtonGroupModule,
    DxListModule,
    DxButtonGroupModule,
    DxTabPanelModule,
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      cacheLocation: 'localstorage',
      useRefreshTokens: environment.auth0.useRefreshTokens,
      authorizationParams: {
        redirect_uri: `${window.location.origin}/public/login`,
        audience: environment.auth0.audience,
        scope: environment.auth0.scope,
      },
      httpInterceptor: {
        allowedList: [
          'api',
          'api/*'
      ]}
    }),
  ],
  providers: [
    AgentAuthorizationGuard,
    AgentService,
    CartService,
    FeatureService,
    FormatNumberService,
    ModalService,
    MonitorService,
    AgentService,
    FormularyService,
    LoginService,
    ClipboardService,
    CatalogService,
    AccountService,
    BudgetService,
    AgencyService,
    SupplierService,
    OrderService,
    PurchaseOrderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: 'googleTagManagerId',
      useValue: environment.google.tagId,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
