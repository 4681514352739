<app-progress [splash]="true" [value]="working">
    <div class="account-edit flex-column">
        <div class="header flex-row no-wrap justify-between">
            <div class="flex-row">
                <H3 *ngIf="formulary?.id; else new">{{formularyName ?? formulary?.name}}</H3>
            </div>
            <ng-template #new>
                <h3 *ngIf="formulary?.id === 0; else placeholder">New Formulary</h3>
            </ng-template>
            <ng-template #placeholder>
                <h3>Formulary</h3>
            </ng-template>
            <div *ngIf="showBackButton" class="flex-row">
                <button class="btn secondary text text-rubik ml-medium " (click)="goBack()">Go Back</button>
            </div>
            <div *ngIf="!showBackButton" class="flex-row">
                <button class="btn secondary text text-rubik ml-medium " (click)="backToFormularies()">Return to
                    Formulary List</button>
            </div>
        </div>

        <div class="body">
            <div class="info">

                        
                <div #navbar *ngIf="formulary" class="sidebar mr-medium mb-auto">

                    <app-access  *ngFor="let nav of appAccessNavigationOptions" [platformFeature]="nav.feature" [ngClass]="{'disabled' : !formulary!.id}" routerLinkActive="active" [displayIfNoAccess]="false">
                        <a id="nav.aTagId" (click)="nav.action()" class="content text text-500 black">{{nav.aTagText}}</a>
                    </app-access>
                </div>
                <div class="ml-small">
                    <router-outlet (activate)='onActivate($event)'></router-outlet>
                </div>
            </div>
            <app-audit-info [auditInfo]="formulary"></app-audit-info>

        </div>
    </div>
</app-progress>