import { environment } from '../../../environments/environment';

/**
 * Base API configuration file
 */
export class AccountApiConfig {
    static path: string = environment.api.account.path;
    static version: string | number | null = environment.api.account.version;

    public static setApiVersion(version: string = 'api'): void {
        AccountApiConfig.version = version;
    }

    public static getApiVersion(): string | number | null {
        return AccountApiConfig.version;
    }

    public static getPath(): string {
        return AccountApiConfig.path;
    }

    public static getFullUrl(path: string): string {
        if (AccountApiConfig.getApiVersion()) {
            return [AccountApiConfig.getPath(), AccountApiConfig.getApiVersion(), path].join('/');
        } else {
            return [AccountApiConfig.getPath(), path].join('/');
        }
    }
}
