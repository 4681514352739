<app-progress [splash]="true" [value]="working">
  <div class="account-edit flex-column">
    <div class="header flex-row no-wrap justify-between">
      <H3>Agents</H3>
    </div>
    <div class="body info">
      <app-access [platformFeature]="feature.AgentUpdate" [displayIfNoAccess]="true">
  
      <dx-data-grid id="dataGrid" [showColumnLines]="true" [dataSource]="dataSource" 
      [showBorders]="true" [columnAutoWidth]="true" (onCellClick)="onCellClick($event)"
      [wordWrapEnabled]="false">
        <dxo-filter-row [visible]="true" ></dxo-filter-row>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-paging [pageSize]="10" [pageIndex]="0"></dxo-paging>
        <dxi-column dataField="firstName" [minWidth]="200" caption="First Name" sortOrder="asc" [sortIndex]="0"></dxi-column>
        <dxi-column dataField="lastName" [minWidth]="200" dataType="string" caption="Last Name" sortOrder="asc" [sortIndex]="1"></dxi-column>
        <dxi-column dataField="email" [minWidth]="240" dataType="string" caption="Email"></dxi-column>
        <dxi-column dataField="userName" [minWidth]="240" dataType="string" caption="Username"></dxi-column>
        <dxi-column dataField="lastLogin" [minWidth]="140" dataType="string" caption="Last Login" [allowResizing]="true"></dxi-column>
        <dxi-column [allowHeaderFiltering]="false" [allowFiltering]="true" dataField="active" [minWidth]="240" [alignment]="'left'"
          dataType="boolean" caption="Active" falseText="No" trueText="Yes" [filterValue]="true">
        </dxi-column>
      </dx-data-grid>
      <dx-button *ngIf="agency" class="mt-large" [width]="120" (click)="newAgent()">Add New Agent</dx-button>
      </app-access>
    </div>
  
  </div>
</app-progress>
