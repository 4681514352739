<app-access [platformFeature]="feature.AccountUpdate" [redirectOnFailure]="true">
    <app-progress [splash]="true" [value]="working">
        <div class="account-edit flex-column">
            <div class="header flex-row no-wrap justify-between">
                <h3>Accounts</h3>
            </div>
            <div class="body info">
                <dx-tree-list id="tasks" [dataSource]="dataSource" [columnAutoWidth]="true"
                    [showBorders]="true" [showColumnLines]="true" [allowColumnResizing]="true" [wordWrapEnabled]="false"
                    (onContentReady)="onContentReady($event)" (onCellClick)="onCellClick($event)">
                    <dxo-search-panel [visible]="true" [width]="240"></dxo-search-panel>
                    <dxo-header-filter [visible]="true"></dxo-header-filter>
                    <dxo-filter-row [visible]="true" [applyFilter]="'auto'"></dxo-filter-row>

                    <dxi-column [width]="600" sortOrder="asc" dataField="name"></dxi-column>
                    <dxi-column dataField="accountNumber" [alignment]="'left'" [width]="200"></dxi-column>
                    <dxi-column dataType="boolean" [alignment]="'left'" dataField="active" [falseText]="'No'"
                        [trueText]="'Yes'" [allowHeaderFiltering]="false" [allowFiltering]="true" [filterValue]="true">
                    </dxi-column>
                </dx-tree-list>
                <dx-button class="mt-large" [width]="120" (click)="newAccount()">Add New
                    Account
                </dx-button>
            </div>
        </div>
    </app-progress>
</app-access>