import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DxFormComponent, DxTreeViewComponent } from 'devextreme-angular';
import { MonitorService, NavigationService } from 'src/app/shared/services';
import { IAccount, AccountType, Agency, Recommendation } from 'src/app/shared/models';
import { AccountService } from 'src/app/shared/services/account.service';
import CustomStore from 'devextreme/data/custom_store';
import { Feature } from 'src/app/shared/services/feature.service';

@Component({
  selector: 'app-account-edit-agencies',
  templateUrl: './account-edit-agencies.component.html',
  styleUrls: ['./account-edit-agencies.component.scss']
})
export class AccountEditAgenciesComponent implements OnInit{



  @ViewChild(DxTreeViewComponent, { static: false }) 
  treeView!: DxTreeViewComponent;
  
  @Input()
  account: IAccount | undefined;

  working = 0; // Set to true when waiting for async queries to complete. Prevents bad requests
  feature = Feature;

  dataSource: any;

  constructor(
    private accountService: AccountService,
    private monitor: MonitorService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService
  ) {
  
  }

  async ngOnInit() {

    this.activatedRoute.parent!.paramMap.subscribe(async params => {
      const accountId = params.get('accountId');

      if (accountId) {
        this.account = await this.accountService.getAccountById(parseInt(accountId), { addresses: true, agents: true, parent: true, budget: true, suppliers: true });

        if (this.account && !this.account?.recommendation) {
          this.account.recommendation = Recommendation.Inherit;
        }

        try {
          this.working = 50;
    
          var a = await this.accountService.getAccountAgencies(this.account?.id ?? 0, { agenices: true });
    
          let agencies: Agency[] = [];
          this.dataSource = new CustomStore({
            loadMode: 'raw',
            load: () => {
              return a ?? agencies;
            },
          });
        } catch (error: any) {
          this.monitor.handleError(error, error?.error?.message ?? error.message);
        } finally {
          this.working = 0;
        }
      }
    });
  }

  // Navigate to agency page of selected agency
  onCellClick(event: any) {
    if (event.column.dataField === 'name' && event.data?.id) {
      const agencyId = event.data?.id;
      const navigationExtras = {
        state: {
          fromSpecificPage: true
        }
      };

      if (event.data.include !== false) {
        this.navigationService.backoffice().agency.details(agencyId);
      }
    }
  }

    onContentReady(e: any) {
      setTimeout(() => {
        const rows = e.component.getVisibleRows();
        rows.forEach((row: any) => {
          const rowKey = e.component.getKeyByRowIndex(row.rowIndex);
          const rowData = row.data;
          const rowIndex = e.component.getRowIndexByKey(rowKey);
          const rowElement = e.component.getRowElement(rowIndex);
          if (!rowData.active) {
            rowElement[0].firstElementChild.classList.add('inactive');
          }
        });
      }, 0);
    }


  }
