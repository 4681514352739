import { Component, Input } from '@angular/core';
import { ILevelApproverStatuses } from '../../models/approval.overview.model';

@Component({
  selector: 'app-approvals-approvers',
  templateUrl: './approvals-approvers.component.html',
  styleUrls: ['./approvals-approvers.component.scss']
})
export class ApprovalsApproversComponent {
  @Input() approvers!: ILevelApproverStatuses[];

}
