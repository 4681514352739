import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Agent } from 'src/app/shared/models';
import { AgentService, NavigationService } from 'src/app/shared/services';

@Component({
  selector: 'app-inactive-agent-dashboard',
  templateUrl: './inactive-agent-dashboard.component.html',
  styleUrls: ['./inactive-agent-dashboard.component.scss']
})
export class InactiveAgentDashboardComponent implements OnInit {

  agent: Agent | undefined;

  isSad = false;
  constructor(
    private agents: AgentService,
    private navigationService: NavigationService) {
  }

  async ngOnInit(): Promise<any> {
    this.agent = this.agents.authenticatedAgent;
    if (!this.agent) {
      this.agent = await this.agents.authenticate();
    }
    this.navigationService.public().message.noAccess();
    setTimeout(() => {
      this.isSad = true;
     }, 1800);
  }
}
