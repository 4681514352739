<app-progress [splash]="true" [value]="working">
    <app-access [platformFeature]="feature.AgentUpdate" [redirectOnFailure]="true">
        <form *ngIf="agent" (ngSubmit)="formSubmit($event)">
            <dx-form id="agentForm" [(formData)]="agent" labelLocation="top" [showColonAfterLabel]="false"
                (onFieldDataChanged)="onFieldDataChanged($event)" (input)="updateUsername($event)">
                <dxi-item itemType="tabbed"> 
                    <dxi-tab title="Agent Details" [colCount]="1">
                        <dxi-item dataField="firstName" [isRequired]="true">
                            <dxo-label template="First Name"></dxo-label>
                            <dxi-validation-rule type="required" message="First Name is required"></dxi-validation-rule>
                        </dxi-item>
                        <dxi-item dataField="lastName" [isRequired]="true">
                            <dxo-label template="Last Name"></dxo-label>
                            <dxi-validation-rule type="required" message="Last Name is required"></dxi-validation-rule>
                        </dxi-item>
                        <dxi-item dataField="email" id="emailField" [isRequired]="true">
                            <dxo-label text="Email"></dxo-label>
                            <dxi-validation-rule type="custom"
                                [validationCallback]="validateEmail"></dxi-validation-rule>
                        </dxi-item>
                        <dxi-item itemType="simple" [editorType]="'dxCheckBox'" dataField="customUsername"></dxi-item>
                        <dxi-item dataField="userName" dataType="string" id="usernameField" [isRequired]="agent!.customUsername" [editorOptions]="{ readOnly: agent?.id }" [editorOptions]="{ readOnly: !agent!.customUsername }">
                            <dxo-label template="Username"></dxo-label>
                            <dxi-validation-rule type="required" message="Username is required"></dxi-validation-rule>
                        </dxi-item>
                        <dxi-item itemType="simple" [editorType]="'dxCheckBox'" dataField="active"></dxi-item>
                        <dxi-item dataField="lastLogin" dataType="string" [isRequired]="false" [editorOptions]="{ readOnly: true}">
                            <dxo-label text="Last Login"></dxo-label>
                        </dxi-item>
                        <dxi-item *ngIf="agent?.inviteLink" dataField="inviteLink" dataType="string" [isRequired]="false" [editorOptions]="{ readOnly: true, value: agent?.inviteLink }">
                            <dxo-label text="Invitation Link"></dxo-label>
                        </dxi-item>
                    </dxi-tab>
                    <dxi-tab title="Platform Details" *ngIf="isPlatformAgency">
                        <dxi-item dataField="role" [isRequired]="true" editorType="dxSelectBox"
                            [editorOptions]="{ dataSource: roles, displayExpr: 'name', valueExpr: 'name' }">
                            <dxo-label template="Platform Role"></dxo-label>
                            <dxi-validation-rule type="required"></dxi-validation-rule> 
                        </dxi-item>

                        <dxi-item dataField="features" *ngFor="let f of currentFeatures">
                            <dxo-label template="{{f.name}}"></dxo-label>
                            <dx-check-box (onValueChanged)="checkboxCheck(f, $event)"
                                [value]="f.checked"></dx-check-box>
                        </dxi-item>

                    </dxi-tab>
                </dxi-item>
            </dx-form>
            <dx-button [disabled]="!isValid" [useSubmitBehavior]="true" text="Save"></dx-button>
        </form>
    </app-access>
</app-progress>