import { BudgetPeriodDetail } from "./budget.detail.model";

export class BudgetPeriod implements IBudgetPeriod {
    budgetPeriodId: number;
    budgetPeriodTitle: string;
    budgetPeriodStartDate: Date;
    budgetPeriodFinishDate: Date;
    budgetPeriodType: BudgetPeriodTypeEnum;
    totalSpent?: number;
    currentTotalSpend: number;
    periodBudget: number;
    minSpend?: number | undefined;
    periodSequence: number;

    constructor(data: IBudgetPeriod) {
        this.budgetPeriodId = data.budgetPeriodId;
        this.budgetPeriodTitle = data.budgetPeriodTitle;
        this.budgetPeriodStartDate = data.budgetPeriodStartDate;
        this.budgetPeriodFinishDate = data.budgetPeriodFinishDate;
        this.budgetPeriodType = data.budgetPeriodType;
        this.totalSpent = data.totalSpent ?? 0;
        this.periodBudget = data.periodBudget;
        this.minSpend = data.minSpend ?? 0;
        this.periodSequence = data.periodSequence;
        this.currentTotalSpend = 0;

    
    }

    addBudgetUsed(amount: number) : void {
        this.currentTotalSpend += amount;
    }

    resetBudgetUsed() : void {
        this.currentTotalSpend = 0;
    }
}

export interface IBudgetPeriod {
    budgetPeriodId: number;
    budgetPeriodTitle: string;
    budgetPeriodStartDate: Date;
    budgetPeriodFinishDate: Date;
    budgetPeriodType: BudgetPeriodTypeEnum;
    totalSpent?: number;
    periodBudget: number;
    minSpend?: number;
    periodSequence: number;
}

export enum BudgetPeriodTypeEnum {
    Monthly = 0,
    Quarterly = 1,
    Annually = 2
}