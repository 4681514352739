import { environment } from '../../../environments/environment';

/**
 * Base API configuration file
 */
export class RequisitionApiConfig {
    static path: string = environment.api.requisition.path;
    static version: string | number | null = environment.api.requisition.version;

    public static setApiVersion(version: string = 'api'): void {
        RequisitionApiConfig.version = version;
    }

    public static getApiVersion(): string | number | null {
        return RequisitionApiConfig.version;
    }

    public static getPath(): string {
        return RequisitionApiConfig.path;
    }

    public static getFullUrl(path: string): string {
        if (RequisitionApiConfig.getApiVersion()) {
            return [RequisitionApiConfig.getPath(), RequisitionApiConfig.getApiVersion(), path].join('/');
        } else {
            return [RequisitionApiConfig.getPath(), path].join('/');
        }
    }
}