import { environment } from '../../../environments/environment';

/**
 * Base API configuration file
 */
export class SupplierApiConfig {
    static path: string = environment.api.supplier.path;
    static version: string | number | null = environment.api.supplier.version;

    public static setApiVersion(version: string = 'api'): void {
        SupplierApiConfig.version = version;
    }

    public static getApiVersion(): string | number | null {
        return SupplierApiConfig.version;
    }

    public static getPath(): string {
        return SupplierApiConfig.path;
    }

    public static getFullUrl(path: string): string {
        if (SupplierApiConfig.getApiVersion()) {
            return [SupplierApiConfig.getPath(), SupplierApiConfig.getApiVersion(), path].join('/');
        } else {
            return [SupplierApiConfig.getPath(), path].join('/');
        }
    }
}
