import { AfterViewInit, Component, Input } from '@angular/core';

@Component({
  selector: 'app-out-of-stock-tag',
  templateUrl: './out-of-stock-tag.component.html',
  styleUrls: ['./out-of-stock-tag.component.scss']
})
export class OutOfStockTagComponent implements AfterViewInit {

  @Input()
  show = false;

  @Input()
  floatDirection: 'left' | 'right' | 'alternates' | 'relative' | 'variations' = 'left';

  _show = false;

  ngAfterViewInit(): void {
    setTimeout(() => {
      this._show = this.show;
    }, 100);
  }

}
