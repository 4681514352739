<div class="scrollable-container">
	<div class="column">
		<ng-container *ngFor="let levelApprover of levelApprovers; let j = index;">
			<div *ngIf="levelApprover.approvalLevelName" class="approval-level">{{levelApprover.approvalLevelName}}</div>
			<div *ngFor="let approver of levelApprover.approvers; let i = index;">

				<div *ngIf="i % 2 === 0" class="line">

					<div class="agents">
						<div class="avatar-agent">
							<div class="avatar"></div>
							<div class="agent">
								<div class="approver-header">
									<div class="approver-name">{{approver.agentName}}</div>

								</div>
								<a class="approver-description" [href]="'mailto:' + approver.agentEmail">{{approver.agentEmail}}</a>
							</div>
						</div>

						<!-- Display the next approver if it exists -->
						<ng-container *ngIf="levelApprover.approvers[i + 1]">

							<div class="avatar-agent">
								<div class="avatar"></div>
								<div class="agent">
									<div class="approver-header">
										<div class="approver-name">{{levelApprover.approvers[i + 1].agentName}}</div>

									</div>
									<a class="approver-description" [href]="'mailto:' + levelApprover.approvers[i + 1].agentEmail">{{levelApprover.approvers[i + 1].agentEmail}}</a>
								</div>
							</div>
						</ng-container>
					</div>

				</div>
			</div>

		</ng-container>
	</div>
</div>


<!-- src="https://placehold.co/80x80" -->