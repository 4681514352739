import { ComponentCanDeactivate } from "./component.candeactivate";
import { LoginService, MonitorService } from "../services";
import { Router } from "@angular/router";

export abstract class FormCanDeactivate extends ComponentCanDeactivate {

    constructor(
        monitor: MonitorService,
        login: LoginService,
        protected router: Router
    ) {
        super(monitor, login);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        }
        this.router.onSameUrlNavigation = 'reload';
    }

    abstract submit(): Promise<boolean>;

    // form submit should be the submit function for all forms
    protected async formSubmit(e: Event): Promise<any> {
        if (this.validate()) {
            return this.execute(async () => {
                return await this.submit();
            }, this.submitMessage);
        } else {
            console.log('Form data is not valid');
        }
    }


    public isValid = false;
    abstract validate(): boolean;
    abstract submitMessage: string;

    valueChanged(e: any): boolean {
        return true;
    }

    postExecute(): void {
        setTimeout(() => 1000);
    }

    protected onFieldDataChanged(e: any): void {
        let result = e.component.validate();
        if (result.brokenRules.length >= 1) {
            this.isValid = false;
        } else {
            this.isValid = true;
        }
    }

}