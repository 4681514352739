import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DxFormComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { IAccount, AccountSupplier, SubmissionMethod, Supplier, SupplierSubmissionMethod } from 'src/app/shared/models';
import { AgentService, LoginService, MonitorService, NavigationService } from 'src/app/shared/services';
import { AccountService } from 'src/app/shared/services/account.service';
import { SupplierService } from 'src/app/shared/services/supplier.service';
import { FormCanDeactivate } from 'src/app/shared/utils/form.candeactivate';
import { Feature, FeatureService } from 'src/app/shared/services/feature.service';

@Component({
  selector: 'app-account-edit-supplier-details',
  templateUrl: './account-edit-supplier-details.component.html',
  styleUrls: ['./account-edit-supplier-details.component.scss'],
})
export class AccountEditSupplierDetailsComponent extends FormCanDeactivate implements OnInit {

  async submit(): Promise<boolean> {
    const formData = this.supplierForm.instance.option('formData');

    let submissionType;
    if (formData.submissionMethod == 'Email') {
      submissionType = SupplierSubmissionMethod.Email;
    } else {
      submissionType = SupplierSubmissionMethod.Cxml;
    }

    var accountSupplier = {
      id: formData.id,
      isPreferred: formData.isPreferred,
      supplierName: formData.supplierName,
      accountNumber: formData.accountNumber,
      active: formData.active,
      contactName: formData.contactName,
      contactPhone: formData.contactPhone,
      contactEmail: formData.contactEmail,
      submissionEmail: formData.submissionEmail,
      submissionMethod: submissionType,
      priceBook: formData.priceBook
    }

    //casting the val of the route param accountSupplier to check whether to create a new or update
    let idCheck = Number(this.accountSupplierId);
    if (this.accountSupplierId != undefined && !Number.isNaN(idCheck)) {
      this.accountSupplier = await this.accountService.updateAccountSupplier(this.account!.id, this.accountSupplierId, accountSupplier);
    } else {
      this.accountSupplier = await this.accountService.createAccountSupplier(this.account!.id, this.currentSupplier!.id, accountSupplier);
    }

    this.navigationService.backoffice().account.suppliers.list(this.account!.id);

    return true;
  }
  validate(): boolean {
    return this.isValid;
  }

  submitMessage: string = "Error saving supplier information";

  @ViewChild("accountSupplierForm", { static: false })
  supplierForm!: DxFormComponent;

  @Input()
  accountSupplierId: number | undefined;

  @Input()
  accountSupplier: AccountSupplier | undefined;

  accountId: number | undefined;

  suppliers: Supplier[] = [];
  currentSupplier: Supplier | undefined;
  account: IAccount | undefined;
  working = 0;
  treeBoxValue!: number;
  isTreeBoxOpened: boolean = false;
  orgs: any;

  dataSource: CustomStore<any, any> | undefined;

  isVisible: boolean = true;

  submissionMethods: any[] = [];
  submissionMethod = SubmissionMethod.None;

  pricebooks: string[] = [];
  showPriceBook = false;

  constructor(
    private agentService: AgentService,
    private accountService: AccountService,
    private featureService: FeatureService,
    private supplierService: SupplierService,
    private activatedRoute: ActivatedRoute,
    monitor: MonitorService,
    login: LoginService,
    router: Router,
    private navigationService: NavigationService
  ) {
    super(monitor, login, router);

    //getting accounts that are not practices for parent org field
    this.activatedRoute.paramMap.subscribe(async (params) => {
      let accountSId = params.get('accountSupplierId');
      if (accountSId !== 'new' && accountSId !== null) {
        this.accountSupplierId = parseInt(accountSId);
      } else if (accountSId === 'new') {
        this.accountSupplierId = -1;
      }
    });

    this.activatedRoute.parent?.paramMap.subscribe(async (params) => {
      let accountId = params.get('accountId');
      if(accountId)
        this.accountId = parseInt(accountId);
    });
  }

  async ngOnInit() {
    
    try {

      if(this.accountId){

        if((!this.account?.id))
        this.account = await this.accountService.getAccountById(this.accountId, { addresses: true, agents: true, parent: true, budget: true, suppliers: true });
      }
      if (this.accountSupplierId) {
        this.accountSupplier = this.account?.accountSuppliers?.find(a => a.id == this.accountSupplierId);
      } else {
        this.accountSupplier = {
          id: 0,
          isPreferred: false,
          supplierName: undefined,
          accountNumber: undefined,
          active: true,
          contactName: undefined,
          contactPhone: undefined,
          contactEmail: undefined
        }
      }

      this.suppliers = await this.supplierService.get();
      this.currentSupplier = this.suppliers.find(a => a.name == this.accountSupplier?.supplierName);

      if (this.currentSupplier) {
        this.submissionMethods = this.currentSupplier.supplierIntegrations;

        const submissionMethod = this.supplierForm.instance.getEditor("submissionMethod");

        if (this.accountSupplier?.submissionMethod == SupplierSubmissionMethod.Email) {
          submissionMethod!.option("value", "Email");
          this.isVisible = true;

        } else {
          submissionMethod!.option("value", "Cxml");
          this.isVisible = false;
        }

        if (this.currentSupplier.name?.toLowerCase() === 'dental city' && this.featureService.check(Feature.AgencyRead, this.agentService.authenticatedAgent!.features)) {
          this.pricebooks = await this.supplierService.getPriceBooks(this.currentSupplier.id);
          if (this.accountSupplier?.priceBook && !this.pricebooks.find(pb => pb === this.accountSupplier?.priceBook)) {
            this.pricebooks.push(this.accountSupplier.priceBook);
          }
          this.showPriceBook = true;
        }
      } else {
        this.navigationService.backoffice().message.invalidRoute();
      }
    } catch (error) {
      this.navigationService.backoffice().message.invalidRoute();
    }
  }




  async onFieldDataChanged(e: any): Promise<any> {

    super.onFieldDataChanged(e);
    if (e.dataField == "submissionMethod") {
      if (e.value == "Email") {
        this.isVisible = true;
        const submissionEmail = this.supplierForm.instance.getEditor("submissionEmail");
        if (!this.accountSupplier?.submissionEmail || this.accountSupplier.submissionEmail == undefined) {
          submissionEmail!.option("value", this.currentSupplier?.contactEmail);
        } else {
          submissionEmail!.option("value", this.accountSupplier?.submissionEmail);
        }

      } else if (e.value == "Cxml") {
        const submissionEmail = this.supplierForm.instance.getEditor("submissionEmail");

        submissionEmail!.option("value", this.currentSupplier?.contactEmail);
        this.isVisible = false;
      }

    }

    if (e.dataField == "supplierName" && !this.accountSupplierId) {
      this.currentSupplier = this.suppliers.find(a => a.name == e.value);

      if (this.currentSupplier) {
        this.isVisible = true;
        this.submissionMethods = this.currentSupplier?.supplierIntegrations;
        const contactName = this.supplierForm.instance.getEditor("contactName");
        const contactEmail = this.supplierForm.instance.getEditor("contactEmail");
        const contactPhone = this.supplierForm.instance.getEditor("contactPhone");

        const submissionMethod = this.supplierForm.instance.getEditor("submissionMethod");

        submissionMethod!.option("value", "Email");




        contactName!.option("value", this.currentSupplier?.contactName);
        contactEmail!.option("value", this.currentSupplier?.contactEmail);
        contactPhone!.option("value", this.currentSupplier?.contactPhone);
        const submissionEmail = this.supplierForm.instance.getEditor("submissionEmail");
        submissionEmail!.option("value", this.currentSupplier?.contactEmail);

        if (this.currentSupplier.name?.toLowerCase() === 'dental city' && this.featureService.check(Feature.AgencyRead, this.agentService.authenticatedAgent!.features)) {
          this.pricebooks = await this.supplierService.getPriceBooks(this.currentSupplier.id);
          if (this.accountSupplier?.priceBook && !this.pricebooks.find(pb => pb === this.accountSupplier?.priceBook)) {
            this.pricebooks.push(this.accountSupplier.priceBook);
          }
          this.showPriceBook = true;
        } else {
          this.pricebooks = [];
          this.showPriceBook = false;
        }

      }

    }

  }

  validateEmail(params: any): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!params.value) {
      params.rule.message = 'Email is required';
     // this.isValid = false;

      return false;
    } else if (!emailRegex.test(params.value)) {
      params.rule.message = 'Email is invalid';
      //this.isValid = false;

      return false;
    }
    return true;
  }
}
