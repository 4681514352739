<app-access [platformFeature]="feature.AccountRead" [accountFeature]="feature.AccountRead" [redirectOnFailure]="true">
  <div class="standard-background">
    <app-progress [splash]="true" [value]="working" [splashOptions]="{ title: workingTitle, message: workingMessage }">

      <div class="flex-row justify-even ml-auto mr-auto">
        <div class="cart-card entry">
          <div class="content-wrapper">
            <div class="body-wrapper">
              <div class="header flex-row justify-between no-wrap">
                <h3>Requisition Details</h3>
              </div>
              <div class="body">
                <div class="main-container">
                  <div class="background flex-row justify-between">
                    <div class="mr-large" *ngIf="isClosed">
                      <label class="text text-500 rubik mb-small">Requisition Number</label>
                      <div class="text text-300 mb-medium">
                        {{ requisition?.requisitionNumber}}
                      </div>
                      <label class="text text-500 rubik mb-small">Requisition Status</label>
                      <div class="text text-300 mb-medium">
                        {{ requisitionType }}
                      </div>
                      <label class="text text-500 rubik mb-small">Submission Date</label>
                      <div class="text text-300 mb-medium">
                        {{ requisition?.created | date}}
                      </div>
                    </div>
                    <div class="flex-column locations " *ngIf="isClosed">
                      <label class="text text-500 rubik">
                        Billing Address
                      </label>
                      <div class="text text-300" *ngIf="account?.practice?.billingContactName?.length">
                        Attn: {{ account?.practice?.billingContactName }}
                      </div>
                      <div class="text text-300">
                        {{ account?.practice?.billingAddress1 }}
                      </div>
                      <div class="text text-300" *ngIf="account?.practice?.billingAddress2?.length">
                        {{ account?.practice?.billingAddress2 }}
                      </div>
                      <div class="text text-300">
                        {{ account?.practice?.billingCity }}
                        {{ account?.practice?.billingState }}
                        {{ account?.practice?.billingZip }}
                      </div>
                      <div class="text text-300" *ngIf="account?.practice?.billingPhoneNumber?.length">
                        {{ numberFormat.formatPhoneNumber(account?.practice?.billingPhoneNumber) }}
                      </div>
                      <div class="text text-300">
                        {{ account?.practice?.billingEmail }}
                      </div>
                    </div>
                    <div>
                      <label class="text text-500 rubik mb-small ">
                        Shipping Address
                      </label>
                      <div class="text text-300" *ngIf="account?.practice?.defaultShippingAddress?.contactName?.length">
                        Attn: {{ account?.practice?.defaultShippingAddress?.contactName }}
                      </div>
                      <div class="text text-300" *ngIf="account?.practice?.defaultShippingAddress">
                        {{ account?.practice?.defaultShippingAddress?.addressLine1 }}
                      </div>
                      <div class="text text-300"
                        *ngIf="account?.practice?.defaultShippingAddress && account?.practice?.defaultShippingAddress?.addressLine2?.length">
                        {{ account?.practice?.defaultShippingAddress?.addressLine2 }}
                      </div>
                      <div class="text text-300" *ngIf="account?.practice?.defaultShippingAddress">
                        {{ account?.practice?.defaultShippingAddress?.city }}
                        {{ account?.practice?.defaultShippingAddress?.state }}
                        {{ account?.practice?.defaultShippingAddress?.zip }}
                      </div>
                      <div class="text text-300" *ngIf="!account?.practice?.defaultShippingAddress">
                        Same as billing
                      </div>
                    </div>
                    <div class="flex-column">
                      <a class="btn grey mb-medium" *ngIf="showBackButton" (click)="goBack()" style="height: 0.5rem">
                        Return to List
                      </a>
                      <label *ngIf="requisition?.purchaseOrder" class="text text-500 rubik mb-small"
                        [ngClass]="{ 'mt-auto': showBackButton}">
                        Purchase Order
                      </label>
                      <a *ngIf="requisition?.purchaseOrder" class="btn grey text text-500 kreon text-center"
                        style="height: 0.5rem" (click)="orderDetails()">
                        {{ requisition!.purchaseOrder!.poNumber }}
                      </a>
                    </div>
                  </div>
                  <div class="mb-large">
                    <div class="spacer"></div>
      
                  </div>
                  <div class="line" *ngFor="let s of supplierOrders; let i = index">
                    <div class="flex-row line-title-row" [ngClass]="{ 'mt-large': i !== 0 }">
                      <h4 class="text text-500 mr-small">{{ s.supplierName }}</h4>
                      <span class="text text-300 rubik mr-small mt-auto mb-auto">({{ s.products.length }} lines)</span>
                      <app-menu-bar [expanded]="isCollapsed(s.id)" [isArrow]="true"
                        (click)="toggleCollapse(s.id)"></app-menu-bar>
                      <app-product-line-total class="supplier-total" [label]="'Estimated ' + s.supplierName + ' Total:'" [total]="s.total"
                        [bold]="true"></app-product-line-total>
                    </div>
                    <div class="line submission-details round-wrapper"
                      [ngClass]="{ 'collapsed': isCollapsed(s.id), 'mb-medium': !isCollapsed(s.id) }">
      
                      <div *ngFor="let l of s.products; let i = index">
                        <!-- SHOW THE SELECTED SUPPLIER PRODUCT -->
                        <div class="line submission-details round-wrapper"
                          [ngClass]="{ 'collapsed': isCollapsed(l.supplierProduct!.supplier!.id!), 'mb-large':  i + 1 < s.products.length }"
                          class="order fill fill-white recommendation used submission-wrapper round-wrapper supplier-card">
                          <div class="flex-column">
                            <div class="flex-row">
                              <div class="flex-row align-center">
                                <img class="mr-medium product-image"
                                  [src]="l.supplierProduct?.imageUrl ?? l.supplierProduct?.coreProduct?.imageUrl ?? 'assets/images/badges/badge.png'" />
                              </div>
                              <div class="flex-column">
                                <div class="flex-row no-wrap" style="height: 19px;">
                                  <div class="text product-name" [tooltip]="l.supplierProduct?.name">
                                    {{ l.supplierProduct?.name }}
                                  </div>
                                </div>
                                <div class="flex-row">
                                  <div class="flex-column" style="min-width: 620px;">
                                    <div class="flex-row no-wrap">
                                      <div class="text text-300 rubik manufacturer-details">
	                                      #{{ l.supplierProduct?.coreProduct?.sku }} - {{l.supplierProduct?.coreProduct?.manufacturer?.name }}
                                      </div>
                                      <div class="flex-row ml-small" style="height: 23px; position: relative;">
                                        <app-product-tag *ngFor="let t of l.supplierProduct?.supplierProductTags"
                                          style="position: relative; top: 3px;"
                                          [color]="t.c" [background]="t.b" [text]="t.t"></app-product-tag>
                                      </div>
                                    </div>
      
                                    <div class="supplier-details text text-300 price">
                                      <span>{{ (l.price | currency) ?? '$--'}}</span>
                                    </div>
                                    <div class="supplier-details text text-300 quantity">
                                      <span>Quantity: {{l.quantity | number}}</span>
                                    </div>
                                  </div>
                                  <div class="supplier-area">
                                    <div class="header" style="display: none;">
                                      {{ l.supplierProduct?.supplier?.name }}
                                    </div>
                                    <div style="width: 100px; max-height: 60px;">
                                      <img style="width: 100px" [src]="l.supplierProduct?.supplier?.imageUrl" />
                                    </div>
                                    <div class="sku">
                                      #{{ l.supplierProduct?.sku }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="line-price">
                                <app-product-line-total [label]="'Line Total:'" [total]="l.linePrice"
                                  [bold]="false"></app-product-line-total>
                              </div>
                            </div>
                          </div>
      
                        </div>
                        <div class="line submission-details round-wrapper"
                          [ngClass]="{ 'collapsed': isCollapsed(l.supplierProduct!.supplier!.id!), 'hidden':  i < s.products.length - 1 }"
                          class="fill fill-white used round-wrapper supplier-bottom-total line-wrapper flex-row">
                          <app-product-line-total [label]="'Estimated ' + s.supplierName + ' Total:'" [total]="s.total"
                            [bold]="true"></app-product-line-total>
                        </div>
                      </div>
                    </div>
                    <div [ngClass]="{'hidden': !isCollapsed(s.id!)}" class="spacer"></div>
                  </div>
                </div>
              </div>
              <div class="footer flex-column" style="display: grid;">
                <app-product-line-total class="req-total" label="Estimated Total:" [total]="requisition?.total"
                  [bold]="true"></app-product-line-total>
              </div>
            </div>
	          <div class="sticky-panel" *ngIf="approvalDetails && viewingAgent && requisition && account">
                  <div class="approval-header">Approval</div>
		          <dx-tabs [dataSource]="tabs" (onSelectionChanged)="tabSelection($event)" [selectedItem]="tabs[0]"></dx-tabs>
		          <app-approvals-timeline [timeline]="approvalDetails.timeline" *ngIf="tabValue === 3"></app-approvals-timeline>
		          <app-approvals-approvers [approvers]="approvalDetails.allLevelsApprovers" *ngIf="tabValue === 2"></app-approvals-approvers>
		          <app-approvals-overview
			          [approvalOverview]="approvalDetails.overview"
			          [agentId]="viewingAgent.id!"
			          [requisitionId]="requisition.id"
			          [accountId]="account.id"
			          [isApprover]="approvalDetails.canApprove"
			          *ngIf="tabValue === 1"
			          [purchaseRequisitionStatus]="approvalDetails.purchaseRequisitionStatus"
			          [approvalQueryParam]="decisionQueryParams"
			          [isAutoApproved]="approvalDetails.autoApproved"
			          [isAbandoned]="approvalDetails.purchaseRequisitionStatus === 'Abandoned'"></app-approvals-overview>
	          </div>
          </div>
        </div>
      </div>
    </app-progress>
  </div>
</app-access>

<link
    rel="stylesheet"
    type="text/css"
    href="https://cdn3.devexpress.com/jslib/23.2.3/css/dx.material.blue.light.css"
/>