import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, lastValueFrom } from 'rxjs';
import { ReportingApiConfig } from '../api/report.api.config';
import IGenericApiResponse from '../models/generic-response.model';
import { GenericReportExceptionList, IReportListItem } from '../models/report.list.item.model';
import { TreeListBudgetItem } from '../models/tree.list.budget.model';
import { inputType } from '../models/filter.model';
import { formatDate } from "@angular/common";

@Injectable({ providedIn: 'root' })
export class ReportingService
{
	constructor(private http: HttpClient) {}

	public async handleExceptionFlow(report: IReportListItem, accountId: number, filters?: any): Promise<IGenericApiResponse>
	{
		switch (report.reportKey)
		{
			case GenericReportExceptionList.BUDGET_REPORT:
				let now = new Date();
				let date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
				let dateFrom = filters && filters["dateFrom"] ? filters["dateFrom"] : date;
				let dateTo = filters && filters["dateTo"] ? filters["dateTo"] : date;

				return this.getBudgetReport(accountId, dateFrom, dateTo);
			default:
				return { success: false, messages: ['Report not registered for exception flow'] } as IGenericApiResponse;
		}
	}

	private async getBudgetReport(accountId: number, dateFrom: Date, dateTo: Date): Promise<IGenericApiResponse>
	{
		let body = {
			dateFrom: formatDate(dateFrom, 'yyyy-MM-dd', "en-US"),
			dateTo: formatDate(dateTo, 'yyyy-MM-dd', "en-US")
		};
		
		let response = await lastValueFrom(this.http.post<IGenericApiResponse>(ReportingApiConfig.getFullUrl(`account/${accountId}/budget`), body));

		if (response.success)
		{
			let accountFilterData: { Id: number; Name: string; }[] = [];
			let suppliersFilterData: { Id: number; Name: string; }[] = [];

			response.value.dataRows.forEach((item: TreeListBudgetItem) =>
			{
				let accountFilterDataMatch = accountFilterData.find((account: any) => account.Id === item.accountId);
				if (item.accountId && item.accountName && !accountFilterDataMatch)
				{
					accountFilterData.push({ Id: item.accountId, Name: item.accountName });
				}

				let supplierFilterDataMatch = suppliersFilterData.find((supplier: any) => supplier.Id === item.supplierId);
				if (item.supplierId && item.supplierName && !supplierFilterDataMatch)
				{
					suppliersFilterData.push({ Id: item.supplierId, Name: item.supplierName });
				}
			});

			let budgetFilters = [
				{
					inputType: inputType.dateRange,
					label: '',
					initialValue: [dateFrom, dateTo],
					name: ["dateFrom", "dateTo"] // for single value filter, name will be a string, otherwise an array
				}
			];

			response.value.filters = budgetFilters;
		}

		return response;
	}

	public async getGenericReport(profile: string, reportId: number, accountId?: number, pageNumber?: number, pageSize?: number): Promise<IGenericApiResponse>
	{
		const body =
		{
			accountId: accountId,
			reportId: reportId,
			profile: profile,
			pageNumber: pageNumber,
			pageSize: pageSize
		}

		return lastValueFrom(this.http.post<IGenericApiResponse>(ReportingApiConfig.getFullUrl(`report`), body));
	}

	public async getReportsList(profile: string, accountId: number | undefined): Promise<IGenericApiResponse>
	{
		const body = {
			profile: profile,
			accountId: accountId
		}

		return await lastValueFrom(this.http.post<IGenericApiResponse>(ReportingApiConfig.getFullUrl(`reports`), body));
	}
}