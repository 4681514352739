import { AuditInfo, Submission, Address, Tag  } from '.';

//Model for the PurchaseOrderDetailsScreen
export class PurchaseOrderDetails { 
    public purchaseOrderId!: number;
    public masterNumber!: number;
    public purchaseOrderNumber!: string;
    public purchaseOrderStatus!: string;
    public purchaseOrderStatusId!: number;
    public purchaseOrderTotal?: number;
    public purchaseOrderSubmissionDate!: Date;
    public requisitionId!: number;
    public requisitionNumber!: string;
    public requisitionApprovalDate!: Date;
    public requisitionApprovedByName!: string;
    public purchaseOrderCreatedDate!: Date;
    public purchaseOrderModifiedDate!: Date;
    public billingAddress!: Address;
    public shippingAddress!: Address;
    public submissions!: PurchaseOrderSubmission[];
}

export class PurchaseOrderSubmission {
    public submissionId!: number;
    public masterNumber!: number;
    public submissionSupplierId!: number;
    public submissionSupplierName!: string;
    public submissionSupplierImageUrl!: string;
    public submissionMethodId!: number;
    public submissionMethod!: string;
    public submissionStatus!: string;
    public submissionStatusId!: number;
    public supplierSalesOrderNumber?: string;
    public submissionTotal?: number;
    public submissionSendDate?: Date;
    public submissionStatusLastModified?: Date;
    public submissionLastModified!: Date;
    public submitter!: PurchaseOrderSubmitter;
    public purchaseOrderLines!: PurchaseOrderLine[];
    public shipments?: PurchaseOrderShipment[];
    public invoices?: PurchaseOrderInvoice[];
    public history?: PurchaseOrderHistory[]; // Assumed to be nullable based on C# comment
  }
  
  export class PurchaseOrderSubmitter {
    public firstName!: string;
    public lastName!: string;
  }
  
  export class PurchaseOrderSupplierProduct {
    public supplierProductId!: string;
    public supplierProductName!: string;
    public supplierProductUoM!: string;
    public supplierProductSku!: string;
    public supplierProductImageUrl?: string;
    public coreProductId!: number;
    public coreProductSku!: string;
    public manufacturerId!: number;
    public manufacturerName!: string;
    public supplierProductTags?: Tag[];
  }
  
  export class PurchaseOrderShipment {
    public shipmentId!: number;
    public masterNumber!: number;
    public submissionId!: number;
    public purchaseOrderId!: number;
    public shipmentDate?: Date;
    public trackingNumber!: string;
    public trackingNumberUrl!: string;
    public shipmentLines!: PurchaseOrderShipmentLine[];
  }
  
  export class PurchaseOrderShipmentLine {
    public shipmentLineId!: number;
    public shipmentId!: number;
    public purchaseOrderLineId!: number;
    public masterNumber!: number;
    public submissionId!: number;
    public purchaseOrderId!: number;
    public supplierProductId!: number;
    public supplierProductSKU!: string;
    public shippedQuantity!: number;
  }
  
  export class PurchaseOrderLine {
    public purchaseOrderLineId!: number;
    public purchaseOrderLineQuantity!: number;
    public purchaseOrderLineItemPrice?: number;
    public purchaseOrderLineConfirmedQuantity?: number;
    public purchaseOrderLineShippedQuantity?: number;
    public purchaseOrderLineInvoicedQuantity?: number;
    public purchaseOrderLineBackorderQuantity?: number;
    public purchaseOrderLineLineTotal?: number;
    public purchaseOrderLineEstimatedShipDate?: Date;
    public supplierProduct!: PurchaseOrderSupplierProduct;
  }
  
  export class PurchaseOrderInvoice {
    public invoiceId!: number;
    public masterNumber!: number;
    public submissionId!: number;
    public purchaseOrderId!: number;
    public invoiceNumber!: string;
    public invoiceUrl!: string;
    public invoiceDate!: Date;
    public invoiceLines!: PurchaseOrderInvoiceLine[];
    public invoiceTotal?: number;
  }
  
  export class PurchaseOrderInvoiceLine {
    public invoiceLineId!: number;
    public invoiceId!: number;
    public purchaseOrderLineId!: number;
    public masterNumber!: number;
    public submissionId!: number;
    public purchaseOrderId!: number;
    public supplierProductId!: number;
    public supplierProductSKU!: string;
    public invoicedQuantity!: number;
  }
  
  export class PurchaseOrderHistory {
    public purchaseOrderStatus!: string;
    public purchaseOrderStatusId!: number;
    public purchaseOrderStatusChangeDate!: Date;
    public historyUser!: string;
    public historyMessage!: string;
  }

  export class PurchaseOrder extends AuditInfo { //TODO: this only exists for legacy code
    public practiceId!: number;
    //public practice!: Practice;
    public purchaseOrderLines?: PurchaseOrderLine[] = [];
    public orderStatus?: string;
    public submissions?: Submission[];
    public poNumber?: string;
	public masterNumber?: number;
	public requisitionNumber?: string;
    public purchaseRequisitionId?: string;
    public billingContactName!: string;
    public billingAddress1!: string;
    public billingAddress2!: string;
    public billingCity!: string;
    public billingState!: string;
    public billingZip!: string;
    public billingCountry!: string;
    public billingPhoneNumber!: string;
    public billingEmail!: string;
    public shippingContactName!: string;
    public shippingAddress1!: string;
    public shippingAddress2!: string;
    public shippingCity!: string;
    public shippingState!: string;
    public shippingZip!: string;
    public shippingCountry!: string;
    public shippingPhoneNumber!: string;
    public shippingEmail!: string;
    public total?: number | undefined;
}

export class PurchaseOrderListRecord {
	public id!: string | undefined;
	public parentId!: string | undefined;
	public purchaseOrderId!: number;
    public purchaseOrderNumber!: number;
	public purchaseRequisitionId!: number;
    public purchaseRequisitionNumber!: number;
	public supplierId!: string;
	public supplierName!: string;
	public purchaseOrderStatusId!: string;
	public purchaseOrderStatus!: string;
	public total!: number;
	public modifiedBy?: string | undefined;
    public modified?: Date;
    public createdBy?: string | undefined;
	public created?: Date;
}

export class OrderStatusType {
    public static Open:string = 'Open';
    public static Complete:string = 'Complete';
    public static Cancelled:string = 'Cancelled';
    public static Accepted:string = 'Accepted';
    public static Accepted3rdParty:string = 'Accepted3rdParty';
    public static Rejected:string = 'Rejected';
    public static Rejected3rdParty:string = 'Rejected3rdParty';
    public static Error3rdParty:string = 'Error3rdParty';
    public static Pending3rdParty:string = 'Pending3rdParty';
    public static Shipped3rdParty:string = 'Shipped3rdParty';
}
