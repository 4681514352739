import { lastValueFrom } from "rxjs";
import { BudgetPeriod, IBudgetPeriod } from "../models/budget.model";
import { HttpClient } from "@angular/common/http";
import { BudgetPeriodApiConfig } from "../api/budget.api.config";
import { Injectable } from "@angular/core";
import IGenericApiResponse from "../models/generic-response.model";
import { BudgetPeriodDetail } from "../models/budget.detail.model";
import { formatDate } from "@angular/common";

@Injectable({providedIn: 'root'})
export class BudgetService {
    
    constructor(private http: HttpClient) { }
    
    public getBudgetPeriodByDate(accountId: number, date?: Date): Promise<IGenericApiResponse> { //TODO: Rename practiceId -> accountID 
        let dateSend = formatDate(date ?? Date.now(), 'yyyy-MM-dd', "en-US");
        
        return lastValueFrom(this.http.get<IGenericApiResponse>(BudgetPeriodApiConfig.getFullUrl(`${accountId}/period/${dateSend}`)));
        
    }

    public getBudgetPeriodById(accountId: number, budgetId: number): Promise<IGenericApiResponse> {
        return lastValueFrom(this.http.get<IGenericApiResponse>(BudgetPeriodApiConfig.getFullUrl(`${accountId}/period/${budgetId}`)));
    }
}