<app-progress [splash]="true" [value]="working">
  <dx-tabs #addressTabs class="mb-large" [dataSource]="tabs" [selectedIndex]="tabIndex" (onItemClick)="selectTab($event)" 
    (onItemRendered)="onTabItemRendered($event)" *ngIf=" account && account!.accountType === accountType.PRACTICE">
  </dx-tabs>
  <form (submit)="formSubmit($event)">
    <dx-form #billingForm [formData]="account" [visible]="isBillingTab" [customizeItem]="customizeBilling" [showValidationSummary]="true"
      (onFieldDataChanged)="onFieldDataChanged($event)">
      <dxi-item itemType="group" [colCount]="1">
        <dxi-item dataField="practice.billingContactName" [disabled]="isDisabled">
          <dxo-label template="Attention"></dxo-label>
          <dxi-validation-rule type="required" message="Attention line is required."></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="practice.billingAddress1" [disabled]="false">
          <dxo-label template="Address line 1"></dxo-label>
          <dxi-validation-rule type="required" message="Address line 1 is required."></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="practice.billingAddress2" [disabled]="false">
          <dxo-label template="Address line 2"></dxo-label>
        </dxi-item>
        <dxi-item dataField="practice.billingCity" [disabled]="false">
          <dxo-label template="City"></dxo-label>
          <dxi-validation-rule type="required" message="The city is required."></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="practice.billingState" [disabled]="false">
          <dxo-label template="State"></dxo-label>
          <dxi-validation-rule type="required" message="The state is required."></dxi-validation-rule>
          <dxi-validation-rule type="stringLength" message="Maximum length of state is 2" dataField="state"
            [max]="2"></dxi-validation-rule>
          <dxi-validation-rule type="pattern" [pattern]="statePattern" message="Please put a valid state."></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="practice.billingZip" [disabled]="false">
          <dxo-label template="Zipcode"></dxo-label>
          <dxi-validation-rule type="required" message="The zipcode is required."></dxi-validation-rule>
        </dxi-item>
      </dxi-item>
    </dx-form>
    <dx-button [visible]="isBillingTab" [useSubmitBehavior]="true" (onClick)="submit()" [elementAttr]="{ class: 'custom-button' }">SAVE</dx-button>
  </form>
  <form (submit)="formSubmit($event)">
    <dx-form #shippingForm [formData]="account?.practice?.defaultShippingAddress" [visible]="!isBillingTab" [customizeItem]="customizeShipping" (onFieldDataChanged)="onFieldDataChanged($event)" [showValidationSummary]="true">
      <dxi-item itemType="group" [colCount]="1">
        <dxi-item dataField="sameAsBilling" [disabled]="false" editorType="dxCheckBox">
        </dxi-item>
        <dxi-item dataField="contactName" [disabled]="isDisabled">
          <dxo-label template="Attention"></dxo-label>
          <dxi-validation-rule type="required" message="Attention line is required."></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="addressLine1" [disabled]="isDisabled">
          <dxo-label template="Address line 1"></dxo-label>
          <dxi-validation-rule type="required" message="Address line 1 is required."></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="addressLine2" [disabled]="isDisabled">
          <dxo-label template="Address line 2"></dxo-label>
        </dxi-item>
        <dxi-item dataField="city" [disabled]="isDisabled">
          <dxo-label template="City"></dxo-label>
          <dxi-validation-rule type="required" message="The city is required."></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="state" [disabled]="isDisabled">
          <dxo-label template="State"></dxo-label>
          <dxi-validation-rule type="required" message="The state is required."></dxi-validation-rule>
          <dxi-validation-rule type="stringLength" message="Maximum length of state is 2" dataField="state"
            [max]="2"></dxi-validation-rule>
          <dxi-validation-rule type="custom" [validationCallback]="validateNoSpace" message="Please remove spaces from this field."></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="zip" [disabled]="isDisabled">
          <dxo-label template="Zipcode"></dxo-label>
          <dxi-validation-rule type="required" message="The zipcode is required."></dxi-validation-rule>
        </dxi-item>
      </dxi-item>
    </dx-form>
    <dx-button [visible]="!isBillingTab" [disabled]="disableSubmit" [useSubmitBehavior]="true" (onClick)="submit()" [elementAttr]="{ class: 'custom-button' }">SAVE</dx-button>
  </form>
</app-progress>