import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DxFormComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { IAccount, SupplierProduct } from 'src/app/shared/models';
import { LoginService, MonitorService } from 'src/app/shared/services';
import { AccountService } from 'src/app/shared/services/account.service';
import { Feature } from 'src/app/shared/services/feature.service';
import { FormularyService } from 'src/app/shared/services/formulary.service';
import { GenericComponent } from 'src/app/shared/utils/generic.component';

@Component({
  selector: 'app-account-edit-formulary-products-composite',
  templateUrl: './account-edit-formulary-products-composite.component.html',
  styleUrls: ['./account-edit-formulary-products-composite.component.scss']
})
export class AccountEditFormularyProductsCompositeComponent extends GenericComponent {

  accountId: number = 0;
  parentAccount: IAccount | undefined;

  @ViewChild(DxFormComponent, { static: false })
  form!: DxFormComponent;

  dataSource: any;
  feature = Feature;
  uploadUrl = '';

  constructor(
    private formularyService: FormularyService,
    private accountService: AccountService,
    monitor: MonitorService,
    login: LoginService,
    private activatedRoute: ActivatedRoute
  ) {
    super(monitor, login);

    this.activatedRoute.parent!.parent!.paramMap.subscribe(async params => {
      const accountId = parseInt(params.get('accountId') || '0');
  
      if(accountId > 0){
        this.accountId = accountId;

        const formularies = this.formularyService;
        const id = this.accountId;
    
        this.dataSource = new CustomStore({
          key: 'id',
          loadMode: 'processed',
          load(loadOptions: any) {
            let params: HttpParams = new HttpParams();
            [
              'skip',
              'take',
              'requireTotalCount',
              'requireGroupCount',
              'sort',
              'filter',
              'totalSummary',
              'group',
              'groupSummary',
            ].forEach((i) => {
              if (i in loadOptions && (i === 'skip' || i === 'take' || loadOptions[i]?.length)) {
                params = params.set(i, JSON.stringify(loadOptions[i]));
              }
            });
    
            params = params.append('requireTotalCount', 'true'); // force it to bring the total back
    
            return formularies.searchFormularyComposite(id!, params).then(data => {
              return {
                data: data.data,
                totalCount: data.totalCount,
                summary: data.summary,
                groupCount: data.groupCount,
              };  
            });
          }
        });
      }
    })
  }

  onContentReady(e: any) {
    const rows = e.component.getVisibleRows();
    rows.forEach((row: any) => {
      const rowKey = e.component.getKeyByRowIndex(row.rowIndex);
      const rowData = row.data;
      const rowIndex = e.component.getRowIndexByKey(rowKey);
      const rowElement = e.component.getRowElement(rowIndex);
      if (rowData.include === false) {
        const element = rowElement[0].children[rowElement[0].children.length - 1];
        element.classList.add('not-included');
      }
    });
  }

}
