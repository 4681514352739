import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PingService } from 'src/app/shared/services/ping.service';

@Component({
  selector: 'app-display-message',
  templateUrl: './display-message.component.html',
  styleUrls: ['./display-message.component.scss']
})
export class DisplayMessageComponent implements OnInit {
  message?: string;
  messageId?: string;

  constructor(private activatedRoute: ActivatedRoute, private pingService: PingService) {
    this.activatedRoute.params.subscribe((data) => {
      //grab the message key from route
      this.messageId = data.id;
    });

  }

  async ngOnInit() {
    try {
      let messages = await this.pingService.getAllMessages();
      this.message = messages.find(message => message.messageKey == this.messageId)?.messageText;
  
      if (!this.message && this.messageId) {
        this.message = 'Something went wrong and the message could not be displayed.';
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
      this.message = 'Error fetching messages.';
    }
  
  }
}



