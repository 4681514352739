<div class="timeline-wrapper">
    <div class="timeline-entry" *ngFor="let timelineRecord of timeline">
   
        <div class="avatar-agent timeline">
            <div class="avatar"></div>
            <div class="agent">
                <div class="approver-header">
                    <div class="change-name">{{timelineRecord.timeLineAgent.agentName}}</div>
                    <div class="change-date">{{timelineRecord.timeLineChangeDate | date:"M/d/yy, h:mm a"}}</div>
                </div>
            </div>
        </div>

        <div style="margin-left: 50px; margin-top: -15px;">
            <div [id]="timelineRecord.timeLineRecordId" class="change-description" [ngClass]="(showMoreState.get(timelineRecord.timeLineRecordId) ? 'show-more' : 'clamp')" [innerHTML]="timelineRecord.timeLineChangeDescription">
            </div>
            <a class="pink bold align-end link mt-medium" *ngIf="!showMoreState.get(timelineRecord.timeLineRecordId) && isTextOverflow(timelineRecord.timeLineRecordId.toString())"
               (click)="showMore(timelineRecord.timeLineRecordId)">Show more</a>
        </div>
    </div>
</div>

