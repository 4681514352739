import { environment } from '../../../environments/environment';

/**
 * Base API configuration file
 */
export class FormularyApiConfig {
    static path: string = environment.api.formulary.path;
    static version: string | number | null = environment.api.formulary.version;

    public static setApiVersion(version: string = 'api'): void {
        FormularyApiConfig.version = version;
    }

    public static getApiVersion(): string | number | null {
        return FormularyApiConfig.version;
    }

    public static getPath(): string {
        return FormularyApiConfig.path;
    }

    public static getFullUrl(path: string): string {
        if (FormularyApiConfig.getApiVersion()) {
            return [FormularyApiConfig.getPath(), FormularyApiConfig.getApiVersion(), path].join('/');
        } else {
            return [FormularyApiConfig.getPath(), path].join('/');
        }
    }
}
