import { Component, Input } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import {PagerPageSize} from 'devextreme/common/grids';
import { ReportDisplayTypeEnum, IReportColumnMetadata } from '../../models/report.list.item.model';

@Component({
	selector: 'app-generic-report',
	templateUrl: './generic-report.component.html',
	styleUrls: ['./generic-report.component.scss']
})
export class GenericReportComponent
{
	@Input()
	reportData!: DataSource;
	@Input()
	reportType!: ReportDisplayTypeEnum;
	@Input()
	columnsMetadata?: IReportColumnMetadata[];
	@Input()
	renderReport: boolean = false;

	protected reportTypes = ReportDisplayTypeEnum;

	protected _noDataMessage: string = 'No data available for this report.';

	readonly allowedPageSizes: (number | PagerPageSize)[] = [100, 250, 1000, 'all'];

	constructor()
	{
	}

	contentReady = (e: any) =>
	{
		if (this.columnsMetadata)
		{
			e.component.columnOption(0, "visible", false); // always hide first column which should be 'id' by convention

			for (let i = 0; i < this.columnsMetadata.length; i++)
			{
				e.component.columnOption(i, "width", this.columnsMetadata[i].width);	
			}
		}
		else
		{
			e.component.option(1, "columnAutoWidth", true);
		}
	};
}