<div class="overview-wrapper">
    <div class="submitter-area">
        <h4>Submitter</h4>
        <!-- We should use a variation of the user shared component for this -->
        <div class="user">
            <div class="avatar-agent" style="clear: both;">
                <div class="avatar"></div>
                <div class="agent">
                    <div class="approver-header">
                        <div class="approver-name">{{approvalOverview.submitter.agent.agentName}}</div>

                    </div>
                    <a class="approver-description" [href]="'mailto:' + approvalOverview.submitter.agent.agentEmail">{{approvalOverview.submitter.agent.agentEmail}}</a>
                </div>
            </div>
        </div>
        <div *ngIf="approvalOverview.submitter.submitComments" class="comments-section mt-medium">
            <div [id]="approvalOverview.submitter.agent.agentId" [ngClass]="(submitterCommentShowMore ? 'show-more' : 'clamp')">{{approvalOverview.submitter.submitComments}}</div>
            <a class="pink bold align-end link mt-medium" *ngIf="isTextOverflow(approvalOverview.submitter.agent.agentId.toString())" (click)="showMore()">Show more</a>
        </div>
    </div>
    <div class="deadline-area" *ngIf="approvalOverview.deadline">
        <div class="deadline space-between">
            <div style="margin-left: 50px; color: #777; font-size: 15px;">Deadline: {{approvalOverview.deadline | date: 'short' }}</div>
        </div>
    </div>
    <div class="auto-approved-message-area" *ngIf="isAutoApproved">
        <div style="font-weight: 500; font-size: 14px; border-top: solid 1px #ccc; padding-top: 20px; display: block; width: 100%; text-align: center;">This requisition has been automatically approved.</div>
    </div>
    <div class="auto-approved-message-area" *ngIf="isAbandoned">
        <div style="font-weight: 500; font-size: 14px; border-top: solid 1px #ccc; padding-top: 20px; display: block; width: 100%; text-align: center;">This requisition has been been abandoned.</div>
    </div>
    <div class="approvers-area" *ngIf="!isApprover && approvalOverview.currentLevelApprovers">
        <h4>Current Approvers</h4>
        <div class="approvers-wrapper">
            <div *ngIf="approvalOverview.currentLevelApprovers.approvalLevelName" class="approval-level-name"><p>{{approvalOverview.currentLevelApprovers.approvalLevelName}}</p></div>
            <div class="approvers-list" *ngFor="let approver of approvalOverview.currentLevelApprovers.approvers; let i = index">
                <div class="user" *ngIf="i < maxCurrentApproversListSize">

                    <div class="avatar-agent">
                        <div class="avatar"></div>
                        <div class="agent">
                            <div class="approver-header">
                                <div class="approver-name">{{approver.approver.agentName}}</div>

                            </div>
                            <a class="approver-description" [href]="'mailto:' + approver.approver.agentEmail">{{approver.approver.agentEmail}}</a>
                        </div>
                    </div>

                </div>
            </div>
            <a class="pink bold align-end link mt-medium" *ngIf="maxCurrentApproversListSize < approvalOverview.currentLevelApprovers.approvers.length" (click)="setApproversListSize('current')">Show more</a>
        </div>
    </div>
    <div class="approvers-area final-approvers" *ngIf="approvalOverview.approvalApprovers">
        <h4>Approvers</h4>
        <div class="approvers-wrapper">
            <div class="level-approvers-list" *ngFor="let approvalApprover of approvalOverview.approvalApprovers; let i = index">
                <div *ngIf="approvalApprover.approvalLevelName" class="approval-level-name"><p>{{approvalApprover.approvalLevelName}}</p></div>
                <div class="approvers-list" *ngFor="let approver of approvalApprover.approvers; let j = index">
                    <div class="user" *ngIf="i < maxApprovalApproversListSize">

                        <div class="avatar-agent">
                            <div class="avatar"></div>
                            <div class="agent">
                                <div class="approver-header">
                                    <div class="approver-name">
                                        {{approver.approver.agentName}}
                                    </div>

                                </div>
                                <a class="approver-description" [href]="'mailto:' + approver.approver.agentEmail">{{approver.approver.agentEmail}}</a>
                            </div>
                        </div>

                        <div class="approver-decision">
                            <span *ngIf="approver.approver.isMasterApprover" class="master-approver">Master Approver</span>
                            <div class="container">
                                <div class="status" [ngClass]="{'approved': approver.status.status.toLowerCase() === 'approved'}">{{approver.status.status}}</div>
                                <div class="date">{{approver.status.date | date:"short"}}</div>
                            </div>
                            <div class="comments">
                                {{approver.status.approvalComments}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a class="pink bold align-end link mt-medium" *ngIf="maxApprovalApproversListSize < approvalOverview.approvalApprovers.length" (click)="setApproversListSize('approval')">Show more</a>
        </div>
    </div>
    <div class="approvers-area" *ngIf="isApprover">
	    <h4>Approver Comments</h4>
	    <dx-text-area [(value)]="approverComments" [width]="'100%'"></dx-text-area>
        <app-approval-submit [accountId]="accountId"
                             [agentId]="agentId"
                             [requisitionId]="requisitionId"
                             [approverComments]="approverComments"
                             [approvalQueryParam]="approvalQueryParam"></app-approval-submit>
    </div>
</div>
