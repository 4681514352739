<div #dropdown class="dropdown-wrapper" [ngClass]="{ 'active': expanded }">
  <div class="dropdown-menu-item" (click)="expanded = !expanded">
    <svg width="20" height="20" *ngIf="content?.iconId" class="icon-style">
      <use [attr.xlink:href]="'../../../../assets/images/icons/sprites.svg#' + content?.iconId" />
    </svg>
    <a  
    class="drop-down {{ buttonClass }} {{ borderClass }}" >
      {{ content?.name }}
  </a>
  </div>
  <div class="dropdown-contents {{ borderClass }}">
    <ng-template ngFor let-item [ngForOf]="content?.children">
        <a *ngIf="item.action" (click)="click(item)"
           class="drop-down-item {{ linkClass }}">
          <svg width="20" height="20" *ngIf="item.iconId" class="icon-style">
            <use [attr.xlink:href]="'../../../../assets/images/icons/sprites.svg#' + item.iconId" />
          </svg>
          <span [ngClass]="{'activated': activeRoute === item.routeId}">{{ item.name }}</span>
          <span *ngIf="item.hasNotification && item.notificationValue !== undefined && item.notificationValue > 0" class="notification">{{item.notificationValue}}</span>
        </a>
        <div class="sub-dropdown-link" (click)="toggleSubmenu(item)">
          <a *ngIf="item.children" class="drop-down-item {{ linkClass }}">
            <svg width="20" height="20" *ngIf="item.iconId" class="icon-style">
              <use [attr.xlink:href]="'../../../../assets/images/icons/sprites.svg#' + item.iconId" />
            </svg>
            <span [ngClass]="{'activated': activeRoute === item.routeId}">{{ item.name }}</span>
            <span *ngIf="item.hasNotification && item.notificationValue !== undefined && item.notificationValue > 0" class="notification">{{item.notificationValue}}</span>
          </a>
          <svg width="20" height="20" *ngIf="item.children" class="icon-style">
            <use [attr.xlink:href]="'../../../../assets/images/icons/sprites.svg#'  + (item.expanded ? 'chevron-up' : 'chevron-down')"></use>
          </svg>
        </div>

        <!-- Recursively render sub-items -->
        <div *ngIf="item.children && item.expanded" class="dropdown-submenu">
          <ng-template ngFor let-subitem [ngForOf]="item.children">
            <a class="drop-down-item" (click)="click(subitem)">
              <svg width="20" height="20" *ngIf="subitem.iconId" class="icon-style">
                <use [attr.xlink:href]="'../../../../assets/images/icons/sprites.svg#' + subitem.iconId" />
              </svg>
              <span [ngClass]="{'activated': activeRoute === subitem.routeId}">{{ subitem.name }}</span>
              <span *ngIf="subitem.hasNotification && subitem.notificationValue !== undefined && subitem.notificationValue > 0" class="notification">{{subitem.notificationValue}}</span>
            </a>
          </ng-template>
        </div>
    </ng-template>
  </div>
</div>