<app-progress [splash]="true" [value]="working">
  <form (submit)="formSubmit($event)">
    <dx-form #accountSupplierForm [(formData)]="accountSupplier" labelLocation="top" [showColonAfterLabel]="false"
      (onFieldDataChanged)="onFieldDataChanged($event)">
      <dxi-item itemType="group" [colCount]="1">
        <dxi-item editorType="dxSelectBox" dataField="supplierName" [isRequired]="true" [editorOptions]="{ dataSource: suppliers, displayExpr: 'name', valueExpr: 'name', value: currentSupplier?.name, 
            searchMode: 'contains',
            searchExpr: 'name',
            searchTimeout: 200,
            minSearchLength: 0,
            searchEnabled: true }" [disabled]="accountSupplier?.id">
          <!-- <dx-select-box [dataSource]="accountTypes"></dx-select-box> -->
          <dxo-label template="Supplier name"></dxo-label>
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="accountNumber" [isRequired]="true">
          <dxo-label template="Account Number"></dxo-label>
          <dxi-validation-rule type="required" message="Account number is required"></dxi-validation-rule>
          <dxi-validation-rule type="stringLength" message="Maximum length is 200" dataField="accountSupplierNumber"
            [max]="200"></dxi-validation-rule>
        </dxi-item>

        <dxi-item dataField="submissionMethod" [isRequired]="true" editorType="dxSelectBox"
          [editorOptions]="{ dataSource: submissionMethods}">
          <dxi-validation-rule type="required"></dxi-validation-rule>
          <dxo-label template="Submission Method"></dxo-label>
        </dxi-item>

        <dxi-item dataField="submissionEmail" editorType="dxTextBox" [visible]="isVisible">
          <dxo-label template="Submission Email" [visible]="isVisible"></dxo-label>
          <dxi-validation-rule type="stringLength" message="Maximum length is 256" dataField="submissionEmail"
            [max]="256"></dxi-validation-rule>
          <dxi-validation-rule type="custom" [validationCallback]="validateEmail"></dxi-validation-rule>
        </dxi-item>

        <dxi-item dataField="contactName" [isRequired]="true">
          <dxo-label template="Contact Name"></dxo-label>
          <dxi-validation-rule type="required" message="Account number is required"></dxi-validation-rule>
          <dxi-validation-rule type="stringLength" message="Maximum length is 250" dataField="contactName"
            [max]="250"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="contactEmail" [isRequired]="true">
          <dxo-label template="Contact Email"></dxo-label>
          <dxi-validation-rule type="required" message="Account number is required"></dxi-validation-rule>
          <dxi-validation-rule type="stringLength" message="Maximum length is 256" dataField="contactEmail"
            [max]="256"></dxi-validation-rule>
          <dxi-validation-rule type="custom" [validationCallback]="validateEmail"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="contactPhone" [isRequired]="true">
          <dxo-label template="Contact Phone Number"></dxo-label>
          <dxi-validation-rule type="required" message="Account number is required"></dxi-validation-rule>
          <dxi-validation-rule type="stringLength" message="Maximum length is 50" dataField="contactPhone"
            [max]="50"></dxi-validation-rule>
        </dxi-item>
        
        <dxi-item dataField="priceBook" editorType="dxSelectBox" [visible]="showPriceBook"
          [editorOptions]="{ dataSource: pricebooks}">
          <dxo-label template="Price Book"></dxo-label>
        </dxi-item>

        <dxi-item dataField="isPreferred" editorType="dxCheckBox">
          <dxo-label template="Is Preferred"></dxo-label>
        </dxi-item>
        <dxi-item dataField="active" editorType="dxCheckBox">
          <dxo-label template="Active"></dxo-label>
        </dxi-item>
      </dxi-item>
    </dx-form>
    <dx-button [disabled]="!isValid" [useSubmitBehavior]="true" text="Save"></dx-button>
  </form>
</app-progress>