import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { iAuditInfo } from '../../models';
import { AgentService, MonitorService } from '../../services';
import { AccountService } from '../../services/account.service';

@Component({
  selector: 'app-audit-info',
  templateUrl: './audit-info.component.html',
  styleUrls: ['./audit-info.component.scss']
})
export class AuditInfoComponent implements OnInit{

  @Input() 
  auditInfo: iAuditInfo | undefined;

  createdByUsername: any | undefined;

  modifiedByUsername: any | undefined;

  constructor(
    private agentService: AgentService
  ) {  }

  
  async ngOnInit() {

    if(this.auditInfo?.createdBy){
      this.agentService.getAuditInfo(this.auditInfo?.createdBy)
      .then((response: any) => {this.createdByUsername = response.username });
   
    }

    if(this.auditInfo?.modifiedBy){
      this.agentService.getAuditInfo(this.auditInfo?.modifiedBy)
      .then((response: any) => {this.modifiedByUsername = response.username});
    }
 
  }

}
