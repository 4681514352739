<div class="filter-container" *ngIf="!isGeneric && renderFilters">
    <ng-template ngFor [ngForOf]="multiSelectFilters" let-filter let-i="index">
        <!-- Iterate through all input filters based on their type -->
        <div class="multi-select-dropdown-wrapper filter" *ngIf="filter.inputType === inputType.multiSelect">
            <div class="label">{{filter.label}}</div>
	        <dx-drop-down-box [dataSource]="filter.filterData!"
	                          width="300"
	                          valueExpr="Id"
	                          displayExpr="Name"
	                          [(value)]="multiSelectValueArray[i]"
	                          [(opened)]="multiDropdownOpenedArray[i]">

		        <dx-list [dataSource]="filter.filterData!"
		                 displayExpr="Name"
		                 valueExpr="Id"
		                 selectionMode="multiple"
		                 (onSelectionChanged)="syncListSelection($event, i, 'multi')">

		        </dx-list>
	        </dx-drop-down-box>
        </div>

    </ng-template>

    <ng-template ngFor [ngForOf]="singleSelectFilters" let-filter let-i="index">
        <div class="single-select-dropdown-wrapper filter" *ngIf="filter.inputType === inputType.select">
            <div class="label">{{filter.label}}</div>
            <dx-drop-down-box [dataSource]="filter.filterData!"
                              width="300"
                              valueExpr="Id"
                              displayExpr="Name"
                              [(value)]="singleSelectValueArray[i]"
                              [(opened)]="singleDropdownOpenedArray[i]">
                <dx-list [dataSource]="filter.filterData!"
                         displayExpr="Name"
                         valueExpr="Id"
                         selectionMode="single"
                         (onSelectionChanged)="syncListSelection($event, i, 'single')">

                </dx-list>
            </dx-drop-down-box>
        </div>
    </ng-template>

    <ng-template ngFor [ngForOf]="dateFilters" let-filter let-i="index">
        <div class="date-range-wrapper filter" *ngIf="filter.inputType === inputType.date">
            <div class="label">{{filter.label}}</div>
            <dx-date-box type="date"
                         [inputAttr]="{ 'aria-label': 'Date' }"
                         [name]="filter.label">

            </dx-date-box>
        </div>
    </ng-template>

    <ng-template ngFor [ngForOf]="textFilters" let-filter let-i="index">
        <div class="text-search-wrapper filter" *ngIf="filter.inputType === inputType.text">
            <div class="label">{{filter.label}}</div>
            <dx-text-box [showClearButton]="true">

            </dx-text-box>
        </div>
    </ng-template>

    <ng-template ngFor [ngForOf]="dateRangeFilters" let-filter let-i="index">
        <div class="date-range-wrapper filter" *ngIf="filter.inputType === inputType.dateRange">
            <div *ngIf="filter.label" class="label">{{filter.label}}</div>
            
            <dx-date-range-box *ngIf="!filter.initialValue[0]"></dx-date-range-box>
            <dx-date-range-box *ngIf="filter.initialValue.length === 2" [startDate]="filter.initialValue[0]" [endDate]="filter.initialValue[1]"></dx-date-range-box>
        </div>
    </ng-template>

    <div class="button-container">
        <dx-button (onClick)="submitData()" class="btn text secondary border-secondary-accent mt-auto mb-auto mr-small" height="50px">Filter</dx-button>
    </div>
</div>