<app-progress [splash]="true" [value]="working" [splashOptions]="splashOptions">
    <app-access [platformFeature]="feature.AgencyUpdate" [redirectOnFailure]="true">
        <div class="account-edit flex-column">
            <div class="header flex-row no-wrap justify-between">
                <H3>Accounts</H3>
            </div>
            <div class="body info">
                <dx-tabs class="mb-large" [dataSource]="tabs" [selectedIndex]="tabIndex"
                    (onItemClick)="selectTab($event)">
                </dx-tabs>
                <dx-tree-list #treeList1 id="treeList1" [dataSource]="agencyAccounts" [columnAutoWidth]="true" [wordWrapEnabled]="true"
                    [showBorders]="true" [showColumnLines]="true" (onContentReady)="onContentReady($event)"
                    (onCellClick)="onCellClick($event)" (onRowClick)="onRowClick(treeList1, $event)"
                    [autoExpandAll]="true" parentExpr="parentId" [wordWrapEnabled]="false" [visible]="isViewMode"
                    [expandedRowKeys]="expandedKeys">
                    <dxo-selection mode="single"></dxo-selection>
                    <dxo-search-panel [visible]="true" [width]="240"></dxo-search-panel>
                    <dxo-header-filter [visible]="true"></dxo-header-filter>
                    <dxo-filter-row [visible]="true" [applyFilter]="'auto'"></dxo-filter-row>

                    <dxi-column sortOrder="asc" dataField="name" [width]="500"></dxi-column>
                    <dxi-column dataField="accountNumber" [alignment]="'left'" [width]="200"></dxi-column>
                    <dxi-column dataType="boolean" dataField="active" [falseText]="'No'" [trueText]="'Yes'"
                        [allowHeaderFiltering]="false" [alignment]="'left'">
                    </dxi-column>
                </dx-tree-list>
                <dx-tree-list #treeList2 id="treeList2" class="dx-tree-list" [dataSource]="accounts" [keyExpr]="'id'"
                    parentIdExpr="parentId" [columnAutoWidth]="true" [wordWrapEnabled]="true" [showBorders]="true"
                    [showColumnLines]="true" (onContentReady)="onContentReady($event)"
                    (onRowClick)="onRowClick(treeList2, $event)" [autoExpandAll]="true" [selectedRowKeys]="selectedRows"
                    (onSelectionChanged)="onSelectionChanged($event)" selectionMode="'multiple'"
                    [wordWrapEnabled]="false" [visible]="!isViewMode">
                    <dxo-selection mode="multiple"></dxo-selection>
                    <dxo-search-panel [visible]="true" [width]="240"></dxo-search-panel>
                    <dxo-header-filter [visible]="true"></dxo-header-filter>
                    <dxo-filter-row [visible]="true" [applyFilter]="'auto'"></dxo-filter-row>

                    <dxi-column sortOrder="asc" dataField="name" dataType="string" [width]="240"></dxi-column>
                    <dxi-column dataField="accountNumber" dataType="string" [alignment]="'left'" [width]="240">
                    </dxi-column>
                    <dxi-column dataType="boolean" dataField="active" [falseText]="'No'" [trueText]="'Yes'"
                        [allowHeaderFiltering]="false" [allowFiltering]="true" [filterValue]="true"
                        [alignment]="'left'">
                    </dxi-column>
                </dx-tree-list>
                <dx-button text="Save" class="mt-large" (click)="formSubmit($event)"
                    [visible]="!isViewMode"></dx-button>
                <!-- <dx-check-box text="Show hierarchies" [value]="true" (onValueChanged)="onValueChanged($event)"></dx-check-box> -->
            </div>
        </div>

    </app-access>
</app-progress>