<app-progress [splash]="true" [value]="working">
    <app-access [platformFeature]="feature.AgencyUpdate" [redirectOnFailure]="true">
        <div class="account-edit flex-column">
            <div class="header flex-row no-wrap justify-between">
                <H3>Access</H3>
            </div>
            <div class="body info mb-medium">
                <dx-select-box [items]="agents" displayExpr="fullName" valueEpxpr="id" class="mb-medium"
                    placeholder="Select an agent to begin" [value]="defaultAgent"
                    (onValueChanged)="changeAgent($event)">
                </dx-select-box>

                <dx-tree-list id="accountroles" [dataSource]="accounts" [columnAutoWidth]="true" keyExpr="id"
                    class="mb-large" [expandedRowKeys]="accountIds" parentIdExpr="parentId" [wordWrapEnabled]="true"
                    [showBorders]="true" [showColumnLines]="true" (onCellPrepared)="onCellPrepared($event)"
                    (onEditingStart)="startEdit($event)" (onEditCanceled)="cancelEdit($event)"
                    (onSaving)="update($event, 'confirm-cascade')" (onContentReady)="onContentReady($event)"
                    [disabled]="!selectedAgent" [wordWrapEnabled]="false">

                    <dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="false" [allowAdding]="false">
                    </dxo-editing>


                    <!-- <dxo-search-panel [visible]="true" [width]="240"></dxo-search-panel> -->
                    <dxo-header-filter [visible]="true"></dxo-header-filter>
                    <!-- <dxo-selection mode="multiple"></dxo-selection> -->
                    <!-- <dxo-column-chooser [enabled]="true"></dxo-column-chooser> -->
                    <dxo-filter-row [visible]="true" [applyFilter]="'auto'"></dxo-filter-row>

                    <dxi-column [width]="400" sortOrder="asc" dataField="name" dataType="string"
                        caption="Account Name"></dxi-column>
                    <dxi-column [width]="240" sortOrder="asc" dataField="accountNumber" dataType="string"
                        caption="Account Number"></dxi-column>

                    <dxi-column dataField="role" width="240" caption="Role" [allowEditing]="true">
                        <dxo-lookup placeholder="select a role" [dataSource]="accountRoles" displayExpr="name"
                            valueExpr="id" applyValueMode="instantly">
                        </dxo-lookup>
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-column>

                    <dxi-column [width]="200" dataType="boolean" dataField="requistionApprover" [falseText]="'No'"
                        [trueText]="'Yes'" [alignment]="'center'" [allowHeaderFiltering]="false" [allowFiltering]="true"
                        caption="Requisition Approver">
                    </dxi-column>
                    <dxi-column [width]="400" type="buttons" [alignment]="'left'">
                        <dxi-button name="edit"></dxi-button>
                    </dxi-column>
                </dx-tree-list>
            </div>
            <div> <!-- div makes size work, flex stretches it-->
                <dx-button text="Save" type="default" (click)="formSubmit($event)" [disabled]="!selectedAgent || disableSubmit"></dx-button>
            </div>
        </div>
    </app-access>
</app-progress>

<app-modal id="confirm-cascade">
    <div clas="text text-800 rubik mb-small">
        {{ cascadeAccount?.name }} updates will affect previously defined roles.
    </div>
    <div class="text rubik mb-medium">
        There are sub-accounts with overridden roles that will be affected by this update, how do you want to proceed?
    </div>
    <div class="flex-row no-wrap justify-between">
        <button type="button" class="btn pink" (click)="accountUpdate('confirm-cascade')">
            Update this Account Only
        </button>
        <button type="button" class="btn blue" (click)="cascadeUpdate('confirm-cascade')">
            Update All
        </button>
    </div>
</app-modal>