import { Component, Input } from '@angular/core';
import { ITimelineRecord } from '../../models/approval.overview.model';

@Component({
  selector: 'app-approvals-timeline',
  templateUrl: './approvals-timeline.component.html',
  styleUrls: ['./approvals-timeline.component.scss']
})
export class ApprovalsTimelineComponent {
  @Input() timeline!: ITimelineRecord[];

  protected showMoreState: Map<number, boolean> = new Map<number, boolean>();

  ngChanges() { 
    this.timeline.forEach((record) => {
      this.showMoreState.set(record.timeLineRecordId, false);
    });
  }

  showMore(timelineRecordId: number) {
    this.showMoreState.set(timelineRecordId, true);
  }

    /**
   * Check the element if it is text-overflow
   * @param elementId
   */
  isTextOverflow(elementId: string): boolean {
    const elem = document.getElementById(elementId);
    if (elem) {
      return (elem.offsetHeight < elem.scrollHeight);
    }
    else {
      return false;
    }
  }
}
