import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AgentApiConfig } from '../api';
import { IAccount, Agent, iMessage, Profile, Role } from '../models';
import { Feature } from './feature.service';
import { InterceptorSkipHeader } from 'src/app/interceptors';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { LocalStorageService } from './localStorage.service';
import { GetParamValueByKeyActivated, GetParamValueByKeySnapshot } from '../utils/routeHelpers';
import { NavigationService } from './navigation.service';
import { MonitorService } from './monitor.service';

export class AgentLoadOptions {
    public accounts?: boolean = undefined;
    public roles?: boolean = undefined;
    public features?: boolean = undefined;
    public agency?: boolean = undefined;
    public activities?: boolean = undefined;

    public static empty: AgentLoadOptions = new AgentLoadOptions();
}

@Injectable()
export class AgentService {
    public authenticated$: EventEmitter<Agent> = new EventEmitter<Agent>();
    public profileChange$: EventEmitter<Profile> = new EventEmitter<Profile>();

    constructor(
        private http: HttpClient, 
        private localStorageService: LocalStorageService,
        private navigationService: NavigationService,
        private monitorService: MonitorService
    ) {}

    private buildParams(options: AgentLoadOptions): HttpParams {
        let params = new HttpParams();

        if (options.accounts) {
            params = params.append('includeaccounts', true);
        }
        if (options.roles) {
            params = params.append('includeroles', true);
        }
        if (options.features) {
            params = params.append('includefeatures', true);
        }
        if (options.agency) {
            params = params.append('includeagency', true);
        }
        if (options.activities) {
            params = params.append('includeactivities', true);
        }

        return params;
    }

    private readonly PROFILE_INDEX = 0;
    private readonly ACCOUNT_ID_INDEX = 1;

    public get authenticatedAgent(): Agent | undefined {
        return this.getAgent();
    }

    //TODO: When we tackle state management, we should put this logic else where. Not worth it to move around dependencies now.
    public get currentAccount(): IAccount | undefined {
        const profilesWithoutAccount = ['backoffice', 'public', 'unauthorized']
        let parsedRoute = window.location.pathname.split('/').filter(x => x !== '');
        let account = undefined;
        if(parsedRoute.length > 0 && !profilesWithoutAccount.includes(parsedRoute[this.PROFILE_INDEX].toLowerCase())) {
            let accountId = parsedRoute[this.ACCOUNT_ID_INDEX];

            if(parseInt(accountId) && this.localStorageService.accountInStorage(accountId)) {
                account = this.localStorageService.getAccount(accountId);
            }
        } 

       return account;
    }

    public get currentProfile(): Profile | undefined {
        let parsedRoute = window.location.pathname.split('/').filter(x => x !== '');
        switch (parsedRoute[this.PROFILE_INDEX].toLowerCase()) {
            case 'backoffice':
                return Profile.Platform;
            case 'practice':
                return Profile.Practice;
            case 'dso':
                return Profile.DSO;
            case 'gpo':
                return Profile.GPO;
            case 'public':
                return Profile.Public;
            default:
                return Profile.Unauthorized;
        }
    }

    /**
     * Adds the given account to local storage key 'accounts' if it doesn't already exist
     * @param value Account to add to storage
     */
    public addAuthorizedAccount(value: IAccount) {
        this.localStorageService.storeAccount(value);
    }

    public async authenticate(): Promise<Agent | undefined> {
        let found = false;
        let agent = this.getAgent();
        if (!agent) {
            agent = await lastValueFrom(this.http.get<Agent>(AgentApiConfig.getFullUrl('me')));
                    } else {
            found = true;
        }
        this.setAgent(agent);

        if (!found) {
            this.authenticated$.emit(agent);
        }

        let accounts = await this.getAgentAccounts(agent.id!, { accounts: true });

        accounts.forEach(a => {
            this.localStorageService.storeAccount(a);
        });

        return agent;
    }

    public refreshProfile(profile: Profile) {
        this.profileChange$.emit(profile);
    }

    public unauthenticate() {
        this.clearAgent();
    }

    public async reload(): Promise<Agent | undefined> {
        this.unauthenticate();
        return await this.authenticate();
    }

    public getAuditInfo(agentId: number): Promise<string> {
        return lastValueFrom(this.http.get<string>(AgentApiConfig.getFullUrl(`${agentId}/audit`)));
    }

    public get(options: AgentLoadOptions): Promise<Agent[]> {
        const params = this.buildParams(options);
        return lastValueFrom(this.http.get<Agent[]>(AgentApiConfig.getFullUrl(''), { params }));
    }

    public getAgentByName(name: string, options: AgentLoadOptions): Promise<Agent> {
        const params = this.buildParams(options);
        return lastValueFrom(this.http.get<Agent>(AgentApiConfig.getFullUrl(`${name}`), { params }));
    }

    public getAgentById(agentId: number, options: AgentLoadOptions): Promise<Agent> {
        const params = this.buildParams(options);
        return lastValueFrom(this.http.get<Agent>(AgentApiConfig.getFullUrl(`${agentId}`), { params }));
    }

    public getAgentAccounts(agentId: number, options: AgentLoadOptions): Promise<IAccount[]> {
        const params = this.buildParams(options);
        return lastValueFrom(this.http.get<IAccount[]>(AgentApiConfig.getFullUrl(`${agentId}/account`), { params }));
    }

    public getAgentAccount(agentId: number, accountId: number, options: AgentLoadOptions): Promise<IAccount> {
        const params = this.buildParams(options);
        return lastValueFrom(this.http.get<IAccount>(AgentApiConfig.getFullUrl(`account/${accountId}/agent/${agentId}`), { params }));
    }

    public getRecentAgentAccount(agentId: number, maxCount: number, options: AgentLoadOptions): Promise<IAccount[]> {
        let params = this.buildParams(options);
        params = params.append('mostRecent', true);
        params = params.append('take', maxCount);

        return lastValueFrom(this.http.get<IAccount[]>(AgentApiConfig.getFullUrl(`${agentId}/account`), { params }));
    }

    public postAgent(agentId: number, header: Agent): Promise<Agent> {
        return lastValueFrom(this.http.post<Agent>(AgentApiConfig.getFullUrl(`${agentId}`), header));
    }

    private setAgent(value: Agent): void {
        localStorage.setItem('agentInfo', JSON.stringify(value));
    }
    
    public getAgent(): Agent | undefined {
        const a = localStorage.getItem('agentInfo');
        return a ? JSON.parse(a) as Agent : undefined;
    }

    private clearAgent(): void {
        localStorage.removeItem('agentInfo');
    }

    public requestReset(value: string): Promise<iMessage> {
        const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
        
        return lastValueFrom(this.http.post<iMessage>(AgentApiConfig.getFullUrl('reset'), {
            name: value,
            active: true,
            id: new Date().getDay(),
            created: new Date()
        }, {
            headers
        }));
    }

    public getRoles(platform: boolean, type: string | undefined): Promise<Role[]> {
        let params = new HttpParams();
        if (platform) {
            params = params.append('platform', true);
        }
        if (type) {
            params = params.append('accountType', type);
        }
        return lastValueFrom(this.http.get<Role[]>(AgentApiConfig.getFullUrl('role'), { params }));
    }

    public trackLogin(): Promise<any> {
        return lastValueFrom(this.http.post<iMessage>(AgentApiConfig.getFullUrl('login'), {
            activity: 'login'
        }));
    }

    public trackProfileSwitch(account: IAccount | undefined) {
        return lastValueFrom(this.http.post<iMessage>(AgentApiConfig.getFullUrl('activity'), {
            activity: 'switchaccount',
            description: account?.accountNumber
        }));
    }

    // PLATFORM
    public postFeature(agentId: number, feature: Feature): Promise<any> {
        return lastValueFrom(this.http.post(AgentApiConfig.getFullUrl(`${agentId}/feature/${feature}`), {}));
    }

    public deleteFeature(agentId: number, featureId: number): Promise<any> {
        return lastValueFrom(this.http.delete(AgentApiConfig.getFullUrl(`${agentId}/feature/${featureId}`)));
    }

    public postRole(agentId: number, role: Role): Promise<any> {
        return lastValueFrom(this.http.post(AgentApiConfig.getFullUrl(`${agentId}/role/${role.id}`), {}));
    }

    public deleteRole(agentId: number): Promise<any> {
        return lastValueFrom(this.http.delete(AgentApiConfig.getFullUrl(`${agentId}/role`)));
    }

    // ACCOUNT
    public postAccountFeature(agentId: number, account: IAccount, feature: Feature): Promise<any> {
        return lastValueFrom(this.http.post(AgentApiConfig.getFullUrl(`${agentId}/feature/${account.id}/${feature}`), {}));
    }

    public deleteAccountFeature(agentId: number, account: IAccount, featureId: number): Promise<any> {
        return lastValueFrom(this.http.delete(AgentApiConfig.getFullUrl(`${agentId}/feature/${account.id}/${featureId}`)));
    }

    public postAccountRole(agentId: number, account: IAccount, role: Role, inheritRole: boolean): Promise<any> {
        const params = new HttpParams().append('inheritRole', inheritRole);

        return lastValueFrom(this.http.post(AgentApiConfig.getFullUrl(`${agentId}/role/${account.id}/${role.id}`), { params }));
    }

    public deleteAccountRole(agentId: number, account: IAccount): Promise<any> {
        return lastValueFrom(this.http.delete(AgentApiConfig.getFullUrl(`${agentId}/role/${account.id}/role`)));
    }

}
