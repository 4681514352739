import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Cart, CartLine, CoreProduct, SupplierProduct } from 'src/app/shared/models';
import { OptimizeOrderLine } from 'src/app/shared/models/optimize.order.line.model';
import { CartService, ModalService, MonitorService, ClipboardService, LoginService } from 'src/app/shared/services';
import { GenericComponent } from 'src/app/shared/utils/generic.component';

@Component({
	selector: 'app-add-to-cart',
	templateUrl: './add-to-cart.component.html',
	styleUrls: ['./add-to-cart.component.scss']
})
export class AddToCartComponent extends GenericComponent implements OnDestroy
{
	@Input()
	mode: 'add' | 'update' | 'add-to' | 'variations-only' = 'add';

	private _showQty = false;

	@Input()
	get showCurrentQuantity(): boolean
	{
		return this._showQty;
	}

	set showCurrentQuantity(value: boolean)
	{
		this._showQty = value;
		if (this._showQty)
		{
			this.checkCart();
		}
	}

	@Input()
	buttonText: string = 'Pak the Yak';

	@Input()
	allowVariations: boolean = false;

	@Input()
	hideRemove = false;
	defaultQuantity: number = 1;

	@Input()
	set coreProduct(value: CoreProduct)
	{
		if (!this._coreProduct || (this.coreProduct?.id ?? 0) != (value?.id ?? 0))
		{
			this._coreProduct = value;
			this.checkCart();
		}
	}

	get coreProduct(): CoreProduct
	{
		return this._coreProduct;
	}

	@Input()
	set supplierProduct(value: SupplierProduct | undefined)
	{
		if (!this._supplierProduct || (this.supplierProduct?.id ?? 0) != (value?.id ?? 0))
		{
			this._supplierProduct = value;
			this.checkCart();
		}
	}

	get supplierProduct(): SupplierProduct | undefined
	{
		return this._supplierProduct;
	}

	@Input()
	get quantity(): number | undefined
	{
		return this._quantity;
	}

	set quantity(value: number | undefined)
	{
		this._quantity = value;
	}

	_default: number | undefined;

	@Input()
	get defaultValue(): number | undefined
	{
		return this._default;
	}

	set defaultValue(value: number | undefined)
	{
		this._default = value;
		if (value)
		{
			this.quantity = value;
		}
	}

	@Input()
	get currentQuantity()
	{
		return this._quantity;
	}

	@Input()
	layout: 'row' | 'wrap' = 'row';

	@Output()
	removed: EventEmitter<CartLine> = new EventEmitter<CartLine>();
	@Output()
	added: EventEmitter<CartLine> = new EventEmitter<CartLine>();
	@Output()
	loading: EventEmitter<CartLine> = new EventEmitter<CartLine>();

	@ViewChild('quantityInput', { static: false })
	quantityInput!: ElementRef;

	inCart = 0;
	isLoading = false;

	private _coreProduct: CoreProduct;
	private _supplierProduct: SupplierProduct | undefined;
	private _quantity: number | undefined = undefined;
	private subscriptions: Subscription[] = [];
	static _counter = 0;
	private _internalCount = 0;
	private quantityValid: boolean = true;
	currentLineId?: number = 0;
	allowCheckCart?: boolean = true;
	hasPendingApproval: boolean = true;

	constructor(
		private cartService: CartService,
		monitorService: MonitorService,
		login: LoginService,
		public modals: ModalService,
		private clipboard: ClipboardService
	)
	{
		super(monitorService, login);

		this._internalCount = AddToCartComponent._counter++;
		this._coreProduct = this.coreProduct;

		this.cartService.pendingApprovals.subscribe(pendingApprovalCount => {
			this.hasPendingApproval = pendingApprovalCount > 0;
		});

		this.cartService.cartChangedObservable$.subscribe(x =>
		{
			this.currentLineId = this.cartService.getCurrentLineId();
			this.allowCheckCart = this.cartService.getAllowUpdateBool();
			if (this.mode !== 'update' || this.mode == 'update' && this.currentLineId)
			{
				this.checkCart();

				this.currentLineId = undefined;
				this.allowCheckCart = true;
			}
		});
	}

	async ngOnInit(): Promise<void>
	{
		this.checkCart();
	}

	public load()
	{
		this.working = 1;
	}

	//check that product quantity is not negative or 0
	checkVal()
	{
		let value = this.quantityInput.nativeElement.value;
		const number = Number(value);
		if (isNaN(number) && value !== '')
		{
			this.monitor.handleError('Quantity must be a number');
			this.quantityValid = false;
		}
		else if ((!Number.isInteger(number) || number <= 0) && value !== '')
		{
			this.monitor.handleError('Quantity must be greater than 0');
			this.quantityValid = false;
		}
		else
		{
			this.quantityValid = true;
		}
	}

	//for returning unique id for each modal
	getModalId(): string
	{
		return `add-to-cart-${this._internalCount}`;
	}

	getATCId(): string
	{
		return `atc-${this._internalCount}`;
	}

	ngOnDestroy(): void
	{
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	increment(e: Event): void
	{
		if (this._quantity === undefined)
		{
			this._quantity = 1;
			this.quantity = 1
		}
		else if (this._quantity <= 0)
		{
			this._quantity = undefined;
		}
		else if (typeof this._quantity === 'string')
		{
			const q = parseInt(this._quantity, 10);
			if (isNaN(q))
			{
				this._quantity = undefined;
			}
			else
			{
				this._quantity = q + 1;
			}
		}
		else
		{
			this._quantity++;
		}
		this.quantityValid = true;
	}

	decrement(e: Event): void
	{
		if (this._quantity === undefined)
		{
			return;
		}
		else if (this._quantity <= 1)
		{
			this._quantity = undefined;
		}
		else if (typeof this._quantity === 'string')
		{
			const q = parseInt(this._quantity, 10);
			if (isNaN(q))
			{
				this._quantity = undefined;
			}
			else
			{
				this._quantity = q - 1;
				if (this._quantity < 0)
				{
					this._quantity = undefined;
				}
			}
		}
		else
		{
			this._quantity--;
		}
		this.quantityValid = true;
	}

	async removeLine(e: Event, id: string): Promise<any>
	{
		e.preventDefault();

		await this.execute(async () =>
		{
			const cart = this.cartService.cart;

			if (cart)
			{
				let line = cart.lines.find(l => l.supplierProductId !== undefined && l.supplierProductId === this._supplierProduct?.id);
				if (!line)
				{
					line = cart.lines.find(l => l.coreProductId === this._coreProduct?.id);
				}
				if (line)
				{
					this.loading.emit(line);
					this.modals.close(id);
					await this.cartService.removeLine(line);
					this.removed.emit(line);
					this.checkCart();
					this.inCart = 0;
					this._quantity = undefined;
				}
			}
			return true;
		}, 'Error removing product');
	}

	async createLine(e: Event): Promise<any>
	{
		e.preventDefault();

		const cart = this.cartService.cart;
		this.inCart = this.quantity ?? 0;
		if (cart)
		{
			await this.execute(async () =>
			{
				let productChange = false;

				let lineToUpdate = cart.lines.find(l => l.supplierProductId !== undefined && l.supplierProductId === this._supplierProduct?.id);
				if (!lineToUpdate)
				{
					lineToUpdate = cart.lines.find(l => l.coreProductId === this._coreProduct?.id);
				}
				if (lineToUpdate)
				{
					this.setWorking(99);
					this.loading.emit(lineToUpdate);
					if (Number(this.quantity) == 0)
					{
						this.quantity = 1;
					}
					else if (Number(this._quantity) < 0)
					{
						return false;
					}
					if (this.mode === 'add' || (this.mode === 'update' && this.inCart === 0))
					{
						lineToUpdate.quantity = Number(this.quantity ?? 1);
						if (this._supplierProduct && lineToUpdate.supplierProductId !== this.supplierProduct?.id)
						{
							lineToUpdate.supplierProductId = this._supplierProduct.id;
							productChange = true;
						}
						this.loading.emit(lineToUpdate);
						lineToUpdate = await this.cartService.addLine(lineToUpdate);
						this.added.emit(lineToUpdate);
					}
					else
					{
						lineToUpdate.quantity = Number(this.quantity ?? 1);
						lineToUpdate = await this.cartService.updateLine(lineToUpdate);
						this.added.emit(lineToUpdate);
					}
				}
				else if (this.mode === 'add' || (this.mode === 'update' && this.inCart === 0))
				{
					let lineToAdd = new CartLine();

					lineToAdd.id = 0;
					lineToAdd.coreProductId = this._coreProduct?.id;
					lineToAdd.quantity = Number(this._quantity ?? 1);
					lineToAdd.price = this._coreProduct.price;
					lineToAdd.uom = 'EA';
					lineToAdd.supplierProductId = this._supplierProduct?.id;

					this.loading.emit(lineToAdd);

					lineToUpdate = await this.cartService.addLine(lineToAdd);

					this.added.emit(lineToAdd);
				}
				this.quantity = undefined;

				if (productChange)
				{
					await this.cartService.refreshCart();
				}
				else
				{
					this.checkCart();
				}

				setTimeout(() =>
				{
					if (!this._showQty)
					{
						this.quantity = undefined;
					}
				}, 1000);
				return true;
			}, 'Error updating cart');
		}
	}

	public async createLineNonInteractive(): Promise<any>
	{
		const cart = this.cartService.cart;

		this.inCart = this.quantity ?? 0;
		if (cart)
		{
			await this.execute(async () =>
			{
				if (this._quantity)
				{
					this.isLoading = true
					if (Number(this.quantity) == 0)
					{
						this.quantity = 1;
					}
					else if (Number(this.quantity) < 0 || isNaN(this._quantity))
					{
						return false;
					}
					let line = cart.lines.find(l => l.supplierProductId !== undefined && l.supplierProductId === this._supplierProduct?.id);
					if (!line)
					{
						line = cart.lines.find(l => l.coreProductId === this._coreProduct?.id);
					}
					line = new CartLine();
					line.id = line?.id ?? 0;
					line.coreProductId = this._coreProduct?.id;
					line.quantity = Number(this._quantity ?? 1);
					line.price = this._coreProduct?.price;
					line.uom = 'EA';
					line.supplierProductId = this._supplierProduct?.id;

					if (this.mode === 'update')
					{
						line = await this.cartService.updateLine(line!);
					}
					else if (this.mode === 'add' || this.mode === 'add-to')
					{
						line = await this.cartService.addLine(line!);
					}

					this.quantity = undefined;
				}
				this.isLoading = false;
				return true;
			}, 'Error updating cart');
		}
	}

	checkCart(): void
	{
		const cart = this.cartService.cart;
		if (cart && cart.lines && (this._supplierProduct || this._coreProduct))
		{
			let line = cart.lines.find(l => this._supplierProduct && l.supplierProductId === this._supplierProduct?.id);
			if (!line)
			{
				line = cart.lines.find(l => l.coreProductId === this._coreProduct?.id);
			}

			if (line)
			{
				if (this.mode === 'update' && this.allowCheckCart || this.mode == 'update' && this.currentLineId == line.id)
				{
					this.quantity = line.quantity;
				}

				this.inCart = line.quantity;
			}
			else
			{
				this.inCart = 0;
			}
		}
		else
		{
			this.inCart = 0;
		}
	}

	openModal(id: string): void
	{
		this.modals.open(id);
	}

	closeModal(id: string): void
	{
		this.modals.close(id);
	}


	async openVariations(modal: string, show_variations: any)
	{
		//This stops a bug of two error messages popping up at once
		if (this.quantityInput.nativeElement.value === '' || this._quantity! > 0)
		{
			this.checkVal();
		}
		if (!this.quantityValid)
		{
			this.quantityInput.nativeElement.value = undefined;
			this.quantity = undefined;
		}
		//if the text input is empty, set the quantity to the default quantity
		if (this.quantityInput.nativeElement.value === '' || !this.quantityValid)
		{
			this.clipboard.setValue(`atc-qty-${this.coreProduct!.id}`, this.defaultQuantity);
		}
		else
		{
			this.clipboard.setValue(`atc-qty-${this.coreProduct!.id}`, this.quantity);
		}


		if (this.mode == 'add-to')
		{
			this.quantity = undefined;
		}
		this.openModal(modal);
		show_variations.openVariations();
	}

	get inCartDisplay(): string
	{
		if (this.inCart < 999)
		{
			return this.inCart.toString();
		}
		let c = this.inCart.toString();
		c = c.substring(0, c.length - 3);
		return `${c}k`;
	}
}