<app-progress [splash]="true" [value]="working">
    <div class="header flex-row no-wrap justify-between">
        <H3>Organization Formulary</H3>
        <label *ngIf="!(parentAccount?.allowManageFormulary ?? true)" class="text text-rubik text-500 mt-auto mb-small">
            *The parent organization has disabled formulary management on this account*
        </label>
    </div>
    <form (ngSubmit)="formSubmit($event)">
        <dx-form [formData]="formSource"
             (onFieldDataChanged)="onFieldDataChanged($event)" [disabled]="!(parentAccount?.allowManageFormulary ?? true)">
            <dxi-item itemType="group">
                
                <dxi-item editorType="dxSelectBox" dataField="publicFormularyId" [isRequired]="true"
                    [editorOptions]="{ dataSource: formularies | sort:'id', displayExpr: 'name', valueExpr: 'id' }">
                    <dxo-label template="Formulary"></dxo-label>
                    <dxi-validation-rule type="required" message="Formulary is required"></dxi-validation-rule>
                </dxi-item>

                <dxi-item>
                    <dx-button (click)="gotToFormulary()" class="mt-auto" [useSubmitBehavior]="false" text="Manage Formulary"></dx-button>
                </dxi-item>
    
                <dxi-item dataField="allowManageFormulary" editorType="dxCheckBox">
                    <dxo-label template="Allow practices to manage formulary"></dxo-label>
                </dxi-item>
                <dxi-item dataField="allowOrderHistory" editorType="dxCheckBox">
                    <dxo-label template="Allow practices to purchase from order history"></dxo-label>
                </dxi-item>
                <dxi-item dataField="allowDentalCity" editorType="dxCheckBox">
                    <dxo-label template="Allow practices to order from Dental City"></dxo-label>
                </dxi-item>
            </dxi-item>
            <dxi-item>
                <dx-button [disabled]="!isValid" class="mt-large" [useSubmitBehavior]="true" text="Save"></dx-button>
            </dxi-item>
        </dx-form>
    </form>
</app-progress>