import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  
  constructor(
    private router: Router,
    private location: Location
  ) { 
    const initialUrl = this.router.url;
    this.setActiveRoute(initialUrl);

    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      const fullUrl = event.urlAfterRedirects;
      this.setActiveRoute(fullUrl);
    });
  }

  private activeRouteSubject = new BehaviorSubject<string>('');
  activeRoute$ = this.activeRouteSubject.asObservable();

  private messageRoutes = (prefix: string) => {
    const messagePrefix = prefix + '/message';
    return {
      noAccess: () => this.router.navigate([messagePrefix + '/NO_ACCESS']),
      inactiveAgent: () => this.router.navigate([messagePrefix + '/INACTIVE_AGENT']),
      agencyInactive: () => this.router.navigate([messagePrefix + '/AGENCY_INACTIVE']),
      accountSwitch: () => this.router.navigate([messagePrefix + '/ACCOUNT_SWITCH']),
      loggedOut: () => this.router.navigate([messagePrefix + '/LOGGED_OUT']),
      invalidRoute: () => this.router.navigate([messagePrefix + '/INVALID_ROUTE']),
      accountAccessInvalid: () => this.router.navigate([messagePrefix + '/ACCOUNT_ACCESS_INVALID']),
    }
  }

  backoffice(){
    const prefix = '/backoffice';

    return {
      dashboard: () => this.router.navigate([prefix + '/dashboard']),
      account: {
          list: (parentRoute: ActivatedRoute | undefined = undefined, relativeToParent: boolean = false) => {
            if (parentRoute && relativeToParent) {
              this.router.navigate(['account'], { relativeTo: parentRoute });
            } else {
              this.router.navigate([prefix + '/account']);
            }
          },
          details: (accountId: number, parentRoute: ActivatedRoute | undefined = undefined, relativeToParent: boolean = false) => {
            if (parentRoute && relativeToParent) {
              this.router.navigate([`details`], { relativeTo: parentRoute });
            } else {
              this.router.navigate([prefix + `/account/${accountId}/details`]);
            }
          
          },
          agencies: (accountId: number, parentRoute: ActivatedRoute | undefined  = undefined, relativeToParent: boolean = false) => {
            if (parentRoute && relativeToParent) {
              this.router.navigate([`agencies`], { relativeTo: parentRoute });
            } else {
              this.router.navigate([prefix + `/account/${accountId}/agencies`]);
            }
          },
          addresses: (accountId: number, parentRoute: ActivatedRoute | undefined  = undefined, relativeToParent: boolean = false) => {
            if (parentRoute && relativeToParent) {
              this.router.navigate([`addresses`], { relativeTo: parentRoute });
            } else {
              this.router.navigate([prefix + `/account/${accountId}/addresses`]);
            }
          },
          suppliers:{
            list: (accountId: number, parentRoute: ActivatedRoute | undefined  = undefined, relativeToParent: boolean = false) => {
              if (parentRoute && relativeToParent) {
                this.router.navigate([`suppliers`], { relativeTo: parentRoute });
              } else {
                this.router.navigate([prefix + `/account/${accountId}/suppliers`]);
              }
            },
            edit: (accountId: number, supplierId: number, parentRoute: ActivatedRoute | undefined  = undefined, relativeToParent: boolean = false) => {
              if(parentRoute && relativeToParent){
                this.router.navigate([`${supplierId}`], { relativeTo: parentRoute });
              }
              else{
                this.router.navigate([prefix + `/account/${accountId}/suppliers/${supplierId}`]);
              }
            },
            new: (accountId: number, parentRoute: ActivatedRoute | undefined  = undefined, relativeToParent: boolean = false) => {
              if(parentRoute && relativeToParent){
                this.router.navigate([`new`], { relativeTo: parentRoute });
              }
              else{
                this.router.navigate([prefix + `/account/${accountId}/suppliers/new`]);
              }
            }
          },
          formulary: {
            configurePractice: (accountId: number, parentRoute: ActivatedRoute | undefined  = undefined, relativeToParent: boolean = false) => {
              if (parentRoute && relativeToParent) {
                this.router.navigate([`formulary/configuration/practice`], { relativeTo: parentRoute });
              } else {
                this.router.navigate([prefix + `/account/${accountId}/formulary/configure`]);
              }
            
            },
            configureOrganization: (accountId: number, parentRoute: ActivatedRoute | undefined  = undefined, relativeToParent: boolean = false) => {
              if (parentRoute && relativeToParent) {
                this.router.navigate([`formulary/configuration/organization`], { relativeTo: parentRoute });
              } else {
                this.router.navigate([prefix + `/account/${accountId}/formulary/organization`]);
              }
            
            },
            formulary: (accountId: number, formularyId: number, parentRoute: ActivatedRoute | undefined  = undefined, relativeToParent: boolean = false) => {
              if (parentRoute && relativeToParent) {
                this.router.navigate([`${formularyId}`], { relativeTo: parentRoute });
              } else {
                this.router.navigate([prefix + `/account/${accountId}/formulary/${formularyId}`]);
              }
            
            },
            products: (accountId: number, parentRoute: ActivatedRoute | undefined  = undefined, relativeToParent: boolean = false) => {
              if (parentRoute && relativeToParent) {
                this.router.navigate([`products`], { relativeTo: parentRoute });
              } else {
                this.router.navigate([prefix + `/account/${accountId}/formulary/products`]);
              }
            },
          },
          new: () => this.router.navigate([prefix + `/account/new/details`]),
      },
      agency: {
          list: () => this.router.navigate(['/backoffice/agency']),
          details: (agencyId: number) => this.router.navigate([prefix + `/agency/${agencyId}/details`]),
          accounts: (agencyId: number) => this.router.navigate([prefix + `/agency/${agencyId}/account`]),
          agents: {
            list: (agencyId: number, parentRoute: ActivatedRoute | undefined = undefined, relativeToParent: boolean = false) => {
              if (parentRoute && relativeToParent) {
                this.router.navigate(['agents'], { relativeTo: parentRoute });
              } else {
                this.router.navigate([prefix + `/agency/${agencyId}/agents`]);
              }
            },
            details: (agencyId: number, agentId: number, parentRoute: ActivatedRoute | undefined = undefined, relativeToParent: boolean = false) => {
              if (parentRoute && relativeToParent) {
                this.router.navigate([`agents/${agentId}`], { relativeTo: parentRoute });
              } else {
                this.router.navigate([prefix + `/agency/${agencyId}/agents/${agentId}`]);
              }
            },
            new: (agencyId: number, parentRoute: ActivatedRoute | undefined = undefined, relativeToParent: boolean = false) => {
              if (parentRoute && relativeToParent) {
                this.router.navigate([`agents/new`], { relativeTo: parentRoute });
              } else {
                this.router.navigate([prefix + `/agency/${agencyId}/agents/new`]);
              }
            }
          },
          access: (agencyId: number) => this.router.navigate([prefix + `/agency/${agencyId}/access`]),
          formulary: (agencyId: number, formularyId: number) => this.router.navigate([prefix + `/agency/${agencyId}/formulary/${formularyId}`]),
          new: () => this.router.navigate([prefix + `/agency/new/details`])
      },
      formulary: {
        list: () => this.router.navigate(['/backoffice/formulary']),
        details: (formularyId: number) => this.router.navigate([prefix + `/formulary/${formularyId}/details`]),
        products: (formularyId: number) => this.router.navigate([prefix + `/formulary/${formularyId}/products`]),
        new: () => this.router.navigate([prefix + `/formulary/new/details`])
      },
      reports: () => this.router.navigate([prefix + '/reports']),
      message: this.messageRoutes(prefix)
    }
  }

  practice(accountId: number){
    const prefix = `/practice/${accountId}`;

    return {
      search: (fragment: string | undefined = undefined) => this.router.navigate([prefix + `/catalog/search`], {fragment: fragment}),
      reports: () => this.router.navigate([prefix + `/reports`]),
      dashboard: (parentRoute: ActivatedRoute | undefined = undefined, relativeToParent: boolean = false) => {
        if (parentRoute && relativeToParent) {
          this.router.navigate([`dashboard`], { relativeTo: parentRoute });
        } else {
          this.router.navigate([`${prefix}/dashboard`]);
        }
      },
      requisition: {
        details: (requisitionId: number, parentRoute: ActivatedRoute | undefined = undefined, relativeToParent: boolean = false): Promise<boolean> => {
          if (parentRoute && relativeToParent) {
            return this.router.navigate([`requisition/${requisitionId}/details`], { relativeTo: parentRoute });
          } else {
            return this.router.navigate([`${prefix}/requisition/${requisitionId}/details`]);
          }
        },
        review: (requisitionId: number, parentRoute: ActivatedRoute | undefined = undefined, relativeToParent: boolean = false) => {
          if (parentRoute && relativeToParent) {
            this.router.navigate([`requisition/${requisitionId}/review`], { relativeTo: parentRoute });
          } else {
            this.router.navigate([`${prefix}/requisition/${requisitionId}/review`]);
          }
        },
        list: () => this.router.navigate([prefix + `/requisition`]),
      },
      order: {
        details: (orderId: number, parentRoute: ActivatedRoute | undefined = undefined, relativeToParent: boolean = false) => {
          if (parentRoute && relativeToParent) {
            this.router.navigate([`order/${orderId}/details`], { relativeTo: parentRoute });
          } else {
            this.router.navigate([`${prefix}/order/${orderId}/details`]);
          }
        },
        review: (parentRoute: ActivatedRoute | undefined = undefined, relativeToParent: boolean = false) => {
          if (parentRoute && relativeToParent) {
            this.router.navigate([`order/review`], { relativeTo: parentRoute });
          } else {
            this.router.navigate([`${prefix}/order/review`]);
          }
        
        },
        list: () => this.router.navigate([prefix + `/order`]),
      },
      cart: {
        review: () => this.router.navigate([`${prefix}/cart/review`]),

      },
      approvals: () => this.router.navigate([`${prefix}/pending-approvals`]),
      message: this.messageRoutes(prefix)
    }
  }

  dso(accountId: number){
    const prefix = `/dso/${accountId}`;

    return {
      reports: () => this.router.navigate([prefix + `/reports`]),
      dashboard: (parentRoute: ActivatedRoute | undefined = undefined, relativeToParent: boolean = false) => {
        if (parentRoute && relativeToParent) {
          this.router.navigate([`dashboard`], { relativeTo: parentRoute });
        } else {
          this.router.navigate([`${prefix}/dashboard`]);
        }
      },
      message: this.messageRoutes(prefix)
    }
  }

  gpo(accountId: number){
    const prefix = `/gpo/${accountId}`;

    return {
      reports: () => this.router.navigate([prefix + `/reports`]),
      dashboard: (parentRoute: ActivatedRoute | undefined = undefined, relativeToParent: boolean = false) => {
        if (parentRoute && relativeToParent) {
          this.router.navigate([`dashboard`], { relativeTo: parentRoute });
        } else {
          this.router.navigate([`${prefix}/dashboard`]);
        }
      },
      message: this.messageRoutes(prefix)
    }
  }

  root(){
    return {
      releaseNotes: () => this.router.navigate(['backoffice/release-notes']),
      login: () => this.router.navigate(['/login']),
      unauthorized: () => this.router.navigate(['public/unauthorized']),
      home: () => this.router.navigate(['/home']),
    }
  }

  public(){
    const prefix = '/public';

    return {
      login: () => this.router.navigate([prefix + '/login']),
      unauthorized: () => this.router.navigate([prefix + '/unauthorized']),
      message: this.messageRoutes(prefix)
    } 
  }

  back(){
    this.location.back();
  }

  private setActiveRoute(fullUrl: string) {
    const subRoute = fullUrl.split('/').pop();
    const activeRoute = subRoute ? subRoute : "";
    this.activeRouteSubject.next(activeRoute);
  }
}
