<div class="standard-background flex-column" id="dashboard">


  <h3 class="mb-medium" style="font-weight: 300;">{{ accounts.customizations['PracticeDashboardTitle'] ?? 'Practice Dashboard' }}</h3>


  <div class="mt-large" [ngClass]="{'hidden': checkIfEmpty('freq')}">
    <div style="float: left;">
      <a (click)="goToSearch(catalogFilterButtons[0].value)">
        <h4 class="text text-black">{{ accounts.customizations['FrequentlyOrderedTitle'] ?? catalogFilterButtons[0].text }}</h4>
      </a>
    </div>
    <div class="view-all-link">
      <a (click)="goToSearch(catalogFilterButtons[0].value)" style="color: #f26363; margin-top: 5px;"> View all </a>
    </div>
  </div>
  <app-product-search [ngClass]="{'hidden': checkIfEmpty('freq')}" (display)="displayProductSearch('freq')"
    id="practice-frequent-products" class="mb-medium" direction="horizontal" [tab]="catalogFilterButtons[0]" [limit]="true" [pageSize]="20"
    [allowSearch]="false" [isHomePage]="true"></app-product-search>


  <div class="mt-large" [ngClass]="{'hidden': checkIfEmpty('recent')}">
    <div style="float: left;">
      <a (click)="goToSearch(catalogFilterButtons[1].value)">
        <h4 class="text text-black">{{ accounts.customizations['RecentlyOrderedTitle'] ?? catalogFilterButtons[1].text }}</h4>
      </a>
    </div>
    <div class="view-all-link">
      <a (click)="goToSearch(catalogFilterButtons[1].value)" style="color: #f26363; margin-top: 5px;"> View all </a>
    </div>
  </div>
  <app-product-search [ngClass]="{'hidden': checkIfEmpty('recent')}" (display)="displayProductSearch('recent')"
    id="practice-recent-products" class="mb-medium" direction="horizontal" [tab]="catalogFilterButtons[1]" [limit]="true" [pageSize]="20"
    [allowSearch]="false" [isHomePage]="true"></app-product-search>


  <div class="mt-large " [ngClass]="{'hidden': checkIfEmpty('rec')}">
    <div style="float: left;">
      <a (click)="goToSearch(catalogFilterButtons[2].value)">
        <h4 class="text text-black">{{ accounts.customizations['RecommendedTitle'] ?? catalogFilterButtons[2].text }} </h4>
      </a>
    </div>
    <div class="view-all-link">
      <a (click)="goToSearch(catalogFilterButtons[2].value)" style="color: #f26363; margin-top: 5px;"> View all </a>
    </div>
  </div>
  <app-product-search [ngClass]="{'hidden': checkIfEmpty('rec')}" (display)="displayProductSearch('rec')"
    id="practice-recommended-products" class="mb-medium" direction="horizontal" [tab]="catalogFilterButtons[2]" [limit]="true" [pageSize]="20"
    [allowSearch]="false" [isHomePage]="true"></app-product-search>


</div>