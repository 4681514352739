export interface IFilter {
	inputType: inputType;
	name: string;
    label: string;
    filterData?: { Id: number, Name: string }[]; //filter data for single and multi select must have key value pairs
    initialValue?: any;
}

export enum inputType {
	date = "date",
	dateRange = "dateRange",
    text = "text",
    multiSelect = "multiSelect",
	select = "select"
}