import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AgencyApiConfig } from '../api/agency.api.config';
import { Agent, Agency, IAccount } from '../models';

export class AgencyLoadOptions {
  public agents?: boolean | undefined;
  public accounts?: boolean | undefined;
  public roles?: boolean | undefined;
  public formulary?: boolean | undefined;
}

@Injectable({providedIn: 'root'})
export class AgencyService {
  constructor(private http: HttpClient) { }

  private buildParams(options: AgencyLoadOptions | undefined): HttpParams {
    let params = new HttpParams();

    if (options) {
      if (options.agents) {
        params = params.append('includeagents', true);
      }
      if (options.accounts) {
        params = params.append('includeaccounts', true);
      }
      if (options.roles) {
        params = params.append('includeroles', true);
      }
      if (options.formulary) {
        params = params.append('includeformulary', true);
      }
    }
    return params;
  }

  public get(options: AgencyLoadOptions | undefined): Promise<Agency[]> {
    const params = this.buildParams(options);
    return lastValueFrom(this.http.get<Agency[]>(AgencyApiConfig.getFullUrl(''), { params }));
  }

  public getAgencyById(agencyId: number, options: AgencyLoadOptions | undefined): Promise<Agency> {
    const params = this.buildParams(options);
    return lastValueFrom(this.http.get<Agency>(AgencyApiConfig.getFullUrl(`${agencyId}`), { params }));
  }

  public updateAgency(agencyId: number, value: Agency): Promise<Agency> {
    return lastValueFrom(this.http.put<Agency>(AgencyApiConfig.getFullUrl(`${agencyId}`), value));
  }

  public postAgency(value: Agency): Promise<Agency> {
    return lastValueFrom(this.http.post<Agency>(AgencyApiConfig.getFullUrl(``), value));
  }
  
  public deleteAgency(agencyId: number): Promise<Agency> {
    return lastValueFrom(this.http.delete<Agency>(AgencyApiConfig.getFullUrl(`${agencyId}`)));
  }

  public deleteAgent(agentId: number, agencyId: number): Promise<Agency> {
    return lastValueFrom(this.http.delete<Agency>(AgencyApiConfig.getFullUrl(`${agentId}/agent/${agencyId}`)));
  }

  public createAgent(agencyId: number, agent: Agent): Promise<Agent>{
    // clear these on save, they won't work
    Object.assign(agent, { accountFeatures: undefined, accountRoles: undefined });
    return lastValueFrom(this.http.put<Agent>(AgencyApiConfig.getFullUrl(`${agencyId}/agent`), agent));
  }

  public addAgencyAccount(agencyId: number, account: IAccount): Promise<any>{
    return lastValueFrom(this.http.put<any>(AgencyApiConfig.getFullUrl(`${agencyId}/account`), account));
  }

  public deleteAgencyAccount(agencyId: number, accountId: number): Promise<any>{
    return lastValueFrom(this.http.delete<any>(AgencyApiConfig.getFullUrl(`${agencyId}/account/${accountId}`)));
  }
  
}
