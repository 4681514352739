import { Component, Input, ViewChild } from '@angular/core';
import { DxDateBoxComponent } from 'devextreme-angular';
import { IGetRequisitionSubmitDetailsResponse } from 'src/app/shared/models/requisition.submit.details.response.model';
import { ISubmitRequisitionRequest } from 'src/app/shared/models/submit.requisition.request.model';
import { AgentService, ModalService, MonitorService } from 'src/app/shared/services';

@Component({
	selector: 'app-submission-details-form',
	templateUrl: './submission-details-form.component.html',
	styleUrls: ['./submission-details-form.component.scss']
})
export class SubmissionDetailsFormComponent
{
	@Input()
	sendSubmission!: (submissionData: ISubmitRequisitionRequest) => void;
	@Input()
	approvalDetails?: IGetRequisitionSubmitDetailsResponse;
	@Input()
	errorMessage?: string;

	@ViewChild(DxDateBoxComponent, { static: false }) // if static is set to true, deadline wont be populated
	deadlineDate?: DxDateBoxComponent;
	ccBoxValue: string = '';
	commentBoxValue: string = '';

	constructor(private modals: ModalService, private agentService: AgentService, private monitor: MonitorService)
	{
	}


	verifySubmit(): void
	{
		let ccEmails: string[] = [];

		if (this.approvalDetails?.hasManualApproval)
		{
			let deadlineDate = this.deadlineDate?.instance?.option('value') as Date;

			let comment = this.commentBoxValue;
			let emailValue = this.ccBoxValue.trim();
			let today = new Date();
			today.setHours(0,0,0,0);

			if (emailValue !== '')
			{
				ccEmails = emailValue.split(/\r?\n/);
			}

			if (!this.approvalDetails.manualApprovalDetails?.isDeadlineOptional && !deadlineDate)
			{
				//show required message
				this.monitor.handleError('Deadline is required');
				return;
			}
			else if (deadlineDate != null && deadlineDate < today)
			{
				//show invalid date message
				this.monitor.handleError('Invalid deadline date');
				return;
			}

			if (!this.validateEmailFormat(ccEmails))
			{
				//show invalid email message
				this.monitor.handleError('Invalid email format');
				return;
			}

			let submissionData: ISubmitRequisitionRequest = {
				deadline: deadlineDate ?? undefined,
				approverNotes: comment ?? '',
				ccEmails: ccEmails ?? [],
				requisitionId: this.approvalDetails?.requisitionId ?? -1,
				submitAgentId: this.agentService.authenticatedAgent?.id ?? 0
			};

			this.sendSubmission(submissionData);
		}
		else
		{
			let emailValue = this.ccBoxValue.trim();

			if (emailValue !== '')
			{
				ccEmails = emailValue.split(/\r?\n/);
			}

			if (!this.validateEmailFormat(ccEmails))
			{
				//show invalid email message
				this.monitor.handleError('Invalid email format');
				return;
			}

			let submissionData: ISubmitRequisitionRequest = {
				ccEmails: ccEmails ?? [],
				requisitionId: this.approvalDetails?.requisitionId ?? -1,
				submitAgentId: this.agentService.authenticatedAgent?.id ?? 0,
				approverNotes: ''
			};

			this.sendSubmission(submissionData);
		}
	}

	closeModal(modalId: string)
	{
		this.modals.close(modalId);
	}

	validateEmailFormat(ccEmails: string[]): boolean
	{
		let regex = new RegExp(/^\S+@\S+\.\S+$/);

		for (let email of ccEmails)
		{
			if (!regex.test(email))
			{
				//show invalid email message
				return false;
			}
		}
		return true;
	}

	public clearForm(): void
	{
		this.ccBoxValue = '';
		this.commentBoxValue = '';
		this.deadlineDate?.instance?.option('value', undefined);
	}
}