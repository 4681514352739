import { Component, Input, ViewChild } from '@angular/core';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { CoreProduct } from 'src/app/shared/models';
import { ModalService } from 'src/app/shared/services';
import { AddToCartComponent } from '../../cart/add-to-cart/add-to-cart.component';

@Component({
  selector: 'app-core-product-card',
  templateUrl: './core-product-card.component.html',
  styleUrls: ['./core-product-card.component.scss'],
})
export class CoreProductCardComponent {
  @Input()
  product: CoreProduct | undefined;
  @Input()
  allowQuickAdd = true;
  @Input()
  showCategories = true;
  @Input()
  showTags = true;
  @Input()
  size: 'regular' | 'small' = 'regular';
  @Input()
  nameIsLink = false;
  private _internalCount = 0;

  @ViewChild('show_variations', { static: false })
  show_variations: ModalComponent | undefined;

  constructor(
    public modals: ModalService,
  ) {
    this._internalCount = AddToCartComponent._counter++;
  }

  openModal(id: string): void {
    this.modals.open(id);
  }
  closeModal(id: string): void {
    this.modals.close(id);
  }
  //for returning unique id for each modal
  getModalId(): string {
    return `add-to-cart-${this._internalCount}`;
  }

  getATCId(): string {
    return `atc-${this._internalCount}`;
  }
}
