import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LoginService } from 'src/app/shared/services';

@Component({
  selector: 'app-nav-to-login',
  templateUrl: './nav-to-login.component.html',
  styleUrls: ['./nav-to-login.component.scss']
})
export class NavToLoginComponent {

  @Output()
  login: EventEmitter<boolean> = new EventEmitter<boolean>();

  working: number = 100;
  constructor(private loginService: LoginService) { 
    //new code
    this.logout();
    this.loginService.login('/home');
  }

  logout(): void {
    this.loginService.logout();
  }
}
