import { Injectable } from "@angular/core";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { environment } from "src/environments/environment";
import { ToastService } from "./toastr.service";

@Injectable({ providedIn: 'root' })
export class MonitorService {
    appInsights: ApplicationInsights;

    constructor(
        private snackbar: ToastService,
    ) {
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: environment.appInsights.instrumentationKey,
                enableAutoRouteTracking: true
            }
        });
        this.appInsights.loadAppInsights();
    }

    // log a page was viewed (like GA)
    logPageView(name?: string, url?: string) {
        this.appInsights.trackPageView({
            name: name,
            uri: url
        });
    }

    // log that an event happened
    logEvent(name: string, properties?: { [key: string]: any }) {
        this.localHelper({name: name, properties: properties}, LogLevel.Information);
        this.appInsights.trackEvent({ name: name }, properties);
    }
    // log an exception
    logException(exception: any, severityLevel?: number) {
        this.localHelper(exception, LogLevel.Exception);
        this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    }

    // trace level logging
    logTrace(message: string, properties?: { [key: string]: any }) {
        this.localHelper(message, LogLevel.Information);
        this.appInsights.trackTrace({ message: message }, properties);
    }

    handleError(error: any, userMessage: string = 'An error has occured.', extendedTime: boolean = false, title: string | undefined = undefined) {
        var sherpaError = error;
        if (error?.error?.SherpaError?.length) {
            sherpaError = error.error.SherpaError[0];
        } else if (error?.error) {
            sherpaError = error.error;
        }
        switch (error?.status ?? error) {
            case 401:
                this.snackbar.error({
                    title: title ?? 'Unauthorized',
                    message: 'Agent is unauthorized',
                }, extendedTime);
                this.logException(error);
                break;
            case 500:
                this.snackbar.error({
                    title: title ?? `Internal Server Error`,
                    message: sherpaError + (userMessage ? " " + userMessage : ""), 
                }, extendedTime);
                this.logException(error);
                break;
            case 404:
                this.snackbar.error({
                    title: title ?? `Not Found`,
                    message: sherpaError ?? userMessage,
                }, extendedTime);
                this.logException(error);
                break;
            case 403:
                this.snackbar.error({
                    title: title ?? `Forbidden`,
                    message: 'Agent is unauthorized',
                }, extendedTime);
                this.logException(error);
                break;
            case 400:
                this.snackbar.error({
                    title: title ?? `Bad Request`,
                    message: sherpaError ?? userMessage,
                }, extendedTime);
                this.logException(error);
                break;
            default:
                this.snackbar.error({
                    title: title ?? `Error`,
                    message: sherpaError ?? userMessage,
                }, extendedTime);
                this.logException(error);
                break;
        }
    }

    handleSuccess(userMessage: string = 'Success', extendedTime: boolean = false, title: string | undefined = undefined) {
        this.snackbar.success({
            title: title ?? 'Success',
            message: userMessage,
        }, extendedTime);
        this.logEvent(userMessage);
    }

    handleInfo(message: string, extendedTime: boolean = false, title: string | undefined = undefined) {
        this.snackbar.info({
            title: title,
            message: message
        }, extendedTime);
        this.logTrace(message);
    }

    /**
     * Used to log messages locally not just to application insights for better debugging and error handlng
     * @param toLog What will be logged to console
     * @param level The log level it will be logged at, can filter on in dev tools
     */
    private localHelper(toLog: any, level: LogLevel){
        if(!environment.production){
            switch(level){
                case LogLevel.Information:
                    console.info(toLog);
                    break;
                case LogLevel.Warning:
                    console.warn(toLog);
                    break;
                case LogLevel.Exception:
                    console.error(toLog);
                    break;
                default:
                    console.log(toLog);
                    break;
            }
        }
    }
}

enum LogLevel {
    Information = 0,
    Warning = 1,
    Exception = 2
}