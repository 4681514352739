import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Agency } from 'src/app/shared/models';
import { LoginService, MonitorService, NavigationService } from 'src/app/shared/services';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { Feature } from 'src/app/shared/services/feature.service';
import { GenericComponent } from 'src/app/shared/utils/generic.component';

@Component({
  selector: 'app-agency-edit',
  templateUrl: './agency-edit.component.html',
  styleUrls: ['./agency-edit.component.scss']
})
export class AgencyEditComponent extends GenericComponent implements OnInit, OnDestroy {

  agency: Agency | undefined;
  agentId: number | undefined;

  agencyType: string | undefined;
  working = 0;

  feature = Feature;
  showBackButton: boolean = false;
  previousRoutePath?: string;
  returnUrl?: string;
  subscriptions: Subscription[] = [];

  appAccessNavigationOptions: { feature: Feature, aTagId: string, aTagText: string, action: Function }[] = [
    {
      feature: Feature.AgencyUpdate,
      aTagId: 'details',
      aTagText: 'Details',
      action: () => this.navigationService.backoffice().agency.details(this.agency!.id)
    },
    {
      feature: Feature.AgentUpdate,
      aTagId: 'accounts',
      aTagText: 'Accounts',
      action: () => this.navigationService.backoffice().agency.accounts(this.agency!.id)
    },
    {
      feature: Feature.AgentUpdate,
      aTagId: 'agents',
      aTagText: 'Agents',
      action: () => this.navigationService.backoffice().agency.agents.list(this.agency!.id)
    },
    {
      feature: Feature.AgentUpdate,
      aTagId: 'access',
      aTagText: 'Access',
      action: () => this.navigationService.backoffice().agency.access(this.agency!.id)
    },
    {
      feature: Feature.AgentUpdate,
      aTagId: 'formulary',
      aTagText: 'Formulary',
      action: () => this.navigationService.backoffice().agency.formulary(this.agency!.id, this.agency?.formulary?.id ?? 0)
    }
  ]

  constructor(
    private agencyService: AgencyService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    monitor: MonitorService,
    login: LoginService,
    private router: Router,
  ) { 
    super(monitor, login);
  }

  ngOnInit() {
    this.working = 1;
    const state = history.state;
    this.showBackButton = state && state.fromSpecificPage;

    this.subscriptions.push(this.route.params.subscribe(async (params) => {
      // If a agency Id was supplied in the route populate the form fields with current values

      try {
        if (params.agencyId !== undefined && params.agencyId !== 'new') {
          this.agency = await this.loadAgency(params.agencyId);
        } else {
          this.agency = {
            active: true,
            id: 0,
            name: '',
            created: new Date(),
            agents: [],
            platformAgency: false
          }
        }        
        this.checkAgencytype();  
      } catch (exception) {
        this.navigationService.backoffice().message.invalidRoute();
      } finally {
        this.working = 0;
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  async loadAgency(id: number): Promise<Agency | undefined> {
    var a: Agency | undefined = await this.agencyService.getAgencyById(id, { accounts: true, agents: true, formulary: true });
    a.agents?.forEach(ag => {
      if (ag.lastLogin) {
        ag.lastLogin = ag.lastLogin;
      }
    });
    return a;
  }


  onActivate(component: any) {    
      component.agency = this.agency;   
      component.formulary = this.agency?.formulary;
  }

  updateAgency(e: any) {
    this.agency = e;
    this.navigationService.backoffice().agency.details(this.agency!.id);
    this.checkAgencytype();
  }

  checkAgencytype() {
    if (this.agency) {
      if (this.agency.platformAgency) {
        this.agencyType = 'Platform Agency';
      } else {
        this.agencyType = 'Agency';
      }
    }

  }

  goBack() {
    window.history.back();
  }

  returnToAgencyList() {
    this.navigationService.backoffice().agency.list();
  }
}
