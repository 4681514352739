import { IAccount, Agency } from ".";


export class Agent {
  public id?: number;
  public firstName!: string;
  public lastName!: string;
  public active!: boolean;
  public email!: string;
  public phone?: string;
  public company?: string;
  public lastLogin?: string;
  public userName?: string;
  public role?: string;
  public features: number = 0;
  public accountFeatures: any | undefined = undefined;
  public accountRoles: any | undefined = undefined;
  public adminRole?: string;
  public createdBy?: number;
  public created?: Date;
  public modifiedBy?: number;
  public modified?: Date;
  public agency: Agency | undefined;
  public customUsername!: boolean;
  public inviteLink?: string;
}

export class Role {
  public id!: number;
  public name!: string;
}

// export class Feature {
//   public id!: number;
//   public name!: string;
// }


export enum Profile {
  Agent = 0,
  Platform = 1,
  Practice = 2,
  DSO = 3,
  GPO = 4,
  Public = 5,
  Unauthorized = 999
}