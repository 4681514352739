import { Injectable } from '@angular/core';
import { PingApiConfig } from '../api/ping.api.config';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { InterceptorSkipHeader } from 'src/app/interceptors';
import { AgentApiConfig } from '../api';
import { Message } from '../models';

@Injectable({
  providedIn: 'root'
})
export class PingService {

  constructor(private http: HttpClient) { }

  /**
   * 
   * @param id - GUID ID of the user to be activated
   * @param signature - Signature created at time of user creation
   * 
   * Activate a given user and provide them with a redirect ticket to 
   * verify their account and create their password.
   * 
   * Returns a message to be displayed if the user is already activated.
   * 
   * @returns URL or message - redirect ticket or message to be displayed
   */
  public activateUser(id:string, signature:string): Promise<string> {
    let params = new HttpParams();
    params.append("sig", signature);

    return lastValueFrom(this.http.get<string>(PingApiConfig.getFullUrl(`activation/${id}`), { params }));
  }

  public getAllMessages(): Promise<Message[]> {
    let url = PingApiConfig.getFullUrl(`messages`);
   
    const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
    return  lastValueFrom(this.http.get<Message[]>(url, { headers }));;
  }

}
