import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { IAccount, Agent, Cart, CartLine, CoreProduct, SupplierProduct } from 'src/app/shared/models';
import { AgentService, CartService, LoginService, ModalService, NavigationService, OrderService } from 'src/app/shared/services';
import { ApprovalService } from 'src/app/shared/services/approval.service';
import { MonitorService } from 'src/app/shared/services/monitor.service';
import { Feature } from 'src/app/shared/services/feature.service';
import { FormCanDeactivate } from 'src/app/shared/utils/form.candeactivate';
import { RequisitionService } from 'src/app/shared/services/requisition.service';
import { BreadCrumbItem } from 'src/app/shared/components/bread-crumb/bread-crumb.component';
import { IPracticeSupplierProductDto, OptimizeOrderLine } from 'src/app/shared/models/optimize.order.line.model';
import IGenericApiResponse from 'src/app/shared/models/generic-response.model';
import { BudgetPeriod } from 'src/app/shared/models/budget.model';
import { BudgetService } from 'src/app/shared/services/budget.service';
import { ICategorizedCost } from 'src/app/shared/models/categorized.cost.model';
import { ActionWhenNoPriceExistsEnum } from 'src/app/shared/models/actionWhenNoPriceExists.model';
import { PurchaseRequisition } from "../../../shared/models/purchase.requisition.model";
import { IApprovalDetails } from 'src/app/shared/models/approval.overview.model';

@Component({
	selector: 'app-review-cart',
	templateUrl: './review-cart.component.html',
	styleUrls: ['./review-cart.component.scss'],
})
export class ReviewCartComponent extends FormCanDeactivate implements OnInit, OnDestroy
{
	async submit(): Promise<boolean>
	{
		this.cartService.refreshCart();
		this.closeVariations('over-budget');
		this.navigateToReview();
		return true;
	}

	validate(): boolean
	{
		if (this.cartLines.length <= 0)
		{
			return false;
		}

		return true;
	}

	//check if the total is over the budget before submitting
	checkOverBudget()
	{
		if (!this.account!.practice!.hasBudget || !this.budgetPeriod)
		{
			this.submit();
			return;
		}
		//check if the total is over the budget
		let overBudget = ((this.budgetPeriod.totalSpent ?? 0) + this.budgetPeriod.currentTotalSpend) > this.budgetPeriod.periodBudget;

		//if it is, open the modal to confirm
		if (overBudget)
		{
			this.openVariations('over-budget');
		}
		else
		{
			this.submit(); //if not, submit the order
		}
	}

	@ViewChildren('reqLines')
	reqListElements!: QueryList<ElementRef>;

	reqId: number = 0;

	submitMessage: string = "submitted";


	subscriptions: Subscription[] = [];
	account: IAccount | undefined;
	cartLines: OptimizeOrderLine[] = [];
	coreProducts: CoreProduct[] = [];
	supplierProducts: SupplierProduct[] = [];
	orders: any[] = [];

	feature = Feature;
	total: number = 0;
	originalChoice: boolean = false;
	budgetPeriod: BudgetPeriod | undefined;
	supplierCosts: ICategorizedCost[] = [];

	hideCustomerBudget: boolean = false;
	showBackButton: boolean = false;
	workingTitle: string | undefined = undefined;
	workingMessage: string | undefined = undefined;
	selectedProductLines: any[] = [];
	selectedLine: OptimizeOrderLine | undefined = undefined;
	totalTBD: boolean = false;
	budgetTBD: boolean = false;
	loading: boolean = true;
	showBudget: boolean = false;
	requisition: PurchaseRequisition | undefined = undefined;

	breadCrumbItems: BreadCrumbItem[] = [new BreadCrumbItem('Optimize', true), new BreadCrumbItem('Review & Submit', false)];

	approvalDetails!: IApprovalDetails | undefined;

	constructor(
		private cartService: CartService,
		private agentService: AgentService,
		router: Router,
		monitor: MonitorService,
		login: LoginService,
		private orderService: OrderService,
		private activatedRoute: ActivatedRoute,
		private modals: ModalService,
		private requisitionService: RequisitionService,
		private budgetService: BudgetService,
		private navigationService: NavigationService,
		private approvalService: ApprovalService
	)
	{
		super(monitor, login, router);
		this.cartLines = [];

		this.activatedRoute.params.subscribe(async (params) =>
		{
			this.account = this.agentService.currentAccount;
			if (params.id)
			{
				this.reqId = params.id;
				let accountId = this.agentService.currentAccount!.id;
				let models;

				let pageData: Promise<IGenericApiResponse | PurchaseRequisition>[] = [];

				pageData.push(this.requisitionService.GetOrderOptimization(accountId, this.reqId));
				pageData.push(this.orderService.getRequisition(accountId, this.reqId, {}));

				if (this.account?.practice?.hasBudget)
				{
					pageData.push(this.budgetService.getBudgetPeriodByDate(accountId));
				}


				try
				{
					let results = await Promise.all(pageData);
					models = results[0] as IGenericApiResponse;
					this.requisition = results[1] as PurchaseRequisition;

					if (this.account?.practice?.hasBudget)
					{
						let budgetResponse: IGenericApiResponse = results[2] as IGenericApiResponse;

						if (budgetResponse.success && budgetResponse.value != null)
						{
							this.budgetPeriod = new BudgetPeriod(budgetResponse.value);
							this.budgetPeriod.addBudgetUsed(this.total);
						}
						this.showBudget = true;
					}
				}
				catch (err)
				{
					this.loading = false;
					this.monitor.handleError(err, 'failed to load optimize and order');
					return;
				}

				if (models.success && models.value != null)
				{
					this.cartLines = models.value.map((line: OptimizeOrderLine) => new OptimizeOrderLine(line));
				}
				else
				{
					this.loading = false;
					this.monitor.handleError(models.messages.join("\r\n"), "Failed to load the cart.");
					return;
				}

				if (this.account?.practice?.hasBudget)
				{
					let budgetResponse: IGenericApiResponse;
					try
					{
						budgetResponse = await this.budgetService.getBudgetPeriodByDate(this.agentService.currentAccount!.id);
					}
					catch (err)
					{
						this.loading = false;
						this.monitor.handleError(err, 'Failed to load budget.');
						return;
					}

					if (budgetResponse.success && budgetResponse.value != null)
					{
						this.budgetPeriod = new BudgetPeriod(budgetResponse.value);

						this.budgetPeriod.addBudgetUsed(this.total);
					}
				}

				this.loading = false;
				this.calculateAllSupplierCosts();
			}
		});

		//This code updates our total and budgetUsed values whenever any items in the cart changes
		this.subscriptions.push(this.cartService.cartChangedObservable$.subscribe((value: Cart | undefined) =>
		{
			let previousTotal = this.total;
			this.supplierCosts = [];

			this.calcTotal(value);

			let diff = this.total - previousTotal;

			if (this.budgetPeriod)
			{
				if (this.total == 0)
				{
					this.budgetPeriod.resetBudgetUsed();
				}
				else
				{
					this.budgetPeriod.addBudgetUsed(diff);
				}
			}


			this.syncCartLinesWithCart(value);

			this.calculateAllSupplierCosts();
		}));
	}

	async openModal(modalId: string)
	{
		if(modalId === 'timeline'){
			const approvalData = await this.requisitionService.getApprovalDetails(this.account!.id, this.requisition!.id);

			if (approvalData.success && approvalData.value)
			{
				this.approvalDetails = approvalData.value as IApprovalDetails;
			}
			else
			{
				this.monitor.handleError(approvalData.messages.join('\n'));
				return;
			}
		}

		this.modals.open(modalId);
	}

	closeModal(modalId: string)
	{
		this.modals.close(modalId);
	}

	/**
	 * Author: @dcphillips
	 * @description Calculates the total price of the cart
	 * 
	 * if any product does not have a supplier, set the price as NaN
	 * @returns the total price of the cart
	 */
	calcTotal(cart: Cart | undefined)
	{
		let newTotal = 0;
		let tbdPrice = false;

		//cart probably shouldn't be undefined ever, but we expect to refactor the cart service cart soon
		//which will have implications for this function
		if (cart)
		{
			cart.lines.forEach((line) =>
			{
				let price = line.price;

				//This is a temporary fix to remove the dollar sign from the price
				if (price && price.startsWith && price.startsWith('$'))
				{
					price = this.removeDollarSign(price);
				}

				if (price && line.supplierProduct && price !== "--")
				{
					newTotal += (parseFloat(price) * line.quantity);
				}
				else
				{
					newTotal = this.checkAddConstantAction() ? newTotal + (this.account!.practice!.budgetInformation!.priceWhenNoPriceExists! * line.quantity) : 0;
					tbdPrice = true;
				}
			});
		}

		this.total = newTotal;
		this.totalTBD = tbdPrice;

		//budget and estimated total do not show tbds in the same circumstances.
		//There has to be an unknown price and the practice has to have no alternate price action
		if (tbdPrice && !this.checkAddConstantAction())
		{
			this.budgetTBD = true;
		}
		else
		{
			this.budgetTBD = false;
		}
	}

	/**
	 * @description The cart lines on this component need to be synced with the cart in cart service.
	 * This function will update the quantity of each line in this component to match the quantity in the cart.
	 * 
	 */
	private syncCartLinesWithCart(cart: Cart | undefined)
	{
		if (cart)
		{
			this.cartLines.forEach((line) =>
			{
				const cartLine = cart.lines.find((l) => l.id === line.purchaseRequisitionLineId);
				if (cartLine)
				{
					line.purchaseRequisitionLineQuantity = cartLine.quantity;
				}
				else
				{
					this.cartLines.splice(this.cartLines.indexOf(line), 1);
				}
			});
		}
	}

	calculateAllSupplierCosts()
	{
		this.supplierCosts = [];

		const suppliersSet = new Set(this.cartLines.map(line => line.supplier));

		const distinctSuppliers = Array.from(suppliersSet);

		distinctSuppliers.forEach(supplier =>
		{
			this.calculateSupplierCosts(supplier);
		});

		this.supplierCosts.sort((a, b) => a.groupName < b.groupName ? -1 : 1);
	}

	//Calculate the cost for a specific supplier 
	private calculateSupplierCosts(supplierName: string)
	{
		const supplierLines = this.cartLines.filter(line => line.supplier === supplierName);
		let supplierBreakdown = this.supplierCosts.find(s => s.groupName === supplierName);

		if (supplierLines.findIndex(supplier => supplier.price.includes('-')) !== -1)
		{
			if (!supplierBreakdown)
			{
				this.supplierCosts.push({ groupName: supplierName, cost: undefined });
			}
			return;
		}

		supplierLines.forEach((line: OptimizeOrderLine) =>
		{
			let supplierCost = 0;
			let price = line.price;

			price = this.removeDollarSign(price); //As the product evolves, we should not need to remove the dollar sign
			//This is a temporary fix to remove the dollar sign from the price

			supplierCost += parseFloat(price) * line.purchaseRequisitionLineQuantity;

			if (supplierBreakdown)
			{
				this.supplierCosts.find(s => s.groupName === supplierName)!.cost = supplierBreakdown.cost ? (supplierBreakdown.cost + supplierCost) : undefined;
			}
			else
			{
				this.supplierCosts.push({ groupName: supplierName, cost: supplierCost });
				supplierBreakdown = this.supplierCosts.find(s => s.groupName === supplierName);
			}
		});
	}

	private checkAddConstantAction(): boolean
	{
		return this.account?.practice?.budgetInformation?.actionWhenNoPriceExists == ActionWhenNoPriceExistsEnum.ApplyConstantValue;
	}

	private removeDollarSign(price: string): string
	{
		return price.replace('$', '');
	}


	SwapSupplierProductCallback = async (lineId: number, supplierProductId: number) =>
	{
		let response: IGenericApiResponse;
		let isAlternativeProduct: boolean = false;

		let line = this.cartLines.find(l => l.purchaseRequisitionLineId === lineId);

		isAlternativeProduct = line?.alternativeSupplierProductId === supplierProductId;
		try
		{
			response = await this.requisitionService.SwapSupplierProduct(this.agentService.currentAccount!.id, this.reqId, lineId, supplierProductId);
		}
		catch (err)
		{
			this.monitor.handleError(err, 'Failed to swap supplier product.');
			return;
		}

		if (!response.success)
		{
			this.monitor.handleError('Failed to swap supplier product.', response.messages.join("\r\n"));
			return;
		}

		try
		{
			let line = this.cartLines?.find(l => l.purchaseRequisitionLineId === lineId);
			let updateCart = new Cart();
			if (line)
			{
				let newCart = this.cartLines;

				if (response.value)
				{
					line.purchaseRequisitionLineQuantity = parseInt(response.value);
				}

				let index = this.cartLines.indexOf(line);
				line.swapSupplierProducts(supplierProductId);
				newCart[index] = line;

				if (isAlternativeProduct)
				{
					this.originalChoice = !this.originalChoice;
				}

				updateCart.fromOptimizeOrder(this.reqId, this.agentService.currentAccount!.id, newCart);
				this.cartService.cartForceUpdate(updateCart);
				this.cartService.refreshCart();
				this.cartLines = newCart;
			}
			this.calcTotal(updateCart);
		}
		catch (err)
		{
			//check for the properties like success, messages[] and value (Object.HasOwnProperty(success))
			this.monitor.handleError(err, 'Failed to swap supplier product.');
			return;
		}
	}

	async ngOnInit()
	{
		this.cartLines?.forEach(line =>
		{ //add line prices to total
			let price = line.price;

			price = price.slice(1, price.length);


			this.total += parseFloat(price); //says we need to cast to unknown first to then cast as number
		});

		try
		{
			this.cartService.refreshCart();
		}
		catch (err)
		{
			this.monitor.handleError(err, 'Failed to load cart.');
			return;
		}

		const state = history.state;
		this.showBackButton = state && state.fromSpecificPage;
	}

	ngOnDestroy(): void
	{
		this.subscriptions.forEach((s) => s.unsubscribe());
	}

	hideBudget(): void
	{
		this.hideCustomerBudget = true;
	}

	goBack()
	{
		window.history.back();
	}

	getSupplierProduct(id: number | undefined): SupplierProduct | undefined
	{
		if (id)
		{
			return this.supplierProducts[id];
		}
		else
		{
			return undefined;
		}
	}

	async loadCart(): Promise<any>
	{
		this.orders = [];

		if (this.cartLines?.length)
		{
			const lines: Promise<any>[] = [];

			await forkJoin(lines).subscribe(
				() =>
				{
					this.orders = this.orders.sort((a, b) => a.supplier?.name < b.supplier?.name ? 1 : 0);
				},
				(error: any) =>
				{
					this.monitor.handleError(error, 'Failed to load cart.')
				}
			);
		}
	}

	removeLineCallback = async (lineId: number) =>
	{
		if (this.cartLines)
		{
			for (let x = 0; x < this.cartLines.length; ++x)
			{
				if (this.cartLines[x].purchaseRequisitionLineId === lineId)
				{
					this.cartLines.splice(x, 1);
					break;
				}
			}
		}

		if (this.cartLines?.length === 0)
		{
			this.navigationService.practice(this.agentService.currentAccount!.id).dashboard();
		}
	}

	update(lineId: number): void
	{
		if (this.cartLines)
		{
			const l = this.cartLines?.find(li => li.purchaseRequisitionLineId === lineId);
			if (l)
			{
				const index = this.cartLines?.indexOf(l!);

				l.purchaseRequisitionLineQuantity = this.cartService.cart?.lines.find(li => li.id === lineId)?.quantity ?? 0;

				this.cartLines[index!] = l;

				this.calcTotal(this.cartService.cart);
			}
		}
	}

	navigateToReview()
	{
		this.navigationService.practice(this.agentService.currentAccount!.id).order.review();
	}

	reviewOrder(e: any): void
	{
		this.navigateToReview();
	}

	async abandonCart(): Promise<any>
	{
		if (confirm('Are you sure you want to empty your cart?'))
		{
			return await this.execute(async () =>
			{
				let response = await this.orderService.emptyOpenPurchaseRequisition(this.agentService.currentAccount!.id);

				if (response.success)
				{
					this.cartLines = [];

					await this.cartService.refreshCart();
					
					this.navigationService.practice(this.agentService.currentAccount!.id).dashboard();

					return true;
				}

				throw new Error(response.messages.join('\n'));

			}, 'Error emptying cart');
		}
	}

		async abandonRequisition(): Promise<any>
	{
		if (confirm('This requisition is part of an approval process. Are you sure you want to abandon this requisition?'))
		{
			return await this.execute(async () =>
			{
				let response = await this.orderService.emptyOpenPurchaseRequisition(this.agentService.currentAccount!.id);
	
				if (response.success)
				{
					this.cartLines = [];

					await this.cartService.refreshCart();
					
					this.navigationService.practice(this.agentService.currentAccount!.id).dashboard();

					return true;
				}

				throw new Error(response.messages.join('\n'));

			}, 'Error emptying cart');
		}
	}

	optimizeLine(suggestedLine: any)
	{
		const reqLine = suggestedLine.requisitionLineId;
		const existingLineIndex = this.selectedProductLines.findIndex(line => line.requisitionLineId === reqLine);

		if (existingLineIndex !== -1)
		{
			// If a product line from the same reqLine is already selected, remove it
			this.selectedProductLines.splice(existingLineIndex, 1);
		}

		// Add the new suggested line to the selected lines
		this.selectedProductLines.push(suggestedLine);

		// update reqLine's supplierProductId
		let line = this.cartLines?.find(l => l.purchaseRequisitionLineId == suggestedLine.requisitionLineId);
		if (line !== undefined)
		{
			const supplierProductId = line.supplierProductId;
			line.supplierProductId = supplierProductId ?? suggestedLine.supplierProductId;
		}
	}

	isSelected(line: OptimizeOrderLine, suggestion: IPracticeSupplierProductDto): boolean
	{
		if (line && suggestion)
		{
			return line.supplierProductId === suggestion.supplierProductId;
		}
		return false;
	}

	openVariations(modal: string, value?: OptimizeOrderLine): void
	{
		if (modal === 'choose-variation')
		{
			if (!value?.allSupplierProducts)
			{
				return;
			}
			if (modal === "choose-variation" && (value.allSupplierProducts?.length <= 1))
			{
				return;
			}
			
			this.selectedLine = value;
			this.modals.open(modal);
		}
		else
		{
			this.modals.open(modal);
		}
	}

	closeVariations(modal: string): void
	{
		this.selectedLine = undefined;
		this.modals.close(modal);
	}

	async selectSuggestion(modal: string, s: IPracticeSupplierProductDto, l: OptimizeOrderLine)
	{
		this.working = 30;
		if (l)
		{
			this.selectedLine = l;
		}

		await this.SwapSupplierProductCallback(l.purchaseRequisitionLineId, s.supplierProductId);

		this.modals.close(modal);
		this.working = 0;
	}
}