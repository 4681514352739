import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DxFormComponent, DxTreeViewComponent } from 'devextreme-angular';
import { LoginService, MonitorService, NavigationService } from 'src/app/shared/services';
import { IAccount, AccountType, NameId, Recommendation } from 'src/app/shared/models';
import { AccountService } from 'src/app/shared/services/account.service';
import { FormCanDeactivate } from 'src/app/shared/utils/form.candeactivate';

@Component({
  selector: 'app-account-edit-details',
  templateUrl: './account-edit-details.component.html',
  styleUrls: ['./account-edit-details.component.scss'],
})
export class AccountEditDetailsComponent extends FormCanDeactivate implements OnInit{
  submitMessage: string = 'Error editing account';
  validate(): boolean {
    this.form.instance.validate();
    return ((this.form && this.account) ?? false) && this.isValid;
  }
  
  @ViewChild(DxFormComponent, { static: false })
  form!: DxFormComponent;

  @ViewChild(DxTreeViewComponent, { static: false })
  treeView!: DxTreeViewComponent;

  account: IAccount = {} as IAccount;
  parentAccount: IAccount | undefined;

  @Output()
  sendUpdatedAccount = new EventEmitter<IAccount>();
  
  treeBoxValue!: number;
  isTreeBoxOpened: boolean = false;

  accountTypes: NameId[] = [{
    id: AccountType.PRACTICE,
    name: 'Practice'
  },
  {
    id: AccountType.DSO,
    name: 'DSO'
  },
  {
    id: AccountType.GPO,
    name: 'GPO'
  }];

  orgs: any;
  recommendations: NameId[] = [
    { id: Recommendation.Inherit, name: 'Inherit' },
    { id: Recommendation.PreferredSupplier, name: 'Preferred Supplier' },
    { id: Recommendation.LowestPrice, name: 'Lowest Price' },
    { id: Recommendation.MostRecent, name: 'Most Recent' }
    ];
    working = 0; // Set to true when waiting for async queries to complete. Prevents bad requests

  constructor(
    private accountService: AccountService,
    monitor: MonitorService,
    login: LoginService,
    private route: ActivatedRoute,
    private ref: ChangeDetectorRef,
    router: Router,
    private navigationService: NavigationService
  ) {
    super(monitor, login, router);
  }

  ngOnInit(): void {

    this.route.parent!.paramMap.subscribe(async params => {
      const routeParameter = params.get('accountId') ?? undefined;

      if (routeParameter && !Number.isNaN(routeParameter)) {
        
        const accountId = parseInt(routeParameter);

        this.startWorking();

        try {
          this.account = await this.accountService.getAccountById(accountId, { addresses: true, agents: true, parent: true, budget: true, suppliers: true });
        } catch (error) {
          this.endWorking();
          this.monitor.handleError('Error loading account');
        }

        if (!this.recommendations.find(r => r.id == Recommendation.Inherit)) {
          this.recommendations.unshift({ id: Recommendation.Inherit, name: 'Inherit' });
        }

        if (this.account && !this.account?.recommendation) {
          this.account.recommendation = Recommendation.Inherit;
          
          if(this.account.parentId) {
            this.treeBoxValue = this.account.parentId;
          }
          else{
            this.recommendations = this.recommendations.filter(r => r.id != Recommendation.Inherit);
          }
        }
        
      }

      try {
        this.orgs = (await this.accountService.get({ parent: true })).filter(a => a.accountType !== AccountType.PRACTICE);
      } catch (error) {
        this.endWorking();
        this.monitor.handleError('Error loading accounts');
      }

      this.endWorking();
    });
  }

  async submit(): Promise<boolean> {
    // get form data and put in db
    const formData: IAccount = this.form.instance.option('formData');
    let temp = this.treeBoxValue;
    formData.parentId = temp;
    if (formData.id > 0) {
      this.account = await this.accountService.updateAccount(formData.id, formData);
      this.accountService.changeAccountEditAccount(this.account);
    } else {
      this.account = await this.accountService.createNewAccount(formData);
      this.navigationService.backoffice().account.details(this.account.id);
    }
    return true;
  }

  // drop down functionality
  syncTreeViewSelection(e: any) {
    this.isValid = true;
    if (this.treeView) {
      if (!this.treeBoxValue) {
        this.treeView.instance.unselectAll();
      } else {
        this.treeView.instance.selectItem(this.treeBoxValue);
      }
    }

    if (e.previousValue && !e.value) {
      const formData: IAccount = this.form.instance.option('formData');
      if (formData.recommendation === Recommendation.Inherit) {
        formData.recommendation = undefined;
      }
    }
    
    if(e.value == null){
      this.recommendations = this.recommendations.filter(r => r.id != Recommendation.Inherit);
    } else {
      if (!this.recommendations.find(r => r.id == Recommendation.Inherit)) {
        this.recommendations.unshift({ id: Recommendation.Inherit, name: 'Inherit' });
        this.recommendations = this.recommendations.filter(r => r.id);
      }
    }
  }

  treeView_itemSelectionChanged(e: any) {
    this.treeBoxValue = e.itemData.id;
  }

  onTreeBoxOptionChanged(e: any) {
    if (e.name === 'value') {
      this.isTreeBoxOpened = false;
      this.ref.detectChanges();
    }
  }
}
