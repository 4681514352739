import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { User } from '@auth0/auth0-spa-js';
import { BehaviorSubject, lastValueFrom, Observable, Subject, Subscription } from 'rxjs';
import { ModalService } from './modal.service';
import { MonitorService } from './monitor.service';
import { Router } from '@angular/router';
import { AgentService, NavigationService } from '.';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public get authenticatedUser(): User | undefined {
    const u = localStorage.getItem(this.loginUserKey);

    if (u?.length) {
      return JSON.parse(u ?? '{}') as User;
    } else {
      return undefined;
    }
  }
  public set authenticatedUser(val: User | undefined) {
    if (val) {
      localStorage.setItem(this.loginUserKey, JSON.stringify(val));
    } else {
      localStorage.removeItem(this.loginUserKey);
    }
  }

  private loggedInSubject: Subject<boolean> = new BehaviorSubject<boolean>(false);
  public loggedInObservable$: Observable<boolean> =this.loggedInSubject.asObservable();

  private loggedOutSubject: Subject<boolean> = new BehaviorSubject<boolean>(false);
  public loggedOutObservable$: Observable<boolean> = this.loggedOutSubject.asObservable();
  
  private tokenSubject: Subject<boolean> = new BehaviorSubject<boolean>(false);
  public tokenObservable$: Observable<boolean> = this.tokenSubject.asObservable();

  private tokenRefreshSubject: Subject<boolean> = new BehaviorSubject<boolean>(false);
  public tokenRefreshObservable$: Observable<boolean> = this.tokenRefreshSubject.asObservable();

  private subscriptions: Subscription[] = [];
  private isLoggedIn = false;

  private pathKey = 'initialPath';
  private bearerKey = 'bear';
  private bearerExpires = 'bearexpires';
  private loginUserKey = 'loginUser';
  private bearerUsed = 'bearused';

  private _token: string | null | undefined = undefined;
  private initialSet = false;

  constructor(
    private auth: AuthService,
    private modal: ModalService,
    private monitor: MonitorService,
    private navigationService: NavigationService,
    private agents: AgentService
  ) {

    if (localStorage.getItem(this.bearerKey) && this.tokenExpired()) {
      this.login('/backoffice/dashboard');
      console.log('Token Expired');
    }

    this.subscriptions.push(this.auth.isAuthenticated$.subscribe(async (loggedIn: any) => {
      if (loggedIn && !this.isLoggedIn) {
        this.isLoggedIn = true;
      } else if (!loggedIn && this.isLoggedIn) {
        this.isLoggedIn = false;
      }
      this.loggedInSubject.next(this.isLoggedIn);
    }));

    this.subscriptions.push(
      this.auth.user$.subscribe(async (user: User | null | undefined) => {
        if (user) {
          if (user.email !== this.authenticatedUser?.email) {
            this.authenticatedUser = user;
            this.isLoggedIn = true;
            this.loggedInSubject.next(this.isLoggedIn);
          }
        } else {
          this.authenticatedUser = undefined;
          this.isLoggedIn = false;
          this.loggedInSubject.next(this.isLoggedIn);
        }
      })
    );

    this.subscriptions.push(
      this.auth.getAccessTokenSilently().subscribe((token: any) => {
        if (token) {
          this.setToken(token);
          this.tokenSubject.next(!!token);

          this.isLoggedIn = true;
          this.loggedInSubject.next(this.isLoggedIn);
        }
      })
    );

    if (localStorage.getItem(this.bearerKey)?.length) {
      this._token = localStorage.getItem(this.bearerKey);
    }
  }

  public initialize(url: string): void {
    if (url !== '/login') {
      localStorage.setItem(this.pathKey, url);
    }
  }

  public async syncronizeFailover(): Promise<any> {
    const t = await lastValueFrom(this.auth.getAccessTokenWithPopup());
    this.setToken(t!);
    this.tokenSubject.next(!!t);
  }

  public initialUrl(): string | null {
    return localStorage.getItem(this.pathKey);
  }
  public uninitialize(): void {
    localStorage.removeItem(this.pathKey);
  }

  public isAuthenticated(): boolean {
    return (
      this.authenticatedUser !== null && this.authenticatedUser !== undefined
    );
  }

  public async syncronizeToken(): Promise<any> {
    const t = await lastValueFrom(await this.auth.getAccessTokenSilently());
    this.setToken(t);
    this.tokenSubject.next(!!t);
  }

  public getToken(): string | null {
    let t = this._token ?? localStorage.getItem(this.bearerKey);

    if (this.initialSet && (!t || this.tokenExpired())) {
      t = null;
      if (this.tokenExpired() === false)
      {
        // cant call this without checking for token expired otherwise we get an infinite loop
        let user = this.agents.getAgent();
      }
      this.navigationService.public().message.loggedOut();
    } else if ( this.initialSet && t) {
      this.lastAccess = Math.floor((new Date).getTime() / 1000);
    }

    return t;
  }

  public get tokenInitialized(): boolean {
    return this.initialSet;
  }

  public reauthenticate(): void {
    //this.clearToken();
    // this.clearExpires();
    this.initialize(window.location.pathname);
    this.login(window.location.pathname);
  }

  public setToken(token: string): void {
    this._token = token;
    localStorage.setItem(this.bearerKey, token);
    localStorage.setItem(this.bearerExpires, (JSON.parse(atob(token.split('.')[1]))).exp);
    this.initialSet = true;
  }
  public clearToken(): void {
    this._token = undefined;
    localStorage.removeItem(this.bearerKey);
  }

  private set lastAccess(now: number) {
    localStorage.setItem(this.bearerUsed, JSON.stringify(now));
  }
  get lastAccess(): number {
    const la = localStorage.getItem(this.bearerUsed);
    return la ? parseInt(JSON.parse(la), 10) : 0;
  }
  

  public getExpires(): number | undefined {
    const expiry = localStorage.getItem(this.bearerExpires);
    if (expiry) {
      return parseInt(expiry, 10);
    } else {
      return undefined;
    }
  }

  public clearExpires(): void {
    localStorage.removeItem(this.bearerExpires);
  }

  public logout(returnUrl?: string): void {
    this._token = undefined;
    localStorage.clear();
    this.auth.logout({
      logoutParams: {
        returnTo: returnUrl ?? document.location.origin
      }
    });

    this.loggedOutSubject.next(false);
  }


  public login(redirect: string): void {
    this._token = undefined;
    //is part of acceptance criteria that we have ability to maintain different user sessions when tabs are up? 
    localStorage.clear();

    this.auth.loginWithRedirect({
      authorizationParams: {
        redirect_uri: `${document.location.origin}/public/login`,
      },
     appState:  { target: redirect } 
    });
  }

  public handleLoginRequest = () => {

    return this.auth.handleRedirectCallback();
  }

  private tokenExpired() {
    const expiry = this.getExpires() ?? 0;
    const now = Math.floor((new Date).getTime() / 1000);
    return now >= expiry;
  }
}
