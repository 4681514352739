import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DxFormComponent } from 'devextreme-angular';
import {
  IAccount,
  AccountType,
  Formulary,
  FormularyType,
} from 'src/app/shared/models';
import { AgentService, LoginService, MonitorService, NavigationService } from 'src/app/shared/services';
import { AccountService } from 'src/app/shared/services/account.service';
import { FormularyService } from 'src/app/shared/services/formulary.service';
import { FormCanDeactivate } from 'src/app/shared/utils/form.candeactivate';

@Component({
  selector: 'app-practice-edit-formulary-configuration',
  templateUrl: './practice-edit-formulary-configuration.component.html',
  styleUrls: ['./practice-edit-formulary-configuration.component.scss'],
})
export class PracticeEditFormularyConfigurationComponent
  extends FormCanDeactivate
  implements OnInit
{
  @Input()
  account: IAccount | undefined;
  parentAccount: IAccount[] = [];
  parent: any  = { };
  hideCheckbox: boolean = true;

  @ViewChild(DxFormComponent, { static: false })
  form!: DxFormComponent;

  formSource: IAccount | undefined;
  formularies: Formulary[] = [];

  accountType = AccountType;
  reload = false;
  allowlNavigateFormulary = true;

  cooldown: boolean = true;

  postExecute(): void {
    if (this.reload) {
      window.location = window.location;
    }
  }

  constructor(
    private agentService: AgentService,
    private accountService: AccountService,
    private formularyService: FormularyService,
    monitor: MonitorService,
    login: LoginService,
    router: Router,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute
  ) {
    super(monitor, login, router);
  }

  ngOnInit() {

    this.activatedRoute.parent!.parent!.paramMap.subscribe(async (params) => {
      this.startWorking();
      const accountId = params.get('accountId');
      if (accountId) {
        this.account = await this.accountService.getAccountById(
          parseInt(accountId),
          { parent: true, formulary: true }
        );
      }

      if (this.account && this.account.parentId) {
        let p = this.account.parentId;
        this.parentAccount = [];
        while (p) {
          const pa = await this.accountService.getAccountById(p, {});
          this.parentAccount.push(pa);
          p = pa.parentId;
        }
      } else if (this.account?.practice?.inheritPublicFormulary) {
        this.account.practice.inheritPublicFormulary = false;
      }
      this.formularies = (
        await this.formularyService.getFormularies({
          agencyId: this.agentService.authenticatedAgent!.agency!.id,
          formularyType: FormularyType.Public,
        })
      ).filter((f) => f.active);
      if (
        this.account!.publicFormularyId &&
        !this.formularies.find((f) => f.id === this.account!.publicFormularyId)
      ) {
        this.formularies.push(
          await this.formularyService.getFormulary(
            this.account!.publicFormularyId,
            {}
          )
        );
      }

      if(this.parentAccount){
        this.hideCheckbox = false;
        this.parent.allowManageFormulary = !this.parentAccount.find(pa => !(pa.allowManageFormulary ?? true));
        this.parent.allowDentalCity = !this.parentAccount.find(pa => !(pa.allowDentalCity ?? true));
        this.parent.allowOrderHistory = !this.parentAccount.find(pa => !(pa.allowOrderHistory));
      }
      
      if(this.parent && this.parentAccount){
        if (!this.parent.allowManageFormulary) {
          this.account!.allowManageFormulary = false;
        }
        if (!this.parent.allowDentalCity) {
          this.account!.allowDentalCity = false;
        }
        if (!this.parent.allowOrderHistory) {
          this.account!.allowOrderHistory = false;
        }
      }

      this.formSource = this.account;

      const formularyList = this.form.instance.getEditor('publicFormularyId');
      if (this.account?.practice?.inheritPublicFormulary) {
        formularyList!.option('disabled', true);
        formularyList!.option('value', undefined);
        formularyList!.option('required', false);
        this.allowlNavigateFormulary = false;
        // this.form.getedi
      } else {
        formularyList!.option('disabled', false);
        formularyList?.option('required', true);
        this.allowlNavigateFormulary = !!this.account?.publicFormularyId;
      } 
      this.cooldown = false;

      this.endWorking();
    });
  }

  async submit(): Promise<boolean> {
    this.account = await this.accountService.updateAccount(
      this.account!.id,
      this.account!
    );
    this.monitor.handleSuccess('Formulary has been updated');
    this.formSource = this.account;
    this.reload = true;
    return true;
  }
  validate(): boolean {
    return this.isValid;
  }

  submitMessage: string = 'Error updating account formulary';

  gotToFormulary(): void {
    if (this.account) {
      this.navigationService.backoffice().formulary.products(this.account!.publicFormularyId!);
    }
  }

  protected onFieldDataChanged(e: any): void {
    if (this.cooldown) {
      return;
    }
    super.onFieldDataChanged(e);

    if (e.dataField === 'practice.inheritPublicFormulary') {
      const formularyList = this.form.instance.getEditor('publicFormularyId');
      if (e.value) {
        formularyList!.option('disabled', true);
        formularyList!.option('value', undefined);
        formularyList!.option('required', false);
        this.allowlNavigateFormulary = false;
        // this.form.getedi
      } else {
        formularyList!.option('disabled', false);
        formularyList?.option('required', true);
      }
      // alert (e);
    } else if (e.dataField === 'publicFormularyId') {
      this.allowlNavigateFormulary = !!e.value;
    }
  }

}
