import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { text } from 'd3';
import { lastValueFrom } from 'rxjs';
import { IAccount, Agency, Supplier } from '../models';
import { SupplierApiConfig } from '../api/supplier.api.config';
import { PracticeSupplier } from '../models/practice.supplier.model';

@Injectable({ providedIn: 'root' })
export class SupplierService {
  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {

  }

  public get(): Promise<Supplier[]> {
    return lastValueFrom(this.http.get<Supplier[]>(SupplierApiConfig.getFullUrl('')));
  }
  public getPriceBooks(id: number): Promise<string[]> {
    return lastValueFrom(this.http.get<string[]>(SupplierApiConfig.getFullUrl(`${id}/pricebook`)));
  }
  public getPracticeSuppliers(practiceId: number): Promise<PracticeSupplier[]> {
    return lastValueFrom(this.http.get<PracticeSupplier[]>(SupplierApiConfig.getFullUrl(`practice-supplier/${practiceId}`)));
  }
}