<app-progress [splash]="true" [value]="working">
  <div class="account-edit flex-column">
    <div class="header flex-row no-wrap justify-between">
      <div class="flex-column">
        <H3 *ngIf="account?.id; else new">{{ account?.name }} - {{ account?.accountNumber }}</H3>
      </div>
      <ng-template #new>
        <h3>New Account Form</h3>
      </ng-template>
      <div class="flex-row">
        <button class="btn secondary text text-rubik ml-medium" (click)="backToAccounts()"> View Account List</button>
  
        <button class="btn secondary text text-rubik ml-medium" *ngIf="showBackButton" (click)="goBack()">Return to Agency</button>
      </div>
    </div>

    <div class="body">
      <div class="info">
        <div #navbar class="sidebar mr-medium mb-auto">
          <app-access  *ngFor="let nav of appAccessNavigationOptions" [platformFeature]="nav.feature" [ngClass]="{'disabled' : !account?.id}" routerLinkActive="active" [displayIfNoAccess]="false">
            <a id="nav.aTagId" (click)="nav.action()" class="content text text-500 black">{{nav.aTagText}}</a>
          </app-access>
        </div>
        <div class="ml-small">
          <router-outlet></router-outlet>
        </div>
    
      </div>
      <app-audit-info [auditInfo]="account" ></app-audit-info>
    </div>
  </div>
</app-progress>
