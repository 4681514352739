import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DxFormComponent, DxTabsComponent } from 'devextreme-angular';
import { IAccount, AccountType, NameId } from 'src/app/shared/models';
import { Address, AddressType } from 'src/app/shared/models/address.model';
import { LoginService, MonitorService, NavigationService } from 'src/app/shared/services';
import { AccountService } from 'src/app/shared/services/account.service';
import { FormCanDeactivate } from 'src/app/shared/utils/form.candeactivate';

@Component({
  selector: 'app-account-edit-address',
  templateUrl: './account-edit-address.component.html',
  styleUrls: ['./account-edit-address.component.scss']
})
export class AccountEditAddressComponent extends FormCanDeactivate implements OnInit {
  submitMessage: string = 'Error editing address';
  validate(): boolean {
    return this.isValid;
  }

  @ViewChild("billingForm", { static: false })
  billingForm!: DxFormComponent;
  @ViewChild("shippingForm", { static: false })
  shippingForm!: DxFormComponent;

  @ViewChild('addressTabs') addressTabs!: DxTabsComponent;
  @Input() account: IAccount | undefined;

  billingAddress: Address | undefined;
  shippingAddress: Address | undefined;
  disableSubmit: boolean = false;
  isDisabled: boolean = false;

  statePattern = /^(?:AL|AK|AZ|AR|CA|CO|CT|DE|FL|GA|HI|ID|IL|IN|IA|KS|KY|LA|ME|MD|MA|MI|MN|MS|MO|MT|NV|NH|NJ|NM|NY|NC|ND|OH|OK|OR|PA|RI|SC|SD|TN|TX|UT|VT|VA|WA|WV|WI|WY|NE)$/i;
  tabs = ['Billing', 'Shipping'];

  tabIndex: number = 0;

  isBillingTab: boolean = true;

  addressTypes: NameId[] = [{
    id: AddressType.Shipping,
    name: 'Shipping'
  },
  {
    id: AddressType.Billing,
    name: 'Billing'
  }];
  element: any;
  accountType = AccountType;

  constructor(
    private accountService: AccountService,
    monitor: MonitorService,
    login: LoginService,
    router: Router,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute
  ) {
    super(monitor, login, router);
  }

  ngOnInit() {
    this.activatedRoute.parent!.paramMap.subscribe(async params => {
      const accountId = params.get('accountId');
      if (accountId) {

        this.account = await this.accountService.getAccountById(parseInt(accountId), { addresses: true, agents: true, parent: true, budget: true, suppliers: true });

        if (this.account?.practice?.defaultShippingAddress) {
          this.shippingAddress = this.account.practice.defaultShippingAddress;
        }
        
        this.setSameAsBilling();
      }
    });
  }

  /**
   * First checks if all the billing address fields are the same as the shipping address fields, then sets the sameAsBilling checkbox to true
   */
  setSameAsBilling() {
    if(this.account?.practice?.billingAddress1 == this.shippingAddress?.addressLine1 &&
      this.account?.practice?.billingAddress2 == this.shippingAddress?.addressLine2 &&
      this.account?.practice?.billingCity == this.shippingAddress?.city &&
      this.account?.practice?.billingState == this.shippingAddress?.state &&
      this.account?.practice?.billingContactName == this.shippingAddress?.contactName &&
      this.account?.practice?.billingZip == this.shippingAddress?.zip)
    {
        this.shippingForm.instance.getEditor("sameAsBilling")?.option("value", true);
        this.onCheckBoxValueChanged({value: this.shippingForm.instance.getEditor("sameAsBilling")?.option("value")}); 
    }
  }

  onCheckBoxValueChanged(e: any) {
    const attention = this.shippingForm.instance.getEditor("contactName");
    if (attention) {
      attention.option("value", this.billingForm.instance.option("formData.practice.billingContactName"));
      attention.option("disabled", e.value);
    }

    const address1 = this.shippingForm.instance.getEditor("addressLine1");
    if (address1) {
      address1.option("value", this.billingForm.instance.option("formData.practice.billingAddress1"));
      address1.option("disabled", e.value);
    }

    const address2 = this.shippingForm.instance.getEditor("addressLine2");
    if (address2) {
      address2.option("value", this.billingForm.instance.option("formData.practice.billingAddress2"));
      address2.option("disabled", e.value);
    }

    const city = this.shippingForm.instance.getEditor("city");
    if (city) {
      city.option("value", this.billingForm.instance.option("formData.practice.billingCity"));
      city.option("disabled", e.value);
    }

    const state = this.shippingForm.instance.getEditor("state");
    if (state) {
      state.option("value", this.billingForm.instance.option("formData.practice.billingState"));
      state.option("disabled", e.value);
    }

    const zip = this.shippingForm.instance.getEditor("zip");
    if (zip) {
      zip.option("value", this.billingForm.instance.option("formData.practice.billingZip"));
      zip.option("disabled", e.value);
    }
  }
  
  async submit(): Promise<boolean> {
    this.disableSubmit = true;

    try {
      if (this.tabIndex == 0 && this.billingForm.instance.validate().isValid) {
        try {
          this.setWorking(70);
          this.account = this.billingForm.instance.option('formData');
          this.account = await this.accountService.updateAccount(this.account!.id, this.account!);
        
          this.monitor.handleSuccess('address successfully changed.');
          this.navigationService.backoffice().account.addresses(this.account!.id);
        } catch (error: any) {
          this.monitor.handleError(error, error?.error?.message ?? error.message);
        } finally {
          if(this.element){
            this.element.itemElement.setAttribute('disabled', 'false');
            this.element.itemElement.setAttribute('enabled', 'true');
            this.element.itemElement.classList.remove('dx-state-disabled');
            this.element.itemElement.classList.add('dx-state-enabled');
          }
        }
      } else if (this.shippingForm.instance.validate().isValid) {
        this.shippingAddress = this.shippingForm.instance.option('formData');
        if (this.shippingAddress) {
          if (this.shippingAddress?.id) {
            this.shippingAddress = await this.accountService.updateAccountAddress(this.account!.id, this.shippingAddress.id, this.shippingAddress!);
          } else {
            this.shippingAddress.addressType = AddressType.Shipping;
            this.shippingAddress = await this.accountService.createAccountAddress(this.account!.id, this.shippingAddress!);
          }
          this.monitor.handleSuccess('address successfully changed.');
          this.navigationService.backoffice().account.addresses(this.account!.id);
        }
      }
    }
    catch (e) {
      this.monitor.handleError(e);
    }
    finally {
    this.disableSubmit = false;
    }

    return true;
  }

  selectTab(event: any) {
    if (event.itemIndex == 0) {
      this.isBillingTab = true;
    } else {

      this.isBillingTab = false;
    }
    this.tabIndex = event.itemIndex;
  }

  onTabItemRendered(event: any) {
    if (!this.account?.practice?.billingAddress1 && event.itemIndex === 1) {
      this.element = event;
      this.element.itemElement.setAttribute('disabled', 'true');
      this.element.itemElement.classList.add('dx-state-disabled');
    }
  }

  customizeBilling = (item: any) => {
    if (item?.dataField === 'active') {
      const b = this.billingForm.instance.option('formData') as Address;
      item.visible = b?.id !== undefined;
    }
  };
  customizeShipping = (item: any) => {
    if (item?.dataField === 'active') {
      const b = this.shippingForm.instance.option('formData') as Address;
      item.visible = b?.id !== undefined;
    }
  };

  onFieldDataChanged(e: any): void {
    if (e.dataField === 'sameAsBilling') {
      this.onCheckBoxValueChanged(e);
    }

    let result = e.component.validate();

    if (result.brokenRules.length >= 1) {
      this.isValid = false;
    } else {
      this.isValid = true;
    }
  }

  validateNoSpace(e: any) {
    return !e.value.includes('(?:AL|AK|AZ|AR|CA|CO|CT|DE|FL|GA|HI|ID|IL|IN|IA|KS|KY|LA|ME|MD|MA|MI|MN|MS|MO|MT|NV|NH|NJ|NM|NY|NC|ND|OH|OK|OR|PA|RI|SC|SD|TN|TX|UT|VT|VA|WA|WV|WI|WY|NE)');
  }
}