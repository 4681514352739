import { Component, OnInit } from '@angular/core';
import { isFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IAccount, AccountType, Recommendation } from 'src/app/shared/models';
import { AgentService, LoginService, MonitorService, NavigationService } from 'src/app/shared/services';
import { AccountService } from 'src/app/shared/services/account.service';
import { Feature } from 'src/app/shared/services/feature.service';
import { GenericComponent } from 'src/app/shared/utils/generic.component';

@Component({
  selector: 'app-account-edit',
  templateUrl: './account-edit.component.html',
  styleUrls: ['./account-edit.component.scss']
})

export class AccountEditComponent extends GenericComponent implements OnInit {
  account: IAccount | undefined;
  feature = Feature;
  showBackButton: boolean = false;
  previousRoutePath?: string;
  returnUrl?: string;
  accountSupplierId?: number;
  accountType = AccountType;
  routerLink: (string | number)[] | undefined;
  accountId: number = 0;

  appAccessNavigationOptions: { feature: Feature, aTagId: string, aTagText: string, action: Function }[] = [
    {
      feature: Feature.AccountUpdate,
      aTagId: 'details',
      aTagText: 'Details',
      action: () => this.navigationService.backoffice().account.details(this.account!.id, this.route, true)
    },
    {
      feature: Feature.AccountUpdate && Feature.AgencyUpdate,
      aTagId: 'agencies',
      aTagText: 'Agencies',
      action: () => this.navigationService.backoffice().account.agencies(this.account!.id, this.route, true)
    },
    {
      feature: Feature.AccountUpdate && Feature.AgencyUpdate,
      aTagId: 'addresses',
      aTagText: 'Addresses',
      action: () => this.navigationService.backoffice().account.addresses(this.account!.id, this.route, true)
    },
    {
      feature: Feature.AccountUpdate,
      aTagId: 'suppliers',
      aTagText: 'Suppliers',
      action: () => this.navigationService.backoffice().account.suppliers.list(this.account!.id, this.route, true)
    },
    {
      feature: Feature.AgentUpdate,
      aTagId: 'formulary',
      aTagText: 'Formulary',
      action: () => {
        switch (this.account!.accountType) {
          case AccountType.PRACTICE:
            this.navigationService.backoffice().account.formulary.configurePractice(this.account!.id, this.route, true);
            break;
          case AccountType.DSO:
          case AccountType.GPO:
            this.navigationService.backoffice().account.formulary.configureOrganization(this.account!.id, this.route, true);
            break;
          default:
            break;
        }
      }
    }
  ];
  
  constructor(
    private accountService: AccountService,
    monitor: MonitorService,
    login: LoginService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private agentService : AgentService,
    private activatedRoute: ActivatedRoute
  ) {     
    super(monitor, login);
    
    //TODO: When addressing state management in the app, share account data from this component to all other components in this router outlet
    // e.g. this.accountService.currentAccountEditing --> Set in service and share throughout account-edit-x components
    this.activatedRoute.paramMap.subscribe( async (params) => {
      const accountId = params.get('accountId');
      this.working = 1;
      try {
        this.account = await this.accountService.getAccountById(parseInt(accountId!), { addresses: true, agents: true, parent: true, budget: true, suppliers: true });

        if (this.account && !this.account?.recommendation) {
          this.account.recommendation = Recommendation.Inherit;
        }
      } catch (exception) {
        this.monitor.logException(exception);
        
        this.navigationService.backoffice().message.invalidRoute();
      } finally {
        this.working = 0;
      }
    })
  }
  
  async ngOnInit() {
    const state = history.state;
    this.showBackButton = state && state.fromSpecificPage;
  }

  backToAccounts(){
    this.navigationService.backoffice().account.list();
  }

  goBack() {
    window.history.back();
  }
}
