
import { Tag } from "./tag.product.model";

export interface IPracticeSupplierProductDto {
    supplierProductId: number;
    supplierProduct: string;
    supplierProductDescription: string;
    supplierProductSKU: string;
    coreProductId: number;
    coreProduct: string;
    coreProductDescription: string;
    coreProductSupplierProductsCount: number;
    productListingId: number;
    productListing: string;
    productListingCoreProductsCount : number;
    price: string;
    priceValue?: number;
    supplierId: number;
    supplier: string;
    relativeImageUrl: string;
    productCategoryId?: number;
    productCategory: string;
    productSubCategoryId?: number;
    productSubCategory: string;
    recommendedProduct: boolean;
    recentProduct: boolean;
    mostOrderedProduct: boolean;
    manufacturerPartNumber: string;
    manufacturerId: number;
    manufacturer: string;
    lastPurchaseDate: Date;
    lastPurchaseQuantity?: number;
    canPurchase: boolean;
    listingTags: Tag[];
    coreProductTags: Tag[];
    supplierProductTags: Tag[];
    alternativeSupplierProductId?: number;
    topSupplierProduct1ID?: number;
    topSupplierProduct2ID?: number;
    topSupplierProduct3ID?: number;
    alternativeSupplierProductReason?: string;
    supplierImageUrl?: string;
    stockQuantity?: number;
}

export interface IOptimizeOrderLine extends IPracticeSupplierProductDto {
    purchaseRequisitionLineId: number;
    purchaseRequisitionLineQuantity: number;
    alternativeProduct: IOptimizeOrderLine;
    topSupplierProduct1: IPracticeSupplierProductDto;
    topSupplierProduct2: IPracticeSupplierProductDto;
    topSupplierProduct3: IPracticeSupplierProductDto;
    allSupplierProducts: IPracticeSupplierProductDto[];
}

export class OptimizeOrderLine implements IOptimizeOrderLine {
    
    constructor(data: IOptimizeOrderLine) {
        this.supplierProductId = data.supplierProductId;
        this.supplierProduct = data.supplierProduct;
        this.supplierProductDescription = data.supplierProductDescription;
        this.supplierProductSKU = data.supplierProductSKU;
        this.coreProductId = data.coreProductId;
        this.coreProduct = data.coreProduct;
        this.coreProductDescription = data.coreProductDescription;
        this.coreProductSupplierProductsCount = data.coreProductSupplierProductsCount;
        this.productListingId = data.productListingId;
        this.productListing = data.productListing;
        this.productListingCoreProductsCount = data.productListingCoreProductsCount;
        this.price = data.price;
        this.priceValue = data.priceValue;
        this.supplierId = data.supplierId;
        this.supplier = data.supplier;
        this.relativeImageUrl = data.relativeImageUrl;
        this.productCategoryId = data.productCategoryId;
        this.productCategory = data.productCategory;
        this.productSubCategoryId = data.productSubCategoryId;
        this.productSubCategory = data.productSubCategory;
        this.recommendedProduct = data.recommendedProduct;
        this.recentProduct = data.recentProduct;
        this.mostOrderedProduct = data.mostOrderedProduct;
        this.manufacturerPartNumber = data.manufacturerPartNumber;
        this.manufacturerId = data.manufacturerId;
        this.manufacturer = data.manufacturer;
        this.lastPurchaseDate = data.lastPurchaseDate;
        this.lastPurchaseQuantity = data.lastPurchaseQuantity;
        this.canPurchase = data.canPurchase;
        this.listingTags = data.listingTags;
        this.coreProductTags = data.coreProductTags;
        this.supplierProductTags = data.supplierProductTags;
        this.alternativeSupplierProductId = data.alternativeSupplierProductId;
        this.topSupplierProduct1ID = data.topSupplierProduct1ID;
        this.topSupplierProduct2ID = data.topSupplierProduct2ID;
        this.topSupplierProduct3ID = data.topSupplierProduct3ID;
        this.purchaseRequisitionLineId = data.purchaseRequisitionLineId;
        this.purchaseRequisitionLineQuantity = data.purchaseRequisitionLineQuantity;
        this.alternativeProduct = data.alternativeProduct;
        this.topSupplierProduct1 = data.topSupplierProduct1;
        this.topSupplierProduct2 = data.topSupplierProduct2;
        this.topSupplierProduct3 = data.topSupplierProduct3;
        this.alternativeSupplierProductReason = data.alternativeSupplierProductReason;
        this.allSupplierProducts = data.allSupplierProducts;
    }

    swapSupplierProducts(supplierProductId: number) {
        let tempProduct: any;

        switch (supplierProductId) {
            case this.topSupplierProduct1ID:
                tempProduct = this.getLineModel();
                this.swapSupplierProduct(this.topSupplierProduct1);
                this.topSupplierProduct1 = tempProduct;
                this.topSupplierProduct1ID = tempProduct.supplierProductId;

                break;
            case this.topSupplierProduct2ID:
                tempProduct = this.getLineModel();
                this.swapSupplierProduct(this.topSupplierProduct2);
                this.topSupplierProduct2 = tempProduct;
                this.topSupplierProduct2ID = tempProduct.supplierProductId;
                break;
            case this.topSupplierProduct3ID:
                tempProduct = this.getLineModel();
                this.swapSupplierProduct(this.topSupplierProduct3);
                this.topSupplierProduct3 = tempProduct;
                this.topSupplierProduct3ID = tempProduct.supplierProductId;
                break;
            case this.alternativeSupplierProductId:
                tempProduct = this.getLineModel();
                this.alternativeProduct.purchaseRequisitionLineId = this.purchaseRequisitionLineId;

                this.replaceLineModel(this.alternativeProduct);
                this.alternativeProduct = tempProduct;
                this.alternativeSupplierProductId = tempProduct.supplierProductId;
                break;
            case this.allSupplierProducts.find(x => x.supplierProductId === supplierProductId)?.supplierProductId:
                tempProduct = this.getLineModel();
                let supplierProduct = this.allSupplierProducts.find(x => x.supplierProductId === supplierProductId);
                if (!supplierProduct) {
                    throw new Error('Supplier Product not found.');
                }
                if (this.topSupplierProduct1ID === supplierProductId) {
                    this.swapSupplierProduct(supplierProduct);
                    this.topSupplierProduct1 = tempProduct;
                    this.topSupplierProduct1ID = tempProduct.supplierProductId;
                }
                else if (this.topSupplierProduct2ID === supplierProductId) {
                    this.swapSupplierProduct(supplierProduct);
                    this.topSupplierProduct2 = tempProduct;
                    this.topSupplierProduct2ID = tempProduct.supplierProductId;
                }
                else if (this.topSupplierProduct3ID === supplierProductId) {
                    this.swapSupplierProduct(supplierProduct);
                    this.topSupplierProduct3 = tempProduct;
                    this.topSupplierProduct3ID = tempProduct.supplierProductId;
                }
                else {
                    this.swapSupplierProduct(supplierProduct);
                }
                break;
            default:
                throw new Error('Supplier Product Id not found in alternate products or top supplier products.');
        }
    }

    private getLineModel(): IOptimizeOrderLine {
        return {
            allSupplierProducts: this.allSupplierProducts,
            supplierProductId: this.supplierProductId,
            supplierProduct: this.supplierProduct,
            supplierProductDescription: this.supplierProductDescription,
            supplierProductSKU: this.supplierProductSKU,
            coreProductId: this.coreProductId,
            coreProduct: this.coreProduct,
            coreProductDescription: this.coreProductDescription,
            coreProductSupplierProductsCount: this.coreProductSupplierProductsCount,
            productListingId: this.productListingId,
            productListing: this.productListing,
            productListingCoreProductsCount: this.productListingCoreProductsCount,
            price: this.price,
            priceValue: this.priceValue,
            supplierId: this.supplierId,
            supplier: this.supplier,
            relativeImageUrl: this.relativeImageUrl,
            productCategoryId: this.productCategoryId,
            productCategory: this.productCategory,
            productSubCategoryId: this.productSubCategoryId,
            productSubCategory: this.productSubCategory,
            recommendedProduct: this.recommendedProduct,
            recentProduct: this.recentProduct,
            mostOrderedProduct: this.mostOrderedProduct,
            manufacturerPartNumber: this.manufacturerPartNumber,
            manufacturerId: this.manufacturerId,
            manufacturer: this.manufacturer,
            lastPurchaseDate: this.lastPurchaseDate,
            lastPurchaseQuantity: this.lastPurchaseQuantity,
            canPurchase: this.canPurchase,
            listingTags: this.listingTags,
            coreProductTags: this.coreProductTags,
            supplierProductTags: this.supplierProductTags,
            alternativeSupplierProductId: this.alternativeSupplierProductId,
            topSupplierProduct1ID: this.topSupplierProduct1ID,
            topSupplierProduct2ID: this.topSupplierProduct2ID,
            topSupplierProduct3ID: this.topSupplierProduct3ID,
            purchaseRequisitionLineId: this.purchaseRequisitionLineId,
            purchaseRequisitionLineQuantity: this.purchaseRequisitionLineQuantity,
            alternativeProduct: this.alternativeProduct,
            topSupplierProduct1: this.topSupplierProduct1,
            topSupplierProduct2: this.topSupplierProduct2,
            topSupplierProduct3: this.topSupplierProduct3, 
            alternativeSupplierProductReason : this.alternativeSupplierProductReason
        } as IOptimizeOrderLine;
    }

    private replaceLineModel(line: IOptimizeOrderLine): void {
        this.alternativeProduct = this;
        this.alternativeSupplierProductId = this.supplierProductId;
        this.supplierProductId = line.supplierProductId;
        this.supplierProduct = line.supplierProduct;
        this.supplierProductDescription = line.supplierProductDescription;
        this.supplierProductSKU = line.supplierProductSKU;
        this.coreProductId = line.coreProductId;
        this.coreProduct = line.coreProduct;
        this.coreProductDescription = line.coreProductDescription;
        this.coreProductSupplierProductsCount = line.coreProductSupplierProductsCount;
        this.productListingId = line.productListingId;
        this.productListing = line.productListing;
        this.productListingCoreProductsCount = line.productListingCoreProductsCount;
        this.price = line.price;
        this.priceValue = line.priceValue;
        this.supplierId = line.supplierId;
        this.supplier = line.supplier;
        this.relativeImageUrl = line.relativeImageUrl;
        this.productCategoryId = line.productCategoryId;
        this.productCategory = line.productCategory;
        this.productSubCategoryId = line.productSubCategoryId;
        this.productSubCategory = line.productSubCategory;
        this.recommendedProduct = line.recommendedProduct;
        this.recentProduct = line.recentProduct;
        this.mostOrderedProduct = line.mostOrderedProduct;
        this.manufacturerPartNumber = line.manufacturerPartNumber;
        this.manufacturerId = line.manufacturerId;
        this.manufacturer = line.manufacturer;
        this.lastPurchaseDate = line.lastPurchaseDate;
        this.lastPurchaseQuantity = line.lastPurchaseQuantity;
        this.canPurchase = line.canPurchase;
        this.listingTags = line.listingTags;
        this.coreProductTags = line.coreProductTags;
        this.supplierProductTags = line.supplierProductTags;
        this.topSupplierProduct1ID = line.topSupplierProduct1ID;
        this.topSupplierProduct2ID = line.topSupplierProduct2ID;
        this.topSupplierProduct3ID = line.topSupplierProduct3ID;
        this.purchaseRequisitionLineId = line.purchaseRequisitionLineId;
        this.topSupplierProduct1 = line.topSupplierProduct1;
        this.topSupplierProduct2 = line.topSupplierProduct2;
        this.topSupplierProduct3 = line.topSupplierProduct3;
        this.alternativeSupplierProductReason = line.alternativeSupplierProductReason;
        this.allSupplierProducts = line.allSupplierProducts;
    }

    private swapSupplierProduct(data: IPracticeSupplierProductDto) {
        this.supplierProductId = data.supplierProductId;
        this.supplierProduct = data.supplierProduct;
        this.supplierProductDescription = data.supplierProductDescription;
        this.supplierProductSKU = data.supplierProductSKU;
        this.coreProductId = data.coreProductId;
        this.coreProductSupplierProductsCount = data.coreProductSupplierProductsCount;
        this.productListingId = data.productListingId;
        this.productListing = data.productListing;
        this.productListingCoreProductsCount = data.productListingCoreProductsCount;
        this.price = data.price;
        this.priceValue = data.priceValue;
        this.supplierId = data.supplierId;
        this.supplier = data.supplier;
        this.relativeImageUrl = data.relativeImageUrl;
        this.productCategoryId = data.productCategoryId;
        this.productCategory = data.productCategory;
        this.productSubCategoryId = data.productSubCategoryId;
        this.productSubCategory = data.productSubCategory;
        this.recommendedProduct = data.recommendedProduct;
        this.recentProduct = data.recentProduct;
        this.mostOrderedProduct = data.mostOrderedProduct;
        this.manufacturerPartNumber = data.manufacturerPartNumber;
        this.manufacturerId = data.manufacturerId;
        this.manufacturer = data.manufacturer;
        this.lastPurchaseDate = data.lastPurchaseDate;
        this.lastPurchaseQuantity = data.lastPurchaseQuantity;
        this.canPurchase = data.canPurchase;
        this.listingTags = data.listingTags;
        this.coreProductTags = data.coreProductTags;
        this.supplierProductTags = data.supplierProductTags;
    }

    purchaseRequisitionLineId: number;
    purchaseRequisitionLineQuantity: number;
    alternativeProduct: IOptimizeOrderLine;
    topSupplierProduct1: IPracticeSupplierProductDto;
    topSupplierProduct2: IPracticeSupplierProductDto;
    topSupplierProduct3: IPracticeSupplierProductDto;
    allSupplierProducts: IPracticeSupplierProductDto[];
    supplierProductId: number;
    supplierProduct: string;
    supplierProductDescription: string;
    supplierProductSKU: string;
    coreProductId: number;
    coreProduct: string;
    coreProductDescription: string;
    coreProductSupplierProductsCount: number;
    productListingId: number;
    productListing: string;
    productListingCoreProductsCount: number;
    price: string;
    priceValue?: number | undefined;
    supplierId: number;
    supplier: string;
    relativeImageUrl: string;
    productCategoryId?: number | undefined;
    productCategory: string;
    productSubCategoryId?: number | undefined;
    productSubCategory: string;
    recommendedProduct: boolean;
    recentProduct: boolean;
    mostOrderedProduct: boolean;
    manufacturerPartNumber: string;
    manufacturerId: number;
    manufacturer: string;
    lastPurchaseDate: Date;
    lastPurchaseQuantity?: number | undefined;
    canPurchase: boolean;
    listingTags: Tag[];
    coreProductTags: Tag[];
    supplierProductTags: Tag[];
    alternativeSupplierProductId?: number | undefined;
    topSupplierProduct1ID?: number | undefined;
    topSupplierProduct2ID?: number | undefined;
    topSupplierProduct3ID?: number | undefined;
    alternativeSupplierProductReason?: string;
}

 