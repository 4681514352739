<app-access [platformFeature]="feature.AccountRead" [accountFeature]="feature.AccountRead" [redirectOnFailure]="true">
    <app-progress [splash]="true" [value]="working">
        <div class="account-edit flex-column">
            <div class="header flex-row no-wrap justify-between">
                <H3>Purchase Orders</H3>
            </div>
            <div class="body info">
                <dx-tree-list id="tasks" [dataSource]="purchaseOrders" [columnAutoWidth]="true" [wordWrapEnabled]="true"
                              [showBorders]="true" [showColumnLines]="true" [allowColumnResizing]="true" [wordWrapEnabled]="false"
                              (onCellClick)="onCellClick($event)">
                    <!-- <dxo-search-panel [visible]="true" [width]="240"></dxo-search-panel> -->
                    <dxo-header-filter [visible]="true"></dxo-header-filter>
                    <!-- <dxo-selection mode="multiple"></dxo-selection> -->
                    <!-- <dxo-column-chooser [enabled]="true"></dxo-column-chooser> -->
                    <dxo-filter-row [visible]="true" [applyFilter]="'auto'"></dxo-filter-row>

                    <dxi-column dataField="orderNumber" dataType="string" [alignment]="'left'" caption="PO #" [width]="200"></dxi-column>
                    <dxi-column dataField="status" dataType="string" [alignment]="'left'" caption="Status" [width]="135"></dxi-column>
                    <dxi-column dataField="supplier" caption="Suppliers" [width]="300"></dxi-column>
                    <dxi-column [width]="110" dataField="total" caption="Total" format="currency" cellTemplate="totalCellTemplate"></dxi-column>
                    <dxi-column dataField="requisitionNumber" caption="Requisition #" [width]="200" cssClass="link" (onCellClick)="onCellClick($event)"></dxi-column>
                    
                    <dxi-column [width]="175" dataField="createdOn" dataType="date" caption="Created On" format="MM/dd/yyyy hh:mm a"></dxi-column>
                    <dxi-column dataField="modifiedOn" dataType="date" caption="Last Update" format="MM/dd/yyyy hh:mm a"></dxi-column>


                    <div *dxTemplate="let cell of 'totalCellTemplate'">
                        <span style="font-weight: 500;" *ngIf="cell.data.total">{{ cell.data.total | currency  }}</span>
                        <span style="font-weight: 500;" *ngIf="!cell.data.total">TBD</span>
                    </div>

                </dx-tree-list>
            </div>
        </div>
    </app-progress>
</app-access>