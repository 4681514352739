import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RequisitionService } from '../../services/requisition.service';
import { CartService, ModalService, MonitorService, NavigationService } from '../../services';
import { PurchaseRequisitionStatus } from '../../models/purchase.requisition.model';
import { Router } from '@angular/router';
import { ApprovalService } from '../../services/approval.service';
import IGenericApiResponse from '../../models/generic-response.model';

@Component({
	selector: 'app-approval-submit',
	templateUrl: './approval-submit.component.html',
	styleUrls: ['./approval-submit.component.scss']
})
export class ApprovalSubmitComponent implements OnChanges
{
	@Input()
	accountId!: number;
	@Input()
	requisitionId!: number;
	@Input()
	agentId!: number;
	@Input()
	approverComments: string = '';
	@Input()
	approvalQueryParam?: boolean;

	private static queryParamSubmitAttempt: boolean = false;

	private errorBoxHeading: string = 'Rejection Reason Required';
	private rejectedHeading: string = 'Requisition Rejected';
	private approvedMessage: string = 'Requisition has been approved.';
	private rejectedMessage: string = 'Requisition has been rejected and made editable.';
	private needCommentsMessage: string = 'Please provide an approver comment describing your reason for rejection.';

	protected modalMessage: string = this.needCommentsMessage;
	protected modalHeader: string = this.errorBoxHeading;
	protected submitDisabled: boolean = false;
	protected working = false;

	constructor(private requisitionService: RequisitionService,
		private monitor: MonitorService,
		private modalService: ModalService,
		private cartService: CartService,
		private router: Router,
		private approvalService: ApprovalService,
		private navigationService: NavigationService)
	{
	}

	async submit(approved: boolean): Promise<void>
	{
		this.submitDisabled = true;
		this.working = true;
		try
		{
			if (!approved && this.approverComments === '')
			{
				this.modalMessage = this.needCommentsMessage;
				this.modalHeader = this.errorBoxHeading;

				this.openModal('rejection-reason');
				return;
			}

			let res: IGenericApiResponse;
			let status: PurchaseRequisitionStatus;
			let id;

			try
			{
				res = await this.requisitionService.submitApproval(this.accountId, this.requisitionId, approved, this.approverComments);
				status = res.value.purchaseRequisitionStatusId;
				id = res.value.purchaseOrderId || res.value.purchaseRequisitionId;
			}
			catch (error)
			{
				this.monitor.handleError("Approval submission has failed. Please try again later.");
				this.submitDisabled = false;
				return;
			}
			
			if (res.success)
			{
				if (!approved)
				{
					this.modalMessage = this.rejectedMessage;
					this.modalHeader = this.rejectedHeading;

					this.openModal('rejection-reason');
				}
				else
				{
					this.monitor.handleSuccess(this.approvedMessage);

					if (status === PurchaseRequisitionStatus.Approved)
					{
						this.navigationService.practice(this.accountId).order.details(id);
					}

					this.submitDisabled = false;
					this.cartService.refreshCart(); //refresh the cart and to get the true count of pending approvals after the decision
					this.approvalService.decisionMade(status); //notify the panel component that a decision has been made so we need to reload
				}
			}
		}
		catch (error)
		{
			this.monitor.handleError(error);
		} finally {
			this.working = false;
		}
	}

	openModal(modalId: string)
	{
		this.modalService.open(modalId);
	}

	closeModal(modalId: string)
	{
		this.modalService.close(modalId);
		
		if (modalId === "rejection-reason" && this.modalMessage === this.rejectedMessage)
		{
			this.navigationService.practice(this.accountId).requisition.details(this.requisitionId);
		}
	}

	ngOnChanges(simpleChanges: SimpleChanges): void
	{
		if (!ApprovalSubmitComponent.queryParamSubmitAttempt)
		{
			//for some reason, without the timeout the modal does not open. I suspect the modal service hasn't instantiated the modal yet before the timeout
			setTimeout(() =>
			{
				ApprovalSubmitComponent.queryParamSubmitAttempt = true; //prevent the modal from reopening everytime the changes are detected 
				if (this.approvalQueryParam !== undefined)
				{
					this.submit(this.approvalQueryParam);
				}
			}, 1000);
		}
	}
}