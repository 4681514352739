import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DxFormComponent } from 'devextreme-angular';
import {
  IAccount,
  AccountType,
  Formulary,
  FormularyType,
} from 'src/app/shared/models';
import { AgentService, LoginService, MonitorService, NavigationService } from 'src/app/shared/services';
import { AccountService } from 'src/app/shared/services/account.service';
import { FormularyService } from 'src/app/shared/services/formulary.service';
import { FormCanDeactivate } from 'src/app/shared/utils/form.candeactivate';

@Component({
  selector: 'app-organization-edit-formulary-configuration',
  templateUrl: './organization-edit-formulary-configuration.component.html',
  styleUrls: ['./organization-edit-formulary-configuration.component.scss'],
})
export class OrganizationEditFormularyConfigurationComponent
  extends FormCanDeactivate
  implements OnInit
{
  @Input()
  account: IAccount | undefined;
  parentAccount: IAccount | undefined;

  @ViewChild(DxFormComponent, { static: false })
  form!: DxFormComponent;

  formSource: IAccount | undefined;
  formularies: Formulary[] = [];

  accountType = AccountType;

  constructor(
    private agentService: AgentService,
    private accountService: AccountService,
    private formularyService: FormularyService,
    monitor: MonitorService,
    login: LoginService,
    router: Router,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute
  ) {
    super(monitor, login, router);
  }
  async submit(): Promise<boolean> {
    this.account = await this.accountService.updateAccount(
      this.account!.id,
      this.account!
    );
    this.monitor.handleSuccess('Formulary has been updated');
    this.formSource = this.account;
    return true;
  }
  validate(): boolean {
    return this.isValid;
  }

  submitMessage: string = 'Error updating account formulary';

  async ngOnInit() {

    this.activatedRoute.parent!.parent!.paramMap.subscribe(async (params) => {
      const accountId = params.get('accountId');
      if (accountId) {
        this.account = await this.accountService.getAccountById(
          parseInt(accountId),
          { parent: true, formulary: true }
        );
      }

      if (this.account && this.account.parentId) {
        this.parentAccount = await this.accountService.getAccountById(
          this.account.parentId,
          {}
        );
      }
      this.formularies = (
        await this.formularyService.getFormularies({
          agencyId: this.agentService.authenticatedAgent!.agency!.id,
          formularyType: FormularyType.Public,
        })
      ).filter((f) => f.active);
      if (
        this.account!.publicFormularyId &&
        !this.formularies.find((f) => f.id === this.account!.publicFormularyId)
      ) {
        this.formularies.push(
          await this.formularyService.getFormulary(
            this.account!.publicFormularyId,
            {}
          )
        );
      }

      this.formSource = this.account;
    });
  }

  gotToFormulary(): void {
    this.navigationService.backoffice().formulary.products(this.account!.publicFormularyId!);
  }

  canInherit(): boolean {
    return (
      this.account!.accountType === this.accountType.PRACTICE &&
      !!this.account!.parentId
    );
  }

  protected onFieldDataChanged(e: any): void {
    super.onFieldDataChanged(e);

    if (e.dataField === 'inheritFromParent') {
      const formularyList = this.form.instance.getEditor('publicFormularyId');
      if (e.value) {
        formularyList!.option('disabled', true);
        // this.form.getedi
      } else {
        formularyList!.option('disabled', false);
      }
      // alert (e);
    }
  }
}
