<nav class="navbar flex-row justify-start">
    <a class="menu badge" (click)="toDashboard()" [ngClass]="{ 'no-filter': accounts.customizations['Logo'] }">
        <img [src]="accounts.customizations['Logo'] ?? 'assets/images/icons/yak-red-mini.png'" class="nav-logo">
    </a>
    <div class="navbar-items">
        <div class="menu" (click)="toDashboard()">
            <svg width="20" height="20" class="icon-style">
                <use xlink:href="../../../../assets/images/icons/sprites.svg#home" />
            </svg>
            <a routerLinkActive="active" class="drop-down width-medium height-medium text rubik text-500 mr-medium black" [ngClass]="{'active': activeRoute === 'dashboard'}">
                Home
            </a>
        </div>
    
        <div class="menu" (click)="toReports()">
            <svg width="20" height="20" class="icon-style">
                <use xlink:href="../../../../assets/images/icons/sprites.svg#reports" />
            </svg>
            <a routerLinkActive="active" class="drop-down width-medium height-medium text rubik text-500 black" [ngClass]="{'active': activeRoute === 'reports'}" >
                Reports
            </a>
        </div>

        <div class="menu">
            <ng-template ngFor [ngForOf]="dropdownContent" let-dropdown let-i="index">
                <app-drop-down-navigate [content]="dropdown" class="mt-small mb-small mr-small"
                                        [buttonClass]="'width-medium height-medium text rubik text-500 ' + (i % 2 === 0 ? 'primary border-secondary-accent' : 'secondary border-primary-accent')"
                                        linkClass="nav-link-drop-down text kreon text-300">
                </app-drop-down-navigate>
            </ng-template>
        </div>
    </div>

    <div class="menu-bar-dropdown">
        <div class="menu desktop">
            <ng-template ngFor [ngForOf]="responsiveDropdownContent" let-dropdown let-i="index">
                <app-drop-down-navigate [content]="dropdown" class="mt-small mb-small mr-small"
                                        [buttonClass]="'width-medium height-medium text rubik text-500 ' + (i % 2 === 0 ? 'primary border-secondary-accent' : 'secondary border-primary-accent')"
                                        linkClass="nav-link-drop-down text kreon text-300">
                </app-drop-down-navigate>
            </ng-template>
        </div>
    </div>

    <app-account-card class="ml-auto" *ngIf="agent?.active" [profile]="profile"></app-account-card>

    <div class="menu mobile">
        <app-menu-bar [expanded]="mobileexpanded" (toggle)="toggleMenu($event)" color="pink"></app-menu-bar>
        <div class="mobile-menu-content">
            <div class="flex-row no-wrap">
                <div class="flex-column flex-grow">
                    <ng-template ngFor [ngForOf]="dropdownContent" let-dropdown let-i="index">
                        <app-drop-down-navigate [content]="dropdown" class="mb-large" (selected)="toggleMenu(false)"
                                                [buttonClass]="'width-medium height-medium text rubik text-500 secondary border-primary-accent'"
                                                linkClass="nav-link-drop-down text kreon text-300">
                        </app-drop-down-navigate>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</nav>
