<app-progress [splash]="true" [value]="working" [splashOptions]="{ title: workingTitle, message: workingMessage }">

    <app-access [platformFeature]="feature.AccountUpdate" [displayIfNoAccess]="true">
        <div class="header flex-row no-wrap justify-between">
            <H3>Formulary Products</H3>
            <label *ngIf="!(parentAccount?.allowManageFormulary ?? true)"
                class="text text-rubik text-500 mt-auto mb-small">
                *The parent organization has disabled formulary management on this account*
            </label>
        </div>

        <form (submit)="formSubmit($event)">
            <dx-data-grid id="gridContainer" [showColumnLines]="true" [dataSource]="dataSource" [showBorders]="true"
                [columnAutoWidth]="true" [wordWrapEnabled]="false" (onContentReady)="onContentReady($event)"
                (onSelectionChanged)="onSelectionChanged($event)" [remoteOperations]="true" selectionMode="'multiple'">
                <dxo-selection
                    [mode]="(parentAccount?.allowManageFormulary ?? true) ? 'multiple' : 'none'" id="selectionColumn"></dxo-selection>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-header-filter [visible]="true"></dxo-header-filter>
                <dxo-search-panel [visible]="true" [width]="240"></dxo-search-panel>

                <dxo-toolbar>
                    <dxi-item location="before" class="toolbar-button">
                        <dx-button [disabled]="isValid" [useSubmitBehavior]="false" text="Import"
                        (click)="modals.open('import-file')"></dx-button>
                    </dxi-item>
                    <dxi-item location="before" class="toolbar-button">
                        <dx-button class="export" [useSubmitBehavior]="false" text="Export"
                        (click)="exportData()"></dx-button>
                    </dxi-item>
                    <dxi-item location="before" class="toolbar-button">
                        <dx-button [disabled]="!isValid"  [useSubmitBehavior]="true" text="Remove"
                        (click)="mode='Remove'"></dx-button>
                    </dxi-item>
                    <dxi-item location="before" class="toolbar-button">
                        <dx-button [disabled]="!isValid" [useSubmitBehavior]="true" (click)="mode='Include'"
                        text="Mark as Allow"></dx-button>
                    </dxi-item>
                    <dxi-item location="before" class="toolbar-button">
                        <dx-button [disabled]="!isValid" [useSubmitBehavior]="true" (click)="mode='Exclude'"
                        text="Mark as Deny"></dx-button>
                    </dxi-item>
                    <dxi-item name="searchPanel"></dxi-item>
                </dxo-toolbar>
                <dxo-paging [pageSize]="100"></dxo-paging>
                <dxo-pager
                    [visible]="true"
                    [showInfo]="true"
                    infoText="Page {0} of {1} | {2} total"
                    [showPageSizeSelector]="true"
                    [allowedPageSizes]="[20, 100, 200, 500, 1000]"
                    [showNavigationButtons]="true">
                </dxo-pager>
                <dxi-column dataField="sku" [minWidth]="160" [sortIndex]="0" caption="Supplier SKU" dataType="string" sortOrder="asc">
                    <dxo-header-filter [allowSearch]="true"></dxo-header-filter>
                </dxi-column>
                <dxi-column dataField="supplier.name" [minWidth]="200" dataType="string" caption="Supplier">
                    <dxo-header-filter [allowSearch]="true"></dxo-header-filter>
                </dxi-column>
            
                <dxi-column [allowHeaderFiltering]="false" [allowFiltering]="true" dataField="include" [minWidth]="100"
                    [alignment]="'left'" dataType="boolean" caption="Allow" falseText="No" trueText="Yes"
                    [showEditorAlways]="false">
                </dxi-column>
                <dxi-column dataField="manufacturer.name" [minWidth]="200" dataType="string" caption="Manufacturer">
                    <dxo-header-filter [allowSearch]="true"></dxo-header-filter>
                </dxi-column>
                <dxi-column dataField="manufacturerSKU" [minWidth]="160" dataType="string" caption="Manufacturer SKU">
                    <dxo-header-filter [allowSearch]="true"></dxo-header-filter>
                </dxi-column>
                <dxi-column dataField="name" [allowHeaderFiltering]="false" [minWidth]="220" dataType="string" caption="Name">
                </dxi-column>
                <dxi-column dataField="category" [minWidth]="220" dataType="string" caption="Category">
                    <dxo-header-filter [allowSearch]="true"></dxo-header-filter>
                </dxi-column>
                <dxi-column dataField="subcategory"  dataType="string" caption="Sub-Category">
                    <dxo-header-filter [allowSearch]="true"></dxo-header-filter>
                </dxi-column>
             

            </dx-data-grid>
        </form>
    </app-access>
</app-progress>

<app-modal id="import-file" heading="Select a file to replace the products on this formulary">
    <dx-file-uploader *ngIf="!uploadMessages?.length" selectButtonText="Select File" labelText="" accept="*"
        uploadMode="useForm" [multiple]="true" [uploadUrl]="uploadUrl" uploadMode="instantly"
        (onBeforeSend)="onBeforeSend($event)" (onUploaded)="onUploaded($event)">
    </dx-file-uploader>

    <div *ngIf="uploadMessages?.length" class="text text-rubik text-500" style="max-height: 480px; overflow: scroll;">
        <div *ngFor="let m of uploadMessages" class="text text-rubik text-500">
            {{ m }}
        </div>
    </div>

    <div class="flex-row mt-large">
        <dx-button class="mr-small" [useSubmitBehavior]="false" text="Close"
            (click)="uploadMessages = []; modals.close('import-file')"></dx-button>
        <!-- <dx-button *ngIf="uploadMessages?.length" (click)="modals.open('import-messages')"
            [useSubmitBehavior]="false">View Messages</dx-button> -->
    </div>
</app-modal>

<app-modal id="confirm-action" size="medium" heading="Please confirm">
    <p class="mb-medium">
        This will <span class="text-upper text text-800">{{ queuedAction }}</span> {{ queuedActions.length | number }} products from this formulary.
    </p>
    <div class="flex-row no-wrap">
        <dx-button class="mr-medium" [useSubmitBehavior]="false" text="Yes" (click)="confirmedSubmit()"></dx-button>
        <dx-button [useSubmitBehavior]="false" text="No" (click)="cancelSubmit()"></dx-button>

    </div>
</app-modal>
