import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgentService, NavigationService } from 'src/app/shared/services';
import { AccountService } from 'src/app/shared/services/account.service';

@Component({
  selector: 'app-practice-dashboard',
  templateUrl: './practice-dashboard.component.html',
  styleUrls: ['./practice-dashboard.component.scss']
})
export class PracticeDashboardComponent {
  catalogFilterButtons = [
    // {text: "Products", value: 'all'},
    { text: "Frequently Ordered", value: 'freq' },
    { text: "Recently Ordered", value: 'recent' },
    { text: "Recommended", value: 'rec' },
    { text: "Reports", value: 'reports' }
  ];

  hiddenArray: string[] = [];

  constructor (public accounts: AccountService, public navigationService: NavigationService, private agentService: AgentService, private activatedRoute: ActivatedRoute) { }

  displayProductSearch(e: string){
    this.hiddenArray.push(e);
  }

  checkIfEmpty(e: string){
    if(this.hiddenArray.find(h => h == e)){
      return false;
    }
    return true;
  }

  goToSearch(fragment: string | undefined){
    this.navigationService.practice(this.agentService.currentAccount!.id).search(fragment);
  }
}
