import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PurchaseRequisitionStatus } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ApprovalService {

  private decisionMadeSource = new Subject<PurchaseRequisitionStatus>();
  decisionMade$ = this.decisionMadeSource.asObservable();

  decisionMade(status: PurchaseRequisitionStatus) {
    this.decisionMadeSource.next(status);
  }
}
