import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  IAccount,
  AccountAccess,
  AccountType,
  Agency,
  Agent,
  Role,
} from 'src/app/shared/models';
import {
  AgentService,
  LoginService,
  ModalService,
  MonitorService,
} from 'src/app/shared/services';
import { AccountService } from 'src/app/shared/services/account.service';
import { AgencyService } from 'src/app/shared/services/agency.service';
import {
  Feature,
  FeatureService,
} from 'src/app/shared/services/feature.service';
import { FormCanDeactivate } from 'src/app/shared/utils/form.candeactivate';

@Component({
  selector: 'app-agency-edit-access',
  templateUrl: './agency-edit-access.component.html',
  styleUrls: ['./agency-edit-access.component.scss'],
})
export class AgencyEditAccessComponent extends FormCanDeactivate {
  submitMessage: string = 'Error editing agency access';
  validate(): boolean {
    return ((!!this.agency && !!this.selectedAgent?.id) ?? false);
  }

  agency: Agency = {} as Agency;

  allAccounts: AccountAccess[] = [];
  accounts: AccountAccess[] = [];
  accountIds: number[] = [];
  gpoRoles: Role[] = [];
  dsoRoles: Role[] = [];
  practiceRoles: Role[] = [];
  allRoles: Role[] = [];

  accountRoles: any;
  defaultAgent: Agent | undefined = undefined;
  agents: Agent[] = [];
  selectedAgent: Agent | undefined = undefined;
  feature = Feature;
  working = 0;
  roles: Role[] = [];

  editorOptions: object;

  roleOptions: any;
  onlyAgency: boolean | null | undefined = false;
  cascadeAccount: AccountAccess | undefined = undefined;
  cascadeData: any;
  disableSubmit: boolean = false;

  constructor(
    private accountService: AccountService,
    private agentService: AgentService,
    private featureService: FeatureService,
    private agencyService: AgencyService,
    monitor: MonitorService,
    login: LoginService,
    private modals: ModalService,
    private route: ActivatedRoute,
    router: Router
  ) {
    super(monitor, login, router);
    this.editorOptions = {
      itemTemplate: 'roleTemplate',
    };

    this.route.parent?.paramMap.subscribe(async (params) => {
      this.gpoRoles = [{ name: 'None', id: 0 }];
      this.dsoRoles = [{ name: 'None', id: 0 }];
      this.practiceRoles = [{ name: 'None', id: 0 }];
      this.allRoles = [{ name: 'None', id: 0 }];

      this.working = 10;

      this.agency = await this.agencyService.getAgencyById(parseInt(params.get('agencyId') ?? '0'), { accounts: true, agents: true });

      this.working = 10;
      this.agents = this.agency.agents ?? [];
      this.agents
        .sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
        .forEach((a) =>
          Object.assign(a, { fullName: `${a.firstName} ${a.lastName}` })
        );
      this.defaultAgent = this.agents[0];
      this.selectedAgent = this.agents[0];
      await this.loadAgent();

      try {
        (await this.agentService.getRoles(false, 'GPO')).forEach((r) => {
          this.gpoRoles.push(r);
          this.allRoles.push(r);
        });
        (await this.agentService.getRoles(false, 'DSO')).forEach((r) => {
          this.dsoRoles.push(r);
          this.allRoles.push(r);
        });
        (await this.agentService.getRoles(false, 'Practice')).forEach((r) => {
          this.practiceRoles.push(r);
          this.allRoles.push(r);
        });

      } catch (error: any) {
        this.monitor.handleError(error, error?.error?.message ?? error.message);
      }

      this.accountRoles = this.allRoles;
      this.allAccounts = [];
      try {
        (await this.accountService.get({ agents: true })).map((a: IAccount) => {
          this.accountIds.push(a.id);
          this.allAccounts.push({
            id: a.id,
            accountNumber: a.accountNumber,
            accountType: a.accountType,
            active: a.active,
            name: a.name,
            parentId: a.parentId,
            requistionApprover: false,
            role: { name: 'None', id: 0 }.id,
            // roles: [],
            enabled: true,
            changed: false,
            include: true,
            inherit: false,
          });
        });
      } catch (error: any) {
        this.monitor.handleError(error, error?.error?.message ?? error.message);
      }

      try {
        this.working = 90;
        this.bindDatasource();
      } catch (error) {
        monitor.handleError(error, 'Error loading grid');
      } finally {
        this.working = 0;
      }
    });
  }

  onContentReady(e: any) {
    const rows = e.component.getVisibleRows();
    rows.forEach((row: any) => {
      const rowKey = e.component.getKeyByRowIndex(row.rowIndex);
      const rowData = row.data;
      const rowIndex = e.component.getRowIndexByKey(rowKey);
      const rowElement = e.component.getRowElement(rowIndex);
      if (rowData.active === false) {
        for (let i = 0; i < rowElement[0].children.length; ++i) {
          const element = rowElement[0].children[i];
          element.classList.add('inactive');
        }
      }
      if (rowData.include === false) {
        for (let i = 0; i < rowElement[0].children.length; ++i) {
          const element = rowElement[0].children[i];
          element.classList.add('not-included');
        }
      }
    });
  }

  async changeAgent(e: any): Promise<any> {
    this.selectedAgent = e.value;
    await this.loadAgent();
  }

  async loadAgent(): Promise<any> {
    if (this.selectedAgent?.id) {
      try {
        this.selectedAgent = await this.agentService.getAgentById(this.selectedAgent.id, { features: true, roles: true });
        this.bindDatasource();
      } catch (error: any) {
        this.monitor.handleError(error, error?.error?.message ?? error.message
        );
      } finally {
      }
    }
  }

  bindDatasource(): void {
    let roles: Role[] = [];

    this.allAccounts.forEach((a: AccountAccess) => {
      const aa = this.agency.accounts?.find(
        (aaa) => aaa.accountNumber === a.accountNumber
      );
      if (aa) {
        switch (a.accountType) {
          case AccountType.DSO:
            roles = this.dsoRoles;
            break;
          case AccountType.GPO:
            roles = this.gpoRoles;
            break;
          case AccountType.PRACTICE:
          default:
            roles = this.practiceRoles;
            break;
        }

        let ar: string = this.selectedAgent?.accountRoles[a.accountNumber];
        if (ar) {
          const sar = ar.split('|');
          ar = sar[0];
          if (sar.length > 1) {
            if (sar[1] === 'INHERIT') {
              a.inherit = true;
            }
          }
        } else {
          ar = roles[0].name;
          a.inherit = true;
        }
        a.role = roles.find((r) => r.name === ar)!.id;
        a.requistionApprover = this.featureService.check(
          Feature.RequisitionApprover,
          this.selectedAgent?.accountFeatures[a.accountNumber]
        );
        a.enabled = true;
      } else {
        a.role = undefined;
        a.requistionApprover = false;
        a.enabled = false;
        a.inherit = true;
      }
    });

    this.accounts = this.allAccounts;
    this.bindAccounts();
  }

  onCellPrepared(e: any) {
    if (!e.row?.data?.enabled) {
      e.cellElement.classList.add('readonly');
    }
    if (e.column.name === 'role' && e.row?.data?.inherit) {
      e.cellElement.classList.add('inherited');
    }
  }

  startEdit(e: any) {
    switch (e.data.accountType) {
      case AccountType.DSO:
        this.accountRoles = this.dsoRoles;
        break;
      case AccountType.GPO:
        this.accountRoles = this.gpoRoles;
        break;
      case AccountType.PRACTICE:
      default:
        this.accountRoles = this.practiceRoles;
        break;
    }
  }

  cancelEdit(e: any) {
    this.accountRoles = this.allRoles;
  }

  async update(e: any, modalName: string): Promise<any> {
    // only 1 change allowed at a time
    if (e.changes?.length) {
      const account = this.accounts.find((a) => a.id === e.changes[0].key);
      if (account && this.hasInherited(account, false)) {
        this.cascadeAccount = account;
        this.cascadeData = e.changes[0].data;
        this.modals.open(modalName);
      } else {
        if (e.changes?.length) {
          let x = this.accounts.find((bb) => bb.id === e.changes[0].key)!;
          Object.assign(x, e.changes[0].data);
          x.changed = true;
          x.inherit = false;

          this.cascadeChange(x, e.changes[0].data);
        }
        this.accountRoles = this.allRoles;
        this.bindAccounts();
      }
    }
  }

  accountChange(account: AccountAccess, changes: any): void {
    if (changes) {
      Object.assign(account, changes);
      account.changed = true;
      account.inherit = false;
    }
  }

  cascadeChange(account: AccountAccess, changes: any): void {
    const children = this.allAccounts.filter((a) => a.parentId === account.id);
    for (let i = 0; i < children.length; ++i) {
      const c = children[i];

      if (c.enabled) {
        if (
          account.accountType !== c.accountType &&
          changes.role !== undefined
        ) {
          let role = this.allRoles.find((r) => r.id === account.role);
          if (role?.id) {
            const access = role.name.split(' ')[1];
            // can't go fup from dso
            role = (
              c.accountType === AccountType.DSO
                ? this.dsoRoles
                : this.practiceRoles
            ).find((r) => r.name.endsWith(access));
          }

          // don't want to actually change the changes object
          let temp: any = {};
          Object.assign(temp, changes);
          temp.role = role?.id ?? 0;
          Object.assign(c, temp);
        } else {
          Object.assign(c, changes);
        }
        c.inherit = true;
        c.changed = true;
      }

      this.cascadeChange(c, changes);
    }
  }

  hasInherited(account: AccountAccess, state: boolean): boolean {
    let any = false;

    const children = this.accounts.filter((a) => a.parentId === account.id);
    for (let i = 0; i < children.length; ++i) {
      const c = children[i];
      any = any || c.inherit === state;
      any = any || this.hasInherited(c, state);
    }

    return any;
  }

  toggleHierarchy(): void {
    this.onlyAgency = !this.onlyAgency;

    this.bindAccounts();
  }

  bindAccounts(): void {
    this.accounts = this.allAccounts.filter((a) => a.enabled);
    for (let i = 0; i < this.accounts.length; ++i) {
      if (
        this.accounts[i].parentId &&
        !this.accounts.find((a) => a.id === this.accounts[i].parentId)
      ) {
        const x = this.allAccounts.find(
          (a) => a.id === this.accounts[i].parentId
        );
        if (x) {
          x.include = false;
          this.accounts.push(x);
        }
      }
    }
  }

  async submit(): Promise<boolean> {
    this.disableSubmit = true;

    try {
      for (let i = 0; i < this.allAccounts.length; ++i) {
        const a = this.allAccounts[i];
        if (a.changed) {
          if (a.role) {
            await this.agentService.postAccountRole(
              this.selectedAgent!.id!,
              a,
              this.allRoles.find((r) => r.id === a.role)!,
              a.inherit);
              this.working += 1;
          } else {
            await this.agentService.deleteAccountRole(this.selectedAgent!.id!, a);
            this.working += 1;
          }
          if (a.requistionApprover) {
            await this.agentService.postAccountFeature(
              this.selectedAgent!.id!,
              a,
              Feature.RequisitionApprover);
              this.working += 1;
          } else {
            await this.agentService.deleteAccountFeature(this.selectedAgent!.id!, a, Feature.RequisitionApprover);
            this.working += 1;
          }
        }
      }
    } catch (e: any) {
      this.monitor.handleError(e, e?.error?.message ?? e.message); //Note: Keeping the handle error structure in the old format
                                                                  //Previously, some errors returned from our API 
                                                                  //would have messages in different nested places
    } finally {
      this.disableSubmit = false;
      this.monitor.handleSuccess('Agent role successfully changed.');
    }
    
    return true;
  }

  getParentKey(): string | undefined {
    return this.onlyAgency ? 'index' : 'parentId';
  }

  accountUpdate(modalName: string): void {
    this.modals.close(modalName);

    this.accountChange(this.cascadeAccount!, this.cascadeData);
    this.cascadeAccount = undefined;
    this.cascadeData = undefined;
    this.accountRoles = this.allRoles;
  }

  cascadeUpdate(modalName: string): void {
    this.modals.close(modalName);

    this.accountChange(this.cascadeAccount!, this.cascadeData);
    this.cascadeChange(this.cascadeAccount!, this.cascadeData);

    this.cascadeAccount = undefined;
    this.cascadeData = undefined;
    this.accountRoles = this.allRoles;
  }
}
