import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxDataGridComponent, DxFormComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { Agency } from 'src/app/shared/models';
import { LoginService, MonitorService, NavigationService } from 'src/app/shared/services';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { Feature } from 'src/app/shared/services/feature.service';
import { GenericComponent } from 'src/app/shared/utils/generic.component';

@Component({
  selector: 'app-agency-list-view',
  templateUrl: './agency-list-view.component.html',
  styleUrls: ['./agency-list-view.component.scss'],
})
export class AgencyListViewComponent extends GenericComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  form: DxFormComponent | undefined;
  dataGrid!: DxDataGridComponent;

  agencies: Agency[] = [];
  dataSource: any;

  feature = Feature;

  constructor(
    private agencyService: AgencyService,
    monitor: MonitorService,
    login: LoginService,
    private navigationService: NavigationService
  ) {
    super(monitor, login);
  }

  async ngOnInit() {
    this.execute(async () => {
      this.agencies = await this.agencyService.get({ accounts: true, agents: true });
      // create table data from agencies
      this.dataSource = new CustomStore({
        loadMode: 'raw',
        load: () => {
          return this.agencies;
        },
      });
      return true;
    }, 'Error loading agencies');
  }

  // Navigate to agency page of selected agency
  onCellClick(event: any) {
    if (event.column.dataField === 'name' && event.data?.id) {
      const agencyId = event.data?.id;
      this.navigationService.backoffice().agency.details(agencyId);
    }
  }

  onContentReady(e: any) {
    setTimeout(() => {
      const rows = e.component.getVisibleRows();
      rows.forEach((row: any) => {
        const rowKey = e.component.getKeyByRowIndex(row.rowIndex);
        const rowData = row.data;
        const rowIndex = e.component.getRowIndexByKey(rowKey);
        const rowElement = e.component.getRowElement(rowIndex);
        if (!rowData.active) {
          rowElement[0].firstElementChild.classList.add('inactive');
        }
      });
    }, 0);
  }

  newAgency(){
    this.navigationService.backoffice().agency.new();
  }
}
