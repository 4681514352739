import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class ClipboardService {
    private blob: any = {};
    private expiration: any = {};

    setValue(key: string, value: any, seconds: number = 60) {
        this.blob[key] = value;
        const timer = this.expiration[key];
        if (timer) {
            clearTimeout(timer);
        }
        this.expiration[key] = setTimeout(() => {
            this.blob[key] = undefined;
        }, seconds * 1000);
    }
    getValue(key: string): any {
        return this.blob[key];
    }
    clearValue(key: string): any {
        this.blob[key] = undefined;
        const timer = this.expiration[key];
        if (timer) {
            clearTimeout(timer);
        }
        this.expiration[key] = undefined;

    }
}