import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AgentService, LoginService, ToastService } from 'src/app/shared/services';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent {

  formGroup: FormGroup;
  sent: string | undefined = undefined;

  constructor(
    fb: FormBuilder,
    private router: Router,
    private agentService: AgentService,
    private toast: ToastService,
    private loginService: LoginService) {
    this.formGroup = fb.group({
      username: new FormControl('', [Validators.required])
    });
  }

  login(): void {
    this.loginService.login(window.location.origin);
  }

  async reset(): Promise<any> {
    const id = this.formGroup.controls['username'].value;
    const x = await this.agentService.requestReset(id);
    this.sent = 'Request Sent';
    this.toast.success({ 
      message: x.message      
     });
    setTimeout(() => {
      this.loginService.login(window.location.origin);      
    }, 5000);
  }
}
