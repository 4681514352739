import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Subscription } from 'rxjs';
import { Agent } from 'src/app/shared/models';
import { AgentService, MonitorService, NavigationService } from 'src/app/shared/services';
import { LoginService } from 'src/app/shared/services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  private monitorService: MonitorService | undefined;
  private reset: any | undefined;

  constructor(
    private loginService: LoginService,
    private auth: AuthService,
    private agentService: AgentService,
    private monitor: MonitorService,
    private router: Router
  ) {
    this.monitorService = monitor;
    this.agentService = agentService;
    this.loginService = loginService;
  }

  async ngOnInit() {
    this.auth.error$.subscribe((error) => {console.log(error)});
    this.auth.appState$.subscribe(async (state) => {
      try{
        let agent: Agent | undefined = await this.agentService.authenticate();
        if(agent){
          await this.agentService.trackLogin();
        }
        console.log('navigating in authService');
        this.router.navigate([state.target])
      }catch(e){
        console.log(e)
      }
    });

    setTimeout(async () => {
      try {
        await this.loginService.syncronizeToken();
      } catch (error: any) {
        if (
          error?.error === 'login_required' ||
          error?.message === 'There are no query params available for parsing.'
        ) {
          let popups = false;
          try {
            var popup = window.open(
              'https://' + window.location.hostname,
              '_blank',
              'width=1,height=1'
            );
            if (popup) {
              popups = true;
              popup.close();
            }
          } catch { }
          if (popups) {
            await this.loginService.syncronizeFailover();
          } else {
            this.monitor.handleError(
              undefined,
              'Please enable popups for login',
              true,
              'Please enable pop-ups'
            );
          }
        } else {
          this.monitor.handleError(error, 'Error syncing login');
        }
      }
    }, 2000);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}

