import {  ProductInfo, Manufacturer, NameId, Tag } from '.';

export class CoreProductSearchResult {
    public page!: number;
    public records!: number;
    public products!: CoreProduct[];
}

export class CoreProduct implements ProductInfo {
    public listing!: ProductListing;
    public name!: string;
    public id!: number;
    public urlName!: string;
    public description: string | undefined;
    public imageUrl: string | undefined;

    public sku!: string;
    public manufacturer?: Manufacturer;
    public price?: string;
    public lastPurchaseQuantity?: number;
    public lastPurchaseDate?: Date;
    public listingTags?: Tag[];
    public coreProductTags?: Tag[];
    public canPurchase?: boolean;
    public recommendedProduct?: boolean;
  }

export class ProductListing implements ProductInfo {
    public name!: string;
    public id!: number;
    urlName: string | undefined;
    description: string | undefined;
    imageUrl: string | undefined;
    productCount!: number;
}