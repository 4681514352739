import { Component } from '@angular/core';
import { AccountService } from 'src/app/shared/services/account.service';

@Component({
  selector: 'app-gpo-dashboard',
  templateUrl: './gpo-dashboard.component.html',
  styleUrls: ['./gpo-dashboard.component.scss']
})
export class GpoDashboardComponent {

  constructor(public accounts: AccountService) { }
}
