export interface IReportListItem {
    reportId: number;
    reportKey: string;
    reportTitle: string;
    reportSubTitle: string;
    reportMenuSectionName: string;
    reportMenuName: string;
    reportDisplayTypeId: ReportDisplayTypeEnum;
    reportSortOrder: number;
    
    //reportEndpoint: string; do we need this?
}

export enum ReportDisplayTypeEnum {
    None = 0,
    TreeList = "TreeList",
    DataGrid = "DataGrid"
}

export enum GenericReportExceptionList {
    BUDGET_REPORT = "BUDGET_REPORT"
}

export interface IReportColumnMetadata
{
    width: string;
}