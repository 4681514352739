import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sort',
    pure: false
})
export class ArraySortPipe implements PipeTransform {
    transform(array: any, field: string, asc: boolean = true): any[] {
        if (!Array.isArray(array) || !array.length) {
            return array;
        }
        array.sort((a: any, b: any) => {
            let a1 = a;
            let b1 = b;
            if (field.length !== 0) {
                a1 = this.getSubProperty(a, field);
                b1 = this.getSubProperty(b, field);
            } 
            
            if ((typeof a1 === 'string' ? a1?.toLowerCase() : a1)
                < (typeof b1 === 'string' ? b1?.toLowerCase() : b1)) {
                return -1;
            } else if ((typeof a1 === 'string' ? a1?.toLowerCase() : a1)
                > (typeof b1 === 'string' ? b1?.toLowerCase() : b1)) {
                return 1;
            } else {
                return 0;
            }
        });
        return asc ? array : array.reverse();
    }

    getSubProperty(a: any, field: any): any {
        if (typeof (field) === 'string') {
            let props = field.split('.');

            while (props.length > 1 && a) {
                a = a[props[0]];
                props.shift();
            }
            return a ? a[props[0]] : undefined;
        } else {
            return a[field];
        }
    }
}

@Pipe({
    name: 'doubleSort',
    pure: false
})
export class ArrayDoubleSortPipe implements PipeTransform {
    transform(array: any, args: any[]): any[] {
        let field: string = args[0];
        let asc: boolean = true;
        let field2: string = '';
        let asc2: boolean = true;

        for (let i = 1; i < args.length && i < 4; ++i) {
            if (typeof (args[i]) === 'string') {
                field2 = args[i];
            } else if (typeof (args[i]) === 'boolean' && field2.length) {
                asc2 = args[i];
            } else {
                asc = args[i];
            }
        }

        if (!Array.isArray(array) || !array.length) {
            return array;
        }
        array.sort((a: any, b: any) => {
            const a1 = this.getSubProperty(a, field);
            const b1 = this.getSubProperty(b, field);
            const a2 = this.getSubProperty(a, field2);
            const b2 = this.getSubProperty(b, field2);

            if ((typeof a1 === 'string' ? a1.toLowerCase() : a1)
                < (typeof b1 === 'string' ? b1 : b1)) {
                return asc ? -1 : 1;
            } else if ((typeof a1 === 'string' ? a1.toLowerCase() : a1)
                > (typeof b1 === 'string' ? b1.toLowerCase() : b1)) {
                return asc ? 1 : -1;
            } else {
                if ((typeof a2 === 'string' ? a2.toLowerCase() : a2)
                    < (typeof b2 === 'string' ? b2.toLowerCase() : b2)) {
                    return asc2 ? -1 : 1;
                } else if ((typeof a2 === 'string' ? a2.toLowerCase() : a2)
                    > (typeof b2 === 'string' ? b2.toLowerCase() : b2)) {
                    return asc2 ? 1 : -1;
                } else {
                    return 0;
                }
            }
        });
        return array;
    }

    getSubProperty(a: any, field: any): any {
        if (typeof (field) === 'string') {
            let props = field.split('.');

            while (props.length > 1 && a) {
                a = a[props[0]];
                props.shift();
            }
            return a ? a[props[0]] : undefined;
        } else {
            return a[field];
        }
    }
}
