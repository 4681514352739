<app-access [platformFeature]="feature.AccountRead" [accountFeature]="feature.AccountRead" [redirectOnFailure]="true">
    <app-progress [splash]="true" [value]="working">
        <div class="account-edit flex-column">
            <div class="header flex-row no-wrap justify-between">
                <H3>Pending Approvals</H3>
            </div>
            <div class="body info">
				<dx-tree-list id="tasks" [dataSource]="requisitions" [columnAutoWidth]="true" [wordWrapEnabled]="true"
							  [showBorders]="true" [showColumnLines]="true" [allowColumnResizing]="true" [wordWrapEnabled]="false"
							  (onCellClick)="onCellClick($event)">

					<dxo-header-filter [visible]="true"></dxo-header-filter>
					<dxo-filter-row [visible]="true" [applyFilter]="'auto'"></dxo-filter-row>

					<dxi-column [width]="180" dataField="requisitionNumber" caption="Requisition #"></dxi-column>
					<dxi-column [width]="135" dataField="approval.type" caption="Type"></dxi-column>
					<dxi-column [width]="200" dataField="supplier" caption="Supplier"></dxi-column>
					<dxi-column [width]="110" dataField="total" caption="Total" format="currency" cellTemplate="totalCellTemplate"></dxi-column>
					<dxi-column [width]="200" dataField="createdBy" caption="Created By"></dxi-column>
					<dxi-column [width]="175" dataField="createdOn" dataType="date" caption="Created On" format="MM/dd/yyyy hh:mm a"></dxi-column>
					<dxi-column [width]="200" dataField="modifiedBy" caption="Modified By"></dxi-column>
					<dxi-column [width]="175" dataField="modifiedOn" dataType="date" caption="Modified On" format="MM/dd/yyyy hh:mm a"></dxi-column>
					<dxi-column dataField="approvalProcess" caption="Approval Details" cellTemplate="approvalCellTemplate" [allowHeaderFiltering]="false" cssClass="no-padding"></dxi-column>

					<div *dxTemplate="let cell of 'totalCellTemplate'">
						<span *ngIf="cell.data.total">{{ cell.data.total | currency }}</span>
						<span *ngIf="!cell.data.total">TBD</span>
					</div>

					<div *dxTemplate="let cell of 'approvalCellTemplate'">
						<div class="approver-approval-wrapper" *ngIf="cell.data.approval?.approvers">
							<div class="approver-approval-container" *ngFor="let approverApproval of getApprovers(cell.data.approval.approvers)">
								<div class="approver-approval-body">
									<div class="approver-approval-name">
										{{approverApproval.name}}
									</div>
									<div class="approver-approval-status {{approverApproval.status.toLowerCase()}}">
										{{approverApproval.status}}
									</div>
								</div>
							</div>
						</div>
						<span *ngIf="cell.data.approval?.autoApproved">Auto - Approved</span>
					</div>
				</dx-tree-list>
            </div>
        </div>
    </app-progress>
</app-access>