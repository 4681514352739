import { AccountSupplier, AuditInfo, IPractice, Address, Organization, Formulary, } from ".";

export interface IAccount extends AuditInfo {
  name: string;
  active: boolean;
  accountNumber: string;
  accountType: AccountType;
  parentId?: number;
  recommendation?: string;
  accountSuppliers?: AccountSupplier[];
  addresses?: Address[];
  practice?: IPractice;
  organization?: Organization;
  publicFormularyId?: number;
  publicFormulary?: Formulary;
  allowManageFormulary?: boolean;
  allowOrderHistory?: boolean;
  allowDentalCity?: boolean;
  inheritFromParent?: boolean;
}

export interface AccountAccess extends IAccount {

  name: string;
  active: boolean;
  accountNumber: string;
  accountType: AccountType;
  parentId?: number | undefined;
  recommendation?: string | undefined;
  accountSuppliers?: AccountSupplier[] | undefined;
  addresses?: Address[] | undefined;
  practice?: IPractice;
  organization?: Organization | undefined;
  publicFormularyId?: number | undefined;
  publicFormulary?: Formulary | undefined;
  allowManageFormulary?: boolean | undefined;
  allowOrderHistory?: boolean | undefined;
  allowDentalCity?: boolean | undefined;
  inheritFromParent?: boolean | undefined;
  id: number;
  createdBy?: number | undefined;
  created?: Date | undefined;
  modifiedBy?: number | undefined;
  modified?: Date | undefined;

  role: number | undefined;
  requistionApprover: boolean;
  enabled: boolean;
  changed: boolean;
  include: boolean;
  inherit: boolean;
}

export enum AccountType {
  PRACTICE = 'Practice',
  DSO = 'DSO',
  GPO = 'GPO',
  EMPTY = 'EMPTY'
}

export class Recommendation {
  public static Inherit: string = 'Inherit';
  public static None: string = 'None';
  public static PreferredSupplier: string = 'PreferredSupplier';
  public static LowestPrice: string = 'LowestPrice';
  public static MostRecent: string = 'MostRecent';
}
