import { Injectable } from "@angular/core";

@Injectable({providedIn: 'root'})
export class FeatureService {

    check(claim: Feature, against: Feature): boolean {
        return (claim & against) === claim;
    }

}

export enum Feature {
    None = 0,
    AgencyRead = 1,
    AgencyUpdate = 2,
    AgentRead = 4,
    AgentUpdate = 8,
    AccountRead = 16,
    AccountUpdate = 32,
    CatalogApprover = 64,
    RequisitionApprover = 128,
}