import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import DataSource from 'devextreme/data/data_source';
import { Profile } from 'src/app/shared/models';
import { IFilter } from 'src/app/shared/models/filter.model';
import IGenericApiResponse from 'src/app/shared/models/generic-response.model';
import { GenericReportExceptionList, IReportListItem, ReportDisplayTypeEnum, IReportColumnMetadata } from 'src/app/shared/models/report.list.item.model';
import { TreeListBudgetItem } from 'src/app/shared/models/tree.list.budget.model';
import { AgentService, MonitorService } from 'src/app/shared/services';
import { ReportingService } from 'src/app/shared/services/reporting.service';

@Component({
	selector: 'app-reporting-dashboard',
	templateUrl: './reporting-dashboard.component.html',
	styleUrls: ['./reporting-dashboard.component.scss']
})
export class ReportingDashboardComponent implements OnInit
{
	reportCategory: string[] = [];
	reports: IReportListItem[] = []; //this list populates the reports under the selected "tab" category. Eventually,
	selectedReportData?: DataSource;
	selectedReport: IReportListItem = {} as IReportListItem;
	pastReportKey: string | undefined;
	budgetFilters: IFilter[] = [];
	noContent: boolean = false;
	isGeneric: boolean = true;
	columnsMetadata?: IReportColumnMetadata[];
	loading: boolean = false;
	renderReport: boolean = false;
	renderFilters: boolean = false;

	constructor(
		private reportingService: ReportingService,
		private monitorService: MonitorService,
		private router: Router,
		private agentService: AgentService,
		private activatedRoute: ActivatedRoute)
	{
		this.loading = true;
		this.reportCategory = [];
	}

	async ngOnInit(): Promise<void>
	{
		let listData: IGenericApiResponse | undefined = undefined;

		try
		{
			listData = await this.reportingService.getReportsList
			(
				Profile[this.agentService.currentProfile ?? Profile.Unauthorized],
				this.agentService.currentAccount?.id ?? undefined,
			);
		}
		catch (error)
		{
			this.loading = false;
			this.noContent = true;
		}

		if (listData?.success)
		{
			this.reports = listData.value as IReportListItem[];

			if (!this.reports || this.reports.length === 0)
			{
				this.selectedReport = {} as IReportListItem;
				this.selectedReport.reportTitle = 'No reports configured for your account.';
			}
			else
			{
				this.reportCategory = this.reports.map((report: IReportListItem) => report.reportMenuSectionName).filter((value, index, self) => self.indexOf(value) === index); //get the unique report categories

				let reportLinkKey = this.router.parseUrl(this.router.url).queryParams['reportKey']; //get the reportId from the query params

				if (reportLinkKey) {
					let foundReport = this.reports.find((report: IReportListItem) => report.reportKey === reportLinkKey); //find the report type based on the reportId

					if (foundReport) {
						this.selectedReport = foundReport;
						this.loadTab(foundReport); //load the tab if the reportId is present in the query params
					}
					else {
						this.monitorService.handleError('The report you requested was not found.');
					}
				}
				else
				{
					let foundReport = this.reports[0]; //find the report type based on the reportId

					if (foundReport) {
						this.selectedReport = foundReport;
						this.loadTab(foundReport); //load the tab if the reportId is present in the query params
					}
				}
			}
		}
		else
		{
			this.monitorService.handleError('Error fetching available reports.');
			this.noContent = true;
		}

		this.loading = false;
	}

	async loadTab(selectedReport: IReportListItem, filters?: any)
	{
		this.loading = true;
		this.renderReport = false;
		this.renderFilters = false;
		this.selectedReport = selectedReport;

		let renderFilters = false;

		let request: Promise<IGenericApiResponse>;

		if (selectedReport.reportKey in GenericReportExceptionList)
		{
			request = this.reportingService.handleExceptionFlow(selectedReport, this.agentService.currentAccount?.id ?? -1, filters);

			renderFilters = true;
			
			this.isGeneric = false;
		}
		else
		{
			const profile = Profile[this.agentService.currentProfile ?? Profile.Unauthorized];
			const accountId = this.agentService.currentAccount?.id ?? undefined;

			request = this.reportingService.getGenericReport(profile, selectedReport.reportId, accountId);

			this.isGeneric = true;
		}

		try
		{
			this.loading = true;
			let response = await request;

			if (response.success)
			{
				this.selectedReportData = new DataSource({
					load: () =>
					{
						return response.value.dataRows;
					},
				});
				
				this.columnsMetadata = response.value.columns;
				
				this.budgetFilters = response.value.filters;
			}
			else
			{
				throw new Error(response.messages.join('\n'));
			}
		}
		catch (error)
		{
			this.loading = false;
			this.noContent = true;
			this.monitorService.handleError(error, 'Error fetching report data');
		}

		this.renderFilters = renderFilters;
		this.renderReport = true;
		this.loading = false;
	}

	filterSubmit = (filters?: any) =>
	{
		this.loadTab(this.selectedReport!, filters);
	}
}
