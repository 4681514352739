<app-progress [splash]="true" [value]="working">
  <form (ngSubmit)="formSubmit($event)">
    <dx-form id="accountForm" [(formData)]="account" labelLocation="top" [showColonAfterLabel]="false"
      (onFieldDataChanged)="onFieldDataChanged($event)">
      <dxi-item itemType="group" [colCount]="1">
        <dxi-item editorType="dxSelectBox" dataField="accountType" [isRequired]="true"
          [editorOptions]="{ readOnly: account?.id }"
          [editorOptions]="{ dataSource: accountTypes, displayExpr: 'name', valueExpr: 'id' }" [disabled]="account?.id">
          <!-- <dx-select-box [dataSource]="accountTypes"></dx-select-box> -->
          <dxo-label template="Account Type"></dxo-label>
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="accountNumber" [disabled]="true">
          <dxo-label template="Account Number"></dxo-label>
        </dxi-item>
        <dxi-item dataField="name" [isRequired]="true">
          <dxo-label template="Account Name"></dxo-label>
          <dxi-validation-rule type="required" message="Account Name is required"></dxi-validation-rule>
          <dxi-validation-rule type="stringLength" message="Maximum length of Account Name is 50" dataField="name"
            [max]="50"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="parentId" dataType="number" [editorType]="'dx-drop-down-box'"
          [editorOptions]="{ valueExpr: 'id', displayExpr: 'treeBoxValue' }">
          <dxo-label template="Parent Organization"></dxo-label>
          <dx-drop-down-box [(value)]="treeBoxValue" [(opened)]="isTreeBoxOpened" valueExpr="id" displayExpr="name"
            placeholder="Select a value..." [dataSource]="orgs" (onValueChanged)="syncTreeViewSelection($event)" [showClearButton]="true"
            (onOptionChanged)="onTreeBoxOptionChanged($event)">
            <div *dxTemplate="let data of 'content'">
              <dx-tree-view [dataSource]="orgs" dataStructure="plain" keyExpr="id" parentIdExpr="parentId"
                selectionMode="single" displayExpr="name" [selectByClick]="true"
                (onContentReady)="$event.component.selectItem(treeBoxValue)"
                (onItemSelectionChanged)="treeView_itemSelectionChanged($event)">
              </dx-tree-view>
            </div>
          </dx-drop-down-box>
        </dxi-item>

        <dxi-item dataField="recommendation" [isRequired]="true" editorType="dxSelectBox"
          [editorOptions]="{ dataSource: recommendations, displayExpr: 'name', valueExpr: 'id' }">
          <dxi-validation-rule type="required"></dxi-validation-rule>
          <dxo-label template="Base Recommendations On"></dxo-label>
        </dxi-item>

        <dxi-item itemType="simple" dataField="active" editorType="dxCheckBox">
          <dxo-label template="Active"></dxo-label>
        </dxi-item>
      </dxi-item>
      <dxi-item>
          <dx-button [disabled]="!isValid" [useSubmitBehavior]="true" text="Save"></dx-button>
      </dxi-item>
    </dx-form>
  </form>
</app-progress>