import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { ModalService, MonitorService } from '../../services';
import { ProductVariationsComponent } from '../product-variations/product-variations.component';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit, OnDestroy {

  @Input()
  id!: string;

  @Input()
  heading: string | undefined = undefined;

  @Input()
  size: 'small' | 'medium' | 'large' | 'x-large' = 'medium';

  @Input()
  showOverflow = false;

  @Input()
  confirmClose = false;

  @Input()
  confirmationModalId: string | undefined;

  @Input()
  confirm: boolean = false;

  @Input()
  hideClose = false;
  @Input()
  cannotClose = false;

  private element: any;
  isOpen = false;

  variationsComponentInstance: any;
  activeModalId: string | undefined;

  @ViewChild('show_variations', { static: false }) productVariationsComponent: ProductVariationsComponent | undefined;

  constructor(
    private modals: ModalService,
    private el: ElementRef,
    private monitor: MonitorService,
    private renderer: Renderer2
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    if (!this.id) {
      this.monitor.logException('Modal must have an id');
      return;
    }

    document.body.append(this.element);
    this.element.addEventListener('click', (el: any) => {
      if (el.target.className === 'modal') {
        this.close();
      }
    });

    this.modals.add(this);
    // this.modals.setConfirmationCloseBool(false);

  }

  ngOnDestroy(): void {
    this.modals.remove(this.id);
    this.element.remove();
  }

  public open(): void {
    this.activeModalId = this.id


    if (this.id.includes('add-to-cart-')) {
      this.modals.setCloseConfirmationBool(false);

    }

    this.element.style.display = 'block';
    document.body.classList.add('modal-open');
    this.element.classList.add('modal-open');
    this.isOpen = true;
  }
  private closeModal(): void {

    this.element.style.display = 'none';
    document.body.classList.remove('modal-open');
    this.isOpen = false;

  }

  public close(fromapi: boolean | undefined = undefined): void {

    if (this.cannotClose && !fromapi) {
      return;
    } else {
      if (this.confirmClose && this.element.classList.contains('modal-open') && this.activeModalId === this.id) {
        if (!this.modals.getCloseConfirmationBool()) {
          this.modals.setData(this.activeModalId);
          this.modals.triggerMethodInComponentB();
        } else {
          if(this.confirm){
            this.modals.setConfirmation(false);
          }
          this.closeModal();
        }  
      } else {
        if(this.confirm){
          this.modals.setConfirmation(false);
        }
        this.closeModal();
      }
    }
  }

  onConfirmClick(): void {
    this.modals.setConfirmation(true);
    this.closeModal();
  }

  onCancelClick(): void {
    this.modals.setConfirmation(false);
    this.closeModal();
  }

}
