<div #cartcontents id="cart-quick-view" class="cart-wrapper">
  <div class="flex-row" (click)="expanded=!expanded" style="cursor: pointer;">
    <div class="icon ml-auto" [ngClass]="{ 'active': expanded }">
      <div id="cart-count" class="text text-400 rubik ml-small">
        <div class="cart-icon">
          <img src="assets/images/shopping-cart.png">
        </div>
        <div *ngIf="hasPendingApproval === false" class="cart-message">
          {{ cart?.lines?.length ?? 0 }} Products
        </div>
        <div *ngIf="hasPendingApproval" class="cart-message">
          Cart Disabled
        </div>
      </div>
    </div>
  </div>



  <div id="cart-contents" class="contents" [ngClass]="{ 'expanded': expanded }">
    <div class="cart-header flex-row no-wrap mb-medium">
      <div class="text text-400 rubik text-left">Product</div>
      <div class="text text-400 rubik text-right ml-auto">Quantity</div>
    </div>
    <div *ngIf="!hasPendingApproval" class="purchaseRequisitionLines flex-column">
      <div class="messages mb-medium" [ngClass]="{ 'hidden': !cart?.messages?.length }">
        <ng-template ngFor [ngForOf]="cart?.messages" let-m>
          <div class="info-box mb-small" [ngClass]="{ 'dismiss': m.dismissed }">
            <div class="text text-400 rubik"> {{ m.message }} </div>
            <div class="text text-800 rubik" style="cursor: pointer;" (click)="dismiss(m, $event)"> x </div>
          </div>
        </ng-template>
      </div>

      <div class="line-container">
        <ng-template ngFor [ngForOf]="(cart?.lines ?? []) | sort:'id':false" let-l let-i="index">
          <div class="requisition-line" attr.data-sku="{{l.supplierProduct?.sku}}" attr.data-index="{{i}}">
            <img class=""
              [src]="l.supplierProduct?.imageUrl ?? l.supplierProduct?.coreProduct?.imageUrl ?? 'assets/images/badges/badge.png'" />
            <div [class]="'flex-row no-wrap line '" [tooltip]="l.supplierProduct.name">
              <div style="overflow-x: hidden;">
                <div class="text text-ellipsis mt-auto mb-auto text-300 no-wrap"
                  style="max-width: 480px; overflow: hidden; font-size: 15px;"> {{ l.supplierProduct.name }} </div>
                <div class="change-line"> <a
                    (click)="openVariation(getModalId() + l.coreProductId, l, show_variations);">
                    Change </a> </div>
              </div>
              <app-modal [confirmClose]="true" [id]="getModalId() + l.coreProductId" size="large"
                [heading]="'Details for ' + l.supplierProduct.name">
                <app-product-variations [modalId]="getModalId() + l.coreProductId" #show_variations [isListing]="false"
                  [hideRemove]="false" [mainMode]="'update'" [product]="l.coreProduct"
                  [supplierProduct]="l.supplierProduct"
                  [mainImageUrl]="l.supplierProduct?.imageUrl ?? l.coreProduct?.imageUrl"></app-product-variations>
              </app-modal>
            </div>
            <div [class]="'text text-300 rubik text-right quantity mt-auto mb-auto '">
              {{ l.quantity | number }}
            </div>
          </div>
        </ng-template>
      </div>
    </div>

    <div class="cart-disabled" *ngIf="hasPendingApproval">
      The cart is disabled while a requisition is pending approval.
    </div>

    <div class="spacer" *ngIf="!hasPendingApproval"></div>
    <div *ngIf="!hasPendingApproval" class="cart-footer flex-column no-wrap" [ngClass]="{'hidden': !cart?.lines?.length }">
      <div class="flex-column">
        <div class="text rubik flex-row justify-end">
          <app-product-line-total label="Items in cart:" [total]="cart?.lines?.length" [bold]="false"
            [isCurrency]="false" spacing="ml-small"></app-product-line-total>
        </div>
        <div class="text rubik flex-row justify-end mb-medium mt-medium">
          <app-product-line-total label="Estimated Total:" [total]="total" [bold]="false"
            spacing="ml-small"></app-product-line-total>
        </div>
      </div>

      <div class="flex-row justify-end" *ngIf="!hasPendingApproval">
        <a id="cart-quick-view-optimize-and-order" class="btn primary text text-center optimize-and-order" style="margin-bottom: 0;" (click)="goToOptimizeAndOrder()">
          Optimize & Order
        </a>
      </div>
    </div>
  </div>
</div>