// referenced in https://javascript.plainenglish.io/creating-a-tooltip-directive-in-angular-abfc607d52f3
import { Directive, ElementRef, HostListener, Input, OnDestroy } from "@angular/core";

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective implements OnDestroy {

  @Input() tooltip: string | undefined = undefined;
  @Input() delay?= 100;
  @Input() lifetime = 15000;

  private myPopup: any;
  private timer: any;

  constructor(private el: ElementRef) { }

  ngOnDestroy(): void {
    if (this.myPopup) {
      this.myPopup.remove();
    }
  }

  @HostListener('mouseenter') onMouseEnter() {
    if (this.tooltip?.length) {
      this.timer = setTimeout(() => {
        const ele = this.el.nativeElement;
        let x = ele.getBoundingClientRect().left + ele.offsetWidth / 2; // Get the middle of the element
        let y = ele.getBoundingClientRect().top - ele.offsetHeight - ele.offsetHeight; // Get the bottom of the element, plus a little extra
        this.createTooltipPopup(x, y);
      }, this.delay);
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.tooltip?.length) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      if (this.myPopup) {
        this.myPopup.remove();
      }
    }
  }

  private createTooltipPopup(x: number, y: number) {
    if (this.tooltip?.length) {
      const popup = document.createElement('div');
      popup.innerHTML = this.tooltip;
      popup.setAttribute("class", "tooltip-container");
      popup.style.top = y.toString() + "px";
      popup.style.left = x.toString() + "px";
      document.body.appendChild(popup);
      this.myPopup = popup;
      setTimeout(() => {
        if (this.myPopup) {
          this.myPopup.remove();
        }
      }, this.lifetime);
    }
  }

}