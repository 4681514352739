import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'split'
})
export class SplitPipe implements PipeTransform {

  transform(text: string | undefined, by: string, index: number = 1) {
    if (text) {
      const arr = text.split(by);
      return arr.length > index ? arr[index] : '';
    } else {
      return '';
    }
  }

}
