import { environment } from '../../../environments/environment';

/**
 * Base API configuration file
 */
export class AgentApiConfig {
    static path: string = environment.api.agent.path;
    static version: string | number | null = environment.api.agent.version;

    public static setApiVersion(version: string = 'api'): void {
        AgentApiConfig.version = version;
    }

    public static getApiVersion(): string | number | null {
        return AgentApiConfig.version;
    }

    public static getPath(): string {
        return AgentApiConfig.path;
    }

    public static getFullUrl(path: string): string {
        if (AgentApiConfig.getApiVersion()) {
            return [AgentApiConfig.getPath(), AgentApiConfig.getApiVersion(), path].join('/');
        } else {
            return [AgentApiConfig.getPath(), path].join('/');
        }
    }
}
