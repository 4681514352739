import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { EMPTY, NEVER, Observable, timer } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';
import { LoginService } from '../shared/services';

export const authRetryCount = 3;
export const retryWaitMilliSeconds = 1000;
export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private loginService: LoginService) {

    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        if (req.headers.has(InterceptorSkipHeader)) {
            const headers = req.headers.delete(InterceptorSkipHeader);
            return next.handle(req.clone({ headers }));
        }

        const token = this.loginService.getToken();

        if (token) {
            return next.handle(this.addAuthToken(req, token))
                .pipe(
                    map((event: HttpEvent<any>) => {
                        return event;
                    }),
                );
        } else if (!this.loginService.tokenInitialized) {
            return timer(1000).pipe(
                delay(1000),
                switchMap(() => this.loginService.getToken() ?
                    next.handle(this.addAuthToken(req, this.loginService.getToken())).pipe(
                        map((event: HttpEvent<any>) => {
                            return event;
                        }),
                    ) :
                    NEVER));
            // return NEVER;     // never doesn't invoke the sub/callback, basically aborts call
        } else {
            return NEVER;
        }
    }

    private addAuthToken(request: HttpRequest<any>, token: string | null): HttpRequest<any> {
        // const token = this.loginService.getToken();

        if (token) {
            request = request.clone({
                headers: request.headers.set('Authorization', `bearer ${token}`),
            });
        }

        return request;
    }
}
