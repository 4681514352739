import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DxButtonGroupComponent, DxFormComponent, DxListComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { AgentService, CatalogLoadOptions, LoginService, MonitorService } from 'src/app/shared/services';
import { GenericComponent } from 'src/app/shared/utils/generic.component';
import { CatalogApiConfig } from 'src/app/shared/api';
import { ActivatedRoute } from '@angular/router';
import { ProductSearchComponent } from '../product-search/product-search.component';

@Component({
  selector: 'app-catalog-search',
  templateUrl: './catalog-search.component.html',
  styleUrls: ['./catalog-search.component.scss']
})
export class CatalogSearchComponent extends GenericComponent implements OnInit {
  submitMessage: string = 'Search';

  @ViewChild(DxListComponent, { static: false })
  list: DxListComponent | undefined;

  @ViewChild('all', { static: false })
  allComponent: ProductSearchComponent | undefined;

  products: any[] = [];
  dataSource: any;

  @ViewChild('rec', { static: false })
  recommendedComponent: ProductSearchComponent | undefined;

  @ViewChild('recent', { static: false })
  recentComponent: ProductSearchComponent | undefined;

  @ViewChild('freq', { static: false })
  frequentComponent: ProductSearchComponent | undefined;

  catalogFilterButtons = [
    { text: "Products", value: 'all' },
    { text: "Search Purchase History", value: 'recent' },
    { text: "Search By Supplier", value: 'supplier' }
  ];
  direction: string | undefined;
  selectedFilter = this.catalogFilterButtons[0];
  displayCoreProducts = true;
  tabIndex = 0;

  url: string = CatalogApiConfig.getFullUrl('search');

  constructor(
    private route: ActivatedRoute,
    monitor: MonitorService,
    login: LoginService) {
    super(monitor, login);
    this.direction = "vertical";
  }

  ngOnInit(): void {
    if (this.route.snapshot.fragment?.length) {
      const preselect = this.catalogFilterButtons.find(cfb => cfb.value === this.route.snapshot.fragment);
      if (preselect) {
        this.selectedFilter = preselect;
        this.tabIndex = this.catalogFilterButtons.indexOf(preselect);
      }
    }
  }

  onSelectionChanged(e: any) {
    this.selectedFilter = e.addedItems[0];
    this.displayCoreProducts = this.selectedFilter.value === 'all' || this.selectedFilter.value === 'freq';
    this.allComponent?.search(this.url, this.displayCoreProducts);
  }
  selectTab(e: any): void {
    this.selectedFilter = e.itemData;
  }
}
