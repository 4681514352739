<!-- Header -->
<header>
  <app-generic-nav *ngIf="(!agent?.active || selectedProfile !== profile.Platform) && (!selectedAccount)" (login)="login()" [profile]="selectedProfile" [agent]="agent" [user]="user" [nochromeMode]="nochromeMode" [mobileexpanded]="mobileexpanded"></app-generic-nav>
  <app-account-nav *ngIf="(agent?.active && selectedProfile === profile.Agent) || (agent?.active && selectedProfile === profile.Unauthorized)" [profile]="selectedProfile" [agent]="agent" [account]="selectedAccount" [user]="user" [nochromeMode]="nochromeMode" [mobileexpanded]="mobileexpanded"></app-account-nav>
  <app-gpo-nav *ngIf="agent?.active && selectedProfile === profile.GPO && selectedAccount" [profile]="selectedProfile" [agent]="agent" [user]="user" [nochromeMode]="nochromeMode" [mobileexpanded]="mobileexpanded" [account]="selectedAccount"></app-gpo-nav>
  <app-dso-nav *ngIf="agent?.active && selectedProfile === profile.DSO && selectedAccount" [profile]="selectedProfile" [agent]="agent" [user]="user" [nochromeMode]="nochromeMode" [mobileexpanded]="mobileexpanded" [account]="selectedAccount"></app-dso-nav>
  <app-practice-nav *ngIf="agent?.active && selectedProfile === profile.Practice && selectedAccount" [profile]="selectedProfile" [agent]="agent" [user]="user" [nochromeMode]="nochromeMode" [mobileexpanded]="mobileexpanded" [account]="selectedAccount"></app-practice-nav>
  <app-platform-nav *ngIf="agent?.active && selectedProfile === profile.Platform" [profile]="selectedProfile" [agent]="agent" [user]="user" [nochromeMode]="nochromeMode" [mobileexpanded]="mobileexpanded"></app-platform-nav>
</header>

<main class="content">
  <app-progress [splash]="true" [value]="working" [splashOptions]="{ title: '', message: loadingMessage }">
    <router-outlet></router-outlet>

  </app-progress>

  <app-modal id="reauthenticate" size="small" heading="You need to reauthenticate" [hideClose]="true" [cannotClose]="true">
    <p>
      Your session has timed out, would you like to continue?
    </p>
    <div class="flex-row" style="gap: 8px;">
      <button class="btn gold mt-auto mb-auto ml-auto mr-auto" (click)="reauthenticate()" type="button">Yes</button>
      <button class="btn grey black text text-upper text-400 mt-auto mb-auto ml-auto mr-auto" (click)="logout()" type="button">No</button>  
      <button class="btn pink mt-auto mb-auto ml-auto mr-auto" (click)="changeLogin()" type="button">Log in as a different user</button>
    </div>
  </app-modal>
</main>

<footer>
  <div style="margin: 10px 0;">
    <div style="float: left;">
      <div class="text">Copyright © 2023-{{ copyright | date:'yyyy' }} Sherpa Dental</div>
    </div>

    <div class="links" style="text-align: center; float: right;">
      <ul style="list-style-type: none; padding-inline-start: unset; margin: 0;">
        <li style="display: inline-block; padding-inline: 8px;">
          <a class="text mt-auto mb-auto" target="_blank" [href]="'assets/documents/Privacy-Policy.pdf'">Privacy Policy</a>
        </li>
        <li style="display: inline-block; padding-inline: 8px;">
          <a class="text ml mt-auto mb-auto" target="_blank" [href]="'assets/documents/Terms-of-Use.pdf'">Terms of Use</a>
        </li>
        <li style="display: inline-block; padding-inline: 8px;">
          <a class="text ml mt-auto mb-auto" href="mailto:support@sherpadental.com">Email Support</a>
        </li>
      </ul>
    </div>
    
    <div style="float: left; margin-left: 10px;">
      <a style="color: #aaa;" *ngIf="agent?.agency?.platformAgency && featureService.check(feature.AgencyUpdate, agent?.features ?? feature.None); else noAccess" (click)="toReleaseNotes()">v{{ version }} </a>
    </div>

    <ng-template #noAccess>
      {{version}}

    </ng-template>
  </div>
</footer>
