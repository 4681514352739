import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss']
})
export class MenuBarComponent {

  @Input()
  isArrow = false;
  
  @Input()
  expanded = false;

  @Input()
  color: 'black' | 'pink' | undefined = undefined;

  @Output()
  toggle = new EventEmitter<boolean>();
  
  switch(): void {
    this.expanded = !this.expanded;
    this.toggle.next(this.expanded);
  }

}
