import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { IAccount } from 'src/app/shared/models';
import { MonitorService, NavigationService } from 'src/app/shared/services';
import { AccountService } from 'src/app/shared/services/account.service';
import { Feature } from 'src/app/shared/services/feature.service';

@Component({
  selector: 'app-account-edit-suppliers',
  templateUrl: './account-edit-suppliers.component.html',
  styleUrls: ['./account-edit-suppliers.component.scss']
})
export class AccountEditSuppliersComponent {

  @ViewChild(DxDataGridComponent)
  dataGrid!: DxDataGridComponent;

  @Input()
  account: IAccount | undefined;
  working = 0; // Set to true when waiting for async queries to complete. Prevents bad requests
  
  feature = Feature;

  dataSource: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService,
    private monitor: MonitorService,
    private navigationService: NavigationService) 
  {
    this.working = 10;
    this.activatedRoute.parent?.paramMap.subscribe( async (params) => {
      let accountId = parseInt(params.get('accountId') ?? '0');
      this.account = await this.accountService.getAccountById(accountId, { parent: true, suppliers: true });
      this.working = 50;
      try{
        this.dataSource = new CustomStore({
          loadMode: 'raw',
          load: () => {
            return this.account?.accountSuppliers ?? [];
          },
        });
      } catch (error) {
        this.monitor.handleError(error, 'Error loading agents');
      } finally {
        this.working = 0;
      }
    });
  }

  onContentReady(e: any) {
    setTimeout(() => {
      const rows = e.component.getVisibleRows();
      rows.forEach((row: any) => {
        const rowKey = e.component.getKeyByRowIndex(row.rowIndex);
        const rowData = row.data;
        const rowIndex = e.component.getRowIndexByKey(rowKey);
        const rowElement = e.component.getRowElement(rowIndex);
        if (!rowData.active) {
          rowElement[0].firstElementChild.classList.add('inactive');
        }
      });
    }, 0);
  }

   // Navigate to agency page of selected agency
   onCellClick(event: any) {
    if (event.column.dataField === 'supplierName') {
      const accountSupplierId = event.data?.id;
      this.navigationService.backoffice().account.suppliers.edit(this.account?.id ?? 0, accountSupplierId);
    }
  }

  newSupplier(){
    this.navigationService.backoffice().account.suppliers.new(this.account?.id ?? 0);
  }
}
