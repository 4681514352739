<app-progress [splash]="true" [value]="working">

</app-progress>

<div class="flex-column prod-var-column">
  <div class="flex-row no-wrap header">
    <div id="product-image" class="flex-row justify-center align-center">
      <img class="image" [src]="mainImageUrl ?? '/assets/images/no-product-image.jpeg'" />
    </div>
    <div class="card flex-column">
      <div *ngIf="isListing" class="text text-primary no-wrap text-ellipsis listing-name">
        {{ product?.listing?.name ?? product?.name }}
      </div>
      <div *ngIf="!isListing" class="text text-primary no-wrap text-ellipsis listing-name">
        {{ product?.name ?? product?.name }}
      </div>

      <div class="manufacturer flex-row mb-small" *ngIf="product?.manufacturer">
        <label class="text mr-auto manufacturer-name">
          {{ product?.manufacturer?.name }}
        </label>
        <label class="text text-300 ml-auto" *ngIf="isInCart">
          #{{ product?.sku }}
        </label>
      </div>
      <div *ngIf="mainMode === 'update'; else coreTags" class="flex-row">
        <app-product-tag *ngFor="let t of supplierProduct?.supplierProductTags" [color]="t.c" [background]="t.b"
          [text]="t.t"></app-product-tag>
        <app-recommended-tag [show]="supplierProduct?.recommendedProduct === true" floatDirection="variations" class="mr-small"></app-recommended-tag>
        <app-out-of-stock-tag [show]="supplierProduct?.stockQuantity === 0" floatDirection="variations"></app-out-of-stock-tag>
      </div>
      <ng-template #coreTags>
        <div *ngIf="isListing " class="flex-row">
          <app-product-tag *ngFor="let t of product?.listingTags" [color]="t.c" [background]="t.b" [text]="t.t"></app-product-tag>
        </div>
        <div *ngIf="!isListing" class="flex-row" style="position: relative;">
          <app-product-tag *ngFor="let t of product?.coreProductTags" [color]="t.c" [background]="t.b" [text]="t.t"></app-product-tag>
          <app-recommended-tag [show]="supplierProduct?.recommendedProduct === true" floatDirection="variations" class="mr-small"></app-recommended-tag>
          <app-out-of-stock-tag [show]="supplierProduct?.stockQuantity === 0" floatDirection="variations"></app-out-of-stock-tag>
        </div>
      </ng-template>



      <div class="spacer"></div>
      <div class="in_cart flex-row mb-small" [ngClass]="{ 'hidden': isListing }">
        <app-add-to-cart [id]="getATCId()" [allowVariations]="false" [coreProduct]="product!"
          [supplierProduct]="supplierProduct" [mode]="mainMode" layout="wrap" [hideRemove]="hideRemove"
          [quantity]="quantity" [showCurrentQuantity]="mainMode === 'update'">
        </app-add-to-cart>
      </div>
      <div class="spacer" *ngIf="!isListing"></div>
      <div class="text no-wrap text-ellipsis mb-small">
        <label *ngIf="mainMode !== 'update'" class="text p-8 mr-auto">
          {{ supplierProduct?.price ?? product?.price }}
        </label>
        <label *ngIf="mainMode === 'update'" class="text p-8 mr-auto">
          {{ supplierProduct?.price ?? product?.price }}
        </label>
      </div>
      <div class="text no-wrap text-ellipsis">
        <label *ngIf="!(supplierProduct?.lastPurchaseDate ?? product?.lastPurchaseDate)" class="text p-8 mr-auto">
          &nbsp;
        </label>
        <label *ngIf="supplierProduct?.lastPurchaseDate ?? product?.lastPurchaseDate" class="text p-8 mr-auto">
          Last Purchased {{ supplierProduct?.lastPurchaseQuantity ?? product?.lastPurchaseQuantity | number }} on {{
          supplierProduct?.lastPurchaseDate ?? product?.lastPurchaseDate | date }}
        </label>
      </div>
    </div>
  </div>
  <div class="text text-300 text-black no-wrap text-ellipsis p-8 mb-large" style="margin-top: 15px;"
    *ngIf="mainMode === 'add' && !isListing && product?.listing?.productCount! > 1">
    <label>
      This product has variations, ensure the correct one was added:
    </label>
  </div>
  <div style="overflow-y: auto;">
    <ng-template ngFor [ngForOf]="productListing" let-l let-i="index">
      <div *ngIf="i === 0" class="spacer"></div>
        <div class="flex-column pb-small">
          <div class="mb-small mt-small" style="display: flex;">
            <app-recommended-tag *ngIf="l?.recommendedProduct === true" [show]="true" floatDirection="variations" style="padding-right: 5px;"></app-recommended-tag>
            <app-out-of-stock-tag [show]="supplierProduct?.stockQuantity === 0" floatDirection="variations"></app-out-of-stock-tag>
          </div>
          <div class="flex-row">
            <div class="flex-column">
              <label class="text text-primary no-wrap text-ellipsis mr-auto mt-small" style="overflow: hidden; max-width: 544px;">
                {{ l.name }}
              </label>
              <label class="manufacturer text text-300 no-wrap text-ellipsis mr-auto">
                <span class="sku">#{{ l.sku }}</span> - <span class="name">{{ product?.manufacturer?.name}}</span>
              </label>
            </div>
            <div class="ml-auto mt-small" style="padding-right: 15px;">
              {{ l.price }}
            </div>
    
            <div *ngIf="l?.canPurchase === true; else cannotPurchase">
              <app-add-to-cart id="getATCId() + 1" [mode]="'add'" [supplierProduct]="" [coreProduct]="l" [allowVariations]="false"
              (added)="addAll($event)" (loading)="loading($event)" [hideRemove]="true"></app-add-to-cart>
            </div>
            <ng-template #cannotPurchase>
              This product is not enabled for purchase.
            </ng-template>
          </div>


          <div class="flex-row mt-small">
            <app-product-tag *ngFor="let t of l?.coreProductTags" [color]="t.c" [background]="t.b" [text]="t.t">
            </app-product-tag>
          </div>
        </div>
      <div *ngIf="i !== productListing.length - 1" class="spacer"></div>
    </ng-template>
  </div>
</div>


<app-modal class="confirm-modal" [id]="confirmationModalId" [size]="'medium'" [heading]="'Close without saving?'">
  <div>

    <div class="flex-row justify-even">
      <button class="btn text text-upper secondary border-secondary-accent exit_buttons mt-auto mb-auto"
        (click)="close($event, confirmationModalId)">
        Yes
      </button>
      <button class="btn text text-upper secondary border-secondary-accent exit_buttons"
        (click)="addLines($event, confirmationModalId)">
        No
      </button>
    </div>

  </div>
</app-modal>
