import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IAccount } from 'src/app/shared/models';
import { AccountService } from 'src/app/shared/services/account.service';
import CustomStore from 'devextreme/data/custom_store';
import { Feature } from 'src/app/shared/services/feature.service';
import { MonitorService, NavigationService } from 'src/app/shared/services';

@Component({
	selector: 'app-account-list-view',
	templateUrl: './account-list-view.component.html',
	styleUrls: ['./account-list-view.component.scss']
})
export class AccountListViewComponent implements OnInit
{
	@Input()
	rowClass: ((rowData: any) => string) | undefined;

	accounts: IAccount[] = [];
	dataSource: any;
	feature = Feature;

	working = 0;

	constructor(
		private accountService: AccountService,
		private monitor: MonitorService,
		private navigationService: NavigationService
	)
	{
	}

	onContentReady(e: any)
	{
		setTimeout(() =>
		{
			const rows = e.component.getVisibleRows();
			rows.forEach((row: any) =>
			{
				const rowKey = e.component.getKeyByRowIndex(row.rowIndex);
				const rowData = row.data;
				const rowIndex = e.component.getRowIndexByKey(rowKey);
				const rowElement = e.component.getRowElement(rowIndex);
				if (!rowData.active)
				{
					rowElement[0].firstElementChild.classList.add('inactive');
				}
			});
		}, 0);
	}

	onCellClick(event: any)
	{
		if (event.column.dataField === 'name' && event.event.target.classList.contains('dx-treelist-text-content'))
		{
			const accountId = event.data?.id;
			this.navigationService.backoffice().account.details(accountId);
		}
	}

	async ngOnInit()
	{
		try
		{
			this.working = 50;
			this.accounts = await this.accountService.get({});
			this.dataSource = new CustomStore({
				loadMode: 'raw',
				load: () =>
				{
					return this.accounts;
				},
			});
		}
		catch (error: any)
		{
			this.monitor.handleError(error, error?.error?.message ?? error.message);
		}
		finally
		{
			this.working = 0;
		}
	}

	newAccount() {
		this.navigationService.backoffice().account.new();
	}
}