import { AuditInfo } from "./audit.info.model";
import { FormularyProduct } from "./product.formulary.model";

export class Formulary extends AuditInfo {
    public name?: string;
    public active?: boolean;
    public description?: string;
    public agencyId!: number;
    public formularyType!: FormularyType; 
    public products?: FormularyProduct[];
}

export class FormularyType {
    public static Agency: string = 'Agency';
    public static Public: string = 'Public';
    public static Custom: string = 'Custom';
}
