import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '@auth0/auth0-angular';
import { Subscription } from 'rxjs';
import { Agent, IAccount, Profile } from 'src/app/shared/models';
import { AgentService, NavigationService } from 'src/app/shared/services';
import { FeatureService } from 'src/app/shared/services/feature.service';
import { DropdownContent } from 'src/app/shared/user-type';

@Component({
  selector: 'app-account-nav',
  templateUrl: './account-nav.component.html',
  styleUrls: ['./account-nav.component.scss']
})
export class AccountNavComponent implements OnDestroy {
  
  @Input()
  agent: Agent | undefined = undefined;

  @Input()
  user: User | undefined;

  @Input()
  nochromeMode = false;

  @Input()
  mobileexpanded = false;

  @Input()
  account: IAccount | undefined;

  @Input()
  profile: Profile | undefined;

  @Output()
  profileChange: EventEmitter<number> = new EventEmitter<number>();

  private subscriptions: Subscription[] = [];
  dropdownContent: DropdownContent[] = [];
  profileSelector = Profile;
  
  constructor(
    private agentService: AgentService,
    private features: FeatureService,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute
  ) {
    this.subscriptions.push(
      this.agentService.authenticated$.subscribe(async a => {
        this.agent = a;
        this.dropdownContent = [];
        this.dropdownContent = this.dropdownContent.filter(dc => !dc.claims.length || dc.claims.filter(c => features.check(a.features, c)).length === dc.claims.length);
      }));

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  toggleMenu(value: boolean): void {
    this.mobileexpanded = value;
  }
  
  toDashboard() {
    this.navigationService.practice(this.agentService.currentAccount!.id).dashboard();
  }
}
