import { ErrorHandler, Injectable } from "@angular/core";
import { MonitorService } from "./monitor.service";

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
    constructor (private monitor: MonitorService) {
        super();
    }

    handleError(error: any): void {
        this.monitor.logException(error);
    }
}