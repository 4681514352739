<div class="standard-background">
  <app-progress [splash]="true" [value]="working" [splashOptions]="{ title: workingTitle, message: workingMessage }">

    <div class="flex-row justify-even ml-auto mr-auto">
      <div class="cart-card entry">
        <div class="header flex-row justify-between no-wrap">
          <h3>Purchase Order Details</h3>
        </div>
        <div class="body">
          <div class="background flex-row justify-between">
            <div class="mr-large">
              <label class="text text-500 rubik mb-small">PO #</label>
              <div class="text text-300 mb-medium">
                {{ purchaseOrder?.purchaseOrderNumber }}
              </div>
              <label class="text text-500 rubik mb-small">Order Status</label>
              <div class="text text-300 mb-medium">
                {{ purchaseOrder?.purchaseOrderStatus }}
              </div>
              <label class="text text-500 rubik mb-small">Submission Date</label>
              <div class="text text-300 mb-medium">
                {{ purchaseOrder?.purchaseOrderSubmissionDate | date}}
              </div>
            </div>
            <div class="mr-large">
              <label class="text text-500 rubik mb-small">Requisition #</label>
              <div class="text text-300 mb-medium">
                {{ purchaseOrder?.requisitionNumber }}
              </div>
              <label class="text text-500 rubik mb-small">Approved Date</label>
              <div class="text text-300 mb-medium">
                {{ purchaseOrder?.requisitionApprovalDate | date }}
              </div>
              <label class="text text-500 rubik mb-small">Approved By</label>
              <div class="text text-300 mb-medium">
                {{ purchaseOrder?.requisitionApprovedByName }}
              </div>
            </div>
            <div class="flex-column locations">
              <label class="text text-500 rubik">
                Billing Address
              </label>
              <div class="text text-300 rubik" *ngIf="purchaseOrder?.billingAddress!.contactName">
                {{ purchaseOrder?.billingAddress!.contactName }}
              </div>
              <div class="text text-300">
                {{ purchaseOrder?.billingAddress!.addressLine1 }}
              </div>
              <div class="text text-300" *ngIf="purchaseOrder?.billingAddress!.addressLine2">
                {{ purchaseOrder?.billingAddress!.addressLine2 }}
              </div>
              <div class="text text-300">
                {{ purchaseOrder?.billingAddress!.city }}
                {{ purchaseOrder?.billingAddress!.state }}
                {{ purchaseOrder?.billingAddress!.zip }}
              </div>
              <div class="text text-300" *ngIf="purchaseOrder?.billingAddress!.phoneNumber">
                {{ numberFormat.formatPhoneNumber(purchaseOrder?.billingAddress!.phoneNumber) }}
              </div>
              <div class="text text-300">
                {{ purchaseOrder?.billingAddress!.email }}
              </div>
            </div>
            <div class="flex-column locations">
              <label class="text text-500 rubik mb-small ">
                Shipping Address
              </label>
              <div class="text text-300 rubik" *ngIf="purchaseOrder?.shippingAddress?.contactName ">
                {{ purchaseOrder!.shippingAddress!.contactName }}
              </div>
              <div class="text text-300">
                {{ purchaseOrder!.shippingAddress!.addressLine1 }}
              </div>
              <div class="text text-300" *ngIf="purchaseOrder?.shippingAddress?.addressLine2">
                {{ purchaseOrder!.shippingAddress!.addressLine2 }}
              </div>
              <div class="text text-300">
                {{ purchaseOrder!.shippingAddress!.city }}
                {{ purchaseOrder!.shippingAddress!.state }}
                {{ purchaseOrder!.shippingAddress!.zip }}
              </div>
            </div>
            <a class="btn grey text text-500 kreon text-center ml-medium" style="height: 0.5rem;"
              (click)="backToList()">
              Return to List
            </a>


          </div>
          <div class="order-review">
            <div *ngIf="orderMessage?.length" class="spacer mb-medium"></div>
            <div class="text text-800 kreon" *ngIf="orderMessage?.length">
              Order System Message(s)
              <div class="text kreon mt-small" *ngFor="let m of orderMessage" [innerHTML]="m"></div>
            </div>

            <!-- <ng-template ngFor [ngForOf]="purchaseOrders | sort:'supplier.name'" let-o> -->

            <div class="mb-large">
              <div class="spacer"></div>
            </div>
            <div>
              <div class="round-wrapper">
                <div class="line" [ngClass]="{'mb-large':  i + 1 < purchaseOrder!.submissions!.length}"
                  *ngFor="let s of purchaseOrder?.submissions | sort:'supplierName'; let i = index">
                  <div class="flex-row line-title-row">
                    <h4 class="text text-500 mr-small">{{ getSupplierName(s.submissionSupplierId) }}</h4>
                    <span class="text text-300 rubik mr-small mt-auto mb-auto">({{ s.purchaseOrderLines.length }}
                      lines)</span>
                    <app-menu-bar [expanded]="isCollapsed(s.submissionSupplierId)" [isArrow]="true"
                      (click)="toggleCollapse(s.submissionSupplierId)"></app-menu-bar>
                    <!-- <button class="btn secondary" (click)="toggleCollapse(l.supplierProduct.supplier.id)">{{ isCollapsed(l.supplierProduct.supplier.id) ? 'Expand' : 'Collapse' }}</button> -->
                    <div id="submission-date" class="ml-auto text text-kreon text-500 flex-column po-total"
                      *ngIf="getSubmission(s.submissionSupplierId)" style="white-space: nowrap;">
                      <span class="text text-kreon text-400" style="white-space: nowrap;">
                        {{ s.submissionStatus }} on {{ s.submissionStatusLastModified | date }}
                        <app-product-line-total [label]="' - '" [total]="s.submissionTotal" [bold]="false"></app-product-line-total>
                    </span>
                    
                </div>

                  </div>

                  <div class="line submission-details round-wrapper"
                    [ngClass]="{ 'collapsed': isCollapsed(s.submissionSupplierId), 'mb-medium': !isCollapsed(s.submissionSupplierId) }">
                    <div [ngClass]="{ 'collapsed': isCollapsed(s.submissionSupplierId)}">
                      <!-- Submission Header -->
                      <div *ngIf="getSubmission(s.submissionSupplierId)?.supplierSalesOrderNumber"><span
                          class="text text-500 rubik">Order Number:</span>
                        {{getSubmission(s.submissionSupplierId)?.supplierSalesOrderNumber}}</div>
                      <div><span class="text text-500 rubik">Submission Status:
                        </span>{{s?.submissionStatus}} </div>
                      <div><span class="text text-500 rubik">Send Date: </span>{{
                        s.submissionSendDate | date }}</div>
                      <div><span class="text text-500 rubik">Last Updated: </span>{{
                        s.submissionStatusLastModified | date }}</div>
                    </div>

                    <nav class="order-tabs flex-row" [ngClass]="{ 'collapsed': isCollapsed(s.submissionSupplierId)}">
                      <app-access
                        *ngIf="featureService.check(this.feature.AccountRead, agent?.features ?? feature.None)"
                        [ngClass]="{'disabled' : !account!.id, 'active': currentTabs.includes('Products' + s.submissionId)}"
                        [platformFeature]="feature.AgencyRead" [displayIfNoAccess]="false" class="tab">
                        <a routerLinkActive="active" class="drop-down width-medium height-medium text rubik black"
                          (click)="selectTab('Products' + s.submissionId, s.submissionId)">Products ({{s.purchaseOrderLines.length}})
                        </a>
                      </app-access>
                      <app-access
                        *ngIf="this.featureService.check(this.feature.AccountRead, this.agent?.features ?? this.feature.None)"
                        [ngClass]="{'disabled': !account!.id, 'active': currentTabs.includes('Shipments' + s.submissionId), 'hidden': !s.shipments.length}"
                        [platformFeature]="feature.AgencyRead" [displayIfNoAccess]="false" class="tab">
                        <a routerLinkActive="active" class="drop-down width-medium height-medium text rubik black"
                          (click)="selectTab('Shipments' + s.submissionId, s.submissionId)">Shipments ({{s.shipments.length}})
                        </a>
                      </app-access>
                      <app-access
                        *ngIf="this.featureService.check(this.feature.AccountRead, this.agent?.features ?? this.feature.None)"
                        [ngClass]="{'disabled': !account!.id, 'active': currentTabs.includes('Invoices' + s.submissionId), 'hidden': !s.invoices.length}"
                        [platformFeature]="feature.AgencyRead" [displayIfNoAccess]="false" class="tab">
                        <a routerLinkActive="active" class="drop-down width-medium height-medium text rubik black"
                          (click)="selectTab('Invoices' + s.submissionId, s.submissionId)">Invoices ({{s.invoices.length}})
                        </a>
                      </app-access>
                      <app-access
                        *ngIf="this.agent?.agency?.platformAgency && this.featureService.check(this.feature.AgencyRead, this.agent?.features ?? this.feature.None)"
                        [ngClass]="{'disabled': !account!.id, 'active': currentTabs.includes('Admin' + s.submissionId)}"
                        [platformFeature]="feature.AgencyRead" [displayIfNoAccess]="false" class="tab">
                        <a routerLinkActive="active" class="drop-down width-medium height-medium text rubik black"
                          (click)="selectTab('Admin' + s.submissionId, s.submissionId)">Admin
                        </a>
                      </app-access>
                    </nav>
                    <div [id]="'products' + s.submissionId" [ngClass]="{ 'hidden': !currentTabs.includes('Products' + s.submissionId)}">
                      <div *ngFor="let l of s.purchaseOrderLines; let i = index">
                        <div class="fill fill-white recommendation used line-wrapper round-wrapper order"
                          [ngClass]="{ 'collapsed': isCollapsed(s.submissionSupplierId), 'mb-large': i + 1 < s.purchaseOrderLines.length}">
                          <div class="flex-row">
                            <img class="mr-medium product-image"
                              [src]="l.supplierProduct?.supplierProductImageUrl ?? 'assets/images/badges/badge.png'" />
                            <div class="flex-column mr-medium">
                              <div class="flex-row no-wrap" style="height: 19px;">
                                <div class="text product-name" [tooltip]="l.supplierProduct?.supplierProductName">
                                  {{ l.supplierProduct?.supplierProductName }}
                                </div>
                              </div>
                              <div class="flex-row no-wrap">
                                <div class="flex-column" style="width: 620px;">
                                  <div class="flex-row no-wrap">
                                    <div class="text text-300 rubik manufacturer-details">
                                      #{{ l.supplierProduct?.coreProductSku }} - {{
                                      l.supplierProduct?.manufacturerName }}
                                    </div>
                                    <div *ngIf="(l.supplierProduct?.supplierProductTags?.length ?? 0)"
                                      class="flex-row no-wrap ml-small" style="height: 23px; position: relative;">
                                      <app-product-tag *ngFor="let t of l.supplierProduct?.supplierProductTags" 
                                        [inline]="true" style="position: relative; top: 3px;"
                                        [color]="t.c" [background]="t.b" [text]="t.t"></app-product-tag>
                                    </div>

                                  </div>
                                  <div class="supplier-details text text-300 price">
                                    <span>{{ (l.purchaseOrderLineItemPrice | currency) ?? '$--'}}</span>
                                  </div>
                                  <div class="supplier-details text text-300">
                                    Quantity: {{l.purchaseOrderLineQuantity | number}}
                                  </div>
                                  <!-- changes below in backorder tag, variables displayed and the tooltip on the p tag -->
                                  <div id="tags" class="flex-row">
                                    <div [ngClass]="{ 'hidden': l.purchaseOrderLineBackorderQuantity === null}" class="mr-medium">
                                      <div class=" round-wrapper line-tag text-rubik text text-300 black backorder"
                                        [attr.data-content]="l.purchaseOrderLineBackorderQuantity">
                                        Backorder
                                      </div>
                                      <div class="eta text text-300" [tooltip]="'Estimated Ship Date'">
                                        <span>{{(l.estimatedShipDate | date)}}</span>
                                      </div>
                                    </div>

                                    <div class="tag text text-300 mr-medium"
                                      [ngClass]="{ 'hidden': l.purchaseOrderLineShippedQuantity === null}">
                                      <a routerLinkActive="active"
                                        class=" round-wrapper line-tag text-rubik text text-300 black backorder"
                                        [attr.data-content]="l.purchaseOrderLineShippedQuantity"
                                        (click)="selectTab('Shipments' + s.submissionId, s.submissionId, l.supplierProduct?.supplierProductSku)">
                                        <div [ngClass]="{ 'hidden': l.purchaseOrderLineBackorderQuantity > 0}">Shipped</div>
                                        <div [ngClass]="{ 'hidden': l.purchaseOrderLineBackorderQuantity === null}">Partially Shipped</div>
                                      </a>
                                    </div>
                                    <div class="tag text text-300" [ngClass]="{ 'hidden': l.purchaseOrderLineInvoicedQuantity === null}">
                                      <a routerLinkActive="active"
                                        class="round-wrapper line-tag text-rubik text text-300 black backorder"
                                        [attr.data-content]="l.purchaseOrderLineInvoicedQuantity"
                                        (click)="selectTab('Invoices' + s.submissionId, s.submissionId, l.supplierProduct?.supplierProductSku)">
                                        <div [ngClass]="{ 'hidden': l.purchaseOrderLineBackorderQuantity > 0}">Invoiced</div>
                                        <div [ngClass]="{ 'hidden': l.purchaseOrderLineBackorderQuantity === null}">Partially Invoiced</div>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="supplier-area">
                              <div class="header" style="display: none;">
                                {{ s.supplierName }}
                              </div>
                              <div style="width: 100px; max-height: 60px;">
                                <img style="width: 100px" [src]="s.submissionSupplierImageUrl" />
                              </div>
                              <div class="sku">
                                #{{ l.supplierProduct?.supplierProductSku }}
                              </div>
                            </div>
                            <div class="line-price">
                              <app-product-line-total [label]="'Line Total:'" [total]="l.purchaseOrderLineLineTotal"></app-product-line-total>
                            </div>
                          </div>

                        </div>
                        <div class="line submission-details round-wrapper"
                    [ngClass]="{ 'collapsed': isCollapsed(s.submissionSupplierId), 'hidden':  i < s.purchaseOrderLines.length - 1 }"
                    class="fill fill-white used round-wrapper supplier-bottom-total line-wrapper flex-row">
                        <app-product-line-total [label]="'Estimated ' + getSupplierName(s.submissionSupplierId) + ' Total:'" [total]="s.submissionTotal" [bold]="true"></app-product-line-total>
                    </div>
                      </div>

                    </div>

                    <div [id]="'shipments' + s.submissionId" [ngClass]="{ 'hidden': !currentTabs .includes('Shipments' + s.submissionId)}">
                      <div>
                        <div>
                          <dx-data-grid [showBorders]="true" [showColumnLines]="true" [dataSource]="s.shipments"
                            [wordWrapEnabled]="false" (onCellClick)="onCellClick($event)">
                            <dxo-paging [pageSize]="5" [pageIndex]="0"></dxo-paging>
                            <dxo-header-filter [visible]="true"></dxo-header-filter>
                            <dxo-filter-row [visible]="true" [applyFilter]="'auto'"></dxo-filter-row>
                            <dxo-header-filter [visible]="true"></dxo-header-filter>
                            <dxo-filter-row [visible]="true" [applyFilter]="'auto'"></dxo-filter-row>

                            <dxi-column dataField="trackingNumber" dataType="string" [alignment]="'left'"
                              caption="Tracking Number" [width]="200"></dxi-column>
                            <dxi-column dataField="shipDate" dataType="date" [alignment]="'left'"
                              caption="Shipment Date" [width]="200"></dxi-column>
                            <dxi-column [calculateDisplayValue]="formatShipmentLine" caption="Products" dataType="string"></dxi-column>
                          </dx-data-grid>
                        </div>
                      </div>
                    </div>

                    <div [id]="'invoices' + s.submissionId" [ngClass]="{ 'hidden': !currentTabs.includes('Invoices' + s.submissionId)}">
                      <div>
                        <div>
                            <dx-data-grid [showBorders]="true" [showColumnLines]="true" [dataSource]="s.invoices"
                                          class="invoice" [wordWrapEnabled]="false" (onCellClick)="onCellClick($event)">
                                <dxo-paging [pageSize]="5" [pageIndex]="0"></dxo-paging>
                                <dxo-header-filter [visible]="true"></dxo-header-filter>
                                <dxo-filter-row [visible]="true" [applyFilter]="'auto'"></dxo-filter-row>
                                <dxo-header-filter [visible]="true"></dxo-header-filter>
                                <dxo-filter-row [visible]="true" [applyFilter]="'auto'"></dxo-filter-row>

                                <dxi-column dataField="invoiceNumber" dataType="string" [alignment]="'left'"
                                            caption="Invoice Number" [width]="200"></dxi-column>
                                <dxi-column dataField="invoiceDate" dataType="date" [alignment]="'left'"
                                            caption="Invoice Date" [width]="200"></dxi-column>
                                <dxi-column [calculateDisplayValue]="formatInvoiceLine" caption="Products" dataType="string" [width]="400"></dxi-column>
                            <dxi-column dataField="invoiceTotal" dataType="string" [alignment]="'left'" format="currency"
                              caption="Total"></dxi-column>
                            </dx-data-grid>
                        </div>
                      </div>
                    </div>

                    <div [id]="'admin' + s.submissionId" [ngClass]="{ 'hidden': !currentTabs.includes('Admin' + s.submissionId)}">
                      <div class="admin-grid mt-large">
                        <div>
                          <label class="text text-500 mt-medium mb-large">Update Status</label>
                          <form #resendHTMLForm>
                            <dx-form (onContentReady)="onContentReady($event, s.submissionId)" #adminForm labelLocation="top" [id]="s.submissionId" #resendForm
                              [showColonAfterLabel]="false" class="mt-large"
                              (onFieldDataChanged)="onFieldDataChanged($event, s.submissionId)">
                              <dxi-item itemType="group" [colCount]="1">
                                <dxi-item editorType="dxSelectBox" id="selectBox"
                                  [editorOptions]="{
                                    dataSource: s.submissionMethod === 'Email' ? emailStatusArray : cxmlStatusArray, 
                                    valueExpr: 'submissionStatus', 
                                    displayExpr: 'displayName', 
                                    placeholder: s.submissionStatus}" 
                                  dataField="status"> 
                                  <dxo-label [visible]="false" template="Update Status"></dxo-label>
                                </dxi-item>
                              </dxi-item>
                            </dx-form>
                            <dx-button [id]="'saveButton-' + s.submissionId" class="btn grey text text-500 text-upper mt-large"
                              (click)="updateStatus(s.submissionId, adminForm)">Save</dx-button>
                          </form>
                        </div>

                        <div class="ml-large" #resendDiv
                          [ngClass]="{ 'hidden': !(isRejected(s) || wasResent(s))}">
                          <label class="text text-500 mt-medium mb-medium"
                            [ngClass]="{'hidden': s.submissionStatus !== 'Rejected' && s.submissionStatus !== 'Error'}">
                            The order failed to send. Do you want to resend it?</label>
                          <label
                            [ngClass]="{'hidden': !hasRejectedAndResentActions(s) }">
                            Order was resent on {{s.submissionModifiedDate | date:'MMMM d, y h:mm a'}}</label>
                          <form [id]="'resendForm' + s.submissionId">
                            <button 
                              [disabled]=" s.submissionStatus === 'Resent'"
                              class="btn grey text text-500 text-upper mt-large"
                              (click)="resendSubmission(s.submissionSupplierId, s.submissionId)">
                              Resend
                            </button>
                          </form>
                          
                        </div>

                        <div class="ml-large">
                          <div class="mb-medium">
                            <label class="text text-500 mt-medium" style="padding-left: 16px;">History</label>

                          </div>
                          <dx-list #catalog [searchEnabled]="false" [dataSource]="s.history" [height]="200"
                            pageLoadMode="scrollBottom" itemTemplate="history">
                            <div *dxTemplate="let l of 'history' ; let i = index" class="mb-medium">
                              <div>
                                <label class="mr-small text text-300"><span class="text text-500">Status:
                                  </span>{{l.purchaseOrderStatus}}</label>
                                <label class="text text-300"><span class="text text-500">Date: </span> {{l.purchaseOrderStatusChangeDate |
                                  date:'M/d/y h:mm a'}}</label>
                                <label [tooltip]="l.historyUser"
                                  class="ml-small text text-300"><span class="text text-500">By:
                                  </span>{{l.historyUser}}</label>
                              </div>
                              <div [ngClass]="{'hidden': !l.historyMessage}">
                                <label class="text text-300 text-wrap"><span class="text text-500">Details:
                                  </span>{{l?.historyMessage}}</label>
                              </div>


                            </div>
                          </dx-list>

                        </div>
                      </div>

                    </div>
                  </div>
                  <div [ngClass]="{'hidden': !isCollapsed(s.submissionSupplierId)}" class="spacer"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-large">
            <div class="order-total flex-column" style="display: grid;">
              <app-product-line-total class="total" label="Estimated Total:" [total]="purchaseOrder?.purchaseOrderTotal" [bold]="true"></app-product-line-total>
            </div>
          </div>
        </div>

      </div>
    </div>
  </app-progress>
</div>
