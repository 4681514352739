import { HostListener, Injectable } from "@angular/core";
import { GenericComponent } from "./generic.component";
import { LoginService, MonitorService } from "../services";

@Injectable()
export abstract class ComponentCanDeactivate extends GenericComponent {

    constructor(monitor: MonitorService, login: LoginService) {
        super(monitor, login);
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        $event.returnValue = true;
    }
}