<app-progress [value]="working" [splash]="true">
</app-progress>
<div class="button-wrapper">
	<dx-button class="button-padding reject" (click)="submit(false)" [disabled]="submitDisabled">Reject</dx-button>
	<dx-button class="button-padding approve" (click)="submit(true)" [disabled]="submitDisabled">Approve</dx-button>
</div>

<app-modal id="rejection-reason" [heading]="modalHeader" size="small" (click)="closeModal('rejection-reason')">
	<div class="confirm-dialog">
		<div class="header">
			<p class="centered-text">{{modalMessage}}</p>
		</div>
		<div class="buttons-container">
			<dx-button class="btn" (click)="closeModal('rejection-reason')">Close</dx-button>
		</div>
	</div>
</app-modal>
