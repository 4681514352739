import { HttpClient, HttpParams } from '@angular/common/http';
import { PurchaseOrderDetails } from '../models';
import { PurchaseOrderApiConfig } from '../api/purchaseOrder.api.config';
import { lastValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import IGenericApiResponse from '../models/generic-response.model';

@Injectable({ providedIn: 'root' })
export class PurchaseOrderService
{
	constructor(
		private http: HttpClient
	)
	{
	}

    public getPurchaseOrderById(accountId: number, purchaseOrderId: number): Promise<IGenericApiResponse>
	{
		return lastValueFrom(this.http.get<IGenericApiResponse>(PurchaseOrderApiConfig.getFullUrl(`account/${accountId}/order/${purchaseOrderId}/details`)));
	}
}