<app-progress [splash]="true" [value]="working">
    <div class="account-edit flex-column">
      <div class="header flex-row no-wrap justify-between">
        <H3>Suppliers</H3>
      </div>
      <div class="body info">
        <app-access [platformFeature]="feature.AccountUpdate" [displayIfNoAccess]="true">
    
        <dx-data-grid id="dataGrid" [showColumnLines]="true" [dataSource]="dataSource" 
        [showBorders]="true" [columnAutoWidth]="true" (onCellClick)="onCellClick($event)"
        [wordWrapEnabled]="false" (onContentReady)="onContentReady($event)">
          <dxo-filter-row [visible]="true" ></dxo-filter-row>
          <dxo-header-filter [visible]="true"></dxo-header-filter>
          <dxi-column dataField="supplierName" [minWidth]="200" caption="Supplier" sortOrder="asc" [sortIndex]="0"></dxi-column>
          <dxi-column dataField="accountNumber" caption="Account Number"></dxi-column>
          <dxi-column [allowHeaderFiltering]="false" [allowFiltering]="true" dataField="isPreferred" [minWidth]="240" [alignment]="'left'"
            dataType="boolean" caption="Preferred" falseText="No" trueText="Yes">
          </dxi-column>
          <dxi-column [allowHeaderFiltering]="false" [allowFiltering]="true" dataField="active" [minWidth]="240" [alignment]="'left'"
            dataType="boolean" caption="Active" falseText="No" trueText="Yes">
          </dxi-column>
        </dx-data-grid>
        <dx-button class="mt-large" [width]="120" (click)="newSupplier()">Add New Supplier</dx-button>
        </app-access>
      </div>
    
    </div>
  </app-progress>