import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AccountAccess, Agency } from 'src/app/shared/models';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { IAccount } from 'src/app/shared/models';
import { DxTreeListComponent } from 'devextreme-angular';
import { AccountService } from 'src/app/shared/services/account.service';
import { Feature, FeatureService } from 'src/app/shared/services/feature.service';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { AgentService, LoginService, MonitorService, NavigationService } from 'src/app/shared/services';
import { SplashOptions } from 'src/app/shared/components/progress-bar/progress-bar.component';
import { FormCanDeactivate } from 'src/app/shared/utils/form.candeactivate';
import { fakeAsync } from '@angular/core/testing';

@Component({
  selector: 'app-agency-edit-accounts',
  templateUrl: './agency-edit-accounts.component.html',
  styleUrls: ['./agency-edit-accounts.component.scss']
})
export class AgencyEditAccountsComponent extends FormCanDeactivate {
  submitMessage: string = 'Error adding/removing accounts, refresh and try again';
  validate(): boolean {
    return true;
  }
  postExecute(): void {
    super.postExecute();
    setTimeout(() => {
      this.working = 0;
    }, 1000); // dev express runs in different callback and needs time to process.  It will select rows, thus marking the page dirty, so reset
  }

  @ViewChild(
    'treeList2', { static: false }) 
  treeList2!: DxTreeListComponent;

  @Input()
  agency: Agency | undefined;

  agencyAccounts: AccountAccess[] = [];
  accounts: AccountAccess[] = [];
  agencyDataSource: any;
  accountDataSource: any;
  feature = Feature;
  showHierarchies: boolean = true;
  selectedIndex = 0;
  isViewMode = true;
  tabIndex = 0;
  tabs = ['View', 'Edit'];
  expandedKeys: any | undefined;

  selectedRows: number[] = [];
  working = 0;
  splashOptions: SplashOptions = { title: '', message: '' };


  @Input() rowClass: ((rowData: any) => string) | undefined;


  constructor(
    private accountService: AccountService,
    router: Router,
    private navigationService: NavigationService,
    monitor: MonitorService,
    login: LoginService,
    private agencyService: AgencyService,
    private agents: AgentService,
    private featureService: FeatureService,
    private activatedRoute: ActivatedRoute
  ) {
      super(monitor, login, router);

    this.activatedRoute.parent!.paramMap.subscribe(async params => {
      const agencyId = params.get('agencyId');
      if (agencyId) {
        this.agency = await this.agencyService.getAgencyById(parseInt(agencyId), {accounts: true});
        if (this.agency) {
          this.execute(async () => {
            await this.loadAccounts();
            return true;
          }, 'Error loading accounts');
        }
      }
    })
  }
  
  onContentReady(e: any) {
      const rows = e.component.getVisibleRows();
      rows.forEach((row: any) => {
        const rowKey = e.component.getKeyByRowIndex(row.rowIndex);
        const rowData = row.data;
        const rowIndex = e.component.getRowIndexByKey(rowKey);
        const rowElement = e.component.getRowElement(rowIndex);
        if (rowData.active === false) {
          for (let i = 0; i < rowElement[0].children.length; ++i) {
            const element = rowElement[0].children[i];
            element.classList.add('inactive');
          }
        }
        if (rowData.include === false) {
          for (let i = 0; i < rowElement[0].children.length; ++i) {
            const element = rowElement[0].children[i];
            element.classList.add('not-included');
          };
        }
      });
  }

  onRowClick(s: any, e: any) {
    if (e.data.active === false || e.data.include === false) {
      e.cancel = true;
      s.SetFocusedRowIndex(e.rowIndex);
    }

  }

  onSelectionChanged(e: any) {
    this.selectedIndex = e.itemIndex;
  }

  onCellClick(event: any) {
    if (event.column.dataField === 'name' && event.event.target.classList.contains('dx-treelist-text-content')) {
      const navigationExtras = {
        state: {
          fromSpecificPage: true
        }
      };
      
      // is admin or data is valid
      if ((this.agents.authenticatedAgent && this.featureService.check(Feature.AgencyUpdate, this.agents.authenticatedAgent.features)) || 
          (event.data.active !== false && event.data.include !== false)) {
        this.navigationService.backoffice().account.details(event.data.id);
      }
    }
  }

  async loadAccounts(): Promise<any> {
      this.accounts = [];
      this.agencyAccounts = [];
      (await this.accountService.get({parent: true })).map((a: IAccount) => {
        this.accounts.push({
          id: a.id,
          accountNumber: a.accountNumber,
          accountType: a.accountType,
          active: a.active,
          name: a.name,
          parentId: a.parentId,
          requistionApprover: false,
          role: { name: 'None', id: 0 }.id,
          enabled: true,
          changed: false,
          include: true,
          inherit: false
        });
      });

      if (this.agency?.accounts?.length) {
        for (let i = 0; i < this.agency.accounts.length; ++i) {

          let x: AccountAccess = {
            id: this.agency.accounts[i].id,
            accountNumber: this.agency.accounts[i].accountNumber,
            accountType: this.agency.accounts[i].accountType,
            active: this.agency.accounts[i].active,
            name: this.agency.accounts[i].name,
            parentId: this.agency.accounts[i].parentId,
            requistionApprover: false,
            role: { name: 'None', id: 0 }.id,
            enabled: true,
            changed: false,
            include: true,
            inherit: false
          };
          this.agencyAccounts.push(x);
          // let found = this.accounts.find(a => a.id === x.id);
          // if (found) {
          //   this.agency.accounts[i] = found;
          // }
        }

        this.agencyAccounts.forEach(a => {
          const find = this.agencyAccounts.find(aa => aa.id === a.parentId);
          if (find === undefined) {
            const found = this.accounts.find(aa => aa.id === a.parentId);
            if (found) {
              var copy: any = {};
              Object.assign(copy, found);
              copy.include = false;
              this.agencyAccounts.push(copy);
            }
          }
          
        });
      }
      this.expandedKeys = this.agencyAccounts.map(item => item.id);

      for (let i = 0; i < this.agencyAccounts.length; ++i) {
        if (this.agencyAccounts[i].parentId && 
           !this.agencyAccounts.find((a) => a.id === this.agencyAccounts[i].parentId)) {
          const x = this.accounts.find((a) => a.id === this.agencyAccounts[i].parentId);
          if (x) {
            x.include = false;
            this.agencyAccounts.push(x);
            this.expandedKeys.push(x.id);
          }
        }
      }

      if (this.agency?.accounts) {
        const commonValues = this.accounts.filter(account => {
          // Check if an account with the same id is included in agency.accounts
          return this.agency?.accounts?.some(a => a.id === account.id);
        });
        this.selectedRows = commonValues.map(a => a.id);
        this.treeList2.instance.option('selectedRowKeys', this.selectedRows);
      }
  }

  selectTab(event: any) {
    if (event.itemIndex == 0) {
      this.isViewMode = true;
    } else {
      this.isViewMode = false;
    }
    this.tabIndex = event.itemIndex;
  }


  // save accounts that were removed and added to agency
  async submit(): Promise<any> {
      const newSelectedRows = this.treeList2.instance.getSelectedRowsData().filter(selectedRow => {
        // Check if the selected row's id is already in agency.accounts
        return !this.agency?.accounts?.some(a => a.id === selectedRow.id);
      });
      const deselectedRows = this.agency?.accounts?.filter(account => {
        // Check if the account was originally selected and is now deselected
        return this.selectedRows.includes(account.id) && !this.treeList2.instance.isRowSelected(account.id);
      }) ?? [];

      if (this.agency?.id) {
        this.splashOptions.title = 'Adding Accounts';
        for (let i = 0; i < newSelectedRows.length; ++i) {
          const a = newSelectedRows[i];
          this.working += 1;
          if (this.working > 100) {
            this.working = 100;
          }
          this.splashOptions.message = `${a.accountNumber} - ${a.name}`;
          await this.agencyService.addAgencyAccount(this.agency.id ?? 0, a);
          this.agency.accounts?.push(a);
        }

        this.splashOptions.title = 'Removing Accounts';
        for (let i = 0; i < deselectedRows.length; ++i) {
          const a = deselectedRows[i];
          this.working += 1;
          if (this.working > 100) {
            this.working = 100;
          }
          this.splashOptions.message = `${a.accountNumber} - ${a.name}`;
          await this.agencyService.deleteAgencyAccount(this.agency.id ?? 0, a.id);
          var x = this.agency.accounts?.findIndex(ac => ac.id === a.id) ?? -1;
          if (x >= 0) {
            this.agency.accounts?.splice(x, 1);
          }
        }
        this.monitor.handleSuccess('Accounts have been saved');
        // Re pull agency accounts to show the new relationships
        await this.loadAccounts();
        this.isViewMode = true;
        this.tabIndex = 0;
        setTimeout(() => {
          this.working = 0;
        }, 1000); // dev express runs in different callback and needs time to process.  It will select rows, thus marking the page dirty, so reset

      }
  }

}
