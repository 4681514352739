<div *ngIf="pendingApprovals > 0" class="banner"> Purchasing is disabled while there's a requisition pending approval.</div>
<nav class="navbar flex-row">
  <a class="menu badge" (click)="toDashboard()" [ngClass]="{ 'no-filter': accounts.customizations['Logo'] }">
    <img [src]="accounts.customizations['Logo'] ?? 'assets/images/icons/yak-red-mini.png'" class="nav-logo">
  </a>
  <div class="menu desktop">
    <ng-template ngFor [ngForOf]="dropdownContent" let-dropdown let-i="index">
      <app-drop-down-navigate [content]="dropdown" class="mt-small mb-small mr-small"
                              [buttonClass]="'width-medium height-medium text rubik text-500 ' + (i % 2 === 0 ? 'primary border-secondary-accent' : 'secondary border-primary-accent')"
                              linkClass="nav-link-drop-down text kreon text-300">
      </app-drop-down-navigate>
    </ng-template>
</div>
<div class="navbar-items">
  <div class="navbar-item">
    <svg width="20" height="20" class="icon-style">
        <use xlink:href="../../../../assets/images/icons/sprites.svg#home" />
    </svg>
    <a routerLinkActive="active" [ngClass]="{'active': activeRoute === 'dashboard'}" class="drop-down width-medium height-medium text rubik text-500 mr-medium black" (click)="toDashboard()">Home</a>
  </div>
  <div class="navbar-item">
    <svg width="20" height="20" class="icon-style">
        <use xlink:href="../../../../assets/images/icons/sprites.svg#products" />
    </svg>
    <a routerLinkActive="active" [ngClass]="{'active': activeRoute === 'search'}" class="drop-down width-medium height-medium text rubik text-500 mr-medium black" (click)="toSearch()">Products</a>
  </div>
  <div class="navbar-item">
    <svg width="20" height="20" class="icon-style">
        <use xlink:href="../../../../assets/images/icons/sprites.svg#requisitions" />
    </svg>
    <a routerLinkActive="active" [ngClass]="{'active': activeRoute === 'requisition'}" class="drop-down width-medium height-medium text rubik text-500 mr-medium black" (click)="toRequisitions()">Requisitions</a>
  </div>
  <div class="navbar-item">
    <svg width="20" height="20" class="icon-style">
        <use xlink:href="../../../../assets/images/icons/sprites.svg#approvals" />
    </svg>
    <a [attr.id]="pendingApprovals > 0 ? 'approvals-item' : null" routerLinkActive="active" [ngClass]="{'active': activeRoute === 'pending-approvals'}" class="drop-down width-medium height-medium text rubik text-500 black" (click)="toApprovals()">Pending Approvals</a>
    <span class="notification" *ngIf="pendingApprovals > 0">{{pendingApprovals}}</span>
  </div>
  <div class="navbar-item">
    <svg width="20" height="20" class="icon-style">
        <use xlink:href="../../../../assets/images/icons/sprites.svg#purchase-orders" />
    </svg>
    <a routerLinkActive="active" [ngClass]="{'active': activeRoute === 'order'}" class="drop-down width-medium height-medium text rubik text-500 black" (click)="toOrders()">Purchase Orders</a>
  </div>
  <div class="navbar-item">
      <svg width="20" height="20" class="icon-style">
          <use xlink:href="../../../../assets/images/icons/sprites.svg#reports" />
      </svg>
      <a routerLinkActive="active" [ngClass]="{'active': activeRoute === 'reports'}" class="drop-down width-medium height-medium text rubik text-500 black" (click)="toReports()">Reports</a>
  </div>
</div>

  <app-cart-quick-view *ngIf="agent?.active" class="ml-auto menu mt-auto mb-auto mr-small"></app-cart-quick-view>
  <app-account-card *ngIf="agent?.active" [account]="account" [profile]="profile"></app-account-card>
</nav>
