import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AccountType, Agent, Profile } from 'src/app/shared/models';
import { AgentService, LoginService, NavigationService } from 'src/app/shared/services';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy{

  constructor(
    public loginService: LoginService,
    public agentService: AgentService,
    private navigationService: NavigationService,
  ) {
  }
  profileChange$: Subscription | undefined;

  async ngOnInit() {
    if(!this.agentService.authenticatedAgent){ 
      this.agentService.authenticate();
    }

    if(this.agentService.authenticatedAgent?.agency?.platformAgency){
      this.navigationService.backoffice().dashboard();
    }
    else{
      let accounts = await this.agentService.getAgentAccounts(this.agentService.authenticatedAgent!.id!, {accounts: true});
      if(accounts.length > 0){

        switch(accounts[0].accountType){
          case AccountType.PRACTICE:
            this.agentService.addAuthorizedAccount(accounts[0]);
            this.navigationService.practice(accounts[0].id).dashboard();
            break;
          case AccountType.DSO:
            this.agentService.addAuthorizedAccount(accounts[0]);
            this.navigationService.dso(accounts[0].id).dashboard();
            break;
          case AccountType.GPO:
            this.agentService.addAuthorizedAccount(accounts[0]);
            this.navigationService.gpo(accounts[0].id).dashboard();
            break;
          default:
            this.navigationService.public().message.noAccess();
            break;
        }
      }else{
        this.navigationService.public().message.noAccess();
      }
    }
  }

  ngOnDestroy(): void {
    if(this.profileChange$){
      this.profileChange$.unsubscribe();
    }
  }
  
}
