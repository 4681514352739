<div class="approvers-list-wrapper">

    <div class="levels-container" *ngFor="let level of approvers">
	    <div class="level-container">
			<div class="level-header"div *ngIf="level.approvalLevelName">{{level.approvalLevelName}}</div>
			<div class="approver" *ngFor="let approver of level.approvers" [ngClass]="{'multi-level': level.approvalLevelName}">
				<div class="avatar-agent">
					<div class="avatar"></div>
					<div class="agent">
						<div class="approver-header">
							<div class="approver-name">{{approver.approver.agentName}}</div>

						</div>
						<a class="approver-description" [href]="'mailto:' + approver.approver.agentEmail">{{approver.approver.agentEmail}}</a>
					</div>
				</div>

				<div class="approver-decision">
					<span *ngIf="approver.approver.isMasterApprover" class="master-approver">Master Approver</span>
					<div class="container">
						<div class="status" [ngClass]="{'approved': approver.status.status.toLowerCase() === 'approved'}">{{approver.status.status}}</div>
						<div class="date">{{approver.status.date | date:"short"}}</div>
					</div>
					<div class="comments">
						{{approver.status.approvalComments}}
					</div>
				</div>
			</div>
		</div>
    </div>
</div>
