import { CurrencyPipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { CoreProduct, SupplierProduct } from 'src/app/shared/models';
import { IOptimizeOrderLine } from 'src/app/shared/models/optimize.order.line.model';
import { CartService } from 'src/app/shared/services';
import { AddToCartComponent } from '../../add-to-cart/add-to-cart.component';

@Component({
  selector: 'app-optimize-order-row',
  templateUrl: './optimize-order-row.component.html',
  styleUrls: ['./optimize-order-row.component.scss']
})
export class OptimizeOrderRowComponent implements OnInit, AfterViewInit {
  @Input() requisitionLineItem!: IOptimizeOrderLine;

  @Input() swapSupplierProductCallback!: (lineId: number, newSupplierProductId: number) => Promise<void>

  @Input() lineRemove!: (lineId: number) => void;
  @Input() lineUpdate!: (lineId: number) => void;
  @Input() originalChoice: boolean = false;

  supplierProduct?: SupplierProduct = undefined;
  coreProduct?: CoreProduct = undefined ;
  lineTotal: number = 0;
  TBDPrice: boolean = false;

  private _isLineUpdating: boolean = false
  public get isLineUpdating(): boolean { return this._isLineUpdating }
  public set isLineUpdating(newVal: boolean) {
      if(newVal != this._isLineUpdating) this._isLineUpdating = newVal;
  }

  @ViewChild(AddToCartComponent) addToCartComponent!: AddToCartComponent;
  
  constructor(private cartService: CartService) {}

  async ngOnInit() {

    this.cartService.cartChangedObservable$.subscribe((cart) => {
      this.requisitionLineItem.purchaseRequisitionLineQuantity = cart?.lines.find(x => x.id === this.requisitionLineItem.purchaseRequisitionLineId)?.quantity || 0;
      this.calcLineTotal();
    });

    let updatedSuppProduct = new SupplierProduct()
    updatedSuppProduct.id = this.requisitionLineItem.supplierProductId;
    updatedSuppProduct.canPurchase = this.requisitionLineItem.canPurchase;

    this.supplierProduct = updatedSuppProduct;

    let updatedCoreProduct = new CoreProduct();
    updatedCoreProduct.id = this.requisitionLineItem.coreProductId;

    this.coreProduct = updatedCoreProduct;

    this.calcLineTotal();


  }

  ngAfterViewInit(): void {
    this.addToCartComponent.checkCart();
  }

  async SwapSupplierProduct(lineId: number, newSupplierProductId: number) {
    this.isLineUpdating = true;
    await this.swapSupplierProductCallback(lineId, newSupplierProductId);
    this.isLineUpdating = false;
  }

  async removeLine(lineId: number) {
    this.lineRemove(lineId);
  }

  async updateLine(lineId: number) {
    this.lineUpdate(lineId);
    let l = this.cartService.cart!.lines.find(x => x.id === lineId); 

    if (l) {
      this.requisitionLineItem.purchaseRequisitionLineQuantity = l.quantity;

      this.calcLineTotal();
    }
    this.isLineUpdating = false;
  }

  calcLineTotal() {
    this.lineTotal = Number((parseFloat(this.requisitionLineItem.price.slice(1)) * this.requisitionLineItem.purchaseRequisitionLineQuantity).toFixed(2));
    
    if (Number.isNaN(this.lineTotal)) {
      this.TBDPrice = true;
    } else {
      this.TBDPrice = false;
    }

  }
}
