import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DxDataGridComponent, DxFormComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { FormularyApiConfig } from 'src/app/shared/api';
import { IAccount, Formulary, FormularyProduct, FormularyProductAction } from 'src/app/shared/models';
import { LoginService, ModalService, MonitorService, NavigationService } from 'src/app/shared/services';
import { AccountService } from 'src/app/shared/services/account.service';
import { Feature } from 'src/app/shared/services/feature.service';
import { FormularyService } from 'src/app/shared/services/formulary.service';
import { FormCanDeactivate } from 'src/app/shared/utils/form.candeactivate';
import { HttpParams, HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-formulary-edit-products',
  templateUrl: './formulary-edit-products.component.html',
  styleUrls: ['./formulary-edit-products.component.scss']
})
export class FormularyEditProductsComponent extends FormCanDeactivate implements OnInit {

  async submit(): Promise<boolean> {
    this.queuedActions = [];
    this.selected.map(x => {
      this.queuedActions.push({
        action: this.mode,
        sku: x.sku,
        include: x.include
      });
    });
    if (this.queuedActions.length) {
      this.queuedAction = this.queuedActions[0].action!;
      this.modals.open('confirm-action')
    }
    return true;
  }
  validate(): boolean {
    return this.isValid;
  }
  submitMessage: string = 'Error updating formulary';

  async confirmedSubmit(): Promise<any> {
    await this.execute(async () => {
      await this.formularyService.updateFormularyProducts(this.formulary!.id, this.queuedActions);
      this.dataGrid.instance.refresh();
      this.modals.close('confirm-action');
      return true;
    }, 'Error updating formulary');
  }

  cancelSubmit(): void {
    this.queuedActions = [];
    this.modals.close('confirm-action');
  }

  queuedAction = '';
  queuedActions: FormularyProductAction[] = [];

  @Input()
  account: IAccount | undefined;
  parentAccount: IAccount | undefined;

  formulary: Formulary | undefined;

  @ViewChild(DxFormComponent, { static: false })
  form!: DxFormComponent;

  @ViewChild(DxDataGridComponent)
  dataGrid!: DxDataGridComponent;

  dataSource: any;
  formularyProducts: FormularyProduct[] = [];
  feature = Feature;
  gridParams: any | undefined = undefined;
  uploadUrl = '';
  loadUrl = '';

  pageSize = 20;
  workingTitle: string | undefined = undefined;
  workingMessage: string | undefined = undefined;

  mode: 'Remove' | 'Include' | 'Exclude' | undefined = undefined;
  selected: FormularyProduct[] = [];

  constructor(
    private loginService: LoginService,
    private accountService: AccountService,
    private formularyService: FormularyService,
    monitor: MonitorService,
    login: LoginService,
    public modals: ModalService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private navigationService: NavigationService,
    router: Router,
    private el: ElementRef) {
    super(monitor, login, router);

    this.route.paramMap.subscribe(async (params) => {
      let formularyId = parseInt(params.get('formularyId') ?? '0');

      if(!formularyId) formularyId = parseInt(this.route.parent!.snapshot.paramMap.get('formularyId') ?? '0');

      if (formularyId) {
        this.startWorking();
        try {
          this.formulary = await this.formularyService.getFormulary(formularyId, {products: true});
        }catch(e) {
          this.endWorking();
          this.monitor.logException(e);

          this.navigationService.backoffice().message.invalidRoute();
        }

        if(this.formulary != undefined){
          this.execute(async () => {
            if (this.account && this.account.parentId) {
              this.parentAccount = await this.accountService.getAccountById(this.account.parentId, {});
            }
            this.uploadUrl = FormularyApiConfig.getFullUrl(`${this.formulary!.id}/products`);
            this.loadUrl = FormularyApiConfig.getFullUrl(`${this.formulary!.id}`);
            
            return true;
          }, 'Error loading formulary');
  
        }

      }

      await this.configureDatasource('');
    });
  }

  async ngOnInit() {

    this.accountService.accountEditAccount$.subscribe(async (account) => {
      this.account = account;
      
      if (this.account && this.account.parentId) {
        this.parentAccount = await this.accountService.getAccountById(this.account.parentId, {});
      }
    });
  }

  onContentReady(e: any) {
    setTimeout(() => {
      const headerCheckbox = this.el.nativeElement.querySelector('#gridContainer .dx-header-row .dx-checkbox input');
      if(headerCheckbox.value == "false" || this.selected.length == 0){
        this.isValid = false;
      }else{
        this.isValid = true;
      }
    }, 0);
    const rows = e.component.getVisibleRows();
    rows.forEach((row: any) => {
      const rowKey = e.component.getKeyByRowIndex(row.rowIndex);
      const rowData = row.data;
      const rowIndex = e.component.getRowIndexByKey(rowKey);
      const rowElement = e.component.getRowElement(rowIndex);
      if (rowData.include === false) {
        const element = rowElement[0].children[rowElement[0].children.length - 1];
        element.classList.add('not-included');
      }
    });
  }

  onSelectionChanged(e: any) {
    setTimeout(() => {
      this.isValid = true;
      this.selected = e.selectedRowsData;
      const headerCheckbox = this.el.nativeElement.querySelector('#gridContainer .dx-header-row .dx-checkbox input');

      if(headerCheckbox.getAttribute('value') == "false" || this.selected.length == 0){
        this.isValid = false;
      }
    }, 0);
  }

  uploadMessages: string[] = [];
  onBeforeSend(e: any): any {
    e.request.setRequestHeader('Authorization', `Bearer ${this.loginService.getToken()}`);
  }
  onUploaded(e: any): any {
    this.uploadMessages = e.request.responseText.split('|');

    this.execute(async () => {
      await this.configureDatasource('');
      return true;
    }, 'Error refreshing formulary');
  }

  /**
   * Export the formulary products to a CSV file
   */
  async exportData() {
    this.working = 50;
    this.workingMessage = "Exporting Formulary";
    var params: HttpParams = new HttpParams();
    await this.formularyService.exportFormularyProducts(this.formulary!.id!, this.gridParams)
    .then((response: any) => {
      window.location.href = response.blobUrl;
    });
    this.working = 0;
    this.workingMessage = undefined;
  }

  async configureDatasource(searchText: string): Promise<void> {
    const formularies = this.formularyService;
    const id = this.formulary!.id;

    this.dataSource = new CustomStore({
      key: 'id',
      loadMode: 'processed',
      load: (loadOptions: any) =>  {
        let params: HttpParams = new HttpParams();
        [
          'skip',
          'take',
          'requireTotalCount',
          'requireGroupCount',
          'sort',
          'filter',
          'totalSummary',
          'group',
          'groupSummary',
        ].forEach((i) => {
          if (i in loadOptions && (i === 'skip' || i === 'take' || loadOptions[i]?.length)) {
            params = params.set(i, JSON.stringify(loadOptions[i]));
          }
        });

        params = params.append('requireTotalCount', 'true'); // force it to bring the total back
       
        this.gridParams = params;

        return formularies.searchFormulary(id!, params).then(data => {
          return {
            data: data.data,
            totalCount: data.totalCount,
            summary: data.summary,
            groupCount: data.groupCount,
          };  
        });
      }
    });
  }
}
