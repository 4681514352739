import { AuditInfo } from ".";

export class Supplier extends AuditInfo {
    public name?: string;
    public description?: string;
    public contactName?: string;
    public contactPhone?: string;
    public contactEmail?: string;
    public imageUrl?: string;
    public supplierIntegrations: string[] = [];
}

export class SupplierSubmissionMethod {
    public static Email: string = 'Email';
    public static Cxml: string = 'Cxml';
}

export class SubmissionMethod {
    public static None: string = 'None';
    public static Email: string = 'Email';
    public static Cxml: string = 'Cxml';
  }
  