import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { PurchaseRequisition } from "../models";
import { FulfillmentApiConfig } from "../api";

@Injectable({ providedIn: 'root' })
export class FulfillmentService {
  constructor(
    private http: HttpClient
  ) { }

  // NOT SURE THIS IS NEEDED HERE, THE REQUEST SHOULD GO IN THE CXML INTEGRATION MAYBE?

  public submitPurchaseRequisition(id: number, value: PurchaseRequisition): Promise<boolean> {
    return lastValueFrom(this.http.post<boolean>(FulfillmentApiConfig.getFullUrl(`$request?practiceId=${value.practiceId}&`), value));
  }

}