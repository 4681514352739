<div style="position: relative;" *ngIf="showScrollToTop">
  <button class="btn pink" style="position: fixed; left: 84vw; top: 90vh; z-index: 1000;" (click)="scrollTop()">
    top
  </button>
</div>
<div class="flex-row no-wrap search-form search-area" *ngIf="allowSearch">
  <div class="supplier-dropdown" *ngIf="showDropdown">
    <dx-select-box #supplierDropdown
    [items]="supplierList"
    displayExpr="supplierName"
    valueExpr="supplierId"
    [value]="supplierList[0].supplierId"
    (onValueChanged)="onDropdownSelection($event)"
    >
  </dx-select-box>
  </div>
  <div class="form-container">
    <form (submit)="formSubmit($event)" class="mt-auto flex-row flex-start mb-medium">
      <dx-form #searchForm (onFieldDataChanged)="onFieldDataChanged($event)" [colCount]=2>
        <dxi-item>
            <dx-text-box #searchBox labelMode="hidden" dataField="Search" [placeholder]="'Search...'" [width]="400" [showClearButton]="true" (onContentReady)="onContentReady($event)"></dx-text-box>
        </dxi-item>
        <dxi-item>
          <dx-button class="search mt-large search-button" text="Search" [useSubmitBehavior]="true"></dx-button>
        </dxi-item>
      </dx-form>
    </form>
  </div>
</div>
<div class="scroll standard-background" 
  [ngClass]="{ 'horizontal': direction === 'horizontal', 'vertical': direction === 'vertical' }">

  <dx-list #catalog [searchEnabled]="false" searchMode="contains" [dataSource]="dataSource" [height]="(pageSize === 20 ? 'auto' : '95vh')"
    pageLoadMode="scrollBottom" itemTemplate="detail" (onScroll)="onListScrollEvent($event)">

    <div *dxTemplate="let d of 'detail'" class="card-wrapper">

      <app-card *ngIf="displayCoreProducts" class="core-product" buttonClasses="pink border-pink-muted">
        <app-recommended-tag [show]="d.recommendedProduct === true" floatDirection="right"></app-recommended-tag>
        <app-core-product-card [product]="d"></app-core-product-card> 
      </app-card>

      <app-card *ngIf="!displayCoreProducts" class="supplier-product" actionText="View Details">
        <div class="tag-container">
          <app-out-of-stock-tag [show]="d.stockQuantity === 0" floatDirection="variations"></app-out-of-stock-tag>
          <app-recommended-tag [show]="d.recommendedProduct === true" floatDirection="variations" style="padding-left: 5px;"></app-recommended-tag>
        </div>
        <app-supplier-product-card [product]="d"></app-supplier-product-card>
      </app-card>
    </div>
  </dx-list>
</div>
