<nav class="navbar flex-row justify-start">
    <a class="menu badge mr-medium" (click)="toDashboard()">
      <img src="assets/images/icons/yak-red-mini.png" class="nav-logo">
      <a routerLinkActive="active" class="drop-down width-medium height-medium text rubik text-500 mr-medium black ml-large"
      (click)="toDashboard()">Home</a>
    </a>
    

    <app-account-card *ngIf="agent?.active" class="ml-auto" [account]="account" [profile]="profile"></app-account-card>
  
    <div class="menu mobile">
      <app-menu-bar [expanded]="mobileexpanded" (toggle)="toggleMenu($event)" color="pink"></app-menu-bar>
      <div class="mobile-menu-content" [ngClass]="{ 'active': mobileexpanded }">
        <div class="flex-row no-wrap">
          <div class="flex-column flex-grow">
  
          </div>
        </div>
      </div>
    </div>
  </nav>
