import { Component, Input, OnInit, ViewChildren } from '@angular/core';
import { CartLine, CoreProduct, SupplierProduct } from '../../models';
import { CartService, CatalogService, ModalService, MonitorService, ClipboardService, LoginService, CatalogLoadOptions, AgentService } from '../../services';
import { GenericComponent } from '../../utils/generic.component';
import { AddToCartComponent } from 'src/app/components/cart/add-to-cart/add-to-cart.component';

@Component({
  selector: 'app-product-variations',
  templateUrl: './product-variations.component.html',
  styleUrls: ['./product-variations.component.scss'],
})
export class ProductVariationsComponent extends GenericComponent implements OnInit {
  @ViewChildren(AddToCartComponent)
  addToCarts!: AddToCartComponent[];

  private _product: CoreProduct | undefined;

  @Input()
  public get product(): CoreProduct | undefined {
    return this._product;
  }
  public set product(value: CoreProduct | undefined) {
    this._product = value;
    if (this._product?.id !== value?.id) {
      this._product = value;
      this.productListing = [];
    }
  }

  @Input()
  supplierProduct: SupplierProduct | undefined;

  @Input() mainImageUrl: string | undefined;
  @Input()
  mainMode: 'add' | 'update' = 'add';

  @Input() isListing = false;
  @Input() showAll = false;
  @Input() hideRemove = true;
  @Input() defaultQuantity = 1;
  @Input() modalId: string | undefined;


  size: 'regular' | 'small' = 'regular';
  public isInCart?: boolean = false;
  public productListing: any[] = [];

  variationCardOpen: boolean = false;
  quantity?: number;
  mainImage: string | undefined = undefined;
  activeModalId: string | undefined;
  confirmationModalId: string;
  private _internalCount = 0;

  constructor(
    private catalogService: CatalogService,
    public cartService: CartService,
    public modals: ModalService,
    monitor: MonitorService,
    login: LoginService,
    private clipboard: ClipboardService,
    private agentService: AgentService
  ) {
    super(monitor, login);
    this.modals.currentData.subscribe(data => {
      this.activeModalId = data;

    });

    this._internalCount = AddToCartComponent._counter++;

    this.modals.getTriggerSignal().subscribe(() => {
      this.checkQuantities();
    });
    //}
    this.confirmationModalId = `confirm-close-variations-${AddToCartComponent._counter++}`; // Set the dynamic ID here

  }

  async openVariations(): Promise<any> {
    this.mainImage = this.product?.imageUrl ?? 'assets/images/icons/yak-red.png';
    this.modals.setCloseConfirmationBool(false);

    const cart = this.cartService.cart;
    if (cart == undefined) {
      this.isInCart = false;
    } else {
      cart.lines.forEach(line => {
        if (line.coreProductId == this.product?.id) {
          this.isInCart = true;
        }
      });
    }

    const clip: number | undefined = this.clipboard.getValue(`atc-qty-${this.product!.id}`);
    if (clip) {
      this.quantity = undefined;
      setTimeout(() => this.quantity = clip, 1);  // tricks lifecycle, otherwise it could look like no change and not update appropriately
      this.clipboard.clearValue(`atc-qty-${this.product!.id}`);
    }

    await this.execute(async () => {
      if (this.product?.listing?.id) {
        let options: CatalogLoadOptions = 
        {
          accountId: this.agentService.currentAccount!.id, 
          listingId: this.product?.listing?.id, 
          coreProducts: true, 
          includeListing: true
        } as CatalogLoadOptions;

        this.productListing = await this.catalogService.get('', options);
        if (!this.showAll) {
          this.productListing = this.productListing.filter(p => p.id !== this.product?.id);
        }
      }

      return true;
    }, 'Error loading variations');
  }

  async ngOnInit() {

    this.modals.setConfirmationModalId(this.confirmationModalId);
  }

  getATCId(): string {
    return `atc-${this._internalCount}`;
  }
  toggleVariationCard(modal: string) {
    this.modals.close(modal);
  }

  // add all is all, except the listing product
  async addAll(submittingLine: CartLine): Promise<any> {
    const filteredATC = 
    this.addToCarts
      .filter(atc => atc.mode !== 'update' && atc.isLoading != true &&  submittingLine.coreProductId != atc.coreProduct!.id && (this.isListing || atc.coreProduct!.id !== this.product!.id)); 
      filteredATC.forEach(async atc => {
          await atc.createLineNonInteractive();
        });
    }
      
  async loading(submittingLine: CartLine){
    const filteredATC = 
    this.addToCarts
      .filter(atc => (atc?.quantity ?? 0) > 0 && atc.mode !== 'update' && submittingLine.coreProductId != atc.coreProduct!.id && (this.isListing || atc.coreProduct!.id !== this.product!.id)); 
      filteredATC.forEach(async atc => {
          await atc.load();
      });
  }

  public checkQuantities(): void {
    this.modals.setCloseConfirmationBool(false);

    if (this.modalId == this.activeModalId) {
      let conditionMet = false; // Flag variable

      for (const atc of this.addToCarts) {

        if ((atc.quantity != undefined && atc.quantity > 0) && atc.quantity !== atc.inCart ) {
          this.modals.open(this.confirmationModalId);
          conditionMet = true; // Set flag to true when condition is met
          this.modals.setCloseConfirmationBool(false);
          return; // Exit the method after finding the condition
        }
      }

      if (!conditionMet) {
        this.modals.setCloseConfirmationBool(true);
        this.close(null, this.confirmationModalId);
      }
    }
  }

  async addLines(e: any, id: string) {

    this.modals.close(id);
  }

  close(e: any, id: string) {
    this.modals.setCloseConfirmationBool(true);
    this.modals.close(this.activeModalId!);
    this.modals.close(id);
    for(const atc of this.addToCarts){
      if(atc.mode !== 'update' && atc.quantityInput){
        atc.quantityInput.nativeElement.value = undefined;
      }
      atc.checkCart();
    }
  }

}
