<app-progress [splash]="true" [value]="working">

    <app-access [platformFeature]="feature.AccountUpdate" [displayIfNoAccess]="true">
        <div class="header flex-row no-wrap justify-between">
            <H3>Composite Product List</H3>
        </div>

        <dx-data-grid id="gridContainer" [showColumnLines]="true" [dataSource]="dataSource" [showBorders]="true" [columnAutoWidth]="true"
            [wordWrapEnabled]="false" (onContentReady)="onContentReady($event)" [remoteOperations]="true">
            <dxo-search-panel [visible]="true" [width]="240"></dxo-search-panel>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-header-filter [visible]="true"></dxo-header-filter>

            <dxo-paging [pageSize]="100"></dxo-paging>
                <dxo-pager
                    [showInfo]="true"
                    infoText="Page {0} of {1} | {2} total"
                    [showPageSizeSelector]="true"
                    [allowedPageSizes]="[20, 100, 200, 500, 1000]"
                    [showNavigationButtons]="true">
                </dxo-pager>

            <dxi-column dataField="supplier.name" [minWidth]="200" dataType="string" caption="Supplier">
                <dxo-header-filter [allowSearch]="true"></dxo-header-filter>
            </dxi-column>
            <dxi-column dataField="sku" [minWidth]="160" [sortIndex]="0" caption="Supplier SKU" dataType="string" sortOrder="asc">
                <dxo-header-filter [allowSearch]="true"></dxo-header-filter>
            </dxi-column>
            <dxi-column dataField="manufacturer.name" [minWidth]="200" dataType="string" caption="Manufacturer">
                <dxo-header-filter [allowSearch]="true"></dxo-header-filter>
            </dxi-column>
            <dxi-column dataField="manufacturerSKU" [minWidth]="160" dataType="string" caption="Manufacturer SKU">
                <dxo-header-filter [allowSearch]="true"></dxo-header-filter>
            </dxi-column>
            <dxi-column [allowHeaderFiltering]="false" dataField="name" [minWidth]="220" dataType="string" caption="Name">
                <dxo-header-filter [allowSearch]="true"></dxo-header-filter>
            </dxi-column>
            <dxi-column dataField="category" [minWidth]="220" dataType="string" caption="Category">
                <dxo-header-filter [allowSearch]="true"></dxo-header-filter>
            </dxi-column>
            <dxi-column dataField="subcategory" [minWidth]="220" dataType="string" caption="Sub-Category">
                <dxo-header-filter [allowSearch]="true"></dxo-header-filter>
            </dxi-column>
        </dx-data-grid>
    </app-access>
</app-progress>