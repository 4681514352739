import { AfterViewInit, Component,EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Agency, Formulary } from 'src/app/shared/models';
import { LoginService, MonitorService, NavigationService } from 'src/app/shared/services';
import { DxFormComponent, } from 'devextreme-angular';
import { ToolbarItem } from 'devextreme/ui/html_editor';
import { FormCanDeactivate } from 'src/app/shared/utils/form.candeactivate';
import { ActivatedRoute, Router } from '@angular/router';
import { FormularyService } from 'src/app/shared/services/formulary.service';

@Component({
  selector: 'app-formulary-edit-details',
  templateUrl: './formulary-edit-details.component.html',
  styleUrls: ['./formulary-edit-details.component.scss']
})
export class FormularyEditDetailsComponent extends FormCanDeactivate implements AfterViewInit {
  submitMessage: string = 'Error updating agency';
  validate(): boolean {
    return (this.form && this.formulary && this.isValid) ?? false;
  }
  postExecute(): void {
    if (this.isNew) {
      this.navigationService.backoffice().formulary.details(this.formulary!.id);
    } else {
      super.postExecute();
    }
  }

  @ViewChild(DxFormComponent, { static: false })
  form: DxFormComponent | undefined;
  @Input()
  formulary: Formulary | undefined;

  isNew = false;

  @ViewChild('htmlEditor', { static: false }) htmlEditor: any;

  toolbarItems: ToolbarItem[] = [
    { name: 'undo' },
    { name: 'redo' },
    { name: 'separator' },
    { name: 'header', acceptedValues: [1, 2, 3, 4, 5] },
    { name: 'separator' },
    { name: 'bold' },
    { name: 'italic' },
    { name: 'underline' },
    { name: 'strike' },
    { name: 'separator' },
    { name: 'alignLeft' },
    { name: 'alignCenter' },
    { name: 'alignRight' },
    { name: 'alignJustify' }
  ];

  toolbar = {
    items: this.toolbarItems
  };

  @Output()
  sendUpdatedAgency = new EventEmitter<Agency>();

  creating = true; // Used to disable features that are unique to updating current customers
  formGroup!: UntypedFormGroup;
  
  constructor(
    monitor: MonitorService,
    login: LoginService,
    private formularyService: FormularyService,
    private navigationService: NavigationService,
    router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    super(monitor, login, router);
    this.formGroup = new UntypedFormGroup({});
  }

  ngAfterViewInit() {
    if(!this.formulary) {
      this.activatedRoute.parent?.paramMap.subscribe(async (params) => {
        let formularyId = parseInt(params.get('formularyId') ?? '0');

        this.formulary = await this.formularyService.getFormulary(formularyId, {});
      })
    }else {

    this.isNew = !this.formulary?.id;
    }

  }

  async submit(): Promise<boolean> {
    // get form data and put in db
    const formData: Formulary = this.form!.instance.option('formData');
      if (this.isNew) {
        this.formulary = await this.formularyService.createFormulary(formData);
      } else {
        this.formulary = await this.formularyService.updateFormulary(formData.id, formData);
      }
      return true;
  }
}
