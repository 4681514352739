import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IAccount, PurchaseRequisition, RequisitionStatus, PurchaseRequisitionLine, Agent, PurchaseRequisitionStatus } from 'src/app/shared/models';
import { IApprovalDetails } from 'src/app/shared/models/approval.overview.model';
import { ArraySortPipe } from 'src/app/shared/pipes';
import { AgentService, FormatNumberService, LoginService, MonitorService, NavigationService, OrderService } from 'src/app/shared/services';
import { AccountService } from 'src/app/shared/services/account.service';
import { ApprovalService } from 'src/app/shared/services/approval.service';
import { Feature } from 'src/app/shared/services/feature.service';
import { RequisitionService } from 'src/app/shared/services/requisition.service';
import { GenericComponent } from 'src/app/shared/utils/generic.component';

@Component({
	selector: 'app-purchase-requisition',
	templateUrl: './purchase-requisition.component.html',
	styleUrls: ['./purchase-requisition.component.scss']
})
export class PurchaseRequisitionComponent extends GenericComponent implements OnInit, OnDestroy
{
	account: IAccount | undefined;
	requisition: PurchaseRequisition | undefined;
	reqId: number | undefined;
	showBackButton: boolean = false;
	isClosed: boolean = false;
	requisitionType?: string;
	workingTitle: string | undefined = undefined;
	workingMessage: string | undefined = undefined;
	feature = Feature;
	subscriptions: Subscription[] = [];
	// A map to store the collapsed state for each supplier
	collapsedStates: Map<number, boolean> = new Map<number, boolean>();
	supplierOrders: any[] = [];

	approvalDetails!: IApprovalDetails;
	viewingAgent: Agent | undefined;
	tabValue: number = 1;
	decisionQueryParams?: boolean;

	routeParams!: Params;

	tabs: Tab[] = [
		{ id: 1, text: 'Overview' },
		{ id: 2, text: 'Approvers' },
		{ id: 3, text: 'Timeline' }
	]


	constructor(
		private agentService: AgentService,
		private orderService: OrderService,
		monitor: MonitorService,
		login: LoginService,
		private accountService: AccountService,
		protected router: Router,
		public numberFormat: FormatNumberService,
		private requisitionService: RequisitionService,
		private activatedRoute: ActivatedRoute,
		private navigationService: NavigationService,
		private approvalService: ApprovalService)
	{
		super(monitor, login);

		//TODO: Tech Debt: Refactor this to use a promise array and Promise.all
		this.subscriptions.push(activatedRoute.params.subscribe(async (params) =>
		{
			this.routeParams = params;
		}));
	}

	// Method to get the data for the component
	async getData(params: Params)
	{
		this.working = 555;
		try
		{
			this.account = await this.accountService.getAccountById(this.agentService.currentAccount!.id, { budget: true });
			this.viewingAgent = this.agentService.authenticatedAgent!;

			const checkParams = Number(params.id);
			if (params.id && !Number.isNaN(checkParams))
			{
				this.requisition = await this.orderService.getRequisition(this.account!.id, checkParams, { coreProduct: true, supplierProduct: true, purchaseOrder: true });
			}

			if (this.requisition?.purchaseRequisitionStatus == RequisitionStatus.Open)
			{
				this.isClosed = false;
				this.navigationService.practice(this.account!.id).requisition.review(this.requisition.id);
				return;
			}
			else
			{
				this.isClosed = true;
			}
			this.requisitionType = this.requisition?.purchaseRequisitionStatus!;

			if (this.isClosed && this.requisition?.purchaseRequisitionLines)
			{
				this.requisition.purchaseRequisitionLines = new ArraySortPipe().transform(this.requisition.purchaseRequisitionLines, 'supplierProduct.supplier.name', true);
				this.loadSupplierOrders();
			}

			const approvalData = await this.requisitionService.getApprovalDetails(this.account!.id, this.requisition!.id);

			if (approvalData.success && approvalData.value)
			{
				this.approvalDetails = approvalData.value as IApprovalDetails;
			}
			else
			{
				this.monitor.handleError(approvalData.messages.join('\n'));

			}
		}
		catch (error)
		{
			this.monitor.handleError((error as Error).message ?? "An error occurred while loading the purchase requisition");
		}

		this.working = 0;
	}

	loadSupplierOrders()
	{
		if (this.requisition?.purchaseRequisitionLines)
		{
			const supplierIds = Array.from(new Set(this.requisition?.purchaseRequisitionLines.map((line) => line.supplierProduct?.supplierId)));

			this.supplierOrders = supplierIds.map((id) => ({
				id: id,
				products: this.requisition?.purchaseRequisitionLines?.filter(l => { return l.supplierProduct?.supplierId === id; }),
				total: this.calcSupplierTotal(this.requisition?.purchaseRequisitionLines?.filter(l => { return l.supplierProduct?.supplierId === id; })),
				supplierName: this.requisition?.purchaseRequisitionLines?.find(l => l.supplierProduct?.supplierId === id)?.supplierProduct?.supplier?.name
			}));
		}
	}

	calcSupplierTotal(products?: PurchaseRequisitionLine[]): any | undefined
	{
		if (products)
		{
			var nullPrice = products.find(a => a.linePrice == null);
			if (nullPrice)
			{
				return 'TBD';
			}
			let calc = products.reduce((total, product) =>
			{
				return total + (product.linePrice!);
			}, 0)
			return (!isNaN(calc)) ? calc : undefined;
		}
		else
		{
			return 'TBD';
		}
	}

	ngOnDestroy(): void
	{
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	onActivate(component: any): void
	{
		component.account = this.account;
		component.requisition = this.requisition;
	}

	async ngOnInit(): Promise<any>
	{
		this.reqId = this.activatedRoute.snapshot.params.id;

		const state = history.state;
		this.showBackButton = state && state.fromSpecificPage;

		await this.getData(this.routeParams);

		if (this.approvalDetails?.autoApproved)
		{
			this.tabs = this.tabs.filter(tab => tab.id !== 2);
		}

		let queryParams = this.activatedRoute.snapshot.queryParams;

		if (queryParams?.approved)
		{
			this.decisionQueryParams = queryParams.approved === 'true';
		}

		this.approvalService.decisionMade$.subscribe((status: PurchaseRequisitionStatus) =>
		{
			//reload the data if we haven't been redirected to the order details page
			if (status === PurchaseRequisitionStatus.Pending)
			{
				this.getData(this.routeParams);
			}
		});
	}

	goBack()
	{
		window.history.back();
	}


	public shouldDisplaySupplierName(line: any, index: number): boolean
	{
		if (line.supplierProduct && line.supplierProduct.supplier)
		{
			if (index === 0)
			{
				return true;
			}
			else
			{
				if (this.requisition?.purchaseRequisitionLines)
				{
					const previousLine = this.requisition?.purchaseRequisitionLines[index - 1];
					if (previousLine && previousLine.supplierProduct && previousLine.supplierProduct.supplier)
					{
						return line.supplierProduct.supplier.name !== previousLine.supplierProduct.supplier.name;
					}
				}
			}
		}
		return false;
	}

	// Method to toggle the collapsed state of a supplier
	toggleCollapse(supplierId: number)
	{
		const currentState = this.isCollapsed(supplierId);
		this.collapsedStates.set(supplierId, !currentState);
	}

	// Method to check if a supplier is collapsed
	isCollapsed(supplierId: number): boolean
	{
		return this.collapsedStates.get(supplierId) || false;
	}

	tabSelection(event: any)
	{
		this.tabValue = event.addedItems[0].id;
	}

	orderDetails()
	{
		this.navigationService.practice(this.account!.id).order.details(this.requisition!.purchaseOrder!.id);
	}
	
}

export interface Tab
{
	id: number;
	text: string;
}