import { Component, EventEmitter, Input, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '@auth0/auth0-angular';
import { Subscription } from 'rxjs';
import { Agent, IAccount, Profile } from 'src/app/shared/models';
import { AgentService, CartService, NavigationService } from 'src/app/shared/services';
import { AccountService } from 'src/app/shared/services/account.service';
import { Feature, FeatureService } from 'src/app/shared/services/feature.service';
import { DropdownContent } from 'src/app/shared/user-type';

@Component({
  selector: 'app-practice-nav',
  templateUrl: './practice-nav.component.html',
  styleUrls: ['./practice-nav.component.scss']
})
export class PracticeNavComponent implements OnDestroy {
  @Input()
  agent: Agent | undefined = undefined;

  @Input()
  user: User | undefined;

  @Input()
  nochromeMode = false;

  @Input()
  mobileexpanded = false;

  @Input()
  account: IAccount | undefined;

  @Input()
  profile: Profile | undefined

  private subscriptions: Subscription[] = [];
  dropdownContent: DropdownContent[] = [];

  pendingApprovals: number = 0;

  activeRoute: string = "dashboard";
  
  constructor(
    private agentService: AgentService,
    public accounts: AccountService,
    private features: FeatureService,
    private cartService: CartService,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute
  ) {

    this.subscriptions.push(
      this.agentService.authenticated$.subscribe(async a => {
        this.agent = a;
        this.dropdownContent = [];
        this.dropdownContent = this.dropdownContent.filter(dc => !dc.claims.length || dc.claims.filter(c => features.check(a.features, c)).length === dc.claims.length);
      })
    );

    this.cartService.pendingApprovals.subscribe(pendingApprovalCount => {
      this.pendingApprovals = pendingApprovalCount;
    });

    this.navigationService.activeRoute$.subscribe(activeRoute => {
      this.activeRoute = activeRoute;
    });

    const a = this.agent ?? this.agentService.authenticatedAgent; 
    if (a) {
      this.dropdownContent = [
        {
          name: 'Menu',
          claims: [Feature.AccountRead],
          iconId: 'menu',
          children: [
            {
              name: 'Home',
              routeId: 'dashboard',
              claims: [],
              action: () => { this.toDashboard(); },
              iconId: 'home'
            },
            {
              name: 'Search',
              routeId: 'search',
              claims: [Feature.AccountRead],
              action: () => { this.toSearch(); },
              iconId: 'products'
            },
            {
              name: 'Requisitions',
              routeId: 'requisition',
              claims: [Feature.AccountRead],
              action: () => { this.toRequisitions(); },
              iconId: 'requisitions'
            },
            {
              name: 'Pending Approvals',
              routeId: 'pending-approvals',
              claims: [Feature.AccountRead],
              action: () => { this.toApprovals(); },
              iconId: 'approvals',
              hasNotification: true,
              notificationValue: this.pendingApprovals
            },
            {
              name: 'Purchase Orders',
              routeId: 'order',
              claims: [Feature.AccountRead],
              action: () => { this.toOrders(); },
              iconId: 'purchase-orders'
            },
            {
              name: 'Reports',
              routeId: 'reports',
              claims: [Feature.AccountRead],
              action: () => { this.toReports(); },
              iconId: 'reports'
            },
          ]
        }
      ];
    }

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  toggleMenu(value: boolean): void {
    this.mobileexpanded = value;
  }

  toDashboard() {
    this.navigationService.practice(this.agentService.currentAccount!.id).dashboard();
  }

  toSearch() {
    this.navigationService.practice(this.agentService.currentAccount!.id).search();
  }

  toRequisitions(){
    this.navigationService.practice(this.agentService.currentAccount!.id).requisition.list();
  }

  toOrders() {
    this.navigationService.practice(this.agentService.currentAccount!.id).order.list();
  }

  toReports() {
    this.navigationService.practice(this.agentService.currentAccount!.id).reports();
  }

  toApprovals() {
    this.navigationService.practice(this.agentService.currentAccount!.id).approvals();
  }

}
