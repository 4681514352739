import { ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";

/**
 * Traverses the entire route tree first by assuming you are at the root, if given param key is not found
 * it will then traverse the route tree assuming you are at the bottom of the structure
 * @param key name of the parameter you are seeking the value of
 * @param route route to search for params
 * @returns param value or undefined if param not found
 */
export function GetParamValueByKeyActivated(key: string, route: ActivatedRoute): string | undefined{
    let currentRoute: ActivatedRoute | null = route;

    while(currentRoute){
        if(currentRoute.snapshot.params[key]){
            return currentRoute.snapshot.params[key];
        }
        else{
            currentRoute = currentRoute.firstChild;
        }
    }

    currentRoute = route;

    while(currentRoute){
        if(currentRoute.snapshot.params[key]){
            return currentRoute.snapshot.params[key];
        }
        else{
            currentRoute = currentRoute.parent;
        }
    }

    return undefined;
}

/**
 * Traverses the entire route tree first by assuming you are at the root, if given param key is not found
 * it will then traverse the route tree assuming you are at the bottom of the structure
 * @param key name of the parameter you are seeking the value of
 * @param route route to search for params
 * @returns param value or undefined if param not found
 */
export function GetParamValueByKeySnapshot(key: string, route: ActivatedRouteSnapshot): string | undefined{
    let currentRoute: ActivatedRouteSnapshot | null = route;

    while(currentRoute){
        if(currentRoute.params[key]){
            return currentRoute.params[key];
        }
        else{
            currentRoute = currentRoute.firstChild;
        }
    }

    currentRoute = route;

    while(currentRoute){
        if(currentRoute.params[key]){
            return currentRoute.params[key];
        }
        else{
            currentRoute = currentRoute.parent;
        }
    }

    return undefined;
}