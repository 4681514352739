import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss']
})
export class BreadCrumbComponent {
  @Input() public breadcrumbItems: BreadCrumbItem[] = [];
  constructor() { 
  }
}

export class BreadCrumbItem {
  public label: string;
  public url?: string;
  public current: boolean;
  constructor(label: string, current: boolean, url?: string) {
    this.label = label;
    this.url = url;
    this.current = current;
  }
}
