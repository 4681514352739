import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import CustomStore from 'devextreme/data/custom_store';
import { DxDataGridComponent } from 'devextreme-angular';
import { Agency, Agent } from 'src/app/shared/models';
import { LoginService, MonitorService, NavigationService } from 'src/app/shared/services';
import { GenericComponent } from 'src/app/shared/utils/generic.component';
import { Feature } from 'src/app/shared/services/feature.service';
import { AgencyService } from 'src/app/shared/services/agency.service';

@Component({
  selector: 'app-agency-edit-agents',
  templateUrl: './agency-edit-agents.component.html',
  styleUrls: ['./agency-edit-agents.component.scss']
})
export class AgencyEditAgentsComponent extends GenericComponent {

  @ViewChild(DxDataGridComponent)
  dataGrid!: DxDataGridComponent;

  agency: Agency = {} as Agency;
  dataSource: any;
  activeFilter: any;
  working = 0;

  agent: Agent | undefined;
  feature = Feature;

  tab: 'details' | 'accounts' | 'agents' | 'access' | 'agent' = 'agents';
  
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private agencyService: AgencyService,
    monitor: MonitorService,
    private navigationService: NavigationService,
    login: LoginService) {
      super(monitor, login);

      this.activatedRoute.parent?.paramMap.subscribe(async (map) => {
        let agency = map.get('agencyId');

        this.agency = await this.agencyService.getAgencyById(parseInt(agency ?? '0'), {agents: true});

        this.dataSource = new CustomStore({
          loadMode: 'raw',
          load: () => {
            return this.agency.agents ?? [];
          },
        });
      })
  }

   // Navigate to agency page of selected agency
   onCellClick(event: any) {
   
    if (event.column.dataField === 'firstName' && event.rowType === 'data') {
      const agentId = event.data?.id;
      this.navigationService.backoffice().agency.agents.details(this.agency.id, agentId);
    }
  }

  newAgent(){
    this.navigationService.backoffice().agency.agents.new(this.agency.id);
  }

}



