import { LoginService, MonitorService } from "../services";

export abstract class GenericComponent  {
 
    constructor(
        protected monitor: MonitorService,
        login: LoginService) {
            login.tokenRefreshObservable$.subscribe(s => {
                if (this.working) {
                    this.working = 0;
                    this.monitor.handleInfo('Sorry we had to reauthenticate you, please try that again.');
                }
            });
    }

    public working = 0;
    protected startWorking(): void {
        this.working = 1;
    }
    protected setWorking(value: number): void {
        this.working = value;
    }
    protected endWorking(): void {
        this.working = 0;
    }

    protected postExecute(): void { } 

    protected async execute(callback: () => Promise<boolean>, errorMessage: string) {
        try {
            this.startWorking();
            if (await callback()) {
                this.postExecute(); 
            }
        } catch (error: any) {
            this.monitor.handleError(error, error?.error?.message ?? error.message);
            this.monitor.handleError(errorMessage);
        } finally {
            this.endWorking();
        }
    }

}