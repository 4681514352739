import { Component, Input, AfterViewChecked, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IAccount } from 'src/app/shared/models';
import { ActionWhenNoPriceExistsEnum } from 'src/app/shared/models/actionWhenNoPriceExists.model';
import { BudgetPeriod } from 'src/app/shared/models/budget.model';
import { AgentService, CartService } from 'src/app/shared/services';

@Component({
  selector: 'app-budget-preview',
  templateUrl: './budget-preview.component.html',
  styleUrls: ['./budget-preview.component.scss']
})
export class BudgetPreviewComponent implements OnInit, AfterViewChecked {

  @Input()
  budgetPeriod?: BudgetPeriod;

  @Input()
  totalUnknown: boolean = false;
  
  overBudget: boolean = false;

  account: IAccount | undefined;

  constructor(private cartService: CartService, private agentService: AgentService, private activatedRoute: ActivatedRoute) {
    this.account = this.agentService.currentAccount!;
  }

  ngOnInit() {

    this.cartService.cartChangedObservable$.subscribe(() => {
      if (this.budgetPeriod) {
        this.overBudget = ((this.budgetPeriod.totalSpent ?? 0) + this.budgetPeriod.currentTotalSpend) > this.budgetPeriod.periodBudget;
      }
    });
  }

  ngAfterViewChecked(): void
  {
    if (this.budgetPeriod) {
      this.overBudget = ((this.budgetPeriod.totalSpent ?? 0) + this.budgetPeriod.currentTotalSpend) > this.budgetPeriod.periodBudget;
    }
  }

  budgetUnknownValue() : number | string {
    return (this.account?.practice?.budgetInformation?.actionWhenNoPriceExists == ActionWhenNoPriceExistsEnum.ApplyConstantValue ) ? this.account.practice.budgetInformation.priceWhenNoPriceExists! : 'TBD';
  }
    
}
