
import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IAccount} from 'src/app/shared/models';
import { AccountService } from 'src/app/shared/services/account.service';
import { Feature } from 'src/app/shared/services/feature.service';
import { AgentService, MonitorService, NavigationService } from 'src/app/shared/services';
import { OrderService } from 'src/app/shared/services/order.service';

@Component({
	selector: 'app-order-list-view',
	templateUrl: './order-list-view.component.html',
	styleUrls: ['./order-list-view.component.scss']
})
export class OrderListViewComponent
{
	@Input()
	rowClass: ((rowData: any) => string) | undefined;

	accounts: IAccount[] = [];
	purchaseOrders: any[] = [];
	dataSource: any;
	feature = Feature;
	practiceId!: number;

	working = 0;

	constructor(
		private accountService: AccountService,
		private monitor: MonitorService,
		private navigationService: NavigationService,
		private agentService: AgentService,
		private orderService: OrderService,
		private activatedRoute: ActivatedRoute
	)
	{
	}

	onCellClick(event: any)
	{
		if (event.column.index === 0)
		{
			let isButton = event.event.target.parentElement.classList.contains("dx-treelist-expanded")
			||
			event.event.target.parentElement.classList.contains("dx-treelist-collapsed");

			if (isButton) return;

			const id = event.data?.orderId;

			if (id)
			{
				this.navigationService.practice(this.agentService.currentAccount!.id).order.details(id);
			}
		}
		else if (event.column.index === 4)
		{
			const id = event.data?.requisitionId;

			if (id)
			{
				this.navigationService.practice(this.agentService.currentAccount!.id).requisition.details(id);
			}
		}
	}

	async ngOnInit()
	{
		try
		{
			this.practiceId = this.agentService.currentAccount!.id
			this.working = 50;
			let purchaseOrders = await this.orderService.getPurchaseOrderList(this.practiceId);

			purchaseOrders.forEach(async p =>
			{
				// Extract quantity and supplier names
			
				let order = {
					id: p.id,
					parentId: p.parentId,
					orderId: p.purchaseOrderId,
					orderNumber: p.purchaseOrderNumber,
					requisitionId: p.purchaseRequisitionId,
					requisitionNumber: p.purchaseRequisitionNumber,
					statusId: p.purchaseOrderStatusId,
					status: p.purchaseOrderStatus,
					supplier: p.supplierName,
					total: p.total,
					createdBy: p.createdBy,
					createdOn: p.created ? new Date(p.created) : null,
					modifiedBy: p.modifiedBy,
					modifiedOn: p.modified ? new Date(p.modified) : null,
				}
				this.purchaseOrders.push(order);
			});
		}
		catch (error: any)
		{
			this.monitor.handleError(error, error?.error?.message ?? error.message);
		}
		finally
		{
			this.working = 0;
		}
	}
}