import { Component } from '@angular/core';

@Component({
  selector: 'app-generic-agent-dashboard',
  templateUrl: './generic-agent-dashboard.component.html',
  styleUrls: ['./generic-agent-dashboard.component.scss']
})
export class GenericAgentDashboardComponent {

}
