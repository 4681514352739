import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { DashboardComponent } from './components/dashboard/main/dashboard.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { AgencyEditComponent } from './components/agency/edit/agency-edit/agency-edit.component';
import { AgencyListViewComponent } from './components/agency/agency-list-view/agency-list-view.component';
import { RecoverPasswordComponent } from './components/auth/recover-password/recover-password.component';
import { PageNotFoundComponent } from './components/nav/page-not-found/page-not-found.component';
import { AccountEditComponent } from './components/account/edit/account-edit/account-edit.component';
import { AccountListViewComponent } from './components/account/account-list-view/account-list-view.component';
import { UnauthorizedComponent } from './components/nav/unauthorized/unauthorized.component';
import { InactiveAgentDashboardComponent } from './components/dashboard/inactive-agent-dashboard/inactive-agent-dashboard.component';
import { AgentAuthorizationGuard } from './shared/guard/agentAuthorization.guard';
import { AgencyEditDetailsComponent } from './components/agency/edit/agency-edit-details/agency-edit-details.component';
import { AgencyEditAccountsComponent } from './components/agency/edit/agency-edit-accounts/agency-edit-accounts.component';
import { AgencyEditAgentsComponent } from './components/agency/edit/agency-edit-agents/agency-edit-agents.component';
import { AgencyEditAccessComponent } from './components/agency/edit/agency-edit-access/agency-edit-access.component';
import { AgencyEditAgentDetailsComponent } from './components/agency/edit/agency-edit-agent-details/agency-edit-agent-details.component';
import { AccountEditDetailsComponent } from './components/account/edit/account-edit-details/account-edit-details.component';
import { AccountEditAgenciesComponent } from './components/account/edit/account-edit-agencies/account-edit-agencies.component';
import { AccountEditSuppliersComponent } from './components/account/edit/account-edit-suppliers/account-edit-suppliers.component';
import { AccountEditSupplierDetailsComponent } from './components/account/edit/account-edit-supplier-details/account-edit-supplier-details.component';
import { AccountEditAddressComponent } from './components/account/edit/account-edit-address/account-edit-address.component';
import { FormularyListViewComponent } from './components/formulary/formulary-list-view/formulary-list-view.component';
import { FormularyEditComponent } from './components/formulary/edit/formulary-edit/formulary-edit.component';
import { FormularyEditDetailsComponent } from './components/formulary/edit/formulary-edit-details/formulary-edit-details.component';
import { FormularyEditProductsComponent } from './components/formulary/edit/formulary-edit-products/formulary-edit-products.component';
import { AccountEditFormularyComponent } from './components/account/edit/account-edit-formulary/account-edit-formulary.component';
import { CatalogSearchComponent } from './components/catalog/catalog-search/catalog-search.component';
import { PurchaseRequisitionListComponent } from './components/account/purchase-requisition/purchase-requisition-list/purchase-requisition-list.component';
import { AccountEditFormularyProductsCompositeComponent } from './components/account/edit/account-edit-formulary-products-composite/account-edit-formulary-products-composite.component';
import { ReviewCartComponent } from './components/cart/review-cart/review-cart.component';
import { OrganizationEditFormularyConfigurationComponent } from './components/account/edit/organization-edit-formulary-configuration/organization-edit-formulary-configuration.component';
import { PracticeEditFormularyConfigurationComponent } from './components/account/edit/practice-edit-formulary-configuration/practice-edit-formulary-configuration.component';
import { ReviewOrderComponent } from './components/order/review-order/review-order.component';
import { OrderListViewComponent } from './components/order/order-list-view/order-list-view.component';
import { OrderDetailsComponent } from './components/order/order-details/order-details.component';
import { FAQComponent } from './shared/components/faq/faq.component';
import { AboutComponent } from './shared/components/about/about.component';
import { ContactComponent } from './shared/components/contact/contact.component';
import { PrivacyPolicyComponent } from './shared/components/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './shared/components/terms-of-use/terms-of-use.component';
import { PurchaseRequisitionComponent } from './components/purchase-requisition/purchase-requisition/purchase-requisition.component';
import { ReleaseNotesComponent } from './shared/components/release-notes/release-notes.component';
import { NavToLoginComponent } from './components/login/nav-to-login/nav-to-login.component';
import { DisplayMessageComponent } from './components/display-message/display-message.component';
import { PendingApprovalsListViewComponent } from './components/pending-approvals/pending-approvals-list-view/pending-approvals-list-view.component';
import { ReportingDashboardComponent } from './components/dashboard/reporting-dashboard/reporting-dashboard.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'authorize',
    component: LoginComponent,
    data: {
      title: 'Authorize',
    },
  },
  {
    path: 'redirect-login',
    component: NavToLoginComponent,
    data: {
      title: 'Redirecting to login...',
    },
  },
  {
    path: 'logout',
    redirectTo: '/',
  },
  {
    path:'backoffice',
    children: [
      {
        path: 'release-notes',
        component: ReleaseNotesComponent,
        canActivate: [AuthGuard, AgentAuthorizationGuard],
        data: {
          title: 'Release Notes',
        },
      },
      {
        path: 'message/:id',
        component: DisplayMessageComponent,
        data: {
          title: 'Display Message',
        },
      },
      {
        path: 'agency/:agencyId',
        component: AgencyEditComponent,
        canActivate: [AuthGuard, AgentAuthorizationGuard],
        data: {
          title: 'Agency Edit',
        },
        children: [{
          path: 'details',
          pathMatch: 'full',
          component: AgencyEditDetailsComponent,
          canActivate: [AuthGuard, AgentAuthorizationGuard],
          data: {
            title: 'Edit agency details'
          }
        }, {
          path: 'account',
          pathMatch: 'full',
          component: AgencyEditAccountsComponent,
          canActivate: [AuthGuard, AgentAuthorizationGuard],
          data: {
            title: 'Edit agency accounts'
          }
        }, {
          path: 'agents',
          pathMatch: 'full',
          component: AgencyEditAgentsComponent,
          canActivate: [AuthGuard, AgentAuthorizationGuard],
          data: {
            title: 'Edit agency agents'
          }
        }, {
          path: 'agents/:agentId',
          pathMatch: 'full',
          component: AgencyEditAgentDetailsComponent,
          canActivate: [AuthGuard, AgentAuthorizationGuard],
          data: {
            title: 'Edit Agent',
          },
        }, {
          path: 'agent/new',
          pathMatch: 'full',
          component: AgencyEditAgentDetailsComponent,
          canActivate: [AuthGuard, AgentAuthorizationGuard],
          data: {
            title: 'Edit Agent',
          },
        }, {
          path: 'access',
          pathMatch: 'full',
          component: AgencyEditAccessComponent,
          canActivate: [AuthGuard, AgentAuthorizationGuard],
          data: {
            title: 'Edit agency access'
          }
        }, {
          path: 'formulary/:formularyId',
          pathMatch: 'full',
          component: FormularyEditProductsComponent,
          canActivate: [AuthGuard, AgentAuthorizationGuard],
          data: {
            title: 'Edit agency access'
          }
        }]
      },
      {
        path: 'reports',
        component: ReportingDashboardComponent,
        canActivate: [AuthGuard, AgentAuthorizationGuard],
        data: {
            title: 'Reports'
          }
      },
      {
        path: 'agency',
        component: AgencyListViewComponent,
        canActivate: [AuthGuard, AgentAuthorizationGuard],
        data: {
          title: 'Agency List',
        },
      },
      {
        path: 'account',
        component: AccountListViewComponent,
        canActivate: [AuthGuard, AgentAuthorizationGuard],
        data: {
          title: 'Account List',
        },
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard, AgentAuthorizationGuard],
        data: {
          title: 'Dashboard',
        },
      },
      {
        path: 'account/:accountId',
        component: AccountEditComponent,
        canActivate: [AuthGuard, AgentAuthorizationGuard],
        data: {
          title: 'Account Edit'
        }, 
        children: [
          {
          path: 'details',
          component: AccountEditDetailsComponent,
          canActivate: [AuthGuard, AgentAuthorizationGuard],
          data: {
            title: 'Account Edit',
          }
        }, {
          path: 'agencies',
          component: AccountEditAgenciesComponent,
          canActivate: [AuthGuard, AgentAuthorizationGuard],
          data: {
            title: 'Account Edit',
          }
        },
        {
          path: 'suppliers',
          component: AccountEditSuppliersComponent,
          canActivate: [AuthGuard, AgentAuthorizationGuard],
          data: {
            title: 'Account Suppliers'
          }
        }, {
          path: 'suppliers/:accountSupplierId',
          pathMatch: 'full',
          component: AccountEditSupplierDetailsComponent,
          canActivate: [AuthGuard, AgentAuthorizationGuard],
          data: {
            title: 'Account Supplier'
          }
        }, {
          path: 'formulary',
          component: AccountEditFormularyComponent,
          canActivate: [AuthGuard, AgentAuthorizationGuard],
          data: {
            title: 'Manage formularies'
          },
          children: [
            {
              path: 'products',
              component: AccountEditFormularyProductsCompositeComponent,
              canActivate: [AuthGuard, AgentAuthorizationGuard],
              data: {
                title: 'View Composite Formulary'
              }
            },{
            path: ':formularyId',
            component: FormularyEditProductsComponent,
            canActivate: [AuthGuard, AgentAuthorizationGuard],
            data: {
              title: 'Manage Formulary Products'
            }
          }, 
          {
            path: 'configuration/organization',
            pathMatch: 'full',
            component: OrganizationEditFormularyConfigurationComponent,
            canActivate: [AuthGuard, AgentAuthorizationGuard],
            data: {
              title: 'Configure Formulary',
            },
          },
          {
            path: 'configuration/practice',
            pathMatch: 'full',
            component: PracticeEditFormularyConfigurationComponent,
            canActivate: [AuthGuard, AgentAuthorizationGuard],
            data: {
              title: 'Configure Formulary',
            },
          },
          ]
        }, {
          path: 'addresses',
          component: AccountEditAddressComponent,
          canActivate: [AuthGuard, AgentAuthorizationGuard],
          data: {
            title: 'Account Address'
          }
        }]
      },
      {
        path: 'formulary',
        component: FormularyListViewComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard, AgentAuthorizationGuard],
        data: {
          title: 'Manage Formularies'
        }
      }, {
        path: 'formulary/:formularyId',
        component: FormularyEditComponent,
        canActivate: [AuthGuard, AgentAuthorizationGuard],
        data: {
          title: 'Manage Formulary'
        }, children: [{
          path: 'details',
          component: FormularyEditDetailsComponent,
          canActivate: [AuthGuard, AgentAuthorizationGuard],
          data: {
            title: 'Manage Formulary'
          }
        }, {
          path: 'products',
          component: FormularyEditProductsComponent,
          canActivate: [AuthGuard, AgentAuthorizationGuard],
          data: {
            title: 'Manage Formulary'
          }
        }]
      },
      {
        path: 'accounts',
        component: AccountListViewComponent,
        canActivate: [AuthGuard, AgentAuthorizationGuard],
      }
    ]
  },
  {
    path: 'public',
    children: [
      {
        path: 'message/:id',
        component: DisplayMessageComponent,
        data: {
          title: 'Display Message',
        },
      },
      {
        path: 'login',
        component: LoginComponent,
        data: {
          title: 'Login',
        },
      },
      {
        path: 'recover-password',
        component: RecoverPasswordComponent,
        data: {
          title: 'Recover Password',
        },
      }, {
        path: 'unauthorized',
        component: UnauthorizedComponent,
        data: {
          title: 'Unauthorized'
        }
      }, {
        path: 'inactive',
        component: InactiveAgentDashboardComponent,
        data: {
          title: 'Inactive account'
        }
      }, 
      {
        path: 'faq',
        component: FAQComponent,
        data: {
          title: 'FAQs',
        },
      },
      {
        path: 'about',
        component: AboutComponent,
        data: {
          title: 'About Us',
        },
      },
      {
        path: 'contact',
        component: ContactComponent,
        data: {
          title: 'Contact Us',
        },
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
        data: {
          title: 'Privacy Policy',
        },
      },
      {
        path: 'terms-of-use',
        component: TermsOfUseComponent,
        data: {
          title: 'Terms of Use',
        },
      }
    ]
  },
  {
    path: ':profile/:accountId',
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard, AgentAuthorizationGuard],
        data: {
          title: 'Dashboard',
        },
      },
      {
        path: 'catalog/search',
        component: CatalogSearchComponent,
        canActivate: [AuthGuard, AgentAuthorizationGuard],
        data: {
          title: 'Home',
        },
      },
      {
        path: 'requisition',
        component: PurchaseRequisitionListComponent,
        canActivate: [AuthGuard, AgentAuthorizationGuard],
        data: {
            title: 'Requisitions'
        }
      },
      {
        path: 'requisition/:id/details',
        component: PurchaseRequisitionComponent,
        canActivate: [AuthGuard, AgentAuthorizationGuard],
        data: {
          title: 'Requisition'
        },
      },
      {
        path: 'requisition/:id/review',
        component: ReviewCartComponent,
        canActivate: [AuthGuard, AgentAuthorizationGuard],
        data: {
          title: 'Open Requisition',
        },
      },
      {
        path: 'order',
        component: OrderListViewComponent,
        canActivate: [AuthGuard, AgentAuthorizationGuard],
        data: {
            title: 'Purchase Orders'
        }
      },
      {
        path: 'order/:purchaseOrderId/details',
        component: OrderDetailsComponent,
        canActivate: [AuthGuard, AgentAuthorizationGuard],
        data: {
            title: 'Purchase Order Details'
          }
      },
      {
        path: 'order/review',
        component: ReviewOrderComponent,
        canActivate: [AuthGuard, AgentAuthorizationGuard],
        data: {
          title: 'Review Orders',
        },
      },
      {
        path: 'reports',
        component: ReportingDashboardComponent,
        canActivate: [AuthGuard, AgentAuthorizationGuard],
        data: {
            title: 'Reports'
          }
      },
      {
        path: 'pending-approvals',
        component: PendingApprovalsListViewComponent,
        canActivate: [AuthGuard, AgentAuthorizationGuard],
        data: {
          title: 'Pending Approvals',
        }
      },
      {
        path: 'message/:id',
        component: DisplayMessageComponent,
        data: {
          title: 'Display Message',
        },
      }, 
      {
        path: '**',
        redirectTo: '/public/message/INVALID_ROUTE'
      }
    ]
  },  
  {
    path: 'home',
    canActivate: [AuthGuard, AgentAuthorizationGuard],
    component: HomeComponent,
    data: {
      title: 'Home',
    },
  }, {
    path: 'http',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      onSameUrlNavigation: 'reload',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }