<app-progress [splash]="true" [value]="working" [splashOptions]="{ title: workingTitle, message: workingMessage }">
	<div class="standard-background">
		<div class="flex-row justify-even ml-auto mr-auto mt-large">
			<div class="cart-card entry">
				<div class="header flex-row justify-between no-wrap">
					<h3>OPEN REQUISITION</h3>
				</div>
				<div class="body">
					<div class="flex-row justify-between mb-small text rubik breadcrumb-area">
						<app-bread-crumb [breadcrumbItems]="breadCrumbItems"></app-bread-crumb>
					</div>
					<div *ngIf="requisition?.hasApprovalProcess" style="position: absolute; right: 30px; top: 45px; font-weight: 500; font-size: 15px;">
						This requisition is part of an ongoing approval process. <a style="color: #f26363; font-weight: normal" (click)="openModal('timeline')">View timeline</a>
					</div>
					<div class="flex-row justify-between requisition-details-header">
						<div class="flex-column">
							<label class="text text-500 rubik mb-small">Requisition Number</label>
							<div class="text text-300 mb-medium">
								{{ requisition?.requisitionNumber}}
							  </div>							  
						</div>
						<div class="flex-column">
							<label class="text text-500 rubik">
								Billing Address
							</label>
							<div class="text text-300" *ngIf="account?.practice?.billingContactName?.length">
								Attn: {{ account?.practice?.billingContactName }}
							</div>
							<div class="text text-300">
								{{ account?.practice?.billingAddress1 }}
							</div>
							<div class="text text-300" *ngIf="account?.practice?.billingAddress2?.length">
								{{ account?.practice?.billingAddress2 }}
							</div>
							<div class="text text-300">
								{{ account?.practice?.billingCity }}
								{{ account?.practice?.billingState }}
								{{ account?.practice?.billingZip }}
							</div>
							<div class="text text-300" *ngIf="account?.practice?.billingPhoneNumber?.length">
								{{ numberFormat.formatPhoneNumber(account?.practice?.billingPhoneNumber) }}
							</div>
							<div class="text text-300">
								{{ account?.practice?.billingEmail }}
							</div>
						</div>
						<div class="flex-column">
							<label class="text text-500 rubik mb-small ">
								Shipping Address
							</label>
							<div class="text text-300" *ngIf="account?.practice?.defaultShippingAddress?.contactName?.length">
								Attn: {{ account?.practice?.defaultShippingAddress?.contactName }}
							</div>
							<div class="text text-300" *ngIf="account?.practice?.defaultShippingAddress">
								{{ account?.practice?.defaultShippingAddress?.addressLine1 }}
							</div>
							<div class="text text-300"
								*ngIf="account?.practice?.defaultShippingAddress && account?.practice?.defaultShippingAddress?.addressLine2?.length">
								{{ account?.practice?.defaultShippingAddress?.addressLine2 }}
							</div>
							<div class="text text-300" *ngIf="account?.practice?.defaultShippingAddress">
								{{ account?.practice?.defaultShippingAddress?.city }}
								{{ account?.practice?.defaultShippingAddress?.state }}
								{{ account?.practice?.defaultShippingAddress?.zip }}
							</div>
							<div class="text text-300" *ngIf="!account?.practice?.defaultShippingAddress">
								Same as billing
							</div>
						</div>
						<div class="flex-row">
							<a (click)="reviewRequisition()"
							class="btn grey text text-500 kreon text-center mb-medium" style="height: 1rem;">
								Edit Requisition
							</a>
						</div>
					</div>
					<div class="order-review mb-medium">

						<div *ngIf="orderMessage?.length" class="spacer"></div>

						<div class="text text-800 kreon" *ngIf="orderMessage?.length">
							Order System Message(s)
							<div class="text kreon mt-small" *ngFor="let m of orderMessage" [innerHTML]="m"></div>
						</div>
						<div class="mb-large">
							<div class="spacer"></div>

						</div>
						<div class="round-wrapper mb-large">
							<div class="line" *ngFor="let s of supplierOrders; let i = index">
								<div class="flex-row line-title-row" [ngClass]="{ 'mt-large': i !== 0 }">
									<h4 class="text text-500 mr-small">{{ s.supplierName }}</h4>
									<span class="text text-300 rubik mr-small mt-auto mb-auto">({{ s.products.length }} lines)</span>
									<app-menu-bar [expanded]="isCollapsed(s.id)" [isArrow]="true"
												(click)="toggleCollapse(s.id)">
									</app-menu-bar>
									<app-product-line-total class="supplier-total" [label]="'Estimated ' + s.supplierName + ' Total:'" [total]="s.total" [bold]="true"></app-product-line-total>

								</div>
								<!--New Product Row-->
								<div class="line submission-details round-wrapper" [ngClass]="{ 'collapsed': isCollapsed(s.id), 'mb-medium': !isCollapsed(s.id) &&  i + 1 < supplierOrders.length  }">
									<div *ngFor="let l of s.products; let i = index">
										<div class="line submission-details round-wrapper"
											[ngClass]="{ 'collapsed': isCollapsed(l.supplierProduct!.supplier!.id!), 'mb-large':  i + 1 < s.products.length }"
											class="fill fill-white recommendation used round-wrapper order line-wrapper">
											<div class="flex-column">
												<div class="flex-row no-wrap">
													<div class="flex-row align-center" style="position: relative;">
														<img class="mr-medium product-image"
														    [src]="l.supplierProduct?.imageUrl ?? l.supplierProduct?.coreProduct?.imageUrl ?? 'assets/images/badges/badge.png'"/>
														<app-out-of-stock-tag [show]="l.supplierProduct?.stockQuantity === 0" floatDirection="right"></app-out-of-stock-tag>
													</div>
													<div class="flex-column mr-medium">
														<div class="flex-row no-wrap" style="height: 19px;">
															<div class="text product-name" [tooltip]="l.supplierProduct?.name">
																{{ l.supplierProduct?.name }}
															</div>
														</div>
														<div class="flex-row no-wrap" style="width: 620px; overflow-x: hidden;">
															<div class="flex-column">
																<div class="flex-row no-wrap">
																	<div class="text text-300 rubik manufacturer-details">
																		#{{ l.supplierProduct?.sku }} - {{
																		l.supplierProduct?.manufacturer
																		}}
																	</div>
																	<div class="flex-row no-wrap ml-small" style="height: 23px; position: relative;">
																		<app-product-tag *ngFor="let t of l.supplierProduct?.supplierProductTags"
																						[inline]="true" style="position: relative; top: 3px;"
																						[color]="t.c" [background]="t.b" [text]="t.t">
																		</app-product-tag>
																	</div>
																</div>
																<div class="supplier-details text text-300 price">
																	<span>{{(l.supplierProduct?.price | currency) ?? '$--'}}</span>
																</div>
																<div class="supplier-details text text-300 quantity">
																	<span>Quantity: {{l.quantity | number}}</span>
																</div>
															</div>
														</div>
													</div>

													<div class="supplier-area">
														<div class="header" style="display: none;">
															{{ l.supplierProduct?.supplier?.name }}
														</div>
														<div style="width: 100px; max-height: 60px;">
															<img style="width: 100px" [src]="l.supplierProduct?.supplier?.imageUrl"/>
														</div>
														<div class="sku">
															#{{ l.supplierProduct?.sku }}
														</div>
													</div>
													<div class="line-price">
														<app-product-line-total [label]="'Line Total:'" [total]="l.linePrice" [bold]="false"></app-product-line-total>
													</div>
												</div>
											</div>
										</div>
										<div class="line submission-details round-wrapper"
											[ngClass]="{ 'collapsed': isCollapsed(l.supplierProduct!.supplier!.id!), 'hidden':  i < s.products.length - 1 }"
											class="fill fill-white used round-wrapper supplier-bottom-total line-wrapper flex-row">
											<app-product-line-total [label]="'Estimated ' + s.supplierName + ' Total:'" [total]="s.total" [bold]="true"></app-product-line-total>
										</div>
									</div>
								</div>
								<div [ngClass]="{'hidden': !isCollapsed(s.id!)}" class="spacer"></div>
							</div>
							<div class="order-total flex-column mt-medium" style="display: grid;">
								<app-product-line-total class="total" label="Estimated Total:" [total]="requisition?.total" [bold]="true"></app-product-line-total>
							</div>
							<div class="warning-footer flex-row">
								<img [src]="'assets/images/icons/info.png'" width="20px"
									height="20px" class="info-image">
								<p>
									This is a price estimate, it does not include tax, shipping, or other price adjustments by supplier.
								</p>
							</div>
						</div>
						<div class="flex-row justify-between">
							<a class="btn gray text text-center text-white" style="height: 10px; display: inline; line-height: 12px;"
							*ngIf="requisition?.purchaseRequisitionLines?.length" (click)="cancel()">
								Cancel
							</a>

							<app-access [platformFeature]="feature.AccountRead" [accountFeature]="feature.RequisitionApprover"
										[redirectOnFailure]="false" noAccessMessage="No access to submit">
								<dx-button class="btn primary text text-center" style="margin: 0 10px 20px 0px;" [disabled]="buttonsDisabled"
								*ngIf="requisition?.purchaseRequisitionLines?.length" (click)="openModal('approval-modal')">
									Submit Requisition
								</dx-button>
							</app-access>
						</div>

					</div>
				</div>
			</div>
		</div>

	</div>
</app-progress>

<app-modal id="approval-modal" heading="Confirm Requisition Submission">
	<app-submission-details-form #submissionForm *ngIf="approvalDetails" [approvalDetails]="approvalDetails" [sendSubmission]="placeOrders" [errorMessage]="dialogBoxErrorMessage"></app-submission-details-form>
</app-modal>

<app-modal id="timeline" heading="Requisition Timeline" size="small">
	<app-approvals-timeline [timeline]="timelineDetails?.timeline || []"></app-approvals-timeline>
	<div class="buttons-container">
		<dx-button class="btn" style="float: right;" (click)="closeModal('timeline')">Close</dx-button>
	</div>
</app-modal>

<app-modal id="confirm" heading="Unable to Submit Requisition" size="small">
	<app-confirm-dialog [confirmBodyText]="requisitionSubmitUnavailableText"></app-confirm-dialog>
</app-modal>