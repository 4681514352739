import { Component, Input } from '@angular/core';
import { SupplierProduct } from 'src/app/shared/models';
import { ModalService } from 'src/app/shared/services';
import { AddToCartComponent } from '../../cart/add-to-cart/add-to-cart.component';

@Component({
  selector: 'app-supplier-product-card',
  templateUrl: './supplier-product-card.component.html',
  styleUrls: ['./supplier-product-card.component.scss']
})
export class SupplierProductCardComponent {

  @Input()
  product: SupplierProduct | undefined;
  @Input()
  displayAddToCart: boolean = true;

  @Input()
  displayVariations: boolean = true;

  @Input()
  allowQuickAdd = true;
  @Input()
  showSupplier = true;
  @Input()
  showManufacturer = true;
  @Input()
  size: 'regular' | 'small' = 'regular';
  @Input()
  nameIsLink = false;

  private _internalCount = 0;

  constructor(
    private modalService: ModalService
  ) 
  { 
    this._internalCount = AddToCartComponent._counter++;
  }

  // this will set the product image on an error loading the image
  imageError(e: Event): void {
    if (this.product) {
      this.product.imageUrl = 'assets/images/icons/yak-red.png';
    }
  }

  openModal(id: string): void {
    this.modalService.open(id);
  }

  getModalId(): string {
    return `add-to-cart-${this._internalCount}`;
  }

  getATCId(): string {
    return `atc-${this._internalCount}`;
  }

  getMode(): 'add' | 'add-to' {
    return (this.displayVariations && (this.product?.coreProduct?.listing?.productCount ?? 0) > 1) ? 'add-to' : 'add'
  }
}
