<nav class="navbar flex-row justify-between">
    <a class="menu badge" (click)="toHome()">
      <img src="assets/images/icons/yak-red-mini.png" class="nav-logo">
      <a routerLinkActive="active" class="drop-down width-medium height-medium text rubik text-500 mr-medium black ml-large" (click)="toHome()">Home</a>
    </a>
    <button *ngIf="!agent" class="menu btn pink rubik text text-500 text-upper mt-auto mb-auto" type="button" aria-label="Login"
      (click)="requestLogin()">
      Login
    </button>
  <button *ngIf="agent && !agent.active" class="btn pink text text-500 text-upper mt-auto mb-auto" id="logout" type="button"
    aria-label="Logout" (click)="logout()">
    Logout
  </button>
  <app-account-card class="ml-auto" *ngIf="agent?.active" [profile]="profile"></app-account-card>
  </nav>
