import { Component, EventEmitter, Input, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '@auth0/auth0-angular';
import { Subscription } from 'rxjs';
import { Agent, IAccount, Profile } from 'src/app/shared/models';
import { AgentService, NavigationService } from 'src/app/shared/services';
import { AccountService } from 'src/app/shared/services/account.service';
import { Feature, FeatureService } from 'src/app/shared/services/feature.service';
import { DropdownContent } from 'src/app/shared/user-type';
@Component({
  selector: 'app-gpo-nav',
  templateUrl: './gpo-nav.component.html',
  styleUrls: ['./gpo-nav.component.scss']
})
export class GpoNavComponent implements OnDestroy {

  @Input()
  agent: Agent | undefined = undefined;

  @Input()
  user: User | undefined;

  @Input()
  nochromeMode = false;
  @Input()
  mobileexpanded = false;

  @Input()
  account: IAccount | undefined;

  @Input()
  profile: Profile | undefined;

  private subscriptions: Subscription[] = [];
  dropdownContent: DropdownContent[] = [];
  responsiveDropdownContent: DropdownContent[] = [];

  activeRoute: string = "";
  
  constructor(
    private agentService: AgentService,
    public accounts: AccountService,
    private features: FeatureService,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute
  ) {
    this.subscriptions.push(
      this.navigationService.activeRoute$.subscribe(activeRoute => {
        this.activeRoute = activeRoute;
      }),
      
      this.agentService.authenticated$.subscribe(async a => {
        this.agent = a;
        this.dropdownContent = [];
        this.dropdownContent = this.dropdownContent.filter(dc => !dc.claims.length || dc.claims.filter(c => features.check(a.features, c)).length === dc.claims.length);
      }));

      this.responsiveDropdownContent = [{
        name: 'Menu',
        claims: [],
        iconId: 'menu',
        children: [
          {
            name: 'Home',
            routeId: 'dashboard',
            claims: [],
            action: () => { this.toDashboard();},
            iconId: 'home'
          },
          {
            name: 'Reports',
            routeId: 'reports',
            claims: [],
            action: () => { this.toReports();},
            iconId: 'reports'
          }]
      }];
  }
  
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  toggleMenu(value: boolean): void {
    this.mobileexpanded = value;
  }

  toDashboard() {
    this.navigationService.gpo(this.agentService.currentAccount!.id).dashboard();
  }

  toReports(){
    this.navigationService.gpo(this.agentService.currentAccount!.id).reports();
  }

  /**
   * This tracks url and determines which tab on the nav bar to be highlighted (when we switch accounts)
   * 
   * @param simpleChanges 
   */
  ngOnChanges(simpleChanges: SimpleChanges) {

    if (!window.location.pathname.includes(this.activeRoute) ) {
        
      if (window.location.pathname.includes('dashboard')) {
        this.activeRoute = 'dashboard';
      }

      else if (window.location.pathname.includes('reports')) {
        this.activeRoute = 'reports';
      }
      
    }

  }
}
