import { Component } from '@angular/core';
import { AccountService } from 'src/app/shared/services/account.service';

@Component({
  selector: 'app-dso-dashboard',
  templateUrl: './dso-dashboard.component.html',
  styleUrls: ['./dso-dashboard.component.scss']
})
export class DsoDashboardComponent {

  constructor(public accounts: AccountService) {}
}
