import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IAccount } from 'src/app/shared/models';
import { Feature } from 'src/app/shared/services/feature.service';
import { AgentService, MonitorService, NavigationService } from 'src/app/shared/services';
import { PurchaseRequisitionWithApprovalsListView } from 'src/app/shared/models/purchase.requisition.model';
import { OrderService } from 'src/app/shared/services/order.service';

@Component({
	selector: 'app-pending-approvals-list-view',
	templateUrl: './pending-approvals-list-view.component.html',
	styleUrls: ['./pending-approvals-list-view.component.scss']
})
export class PendingApprovalsListViewComponent implements OnInit
{
	@Input()
	rowClass: ((rowData: any) => string) | undefined;

	accounts: IAccount[] = [];
	agents: { key: number, username: string }[] = [];
	purchaseRequisitions: PurchaseRequisitionWithApprovalsListView[] = [];
	requisitions: any[] = [];
	feature = Feature;
	practiceId!: number;

	working = 0;

	constructor(
		private monitor: MonitorService,
		private navigationService: NavigationService,
		private agentService: AgentService,
		private orderService: OrderService,
		private activatedRoute: ActivatedRoute
	)
	{
	}

	getApprovers(approversCsv: any)
	{
		let arr: any[] = [];

		let approvers = approversCsv.split(',');

		approvers.forEach((a: any) =>
		{
			var approverApproval = a.split(';');

			let record = {
				name: approverApproval[0],
				status: approverApproval[1]
			};

			arr.push(record);
		})

		return arr;
	}

	onCellClick(event: any)
	{
		if (event.column.index === 0)
		{
			let isButton = event.event.target.parentElement.classList.contains("dx-treelist-expanded")
			||
			event.event.target.parentElement.classList.contains("dx-treelist-collapsed");

			if (isButton) return;

			const reqId = event.data?.requisitionId;

			if (reqId)
			{
				this.navigationService.practice(this.practiceId).requisition.details(reqId);
			}
		}
	}

	async ngOnInit()
	{
		try
		{
			this.practiceId = this.agentService.currentAccount!.id
			this.working = 50;

			this.purchaseRequisitions = await this.orderService.getPendingPracticePurchaseRequisitionWithApprovals(this.practiceId);

			this.purchaseRequisitions.forEach(async p =>
			{
				var req = {
					id: p.id,
					parentId: p.parentId,
					requisitionId: p.purchaseRequisitionId,
					requisitionNumber: p.purchaseRequisitionNumber,
					statusId: p.purchaseRequisitionStatusId,
					status: p.purchaseRequisitionStatus,
					supplier: p.supplierName,
					total: p.total,
					approval: p.approval,
					createdBy: p.createdBy,
					createdOn: p.created ? new Date(p.created) : null,
					modifiedBy: p.modifiedBy,
					modifiedOn: p.modified ? new Date(p.modified) : null,
				}

				this.requisitions.push(req);
			});
		}
		catch (error: any)
		{
			this.monitor.handleError(error, error?.error?.message ?? error.message);
		}
		finally
		{
			this.working = 0;
		}
	}
}