import { Injectable } from '@angular/core';
import { isNumber } from '@microsoft/applicationinsights-core-js';

export enum NumberFormatType {
  currency,
  phoneNumber
}

@Injectable()
export class FormatNumberService {

  constructor(
    // private currencyPipe: CurrencyPipe
  ) { }

  public formatCurrency(value: string | number | undefined): string {

    if (value === undefined) {
      return '';
    }

    let isNegative: boolean = false;
    if (isNumber(value)) {
      isNegative = value < 0;
      value = value.toString();
    } else {
      isNegative = (value as string).indexOf('-') >= 0;
    }

    let newVal = value.replace(/[^0-9.]+/g, '');

    if (newVal.length === 0) {
      newVal = '';
    } else {
      const f = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      });

      if (isNegative) {
        newVal = `-${newVal}`;
      }

      newVal = f.format(parseFloat(newVal));
    }

    return newVal;
  }

  public formatPhoneNumber(value: string | undefined): string {
    if (value === undefined) {
      return '';
    }

    let newVal = value.replace(/\D/g, '');

    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, '($1)');
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
    } else {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    }
    return newVal;
  }

  public getNumber(value: string | undefined, type: NumberFormatType): string {
    if (value === undefined) {
      return '';
    }

    switch (type) {
      case NumberFormatType.phoneNumber:
        return value.replace(/[^+\d]+/g, '');
        break;
      case NumberFormatType.currency:
      default:
        return value.replace(/[^0-9.]+/g, '');
        break;
    }
  }
}
