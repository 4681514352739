import { AuditInfo } from ".";

export class Address extends AuditInfo {
    public addressLine1!: string;
    public addressLine2!: string;
    public city!: string;
    public state!: string;
    public zip!: string;
    public addressType!: AddressType;
    public active!: boolean;
    public contactName?: string;
    public email?: string;
    public phoneNumber?: string;
}

export class AddressType {
    public static Shipping: string = 'Shipping';
    public static Billing: string = 'Billing';
    public static Invoicing: string = 'Invoicing';
}