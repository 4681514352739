<div class="main budget-component-style" [ngClass]="(!budgetPeriod) ? 'card-wrapper-no-budget-period' : 'card-wrapper'" *ngIf="budgetPeriod">
    <h2 class="footer-title">Budget Overview</h2>
    <div class="title-area">
        <div class="regular-text">{{budgetPeriod.budgetPeriodTitle}}</div>
        <div class="regular-text grey light-font-weight">{{budgetPeriod.budgetPeriodStartDate | date}} - {{budgetPeriod.budgetPeriodFinishDate | date}}</div>
    </div>
    <div class="total-area">
        <div class="line-space-between">
            <div class="spent regular-text grey light-font-weight">Spent<span *ngIf="overBudget"> | </span><span *ngIf="overBudget" class="warning-message">over budget</span></div>
            <div class="total regular-text grey light-font-weight">Total</div>
        </div>
        <div class="line-space-between">
            <div class="subheader-text">
                <span>{{(totalUnknown ? budgetUnknownValue() : (budgetPeriod.currentTotalSpend + (budgetPeriod.totalSpent ?? 0) | currency))}}</span>
            </div>
            <div class="budget-totals subheader-text">
                {{budgetPeriod!.periodBudget | currency : 'USD' : 'symbol' : '2.0-2'}}
            </div>
        </div>
    </div>
</div>
<div class="display-no-budget-period" *ngIf="!budgetPeriod">
    We could not find a budget period for {{account?.name}}
</div>



