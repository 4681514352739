import { CatalogApiConfig } from 'src/app/shared/api';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, SkipSelf } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CoreProduct, SupplierProduct } from '../models';
import { AgentService } from './agent.service';

export interface CatalogLoadOptions {
    accountId: number;
    recommendedProduct?: boolean;
    recentProduct?: boolean;
    mostOrderedProduct?: boolean;
    pageSize?: number;
    pageNumber?: number;
    coreProducts?: boolean;
    listingId?: number;
    includeListing?: boolean;
    isHomePage: boolean;
    supplierId?: number;
}

@Injectable()
export class CatalogService {
    constructor(
        private http: HttpClient,
    ) {

    }
    
    private buildParams(options: CatalogLoadOptions | undefined, searchText: string | undefined = undefined): HttpParams {
        let params = new HttpParams();
    
        if (options) {
            if (options.accountId) {
                params = params.append('accountId', options.accountId);
            }
            if (options.recommendedProduct) {
                params = params.append('includerecommended', true);
            }
            if (options.recentProduct) {
                params = params.append('includerecent', true);
            }
            if (options.mostOrderedProduct) {
                params = params.append('includemost', true);
            }
            if (options.pageNumber) {
                params = params.append('skip', options.pageNumber);
            }
            if (options.pageSize) {
                params = params.append('take', options.pageSize);
            }
            if (options.listingId) {
                params = params.append('listingId', options.listingId);
            }
            if (options.isHomePage) {
                params = params.append('isHomePage', true);
            }
            if(options.supplierId) {
                params = params.append('supplierId', options.supplierId);
            }
            params = params.append('coreProducts', options.coreProducts ?? false);
            params = params.append('includeListing', options.includeListing ?? true);
        }

        if(searchText && searchText != '') {
            params = params.append('searchText', searchText);
        }

        return params;
    }
    
    public get(searchText: string, options: CatalogLoadOptions): Promise<CoreProduct[] | SupplierProduct[]> {
        const params = this.buildParams(options, searchText);
        return lastValueFrom(this.http.get<CoreProduct[] | SupplierProduct[]>(CatalogApiConfig.getFullUrl(`search`), { params: params }));
    }

    public getCoreProduct(id: number, options: CatalogLoadOptions): Promise<CoreProduct> {
        const params = this.buildParams(options);
        return lastValueFrom(this.http.get<CoreProduct>(CatalogApiConfig.getFullUrl(`core/${id}`), { params: params }));
    }

    public getSupplierProduct(id: number, options: CatalogLoadOptions): Promise<SupplierProduct> {
        const params = this.buildParams(options);
        return lastValueFrom(this.http.get<SupplierProduct>(CatalogApiConfig.getFullUrl(`supplier/${id}`), { params: params }));
    }
}