import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Agent, Role, Profile, Agency } from 'src/app/shared/models';
import { MonitorService, AgentService, LoginService, NavigationService } from 'src/app/shared/services';
import { DxFormComponent, } from 'devextreme-angular';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { Feature } from 'src/app/shared/services/feature.service';
import { FormCanDeactivate } from 'src/app/shared/utils/form.candeactivate';
import dxForm from 'devextreme/ui/form';

@Component({
  selector: 'app-agency-edit-agent-details',
  templateUrl: './agency-edit-agent-details.component.html',
  styleUrls: ['./agency-edit-agent-details.component.scss']
})

export class AgencyEditAgentDetailsComponent extends FormCanDeactivate {
  submitMessage: string = 'Error updating agent';
  validate(): boolean {
    return ((this.agentForm && this.agent) ?? false) && this.isValid;
  }
  postExecute(): void {
      super.postExecute();
  }

  @ViewChild(DxFormComponent, { static: false })
  agentForm: DxFormComponent | undefined;

  @ViewChild(dxForm, { static: false })
  agentFormAlt: dxForm | undefined;

  @Input()
  agentId: number | undefined;

  @Input()
  agency: Agency | undefined;

  agent: Agent | undefined;

  isPlatformAgency: boolean | undefined = false;
  details: string = 'agent';

  currEmail: string = '';

  currentFeatures: any[] = [{ id: 64, name: 'Catalog Approver', checked: false }]

  roles: Role[] = [];
  roleDropDown: string[] = [];
  formValid = false;

  feature = Feature;
  postSaveCommand: any[] = [];

  constructor(
    private route: ActivatedRoute,
    monitor: MonitorService,
    login: LoginService,
    private agencyService: AgencyService,
    private agentService: AgentService,
    router: Router,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute
  ) {
    super(monitor, login, router);

    this.activatedRoute.parent!.paramMap.subscribe(async params => {
      const agencyId = params.get('agencyId');

      if(agencyId !== null){
        try{
          this.agency = await this.agencyService.getAgencyById(parseInt(agencyId), {});
        }
        catch (error: any){
          this.monitor.handleError(error, 'Error fetching Agency.');
        }
      }
    })
    
    this.route.params.subscribe(async (params) => {
      try {
        this.working = 30;

        this.roles = [{ id: 0, name: 'None' }];

        (await this.agentService.getRoles(true, 'platform')).forEach(r => this.roles.push(r));

        this.isPlatformAgency = !!this.agency?.platformAgency;

        // If a agency Id was supplied in the route populate the form fields with current values
        if (params.agentId !== 'new' && params.agentId !== undefined) {
          this.agent = await this.loadAgent(params.agentId);
          if (this.agent?.lastLogin) {
            this.agent.lastLogin = this.agent.lastLogin; // dev express recognizes this as a string if it isn't converted
          }
          if (!this.agent.role) {
            this.agent.role = 'None';
          }
          if (this.agent.features) {
            this.populateCheckBoxes(this.agent.features);
          }
        } else {
          // if no agentId in params then we are creating a new agent object
          this.agent = {
            active: true,
            id: undefined,
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            company: '',
            userName: '',
            role: '',
            features: 0,
            accountFeatures: [],
            accountRoles: [],
            adminRole: '',
            agency: undefined, 
            customUsername: false
          }
          //check if agency exists, is it a platform agency
          if (this.agency?.id) {
            this.isPlatformAgency = (await this.agencyService.getAgencyById(this.agency!.id, {})).platformAgency;
          }
          
          if (typeof this.isPlatformAgency == undefined) {
            this.isPlatformAgency = false;
          }
        }

      } catch (error: any) {
        this.navigationService.backoffice().message.invalidRoute();
        this.monitor.handleError(error, error?.error?.message ?? error.message);
      } finally {
        this.working = 0;
      }
    });
    this.agentForm?.onFieldDataChanged.subscribe((event) => {
      if (!this.agent?.customUsername) {
        this.agentFormAlt?.updateData("email", this.agentForm?.formData.email);
      }
    })
  }

  updateUsername(event: any) {
    let inputValue = event.data;
 
    let email = this.agentForm?.instance.getEditor("email");
    let emailFieldInput = document.getElementsByName("email")[0] as HTMLInputElement;

    if (!this.agent?.customUsername && event.target.name == "email") {

      if (inputValue == null) {
        let username = this.agentForm?.instance.getEditor("userName");
        
        username?.option("value", emailFieldInput.value);

        if (emailFieldInput.value == "") {
          username?.option("value", "");
        }
        this.currEmail = username?.option("value");


      } else {
        let username = this.agentForm?.instance.getEditor("userName");
        username?.option("value", emailFieldInput.value);
      }
    } else {
      return;
    }
  }

  validateEmail(params: any): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!params.value) {
      params.rule.message = 'Email is required';
      return false;
    } else
      if (!emailRegex.test(params.value)) {
        params.rule.message = 'Email is invalid';
        return false;
      }
    return true;
  }

  async loadAgent(id: number) {
    return await this.agentService.getAgentById(id, { activities: true, features: true, roles: true });
  }

  async submit(): Promise<boolean> {
    this.isValid = true;
    try {
      const formData: Agent = this.agentForm!.instance.option('formData');
    if (this.agent!.id) {
      // update agent
      await this.agentService.postAgent(this.agent!.id, formData);
      this.monitor.handleSuccess('Agent Updated');
    } else if (this.agency!.id) {
      this.agent = await this.agencyService.createAgent(this.agency!.id, formData);
      this.monitor.handleSuccess('Agent Created');
      this.postSaveCommand =['/agency', this.agency!.id, 'agent', this.agent.id];
    }

    // save agent features
    this.currentFeatures.forEach(async f => {
      if (this.agent?.id && f.checked) {
        await this.agentService.postFeature(this.agent.id, f.id);
      } else if (this.agent?.id) {
        await this.agentService.deleteFeature(this.agent.id, f.id);
      }
    });

    //save role for agent
    this.roles.forEach(async r => {
      if (this.agent?.id && r.id && formData?.role == r.name) {
        await this.agentService.postRole(this.agent.id, r);
      } else if (this.agent?.id && !r.id && this.agent?.role == r.name) {
        await this.agentService.deleteRole(this.agent.id);
      }
      this.postSaveCommand = ['/agency', this.agency!.id, 'agents'];
    });
    this.isValid = false;
    this.navigationService.backoffice().agency.agents.list(this.agency!.id);

    return true;

    }
    catch (error: any) {
      this.monitor.handleError(error.error ? error.error.message : error.error);
      return false;
    }
  }

  // create checkboxes based on list of features array (on catalog approver exists for now)
  populateCheckBoxes(features: number) {
    this.currentFeatures.forEach(cf => {
      cf.checked = (features & cf.id) === cf.id;
    });
  }

  // edit list of features for agent based on checkboxes ()
  checkboxCheck(f: any, e: any) {
    if (this.agent) {
      if (e.value && (this.agent.features & f.id) !== f.id) {
        this.agent.features |= f.id;
        f.checked = e.value;
      } else if (!e.value && (this.agent.features & f.id) === f.id) {
        // remove feature from agent
        this.agent.features -= f.id;
        f.checked = e.value;
      }

      let result = this.agentForm?.instance.validate();
      if (result?.brokenRules?.length) {
        this.formValid = false;
      } else {
        this.formValid = true;
      }

    }
  }

  hasFeature(name: string): boolean {
    const f = this.currentFeatures.find(cf => cf.name === name).id;
    if (this.agent) {
      return (this.agent.features | f) === f;
    } else {
      return false;
    }
  }

  changeFormTab(details: string) {
    this.details = details;
  }

  onFieldDataChanged(e: any) {
    super.onFieldDataChanged(e);

    // manully checking if Platform Role is set since validation woon't work across the 2 tabs unless both are navigated to first
    // this handles the validation check directly
    if(this.agency?.platformAgency){
      const formData = this.agentForm?.instance.option('formData');
      if(formData.role == '' || formData.role == 'None'){
        this.isValid = false;
      }
    }

    if (!this.agent?.customUsername) {
      if (e.dataField == "email") {
        let username = this.agentForm?.instance.getEditor("userName");
        username?.option("value", e.value);
      }
    }

    if (e.dataField == "customUsername") {
      if (e.value) {
        let username = this.agentForm?.instance.getEditor("userName");
        username?.option("value", "");
      } else {
        let username = this.agentForm?.instance.getEditor("userName");
        let email = this.agentForm?.instance.getEditor("email");
        username?.option("value", email?.option("value"));
      }
    }
    

  }
}

