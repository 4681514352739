<div class="modal">
    <div class="modal-body {{ size }}" [ngClass]="{ 'headless': !heading?.length, 'hide-overflow': !showOverflow }">
        <div class="modal-heading flex-row no-wrap" *ngIf="heading?.length">
            <h4>{{ heading }}</h4>
            <div *ngIf="!hideClose" class="ml-auto close" (click)="close()">
                X
            </div>
        </div>
        <div class="modal-content text rubik">
            <ng-content>

            </ng-content>
        </div>
        <div class="flex-row" *ngIf="confirm">
            <button class="btn secondary text text-center mb-medium ml-auto mr-large"
            style="background: #1B365D!important; color: #fff; " (click)="onConfirmClick()">
                Confirm
            </button>
            <button class="btn secondary text text-center mb-medium mr-auto" (click)="onCancelClick()"
            style="background: #1B365D; color: #fff; ">
                Cancel
            </button>
           
        </div>
    </div>
</div>
<div class="modal-background"></div>