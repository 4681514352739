<app-access [platformFeature]="feature.AgencyUpdate" [redirectOnFailure]="true">
    <app-progress [splash]="true" [value]="working">
      <div class="account-edit flex-column">
        <div class="header flex-row no-wrap justify-between">
          <H3>Formularies</H3>
        </div>
        <div class="body info">
          <dx-data-grid [showBorders]="true" [showColumnLines]="true" [dataSource]="dataSource"
            [allowColumnResizing]="true"
            (onCellClick)="onCellClick($event)"  (onContentReady)="onContentReady($event)" [wordWrapEnabled]="false">
            <dxo-search-panel [visible]="true" [width]="240"></dxo-search-panel>
            <dxo-paging [pageSize]="10" [pageIndex]="0"></dxo-paging>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-filter-row [visible]="true" [applyFilter]="'auto'"></dxo-filter-row>
  
            <dxi-column [sortIndex]="0" sortOrder="asc" dataField="name" [width]="580" dataType="string" [allowHeaderFiltering]="true" caption="Name"></dxi-column>
            <dxi-column dataField="active" dataType="boolean" [alignment]="'left'" [filterValue]="true" [falseText]="'No'"
              [trueText]="'Yes'" [allowHeaderFiltering]="false" [allowFiltering]="true" caption="Active">
            </dxi-column>
          </dx-data-grid>
          <dx-button class="mt-large" [width]="240" (click)="newFormulary()">Add New Formulary</dx-button>
        </div>
      </div>
    </app-progress>
  </app-access>