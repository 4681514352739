import { Pipe, PipeTransform } from '@angular/core';
import { NameId } from '../models';

@Pipe({
    name: 'nameIdFilter',
    pure: false
})
export class NameIdFilterPipe implements PipeTransform {
    transform(array: NameId[], term: string): NameId[] {
        term = (term ?? '').toLowerCase();
        if (term?.length) {
            return array.filter(a => a.name.toLowerCase().indexOf(term) >= 0);
        } else {
            return array;
        }
    }
}

