<app-access [platformFeature]="feature.AgencyUpdate" [redirectOnFailure]="true">
    <div class="body" id="version-notes">
        <h3>Version 1.0.23 Notes</h3>
        <p>Release Date: 1/26/2024</p>
        <br>
        <p>Fixed: Platform agency checkbox locked after agency is initially created.</p>
        <p>Fixed: When there is only 1 account for the agent, don't show switch account button</p>
        <p>Fixed: Fixed invite and reset password email templates so they display username when there is a custom username.</p>
        <p>Fixed: Fixed issues that casued error when removing account access for an agent.</p>
        <p>Fixed: We only allow agents to navigate to the practice they have access to.</p>
        <p>Fixed: Preventing users from loggin in if agency is not live.</p>
        <p>Fixed: Fixed issue that was sending user to 'no access' message even when they have access to an account</p>
        <p>Fixed: No longer generating invite link for agent form if the agecy is not live.</p>
        <p>Fixed: Activation link cannot to be used more than once.</p>
        <p>Fixed: no longer immediatly sending user to login screen when they are logged out. We are sending them to home screen instead.</p>

        <br>

        <h4 style="font-size: 1.25rem">Known Issues</h4>
        <p>Issue: Accounts => practice => formulary sidebar tab => formulary top tab does not populate</p>
        <p>Issue: Update variation modal closes when it shouldn't when there are active lines in the variation pop up. Specifically when navigating there from the cart 'change' button</p>
        <p>Issue: When a regular user without access to an account logs in, they are stuck on the endless jumping yalk until they refresh. in this scenario, user should be redirected to inactive dashboard screen.</p>
        <p>Issue: When on final review screen for requisition, if you adjust quantity in the cart variation, the quantity won't change until you refresh or return to cart review screen</p>
        <p>Issue: Requisition list screen: 'submitted by' column currently displays name of user who created the requisition, not who submitted it.</p>
        <p>Issue: If you remove a product from the quick view cart variation modal instead of on the optimize screen while on the optimize screen, the product won't be removed until you refresh</p>
        <p>Issue: When navigating to the variations screen from the cart quick view, if you add products to the cart from the variations list, the modal closes after adding the products</p>
        <p>Issue: Variations view occasionally closes on an add click instead of explicit close click</p>
        <p>Issue: When create a new account: unless we have a parent, we cant access/set account formulary, setting billing address should default the shipping to "Same as"</p>
        <p>Issue: Updating an account without parent, does not make Save button available to be able to save.</p>
        <p>Issue: Even though cXML is the default submission method for Dental City, it is defaulting to Email</p>
        <p>Issue: Save button on account form is enabled even after removing account name.</p>
        <p>Issue: On the PO email that is sent, Contact is still empty instead of showing the contact's name. the submission person should be the contact, as long as we are properly tracking who submitted the order, not who started the order.</p>
        <br>

        <h3>Version 1.0.22 Notes</h3>
        <p>Release Date: 1/18/2024</p>
        <br>
        <p>New: Added a new product search tab for searching by supplier. This tab contains a new dropdown to filter search results based on supplier.</p>
        <p>New: Agency Go Live feature. new users are not sent invite emails untill their ageny is set to 'live'</p>
        <p>New: Saving the date the agent is verified and signup for sherpa.</p>
        <p>New: Displaying invite link on agent form if the agent has not signed up yet.</p>
        <p>New: Created appSetting service to get appSetting values.</p>
        <p>New: Condensed the number of tabs in the product search to three, recommended, recent and frequently ordered are now search purchase history.</p>

        <br>
        <p>Fixed: Fixed issue preventing 'last login' to appear for users in agent list and agent form</p>
        <p>Fixed: No longer using GetEnvironmentVaraible method to get app setting values from server</p>

        <br>


        <h3>Version 1.0.21 Notes</h3>
        <p>Release Date: 1/9/2024</p>
        <br>
        <p>New: Added ability for agents to utilize custom usernames and share emails using the custom username field on agents</p>
        <p>New: Users must sign in with either a username or an email, but not both</p>
        <p>New: Username mirrors email when custom username is set to false</p>

        <br> 
        <p>Fixed: Resetting the password of a user can be done with a username or an email</p>

        <br>

        <h3>Version 1.0.20 Notes</h3>
        <p>Release Date: 12/21/2023</p>
        <br>
        
        <p>Fixed: New users with no access are directed to message screen. no longer getting stuck on endless running yak</p>
        <p>Fixed: When a different user logs in to a new tab on the browser, the original user is redirected to a message screen when they perform an action.</p>
        <p>Fixed: Using different language on set password screen when user is activating account vs when user is resetting password.</p>
        <p>Fixed: Reset password link is no longer using the same route as the activation link.</p>
        <p>Fixed: When user's session is expired or they are logged out in a different tab, they get redirected to message screen with a link to log bacck in</p>
       
        <br>
        <h3>Version 1.0.19 Notes</h3>
        <p>Release Date: 12/7/2023</p>
        <br>
        <p>New: Agent registration flow has been consolidated to 1 verification email, rather than having to verify first then reset password.</p>
        <p>New: The password reset styling matches that of the login page.</p>
        <p>New: A page to display user messages has been created.</p>

        <br>
        <p>Fixed: Reset password email sends the correct link.</p>
        <p>Fixed: When creating or logging into another agent with a tab already opened and logged in, the original tab now refreshes to show the newly logged in agent's dashboard.</p>

        <br>

        <h3>Version 1.0.18 Notes</h3>
        <p>Release Date: 11/16/2023</p>
        <br>
        <p>Fixed: Added unique ID to end of name of all cxml docs that hit the fullfillment controller.</p>
        <p>Fixed: Saved PayloadID as integrationID on submissionAction from cxml.</p>
        <br>


        <br>
        <p>Fixed: Added unique ID to end of name of all cxml docs that hit the fullfillment controller.</p>
        <p>Fixed: Saved PayloadID as integrationID on submissionAction from cxml.</p>
        <br>
        
        <br>
        <h3>Version 1.0.17 Notes</h3>
        <p>Release Date: 10/30/2023</p>

        <br>
        <p>Fixed: When adding multiple lines to a fresh cart, no longer failing to create requisition on the active lines in the variation.</p>
        <br>
        
        <h3>Version 1.0.16 Notes</h3>
        <p>Release Date: 10/26/2023</p>

        <br>
        <p>Fixed: No longer getting endless loading when products are adding or updating on an already existing requisition.</p>
       
        <br>
        <h3>Version 1.0.15 Notes</h3>
        <p>Release Date: 10/25/2023</p>

        <br>
        <p>Fixed: Auto assigning default supplier product on server when a requisition line doesn't have a supplier product.</p>
        <p>Fixed: Width on varaitions header image set to 300px.</p>
        <br>

        <h3>Version 1.0.14 Notes</h3>
        <p>Release Date: 10/20/2023</p>

        <br>
        <p>Fixed: Add Category and Sub-Category columns to export file.</p>
        <p>Fixed: Adjust the width of the columns in the Excel file to makes sense. Some are too short, others too long for the given column.</p>
        <p>Fixed: If the formulary name contains the word “Formulary” don’t add another “Formulary” word.</p>
        <p>Fixed: Remove export selected rows feature and instead keep only exporting the current visible records.</p>
        <p>Fixed: Make the formulary products grid have the same order than that of the export: Supplier SKU, Supplier, Allow, Manufacturer, Manufacturer SKU, Name, Category, Sub-category.</p>
        <p>Fixed: Make the padding between each formulary button smaller, 5px is good.</p>

        <br>

       
        <h3>Version 1.0.13 Notes</h3>
        <p>Release Date: 10/11/2023</p>
        <br>
        <p>New: Export formulary button.</p>

        <br>
        <p>Fixed: Update to webjob access code for availabilty call</p>
        <p>Fixed: Move formulary buttons above grid.</p>
        <br>

        <h3>Version 1.0.12 Notes</h3>
        <p>Release Date: 10/11/2023</p>
        <br>
        <p>New: Using new SendGrid tenant. copied over email templates and created new API keys for Sherpa and Auth0</p>

        <br>

        <h3>Version 1.0.11 Notes</h3>
        <p>Release Date: 10/9/2023</p>
        <br>

        <p>Fixed: When token exprires, users are logged out and redirected to the home screen.</p>
        <br>

        <h3>Version 1.0.10 Notes</h3>
        <p>Release Date: 10/5/2023</p>
        <br>
   
        <p>Fixed: Resolved issue that cleared out the shipping address on a practice when there was an update to the practice.</p>
        <p>Fixed: Return product images to display on cart when there is an update to the cart.</p>
        <p>Fixed: Submission status changed from 'resent' to 'resending'.</p>
        <p>Fixed: Removed audit calls from requisition list screen.</p>
        <p>Fixed: Hide product list on practice dashboard if it is empty.</p>
        <br>
        
        <h3>Version 1.0.9 Notes</h3>
        <p>Release Date: 9/18/2023</p>
        <br />

        <p>New: Out of stock tag on product cards.</p>
        <p>New: Displaying messages in cart when a product is removed or unavailable.</p>
        <p>New: Server side paging on formulary table.</p>
        
        <br>
        <p>Fixed: Using a stored proc to check for changes in the cart and only gets cart info if there are changes. </p>
        <p>Fixed: changed resend button logic on admin tab of order details screen so it only displays when status is error or rejected. button is disabled after clicking, then is hidden on refresh.</p>
        <p>Fixed: Resolved ui issues caused by ng-deep on dev express components.</p>
        <p>Fixed: Expired authentication prompts for reauthentication, doesn't make API calls if expired.</p>
        <p>Fixed: Expired authentication no longer calls API</p>
        <p>Fixed: API always on ping no longer 404s</p>
        <p>Fixed: Sherpa logo sizing and spacing.</p>

      
        <br>
        <h3>Version 1.0.8 Notes</h3>
        <p>Release Date: 9/6/2023</p>
        <br />
        <p>Fixed: Product card tooltips.</p>
        <p>Fixed: Line widths.</p>
        <p>Fixed: Tag alignment.</p>

        <br>
        
        <div class="spacer"></div>


        <h3>Version 1.0.7 Notes</h3>
        <p>Release Date: 9/4/2023</p>
        <br />

      

        <p>New: Product Tags on product cards, requisitions, and POs.</p>
        <p>New: Products that cannot be purchased display a message stating they can't be purchased, or they are filtered out.</p>
        <p>New: UI Override fields - logo, dashboard title, and product list names</p>
        <p>New: Displaying price for product line totals, requisiition totals (in quick cart view and review screen), and order totals per supplier on final review and order details screen</p>
        <br>
        <p>Fixed: Application update ensures clean data.</p>
        <p>Fixed: Application login issues.</p>
        <p>Fixed: Login experience on Firefox and Edge (requires enabling pop-ups).</p>
        <p>Fixed: Automatically set supplier product to new cart line.</p>
        <p>Fixed: requisition is set to 'abandoned' when emptied. New requisition is not created until something is added to the cart again.</p>
        <p>Fixed: Updated spacing, font size, and colors on account switcher.</p>
        <p>Fixed: Display TBD for PO total and PO line total where product price is unknown.</p>
        <p>Fixed: Updating price line total on optimize screen when supplier is changed.</p>
        <p>Fixed: Increase account switcher width to 450px and confirm that ellipsis display on overflow text</p>
        <p>Fixed: On the Final Review and on closed requisitions, lets rename the "Estimated Total" to "Estimated 'Supplier' Total" at the header of each group</p>
        <p>Fixed: The tags on the varation window from the quick cart should be supplier tags given this is what is on the cart, instead it is showing core product tags</p>
        <br>

        <div class="spacer"></div>

        <h3>Version 1.0.6 Notes</h3>
        <p>Release Date: 8/1/2023</p>
        <br />

        <p>New: Purchase order lines display a tag for backorder, shipped, and invoiced products if the order is CXML.</p>
        <p>New: Purchase order details has invoices tab for CXML.</p>
        <p>New: Purchase order details has shipments tab for CXML.</p>
        <p>New: Processing CXML for shipments and invoices.</p>
        
        <br>
        <p>Fixed: Images that stretched in previous releases now display properly.</p>
        <p>Fixed: Preventing 'unsaved changes' prompt on formulary and address forms when no changes have been made yet.</p>
        <p>Fixed: Budget tab on practice account is now unlocked.</p>
        <p>Fixed: Admins can remove parent org from accounts.</p>
        <p>Fixed: Admins can remove formulary from accounts.</p>
        <p>Fixed: Inherit formulary checkbox are not visible when the practice does not have a parent. User can choose a public formulary from drop down.</p>
        <p>Fixed: When creating a new account, recomendation saves.</p>
        <p>Fixed: Styling changes to PO tabs - font size, tag colors, tab spacing, rename table column names for shipment and invoice, added space between sku and quantity.</p>
        <p>Fixed: Preventing gap between bottom of screen and product search component.</p>
        <p>Fixed: Back to top button on product search is based on scroll on list component instead of window scrolling.</p>

        <br>
        
        <div class="spacer"></div>
        <h3>Version 1.0.5 Notes</h3>
        <p>Release Date: 7/29/2023</p>
        <br />

        <p>Fixed: fixed issue preventing unparented practices from loading formulary info on the account's hub for admins.</p>

        <br>
        
        <div class="spacer"></div>

        <h3>Version 1.0.4 Notes</h3>
        <p>Release Date: 7/24/2023</p>
        <br />

        <p>New: Requisition/Purchase Order lines are now always split up by supplier.</p>
        <p>New: Admin and products tabs on purchase order submissions.</p>
        <p>New: Admins can update the status of a submission.</p>        
        <p>New: Admins can resend submissions.</p>
        <p>New: Admins can view submission history in purchase order admin tab.</p>
        <p>New: CXML processing now splits the receiving of a message and processing it (async).</p>
        <p>New: Web jobs have been created to process CXML messages.</p>
        <p>New: Supplier product availability is pulled daily.</p>
        <p>New: Purchase order lines are updated with backordered and confirmed product counts.</p>
        <p>New: Added prices disclaimer to order review.</p>
        <p>New: Purchase order status is updated based on the statuses of all submissions.</p>
        <br>
        <p>Fixed: Reorganized columns on purchase order and purchase requistion lists.</p>
        <p>Fixed: Now displaying manufacturer name on suggested product in cart review.</p>
        <p>Fixed: Now displaying price on suggested Dental City products on cart review.</p>
        <p>Fixed: On order review screen, changing supplier product on line no longer over rdes updated quantity.</p>
        <p>Fixed: Fixed issue preventing confirmed orders from being processed.</p>
        <p>Fixed: Improved cxml error messaging. Details now include more information explaining why order failed.</p>

        <br>

        <div class="spacer"></div>

        <h3>Version 1.0.3 Notes</h3>
        <p>Release Date: 6/28/2023</p>
        <br />

        <p>New: Display supplier order number on PO screen, submission header for supplier</p>
        <p>New: Purchase Order Submission Status:  Received</p>
        <p>New: CXML Order Response message processing.  Admin emailed on non-HTTP-200 result.</p>
        <p>New: CXML Order Confirmation message processing.  Status and supplier order number are displayed with submission information.</p>
        <p>New: Version number in footer</p>
        <br>
        <p>Fixed: When using the account switcher, the cart refreshes correctly</p>
        <br>

        <div class="spacer"></div>
        

        <h3>Version 1.0.2 Notes</h3>
        <p>Release Date: 6/22/2023</p>
        <br>

        <p>New: Pull and save price book on account/supplier</p>
        <p>New: CXML Purchase Order Send</p>
        <p>New: Display quantity bubble on supplier product when core product is added</p>
        <br>

        <p>Fixed: Validation fix for formulary descriptions</p>
        <p>Fixed: Both requisition and order list have submitted by as first and last name</p>
        <p>Fixed: Quick cart sort by line id desc instead of modified</p>
        <p>Fixed: Included time to Req and Order lists date submitted column</p>
        <p>Fixed: Updated no-access message, made it less like an input</p>
        <p>Fixed: Order confirmation status goes to received, not sent</p>
        <p>Fixed: Submission action status Received -> Accepted</p>
        <p>Fixed: Prevent wrong quantity amount saved to line</p>
        <p>Fixed: Product price displays on req line</p>
        <p>Fixed: Populate formulary 'products' tab on account screen</p>
        <p>Fixed: Clear quantiy on product card with variuations when pop up opens, </p>
        <p>Fixed: Prevent refresh  on 'update' line quantity when other lines are added</p>
        <p>Fixed: Allow other variation lines to get submitted even if current line has bad quantity</p>
        <p>Fixed: View Variations header image is the same image from the product card</p>
        <br>
        
       

        <div class="spacer"></div>
        <br>

        <h3>Version 1.0.1 Notes</h3>
        <p>Release Date: 6/2/2023</p>
        <br>

        <p>New: Purchase order email submission</p>

    </div>
</app-access>
