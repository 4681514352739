import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {

  @Input()
  actionText: string | undefined;
  @Input()
  buttonClasses: string | undefined;

  @Output()
  actionHandler: EventEmitter<Event> = new EventEmitter<Event>();

  constructor() { }

  action(e: Event): void {
    if (this.actionHandler) {
      this.actionHandler.next(e);
    }
  }

  get hasAction(): boolean {
    if (!this.actionText) {
      return false;
    } else {
      return !!this.actionHandler && this.actionText.length > 0; 
    }
  }
}
