
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IAccount, OrderStatusType, PurchaseOrderDetails, PurchaseOrderInvoiceLine, Agent, Cart, SubmissionAction, PurchaseOrderSubmission, SubmissionMethodStatus, PurchaseOrderShipmentLine } from 'src/app/shared/models';
import { ArraySortPipe } from 'src/app/shared/pipes';
import { AgentService, CartService, FormatNumberService, LoginService, MonitorService, NavigationService, NumberFormatType, OrderService } from 'src/app/shared/services';
import { AccountService } from 'src/app/shared/services/account.service';
import { Feature, FeatureService } from 'src/app/shared/services/feature.service';
import { PurchaseOrderService } from 'src/app/shared/services/purchaseOrder.service';
import { GenericComponent } from 'src/app/shared/utils/generic.component';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent extends GenericComponent implements OnInit { 


  agent: Agent | undefined;
  subscriptions: Subscription[] = [];
  account: IAccount | undefined;
  purchaseOrder?: PurchaseOrderDetails;
  prices: any = {};
  totalTotal: number = 0;
  workingTitle: string | undefined = undefined;
  workingMessage: string | undefined = undefined;
  orderMessage: string[] = [];
  orderBuild = false;
  hideCustomerBudget: boolean = false;
  cart?: Cart;
  coreProducts: any = {};
  supplierProducts: any = {};
  feature = Feature;
  purchaseOrderId?: number;
  // A map to store the collapsed state for each supplier
  collapsedStates: Map<number, boolean> = new Map<number, boolean>();
  numberFormatType = NumberFormatType;
  currentTabs: any[] = [];

  // tabs
  tabIndex = 0;
  tabs = ['Products', 'Admin'];
  isViewMode = true;
  selectedTab: string = 'products';
  cxmlStatusArray: SubmissionMethodStatus[] = [];
  emailStatusArray: SubmissionMethodStatus[] = [];
  formSubmits: any[] = [];
  @ViewChild('buttonForm', { static: false }) buttonForm!: NgForm;

  constructor(
    private accountService: AccountService,
    private orderService: OrderService,
    public cartService: CartService,
    private agentService: AgentService,
    monitor: MonitorService,
    login: LoginService,
    private activatedRoute: ActivatedRoute,
    public numberFormat: FormatNumberService,
    public featureService: FeatureService,
    public formatNumberService: FormatNumberService,
    private navigationService: NavigationService,
    private purchaseOrderService: PurchaseOrderService
  ) {
    super(monitor, login);

    this.activatedRoute.params.subscribe(async (params) => {
      // If a agency Id was supplied in the route populate the form fields with current values
      if (params.purchaseOrderId) {
        await this.execute(async () => {
          this.workingMessage = 'Loading purchase order';
          await this.loadOrder(params.purchaseOrderId);
          return true;
        }, 'Error loading Purchase Order');
      }
    });

    this.agent = this.agentService.authenticatedAgent;
  }

  async ngOnInit() {
    //gets possible statuses for PO based on type of submission
    let list = await this.orderService.getSubmissionStatus(this.agentService.currentAccount!.id);
    this.emailStatusArray = list.filter(a => a.submissionMethod == 'Email' && a.isOverridable).sort((a, b) => a.rank - b.rank);
    this.cxmlStatusArray = list.filter(a => a.submissionMethod == 'Cxml' && a.isOverridable).sort((a, b) => a.rank - b.rank);
  }

  async loadOrder(purchaseOrderId: number): Promise<any> {
    this.currentTabs = [];
    try {
      this.workingTitle = 'Please wait while we load your purchase order';
      this.workingMessage = 'Loading account configuration';

      if(!this.account){
        this.account = await this.accountService.getAccountById(this.agentService.currentAccount!.id, { budget: true })
      }
      this.workingMessage = 'Loading purchase order information';

      let purchaseOrderResponse = await this.purchaseOrderService.getPurchaseOrderById(this.account!.id, purchaseOrderId);

      if(purchaseOrderResponse.success == true){
        this.purchaseOrder = purchaseOrderResponse.value as PurchaseOrderDetails;
        this.purchaseOrder.submissions.forEach(submission => {
          this.currentTabs.push('Products' + submission.submissionId); 
        });
      }else
      {
        this.monitor.handleError(purchaseOrderResponse.messages?.join("\r\n"));
      }

      this.workingMessage = 'Setting up orders';
    } catch (error: any) {
      this.monitor.handleError(error, `Failed to load purchase order.`);
    }
  }

  public getSubmission(supplierId: number): PurchaseOrderSubmission | undefined {
    return (this.purchaseOrder?.submissions ?? []).find(s => s.submissionSupplierId === supplierId);
  }

  public getLatestSubmissionStatus(supplierId: number): string | undefined {
    let actions = this.getSubmission(supplierId)?.submissionStatus;
    return actions;
  }

  // Method to toggle the collapsed state of a supplier
  toggleCollapse(supplierId: number) {
    const currentState = this.isCollapsed(supplierId);
    this.collapsedStates.set(supplierId, !currentState);
  }

  // Method to check if a supplier is collapsed
  isCollapsed(supplierId: number): boolean {
    return this.collapsedStates.get(supplierId) || false;
  }

  supplierLineCount(id: number): number {
    const submission = this.purchaseOrder?.submissions.find(s => s.submissionSupplierId === id);
    return submission?.purchaseOrderLines?.length ?? 0;
  }

  getSupplierName(id: number) {
    return this.purchaseOrder?.submissions.find(submission => submission.submissionSupplierId === id)?.submissionSupplierName
  }

  selectTab(event: any, id: number, search?: string) {
    const tabToRemove = this.currentTabs.find(tabId => tabId.includes(id.toString()));
    // If found, remove it from the array
    if (tabToRemove) {
      const index = this.currentTabs.indexOf(tabToRemove);
      if (index > -1) {
          this.currentTabs.splice(index, 1);
      }
    }
    this.currentTabs.push(event);
  }

  formatShipmentLine(data: any): string {
      if (data.shipmentLines && data.shipmentLines.length > 0) {
          return data.shipmentLines.map((line: PurchaseOrderShipmentLine) => `${line.supplierProductSKU} (${line.shippedQuantity})`).join(', ');
      }
      return '';
  };

  formatInvoiceLine(data: any): string {
    if (data.invoiceLines && data.invoiceLines.length > 0) {
        return data.invoiceLines.map((line: PurchaseOrderInvoiceLine) => `${line.supplierProductSKU} (${line.invoicedQuantity})`).join(', ');
    }
    return '';
};

  async resendSubmission(supplierId: number, submissionId: number) {
    await this.execute(async () => {
      this.workingMessage = 'Resending purchase order';
      //resend
      await this.orderService.resendOrder(this.account!.id, this.purchaseOrder!.purchaseOrderId, supplierId);

      //update front-end po
      await this.loadOrder(this.purchaseOrder!.purchaseOrderId);

      return true;
    }, 'Error resending purchase order');
  }

  async updateStatus(submissionId: number, e: any) {
    await this.execute(async () => {
      this.workingMessage = 'Updating status';
      let status: string = e.instance.getEditor('status')?.option('value');

      //update status
      await this.orderService.updateOrderStatus(this.account!.id, this.purchaseOrder!.purchaseOrderId, submissionId, status);
      
      //update front-end po
      await this.loadOrder(this.purchaseOrder!.purchaseOrderId);

      return true;
    }, 'Error updating submssion status');
  }

  // Checking whether the current selection matches the submission status
  // if it is, disable the submission button so users cannot continually submit the current status
  onFieldDataChanged(e: any, submissionId: number) {
    const value = e.value;
    const currentSubmission = this.purchaseOrder?.submissions?.find(s => s.submissionId == submissionId);
    let submitButton = document.getElementById('saveButton-' + submissionId);
    if (currentSubmission) {

      if (currentSubmission.submissionStatus == value) {
        submitButton?.classList.add('disabled');
      } else {
        submitButton?.classList.remove('disabled');
      }
    }
  }

  onContentReady(e: any, id: number) {
    let submitButton = document.getElementById('saveButton-' + id);
    submitButton?.classList.add('disabled');
  }

  isRejected(s: PurchaseOrderSubmission): boolean {
    if (s.submissionStatus == 'Rejected' || s.submissionStatus == 'Error') {
      return true;
    } else {
      return false;

    }
  }

  hasRejectedAndResentActions(s: PurchaseOrderSubmission): boolean {
    let rejected = s.history!.find((a: any) => a.purchaseOrderStatus == 'Rejected' || a.purchaseOrderStatus == 'Error');
    let resent = s.history!.find((a: any) => a.purchaseOrderStatus == 'Resent');
    if (rejected && resent && resent.purchaseOrderStatusChangeDate > rejected.purchaseOrderStatusChangeDate) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Checks for resent status on a given submission and if the form has been submitted or not.
   * 
   * If the submission has been resent and the form has not been submitted, the resend area should be hidden.
   * 
   * @param s = submission
   * @returns true if resend area should be hidden, false if not
   */
  wasResent(s: any) {
    if(s.submissionStatus == 'Resent') {
      return true
    }else{
      return false;
    }
  }

  onCellClick(event: any) {
    if (event.column.dataField === 'trackingNumber') {
      if(event.data?.trackingNumberUrl){
        window.open(event.data?.trackingNumberUrl, '_blank');
      }
    }

    if(event.column.dataField == 'invoiceNumber'){
      if(event.data?.invoiceUrl){
        window.open(event.data?.invoiceUrl, '_blank');
      }
    }
  }

  backToList() {
    this.navigationService.practice(this.agentService.currentAccount!.id).order.list();
  }

}
