import { environment } from '../../../environments/environment';

/**
 * Base API configuration file
 */
export class FulfillmentApiConfig {
    static path: string = environment.api.fulfillment.path;
    static version: string | number | null = environment.api.fulfillment.version;

    public static setApiVersion(version: string = 'api'): void {
        FulfillmentApiConfig.version = version;
    }

    public static getApiVersion(): string | number | null {
        return FulfillmentApiConfig.version;
    }

    public static getPath(): string {
        return FulfillmentApiConfig.path;
    }

    public static getFullUrl(path: string): string {
        if (FulfillmentApiConfig.getApiVersion()) {
            return [FulfillmentApiConfig.getPath(), FulfillmentApiConfig.getApiVersion(), path].join('/');
        } else {
            return [FulfillmentApiConfig.getPath(), path].join('/');
        }
    }
}
