import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Agent } from '../../models';
import { AgentService, NavigationService } from '../../services';
import { Feature, FeatureService } from '../../services/feature.service';

@Component({
  selector: 'app-access',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.scss']
})
export class AccessComponent implements OnInit {
  @Input()
  accountId: number | undefined;

  @Input()
  platformFeature: Feature | undefined = undefined;

  @Input()
  accountFeature: Feature | undefined = undefined;

  @Input()
  noAccessMessage = 'approver access required';

  @Input()
  displayIfNoAccess = true;

  @Input()
  specificAgent: number | undefined;

  @Input()
  redirectOnFailure = false;

  agent: Agent | undefined;

  constructor(
    private features: FeatureService,
    private agentService: AgentService,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute
    ) { }

  ngOnInit(): void {
    this.agent = this.agentService.authenticatedAgent;
    if (!this.accountId) {
      this.accountId = this.agentService.currentAccount?.id;
    }
  }

  hasAccess(): boolean {
    let access = false;

    if ( this.platformFeature || this.accountFeature || this.agent?.id === this.specificAgent ) {
      if (this.agent) {
        if (this.platformFeature) {
          access = this.features.check(this.platformFeature, this.agent.features);
        }
        if (!access && this.agentService.currentAccount && this.accountFeature) {
          const aa = this.agent.accountFeatures[this.agentService.currentAccount!.accountNumber];
          if (aa) {
            access = this.features.check(this.accountFeature, aa) || this.features.check(this.accountFeature, parseInt(aa as string, 10));
          }
        }

        if (!access && this.agent?.id === this.specificAgent) {
          access = true;
        }
      } else {
        access = true; // this will only show when it's loading the user, and this wouldn't wrap something that didn't require a user
      }
    } else {
      access = true;
    }

    if (!access && this.redirectOnFailure) {
      this.navigationService.root().unauthorized();
    }

    return access;
  }
}
