import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { BreadCrumbItem } from 'src/app/shared/components/bread-crumb/bread-crumb.component';
import { IAccount, Agent, Cart, PurchaseRequisition, PurchaseRequisitionLine } from 'src/app/shared/models';
import { IGetRequisitionSubmitDetailsResponse } from 'src/app/shared/models/requisition.submit.details.response.model';
import { ISubmitRequisitionRequest } from 'src/app/shared/models/submit.requisition.request.model';
import { ArrayDoubleSortPipe } from 'src/app/shared/pipes';
import { AgentService, CartService, FormatNumberService, MonitorService, OrderService, NumberFormatType, LoginService, ModalService, NavigationService } from 'src/app/shared/services';
import { RequisitionService } from 'src/app/shared/services/requisition.service';
import { AccountService } from 'src/app/shared/services/account.service';
import { Feature } from 'src/app/shared/services/feature.service';
import { GenericComponent } from 'src/app/shared/utils/generic.component';
import { ISubmitRequisitionResponse } from 'src/app/shared/models/submit.requisition.response.model';
import { SubmissionDetailsFormComponent } from '../submission-details-form/submission-details-form.component';
import { IApprovalDetails } from 'src/app/shared/models/approval.overview.model';

@Component({
	selector: 'app-review-order',
	templateUrl: './review-order.component.html',
	styleUrls: ['./review-order.component.scss'],
})
export class ReviewOrderComponent extends GenericComponent implements OnInit, OnDestroy
{
	agent: Agent | undefined;
	subscriptions: Subscription[] = [];
	account: IAccount | undefined;
	requisition?: PurchaseRequisition;
	prices: any = {};
	totalTotal: number = 0;
	workingTitle: string | undefined = undefined;
	workingMessage: string | undefined = undefined;
	//orderRecommendations!: OrderRecommendation[];
	orderMessage: string[] = [];
	orderBuild = false;
	hideCustomerBudget: boolean = false;
	cart?: Cart;
	coreProducts: any = {};
	supplierProducts: any = {};
	feature = Feature;
	numberFormatType = NumberFormatType;
	collapsedStates: Map<number, boolean> = new Map<number, boolean>();
	supplierOrders: any[] = [];
	breadCrumbItems: BreadCrumbItem[] = [new BreadCrumbItem('Optimize', false), new BreadCrumbItem('Review & Submit', true)];

	requisitionSubmitUnavailableText: string = 'Requisition submission is currently unavailable. Please try again later.'; 
	approvalDetails: IGetRequisitionSubmitDetailsResponse | undefined;
	hasDetails: boolean = false;
	dialogBoxErrorMessage?: string;
	buttonsDisabled: boolean = true;

	timelineDetails!: IApprovalDetails | undefined;

	@ViewChild('submissionForm')
	submissionForm?: SubmissionDetailsFormComponent;
	
	constructor(
		private accountService: AccountService,
		private orderService: OrderService,
		public cartService: CartService,
		private agentService: AgentService,
		private requisitionService: RequisitionService,
		private activatedRoute: ActivatedRoute,
		monitor: MonitorService,
		login: LoginService,
		public numberFormat: FormatNumberService,
		private modals: ModalService,
		private navigationService: NavigationService
	)
	{
		super(monitor, login);

		this.subscriptions.push(this.cartService.cartChangedObservable$.subscribe((value: Cart | undefined) =>
		{
			this.cart = value;
		}));
	}

	async ngOnInit(): Promise<any>
	{
		await this.execute(async () =>
		{
			this.setWorking(30);

			await this.loadOrder();

			if (this.account?.id && this.agent?.id && this.requisition?.id)
			{
				await this.getApprovalDetails(this.account.id, this.agent.id, this.requisition.id);
				this.buttonsDisabled = false;
			}
			else
			{
				this.monitor.handleError('Error getting approval details.');
				return false;
			}
			return true;
		}, 'Error loading order');
	}

	ngOnDestroy(): void
	{
		this.subscriptions.forEach((s) => s.unsubscribe());
	}

	async loadOrder(): Promise<any>
	{
		await this.execute(async () =>
		{
			this.workingTitle = 'Please wait while we review your requisition';
			this.workingMessage = 'Loading account configuration';
			this.agent = this.agentService.authenticatedAgent;
			this.account = await this.accountService.getAccountById(this.agentService.currentAccount!.id, { budget: true })
			this.workingMessage = 'Loading requisition';
			this.requisition = await this.orderService.getOpenPurchaseRequisition(this.agentService.currentAccount!.id, { coreProduct: true, supplierProduct: true });
			this.requisition.purchaseRequisitionLines = new ArrayDoubleSortPipe().transform(this.requisition.purchaseRequisitionLines, ['supplierProduct.supplier.name', true, 'supplierProduct.name', true])
			this.supplierOrders = new ArrayDoubleSortPipe().transform(this.requisition.purchaseRequisitionLines, ['supplierProduct.supplier.name', true, 'supplierProduct.name', true]);
			this.loadSupplierOrders();
			this.requisition.total = this.calcSupplierTotal(this.requisition.purchaseRequisitionLines)
			this.workingMessage = 'Loading supplier products';
			return true;
		}, 'Error loading Requisition');
	}

	hideBudget(): void
	{
		this.hideCustomerBudget = true;
	}

	public shouldDisplaySupplierName(line: any, index: number): boolean
	{
		if (line.supplierProduct && line.supplierProduct.supplier)
		{
			if (index === 0)
			{
				return true;
			}
			else
			{
				if (this.requisition?.purchaseRequisitionLines)
				{
					const previousLine = this.requisition?.purchaseRequisitionLines[index - 1];
					if (previousLine && previousLine.supplierProduct && previousLine.supplierProduct.supplier)
					{
						return line.supplierProduct.supplier.name !== previousLine.supplierProduct.supplier.name;
					}
				}
			}
		}
		return false;
	}

	gotoCart(): void
	{
		this.navigationService.practice(this.account!.id).cart.review();
	}

	placeOrders = async (submissionData: ISubmitRequisitionRequest) =>
	{
		this.buttonsDisabled = true;
		this.closeModal('approval-modal');

		this.workingMessage = 'Saving selections';
		this.working = 50;
		if (this.account)
		{
			try
			{
				let submitGenericResponse = (await this.requisitionService.SubmitRequisition(submissionData, this.account.id));
				if (submitGenericResponse.success)
				{
					let submitResponse = submitGenericResponse.value as ISubmitRequisitionResponse;
					if (submitResponse.purchaseOrderId)
					{
						this.monitor.handleSuccess('Purchase order created successfully.');
						await this.cartService.refreshCart();
						this.navigationService.practice(this.agentService.currentAccount!.id).order.details(submitResponse.purchaseOrderId);
					}
					else if (submitResponse.purchaseRequisitionId)
					{
						this.monitor.handleSuccess('Requisition submitted successfully.');
						await this.cartService.incrementPendingApporval();
						this.navigationService.practice(this.agentService.currentAccount!.id).requisition.details(submitResponse.purchaseRequisitionId);
					}
					else
					{
						this.monitor.handleError(submitGenericResponse.messages.join("\r\n"));
						this.openModal('approval-modal');
					}

					this.working = 0;
				}
				else
				{
					this.working = 0;
					this.monitor.handleError(submitGenericResponse.messages.join("\r\n"));
					this.openModal('approval-modal');
				}
			}
			catch (error: any)
			{
				this.working = 0;
				this.openModal('approval-modal');
				this.monitor.handleError('The requisition could not be submitted.', 'Server Error');
			}
		}
		else
		{
			this.working = 0;
			this.openModal('approval-modal');
			this.monitor.handleError('The requisition information was not loaded.', 'Account Error');
		}

		this.buttonsDisabled = false;
	}

	async openModal(modal: string): Promise<void>
	{
		if(modal === 'timeline'){
			const approvalData = await this.requisitionService.getApprovalDetails(this.account!.id, this.requisition!.id);

			if (approvalData.success && approvalData.value)
			{
				this.timelineDetails = approvalData.value as IApprovalDetails;
			}
			else
			{
				this.monitor.handleError(approvalData.messages.join('\n'));
			}
		}

		else if (modal === 'approval-modal' && this.approvalDetails) 
		{

			this.submissionForm?.clearForm();
			this.modals.open(modal);

		} else {
			this.modals.open('confirm');
		}
	}

	closeModal(modal: string): void
	{
		this.hasDetails = false;
		this.modals.close(modal);
	}

	// to avoid a :visited link styling...
	cancel(): void
	{
		this.navigationService.practice(this.account!.id).dashboard();
	}

	reviewRequisition()
	{
		this.navigationService.practice(this.account!.id).requisition.review(this.requisition!.id);
	}

	// Method to toggle the collapsed state of a supplier
	toggleCollapse(supplierId: number)
	{
		const currentState = this.isCollapsed(supplierId);
		this.collapsedStates.set(supplierId, !currentState);
	}

	// Method to check if a supplier is collapsed
	isCollapsed(supplierId: number): boolean
	{
		return this.collapsedStates.get(supplierId) || false;
	}

	supplierLineCount(id: number): number
	{
		return this.requisition?.purchaseRequisitionLines?.filter(l => l.supplierProduct?.supplierId === id)?.length ?? 0;
	}

	loadSupplierOrders()
	{
		if (this.requisition?.purchaseRequisitionLines)
		{
			const supplierIds = Array.from(new Set(this.requisition?.purchaseRequisitionLines.map((line) => line.supplierProduct?.supplierId)));

			// Convert the supplierIds array into an array of Supplier objects
			this.supplierOrders = supplierIds.map((id) => ({
				id: id,
				products: this.requisition?.purchaseRequisitionLines?.filter(l => { return l.supplierProduct?.supplierId === id; }),
				total: this.calcSupplierTotal(this.requisition?.purchaseRequisitionLines?.filter(l => { return l.supplierProduct?.supplierId === id; })),
				supplierName: this.requisition?.purchaseRequisitionLines?.find(l => l.supplierProduct?.supplierId === id)?.supplierProduct?.supplier?.name
			}));
		}
	}

	calcSupplierTotal(products?: PurchaseRequisitionLine[]): any | undefined
	{
		if (products)
		{
			var nullPrice = products.find(a => a.linePrice == null);
			if (nullPrice)
			{
				return 'TBD';
			}
			let calc = products.reduce((total, product) =>
			{
				return total + (product.linePrice!);
			}, 0)
			return (!isNaN(calc)) ? calc : undefined;
		}
		else
		{
			return 'TBD';
		}
	}

	async getApprovalDetails(accountId: number, agentId: number, requisitionId: number)
	{
		let approvalDetailsResponse = await this.requisitionService.GetRequisitionApprovalDetails
		(
			accountId,
			requisitionId
		);

		if (approvalDetailsResponse.success)
		{
			this.approvalDetails = approvalDetailsResponse.value as IGetRequisitionSubmitDetailsResponse;
			this.hasDetails = true;
		}
		else
		{
			this.dialogBoxErrorMessage = approvalDetailsResponse.messages?.join("\r\n");
		}
	}
}