import { AuditInfo, CoreProduct, IPractice, PurchaseOrder, SupplierProduct } from '.';

export class PurchaseRequisition extends AuditInfo {
    public practiceId!: number;
    public purchaseRequisitionLines?: PurchaseRequisitionLine[] = [];
    public purchaseRequisitionStatus?: string;
	public masterNumber?: number
	public requisitionNumber?: string
	public purchaseOrder?: PurchaseOrder | undefined;
	public hasApprovalProcess: boolean = false;
    public total?: number | undefined;
    public messages?: RequisitionMessage[] | undefined = undefined;
    public modifier?: number | undefined = undefined;
}

export class PurchaseRequisitionListView {
    public id!: number;
    public practiceId!: number;
    public purchaseRequisitionLines?: PurchaseRequisitionLine[] = [];
    public purchaseRequisitionStatus?: string;
	public masterNumber?: number
	public requisitionNumber?: string
    public modifiedBy?: string | undefined;
    public modified?: Date;
    public createdBy?: string | undefined;
    public created?: Date;
    public suppliers?: string | undefined;
}

export class PurchaseRequisitionWithApprovalsListView {
	public id!: string | undefined;
	public parentId!: string | undefined;
	public purchaseRequisitionId!: number;
    public purchaseRequisitionNumber!: number;
	public supplierId!: string;
	public supplierName!: string;
	public purchaseRequisitionStatusId!: string;
	public purchaseRequisitionStatus!: string;
	public total!: number;
	public approval?: PurchaseRequisitionWithApprovalDetailsListView;
    public modifiedBy?: string | undefined;
    public modified?: Date;
    public createdBy?: string | undefined;
	public created?: Date;
}

export class PurchaseRequisitionWithApprovalDetailsListView
{
	public isCompleted!: string;
	public autoApproved!: string;
	public approvers!: string;
	public type!: string;
}


export class PlacePurchaseOrder {
    public purchaseRequisition!: PurchaseRequisition;
    public messages!: string[];
    public purchaseOrderId?: number;
}

export class PurchaseRequisitionLine extends AuditInfo{
   
    public purchaseRequisitionId!: number;
    public coreProductId?: number;
    public supplierProductId?: number;
    public coreProduct?: CoreProduct | undefined;
    public supplierProduct?: SupplierProduct | undefined;
    public quantity?: number;
    public price?: string;
    public linePrice?: number | undefined;
}

export class RequisitionStatus {
    public static Open:string = 'Open';
    public static Complete:string = 'Complete';
    public static Cancelled:string = 'Cancelled';
    public static Accepted:string = 'Accepted';
    public static Accepted3rdParty:string = 'Accepted3rdParty';
    public static Rejected:string = 'Rejected';
    public static Rejected3rdParty:string = 'Rejected3rdParty';
    public static Error3rdParty:string = 'Error3rdParty';
    public static Pending3rdParty:string = 'Pending3rdParty';
    public static Shipped3rdParty:string = 'Shipped3rdParty';
}

export class RequisitionMessage {
    public id!: number;
    public message!: string;
    public dismissedBy?: number;
    public dismissedOn?: Date;
    public dismissed: Boolean | undefined = undefined;
}

export enum PurchaseRequisitionStatus {
    Open = 'Open',
    Closed = 'Closed',
    Pending = 'Pending',
    Approved = 'Approved',
    Rejected = 'Rejected',
    Error = 'Error',
    Abandoned = 'Abandoned'
}
