import { Component, Input } from '@angular/core';
import { IApprovalOverview } from '../../models/approval.overview.model';
import { PurchaseRequisitionStatus } from '../../models';
import { ApprovalService } from '../../services/approval.service';

@Component({
  selector: 'app-approvals-overview',
  templateUrl: './approvals-overview.component.html',
  styleUrls: ['./approvals-overview.component.scss']
})
export class ApprovalsOverviewComponent {

  @Input() approvalOverview!: IApprovalOverview;
  @Input() agentId!: number;
  @Input() accountId!: number;
  @Input() requisitionId!: number;
  @Input() isApprover!: boolean;
  @Input() purchaseRequisitionStatus!: PurchaseRequisitionStatus;
  @Input() approvalQueryParam?: boolean;
  @Input() isAutoApproved!: boolean;
  @Input() isAbandoned!: boolean;

  protected statusEnum = PurchaseRequisitionStatus;
  protected approverComments: string = '';
  protected submitterCommentShowMore: boolean = false;

  maxCurrentApproversListSize: number = 2;
  maxApprovalApproversListSize: number = 2;

  constructor(private approvalService: ApprovalService) { 
    approvalService.decisionMade$.subscribe((status: PurchaseRequisitionStatus) => {
      this.approverComments = '';
    });
  }

  setApproversListSize(whichApprovers: string): void {
    switch (whichApprovers) {
      case 'current':
        this.maxCurrentApproversListSize = this.approvalOverview.currentLevelApprovers.approvers.length;
        break;
      case 'approval':
        this.maxApprovalApproversListSize = this.approvalOverview.approvalApprovers.length;
        break;
    }
  }

  isTextOverflow(elementId: string): boolean {
    const elem = document.getElementById(elementId);
    if (elem) {
      return (elem.offsetHeight < elem.scrollHeight);
    }
    else {
      return false;
    }
  }

  showMore() {
    this.submitterCommentShowMore = !this.submitterCommentShowMore;
  }
  
}
