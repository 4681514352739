import { Injectable } from '@angular/core';
import { Agent, IAccount, IDictionary } from '../models';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  public storeAccount(account: IAccount): boolean {

    try{
      localStorage.setItem(`account-${account.id}`, JSON.stringify(account));
    }catch(e){
      console.error(e);
      return false;
    }

    return true;
  }

  public getAccount(accountId: string): IAccount {
    return JSON.parse(localStorage.getItem(`account-${accountId}`) || '{}');
  }

  public get agentDetails(): Agent {
    return JSON.parse(localStorage.getItem('agentDetails') || '{}');
  }

  public set agentDetails(agentDetails: Agent) {
    localStorage.setItem('agentDetails', JSON.stringify(agentDetails));
  }

  public get uiCustomizations(): IDictionary<string> {
    return JSON.parse(localStorage.getItem('ui-customizations') || '{}');
  }

  public set uiCustomizations(uiCustomizations: IDictionary<string>) {
    localStorage.setItem('ui-customizations', JSON.stringify(uiCustomizations));
  }

  public accountInStorage(accountId: string): boolean {
    return localStorage.getItem(`account-${accountId}`) != null;
  }
}
