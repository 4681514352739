import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-product-line-total',
  templateUrl: './product-line-total.component.html',
  styleUrls: ['./product-line-total.component.scss']
})
export class ProductLineTotalComponent {
  @Input()
  label?: string;
  @Input()
  total?: string | number;
  @Input()
  bold?: boolean;
  @Input()
  spacing?: string;
  @Input()
  isCurrency = true;
}
