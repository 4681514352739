import { Component, Input, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { IFilter, inputType } from '../../models/filter.model';
import { DxDateBoxComponent, DxDropDownBoxComponent, DxTextBoxComponent, DxDateRangeBoxComponent } from 'devextreme-angular';

interface FilterItem
{
	Id: number;
	Name: string;
}

@Component({
	selector: 'app-generic-filters',
	templateUrl: './generic-filters.component.html',
	styleUrls: ['./generic-filters.component.scss']
})
export class GenericFiltersComponent
{
	private _filters: IFilter[] = [];

	@Input()
	renderFilters: boolean = false;

	@Input()
	set filters(value: IFilter[])
	{
		this._filters = value;

		this.multiSelectFilters = [];
		this.singleSelectFilters = [];
		this.dateFilters = [];
		this.dateRangeFilters = [];
		this.textFilters = [];
		this.multiDropdownOpenedArray = [];
		this.singleDropdownOpenedArray = [];

		if (this._filters)
		{
			this._filters.forEach((filter: IFilter) =>
			{
				if (filter.inputType === inputType.multiSelect)
				{
					this.multiDropdownOpenedArray.push(false);
					this.multiSelectFilters.push(filter);
					this.multiSelectValueArray.push([]);
				}

				if (filter.inputType === inputType.select)
				{
					this.singleDropdownOpenedArray.push(false);
					this.singleSelectFilters.push(filter);
				}

				if (filter.inputType === inputType.date)
				{
					this.dateFilters.push(filter);
				}

				if (filter.inputType === inputType.dateRange)
				{
					this.dateRangeFilters.push(filter);
				}

				if (filter.inputType === inputType.text)
				{
					this.textFilters.push(filter);
				}
			});
		}
	};

	@Input()
	submit!: (submitData: any) => void; //this is the function that will be called when the submit button is clicked
	@Input()
	isGeneric!: boolean; //this is a flag to determine if the filters are for a generic report or a specific report

	@ViewChildren(DxDropDownBoxComponent)
	dropdowns?: QueryList<DxDropDownBoxComponent>;
	@ViewChildren(DxDateBoxComponent)
	dateBoxes?: QueryList<DxDateBoxComponent>;
	@ViewChildren(DxTextBoxComponent)
	textBoxes?: QueryList<DxTextBoxComponent>;
	@ViewChildren(DxDateRangeBoxComponent)
	dateRangeBoxes?: QueryList<DxDateRangeBoxComponent>;

	//each input type has its own array to store the filters of that type
	multiSelectFilters: IFilter[] = [];
	singleSelectFilters: IFilter[] = [];
	dateFilters: IFilter[] = [];
	dateRangeFilters: IFilter[] = [];
	textFilters: IFilter[] = [];

	filterReturnData: any; //this will be the data returned from the filters
	inputType = inputType; //this is to allow the template to access the enum

	//these arrays are used to track the opened state of the dropdowns and the selected values
	multiSelectValueArray: [number[]] = [[]];
	multiDropdownOpenedArray: boolean[] = [];

	singleSelectValueArray: number[] = [];
	singleDropdownOpenedArray: boolean[] = [];

	constructor() {}

	/**
	 * 
	 * @param e The event object passed from DevExtreme
	 * @param instanceIndex The index of the filter instance in the opened array and value array
	 * @param selectionMethod The type of selection method used in the filter. Either 'single' or 'multi'
	 */
	syncListSelection(e: any, instanceIndex: number, selectionMethod: string)
	{
		if (selectionMethod === 'multi')
		{
			const addedIds = e.addedItems.map((item: FilterItem) => item.Id);
			const removedIdsSet = new Set(e.removedItems.map((item: FilterItem) => item.Id));
			// Combine current selections not removed with new added selections
			this.multiSelectValueArray[instanceIndex] = this.multiSelectValueArray[instanceIndex]
				.filter(id => !removedIdsSet.has(id)) // Remove deselected items
				.concat(addedIds); // Add newly selected items

			// Ensure no duplicates
			this.multiSelectValueArray[instanceIndex] = Array.from(new Set(this.multiSelectValueArray[instanceIndex]));
		}
		else
		{
			this.singleSelectValueArray[instanceIndex] = e.addedItems[0].Id;
			this.singleDropdownOpenedArray[instanceIndex] = false;
		}
	}

	async submitData()
	{
		this.filterReturnData = {}; //reset filters before pushing

		//loop through the arrays and push the values to the return data array
		this.multiSelectFilters.forEach((filter: IFilter, index: number) =>
		{
			this.filterReturnData[filter.name] = this.multiSelectValueArray[index] ?? [];
		});

		this.singleSelectFilters.forEach((filter: IFilter, index: number) =>
		{
			this.filterReturnData[filter.name] = this.singleSelectValueArray[index] ?? null;
		});

		this.dateFilters.forEach((filter: IFilter, index: number) =>
		{
			this.filterReturnData[filter.name] = this.dateBoxes?.toArray()[index].instance.option('value') ?? null;
		});

		this.dateRangeFilters.forEach((filter: IFilter, index: number) =>
		{
			let data: any[] | undefined = this.dateRangeBoxes?.toArray();

			if (data)
			{
				let value = data[index].instance.option('value') ?? null;
				
				if (filter.name && filter.name.length === 2) {
					this.filterReturnData[filter.name[0]] = value[0].toISOString().slice(0,10);
					this.filterReturnData[filter.name[1]] = value[1].toISOString().slice(0,10);
				}
				else
				{
					console.error("No name provided for date range filter:")
					console.error(filter);
				}
			}
		});

		this.textFilters.forEach((filter: IFilter, index: number) =>
		{
			this.filterReturnData[filter.label] = this.textBoxes?.toArray()[index].instance.option('value') ?? "";
		});

		//call the submit function with the return data
		this.submit(this.filterReturnData);
	}
}