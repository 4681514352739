import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxDataGridComponent, DxFormComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { Formulary, FormularyType } from 'src/app/shared/models';
import { AgentService, LoginService, MonitorService, NavigationService } from 'src/app/shared/services';
import { Feature } from 'src/app/shared/services/feature.service';
import { FormularyService } from 'src/app/shared/services/formulary.service';
import { GenericComponent } from 'src/app/shared/utils/generic.component';

@Component({
  selector: 'app-formulary-list-view',
  templateUrl: './formulary-list-view.component.html',
  styleUrls: ['./formulary-list-view.component.scss']
})
export class FormularyListViewComponent extends GenericComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  form: DxFormComponent | undefined;
  dataGrid!: DxDataGridComponent;

  formularies: Formulary[] = [];
  dataSource: any;

  feature = Feature;

  constructor(
    private agentService: AgentService,
    private formularyService: FormularyService,
    monitor: MonitorService,
    login: LoginService,
    private navigationService: NavigationService
  ) {
    super(monitor, login);
  }

  async ngOnInit() {
    this.execute(async () => {      
      this.formularies = await this.formularyService.getFormularies({ agencyId: this.agentService.authenticatedAgent!.agency!.id, formularyType: FormularyType.Public });
      this.formularies = this.formularies.filter(f => f.agencyId == this.agentService.authenticatedAgent!.agency!.id);
      // create table data from agencies
      this.dataSource = new CustomStore({
        loadMode: 'raw',
        load: () => {
          return this.formularies;
        },
      });
      return true;
    }, 'Error loading agencies');
  }

  // Navigate to agency page of selected agency
  onCellClick(event: any) {
    if (event.column.dataField === 'name' && event.data?.id) {
      const formularyId = event.data?.id;
      this.navigationService.backoffice().formulary.details(formularyId);
    }
  }

  onContentReady(e: any) {
    setTimeout(() => {
      const rows = e.component.getVisibleRows();
      rows.forEach((row: any) => {
        const rowKey = e.component.getKeyByRowIndex(row.rowIndex);
        const rowData = row.data;
        const rowIndex = e.component.getRowIndexByKey(rowKey);
        const rowElement = e.component.getRowElement(rowIndex);
        if (!rowData.active) {
          rowElement[0].firstElementChild.classList.add('inactive');
        }
      });
    }, 0);
  }

  newFormulary(){
    this.navigationService.backoffice().formulary.new();
  }
}
