<div class="standard-background padded flex-column">
    <!-- <h3 *ngIf="!(agent?.agency?.active ?? false);">Inactive agency</h3>
    <h5 *ngIf="!(agent?.agency?.active ?? false); else inactiveagent" class="mb-large">
        Your agency is not active. Please contact your agency admin to activate your account.
    </h5> -->

    <!-- <ng-template #inactiveagent>
        <h3>Inactive account</h3>
        <h5>Your account is not active. Please contact your agency admin to activate your account.</h5>
    </ng-template> -->

    <!-- <div class="ml-auto mr-auto mb-large" style="position: relative; width: 50vw; height: 60vh;">
        <div class="yak" [ngClass]="{ 'sad': isSad }">
            &nbsp;
        </div>
    </div> -->
</div>
