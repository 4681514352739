import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, createUrlTreeFromSnapshot } from "@angular/router";
import { AgentService, LoginService, NavigationService } from "../services";
import { AccountType, Profile } from "../models";
import { GetParamValueByKeySnapshot } from "../utils/routeHelpers";
import { AccountService } from "../services/account.service";

@Injectable()
export class AgentAuthorizationGuard implements CanActivate {
    
    constructor(
        private agentService: AgentService,
        private navigationService: NavigationService,
        private loginService: LoginService,
        private accountService: AccountService
    ) {

    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        let currentUser = document.body.getAttribute('user-id'); //data-user-id

        if(!this.agentService.authenticatedAgent)
        {
            this.agentService.authenticate();
        }
        let agent = this.agentService.authenticatedAgent;

        if(route.paramMap.get('accountId') && route.paramMap.get('profile') && !state.url.includes('backoffice') && !state.url.includes('home') && this.agentService.authenticatedAgent) {

            let accountId = route.params['accountId'] || route.parent?.paramMap.get('accountId') || route.firstChild?.paramMap.get('accountId');

            if(accountId) {
                let accounts = await this.agentService.getAgentAccounts(this.agentService.authenticatedAgent.id!, {accounts: true});
            
                let requestedAccount = accounts.find(x => x.id == accountId);

                let profile;
                // Using route.paramMap here to get route information because the window has yet to update to thew new route at this point
                // Meaning this.agentService.currentProfile will represent the previous route rather than the newly requested route
                switch(route.paramMap.get('profile')!.toLowerCase()) {
                    case 'practice':
                        profile = Profile.Practice;
                        break;
                    case 'dso':
                        profile = Profile.DSO;
                        break;
                    case 'gpo':
                        profile = Profile.GPO;
                        break;
                    default:
                        profile = Profile.Unauthorized
                }
                
                if(requestedAccount){
                    this.agentService.addAuthorizedAccount(requestedAccount);

                    if(
                        (profile == Profile.Practice && requestedAccount.accountType != AccountType.PRACTICE)
                        || (profile == Profile.DSO && requestedAccount.accountType != AccountType.DSO)
                        || (profile == Profile.GPO && requestedAccount.accountType != AccountType.GPO)
                    ){
                        this.accountService.clearCustomizations();
                        this.invalidRouteSwitcher(requestedAccount.accountType, accountId);
                        return false;
                    }
                }
                else {    
                    this.accountService.clearCustomizations();
                    
                    switch(profile) {
                        case Profile.Platform:
                            this.navigationService.backoffice().message.noAccess();
                            return false;
                        case Profile.Practice:
                            this.navigationService.practice(accountId).message.noAccess();
                            return false;
                        case Profile.DSO:
                            this.navigationService.dso(accountId).message.noAccess();
                            return false;
                        case Profile.GPO:
                            this.navigationService.gpo(accountId).message.noAccess();
                            return false;
                        case Profile.Public:
                            this.navigationService.public().message.noAccess();
                            return false;
                    }        
                    return false;
                }
            }
        } else if (state.url.includes('backoffice')) {
            //feature check here, like in the previous check profile method 
            if(Object.values(agent?.accountFeatures).every(value => Number(value) <= 0)) {
                if (!agent?.features) {
                    this.navigationService.backoffice().message.noAccess();
                    return false;
                } else {
                    this.agentService.refreshProfile(Profile.Platform);
                }
            }
        }

        if (currentUser == null && this.loginService.authenticatedUser?.sub) {
            currentUser = this.loginService.authenticatedUser.sub;
        }

        let storageData = localStorage.getItem('loginUser');
        if (!storageData) {
            return false;
        }

        // There was an error, contact administrator. (123456)
        
        let authId = JSON.parse(storageData).sub;
        //next case, user is logged in but not authenticated
        if (!this.agentService.authenticatedAgent) {
            return false;
        //third case, local storage does not match the dom and we need to refresh the profile 
        }else if(currentUser != authId) {
            document.body.setAttribute('user-id', authId);
            let accountIdString = GetParamValueByKeySnapshot('accountId', route);

            let profileString = GetParamValueByKeySnapshot('profile', route);

            if(profileString){
                this.agentService.refreshProfile(parseInt(profileString) as Profile);
            }

            if (profileString) {

                let profile = parseInt(profileString) as Profile;

                if (accountIdString) {
                    let accountId = parseInt(accountIdString);
                    
                    switch (profile) {
                        case Profile.Practice:
                            this.navigationService.practice(accountId).message.accountSwitch();
                            return false;
                        case Profile.DSO:
                            this.navigationService.dso(accountId).message.accountSwitch();
                            return false;
                        case Profile.GPO:
                            this.navigationService.gpo(accountId).message.accountSwitch();
                            return false;
                    }
                } else {
                    this.navigationService.backoffice().message.accountSwitch();
                    return false;
                }
            }
            this.navigationService.public().message.accountSwitch();
            return true;
        }
        //Agent is Inactive
        else if (!this.agentService.authenticatedAgent?.active ) {
            
            this.navigationService.public().message.inactiveAgent();
            return true;
        //Agency is inactive
        } else if(!this.agentService.authenticatedAgent?.agency?.live){
            this.navigationService.public().message.agencyInactive();
            return true;
        }
        else {

            return true;
        }
    }

    private invalidRouteSwitcher(accountType: AccountType, accountId: number) {
        switch(accountType) {
            case AccountType.PRACTICE:
                this.navigationService.practice(accountId).message.invalidRoute();
                break;
            case AccountType.DSO:
                this.navigationService.dso(accountId).message.invalidRoute();
                break;
            case AccountType.GPO:
                this.navigationService.gpo(accountId).message.invalidRoute();
                break;
            default:
                this.navigationService.public().message.invalidRoute();
                break;
        }
    }
}