<app-progress [splash]="true" [value]="working">
  <div class="standard-background padded">

    <dx-tabs #catalogFilter class="mb-large" [dataSource]="catalogFilterButtons" [selectedIndex]="tabIndex" (onItemClick)="selectTab($event)">
    </dx-tabs>


    <div [ngClass]="{'hidden' : selectedFilter.value !== 'all'}">
      <app-product-search id="all-products-catalog" #all [pageSize]="25" [tab]="catalogFilterButtons[0]"></app-product-search>
    </div>

    <div [ngClass]="{'hidden' : selectedFilter.value !== 'recent'}">
      <app-product-search id="recent-products-catalog" #recent [pageSize]="25" [tab]="catalogFilterButtons[1]"></app-product-search>
    </div>

    <div [ngClass]="{'hidden' : selectedFilter.value !== 'supplier'}">
      <app-product-search id="supplier-products-catalog" #supplier [pageSize]="25" [tab]="catalogFilterButtons[2]"></app-product-search>
    </div>


  </div>

</app-progress>
