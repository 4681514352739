import { Injectable } from '@angular/core';
import { error, success, warning, options, info } from 'toastr';

type toastrOptions = {
    message: string,
    title?: string,
};

@Injectable({
    providedIn: 'root',
})
export class ToastService {

    private DEFAULT_CLASS = 'toast-bottom-center';

    constructor() { }

    public success(options: toastrOptions, extended?: boolean) {
        if (extended) {
            success(options.message, options.title ?? 'Success!', {
                positionClass: this.DEFAULT_CLASS, timeOut: 10000
            });
        } else {
            success(options.message, options.title ?? 'Success!', {
                positionClass: this.DEFAULT_CLASS
            });
        }
    }

    public info(options: toastrOptions, extended?: boolean) {
        if (extended) {
            info(options.message, options.title ?? 'Info!', {
                positionClass: this.DEFAULT_CLASS, timeOut: 10000
            });
        } else {
            info(options.message, options.title ?? 'Info!', {
                positionClass: this.DEFAULT_CLASS
            });
        }

    }

    public warn(options: toastrOptions, extended?: boolean) {
        if (extended) {
            warning(options.message, options.title ?? 'Warning!', {
                positionClass: this.DEFAULT_CLASS, timeOut: 10000
            });
        } else {
            warning(options.message, options.title ?? 'Warning!', {
                positionClass: this.DEFAULT_CLASS
            });
        }
    }

    public error(options: toastrOptions, extended?: boolean) {
        if (extended) {
            error(options.message, options.title ?? 'Error!', {
                positionClass: this.DEFAULT_CLASS, timeOut: 10000
            });
        } else {
            error(options.message, options.title ?? 'Error!', {
                positionClass: this.DEFAULT_CLASS
            });
        }
    }


}


