<app-access [platformFeature]="feature.AccountRead" [accountFeature]="feature.AccountRead" [redirectOnFailure]="true">
	<div class="standard-background">
		<app-progress [splash]="true" [value]="loading"
		              [splashOptions]="{ title: workingTitle, message: workingMessage }">
			<div class="flex-row justify-even ml-auto mr-auto">
				<div class="cart-card entry">
					<div class="header title flex-row justify-between no-wrap">
						<h3>Open Requisition</h3>
					</div>
					<div class="body">
						<form class="cart-review mb-medium" (ngSubmit)="formSubmit($event)">
							<div class="scroll-container">
								<div class="flex-row justify-between mb-small text rubik breadcrumb-area">
									<app-bread-crumb [breadcrumbItems]="breadCrumbItems"></app-bread-crumb>
								</div>
								<div *ngIf="requisition?.hasApprovalProcess" style="position: absolute; right: 30px; top: 45px; font-weight: 500; font-size: 15px;">
									This requisition is part of an ongoing approval process. <a style="color: #f26363; font-weight: normal" (click)="openModal('timeline')">View timeline</a>
								</div>
								<div class="product-header-titles medium-header-text">
									<div class="total-lines-header">
										Requisition Summary
									</div>
									<div class="supplier-header">
										Selected Supplier
									</div>
									<div class="total-header ml-small">
										Estimated Total
									</div>
									<div class="swap-header">

									</div>
									<div class="alternative-product-header">
										Alternative Products
									</div>
								</div>
								<div class="product-header">
									<div class="product-header-items">
										<div class="total-lines">
											{{cartLines.length}} Items in Requisition
										</div>
										<div class="supplier-header">
										</div>
										<div class="total-header">
											{{ (this.totalTBD) ? 'TBD' : ( this.total | currency ) }}
										</div>
										<div class="swap-header">

										</div>
										<div class="alternative-product-message">
											Option to swap to an alternate product
										</div>
									</div>
								</div>
								<div class="cart-wrapper round-wrapper mb-medium">
									<div class="line-container" *ngFor="let line of cartLines">
										<app-optimize-order-row [requisitionLineItem]="line" [swapSupplierProductCallback]="SwapSupplierProductCallback"
										                        [originalChoice]="originalChoice" [lineRemove]="removeLineCallback" [lineUpdate]="update">
										</app-optimize-order-row>
										<div class="view-all-suppliers"
											 (click)="openVariations('choose-variation', line)"
											 [ngClass]="{'invisible' : (!line.allSupplierProducts)}">
											View all {{line.allSupplierProducts?.length}} Suppliers
										</div>
									</div>
									<div class="footer">
										<div class="footer-container">
											<h2 class="footer-title">Order Summary</h2>
											<div class="supplier-breakdown">
												<div class="footer-items text-small" *ngFor="let supplier of supplierCosts">
													{{supplier.groupName}}<span>{{ supplier.cost ? (supplier.cost | currency) : 'TBD' }}</span>
												</div>
												<div class="footer-items footer-bar medium-header-text">Estimated Order Total: <span>{{ (this.totalTBD) ? 'TBD' : ( this.total | currency ) }}</span></div>
											</div>
										</div>
										<div class="swap-header"></div>
										<div class="budget-widget" *ngIf="showBudget">
											<app-budget-preview class="budget-component-style" [budgetPeriod]="budgetPeriod" [totalUnknown]="budgetTBD"></app-budget-preview>
										</div>
									</div>
								</div>
								<div class="sticky-menu">
									<div class="flex-row justify-between">
										<a class="btn gray text text-center text-white" id="empty-cart"
										   style="height: 10px; display: inline; line-height: 12px;"
										   *ngIf="cartLines.length && !requisition?.hasApprovalProcess" (click)="abandonCart()">
											Empty Cart
										</a>

										<a class="btn gray text text-center text-white" id="empty-cart"
										   style="height: 10px; display: inline; line-height: 12px;"
										   *ngIf="cartLines.length && requisition?.hasApprovalProcess" (click)="abandonRequisition()">
											Abandon Requisition
										</a>

										<a type="submit" class="btn primary text text-center" id="review-and-submit"
										   style="margin: 0 0px 20px 0px;"
										   *ngIf="cartLines.length"
										   (click)="checkOverBudget()">
											Review & Order >
										</a>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>

		</app-progress>
	</div>
</app-access>

<app-modal id="choose-variation" [size]="'large'" [heading]="'Select from available suppliers'" class="select-supplier">
	<div class="flex-row line mb-medium">
		<ng-template ngFor [ngForOf]="selectedLine?.allSupplierProducts" let-s>
			<div class="line">
				<div></div>
				<div (click)="selectSuggestion('choose-variation', s, selectedLine!)"
					 [ngClass]="{ 'selected': isSelected(selectedLine!, s) }"
				     class="fill fill-white recommendation used round-wrapper variation-line">

					<div class="flex-row justify-between">
						<div>
							<div class="flex-row no-wrap" style="position: relative;">
								<div class="text product-name" [tooltip]="s.supplierProduct">
									{{ s.supplierProduct }}
								</div>
								<app-out-of-stock-tag [show]="!s.canPurchase" floatDirection="alternates"></app-out-of-stock-tag>
							</div>
							<div class="flex-row no-wrap">
								<div class="text text-300 rubik manufacturer-details">
									#{{ s.supplierProductSKU }} - {{ s.manufacturer }}
								</div>
								<div class="flex-row ml-small" style="height: 19px; position: relative;">
									<app-product-tag *ngFor="let t of s.supplierProductTags"
									                 style="position: relative; top: 3px;" [color]="t.c" [background]="t.b"
									                 [text]="t.t">
									</app-product-tag>
								</div>
							</div>

							<div class="text text-300 price">
								<span *ngIf="!s.price">$--</span>
								<span *ngIf="s.price">{{s.price}}</span>
							</div>
						</div>
						<div class="flex-row ">
							<div class="supplier-area">
								<div class="supplier-image">
									<img style="width: 100px" [src]="s.supplierImageUrl"/>
								</div>
								<div class="sku">
									#{{ s.supplierProductSKU }}
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>

		</ng-template>

	</div>

	<a class="btn primary text text-center mb-medium"
	   style="background: #aaa; margin: 20px 0px 0px 0px; display: block; float: right;"
	   (click)="closeVariations('choose-variation')">
		Close
	</a>

</app-modal>

<app-modal id="over-budget" [size]="'small'" [heading]="'You are over budget'">
	<div class="over-budget-card confirm-dialog">
		<div class="over-budget-content header">
			<div class="over-budget-message">
				You are over budget. Are you sure you would like to proceed?
			</div>
		</div>
		<div class="over-budget-buttons buttons-container">
			<a type="submit" class="btn primary text text-center" id="review-and-submit"
			   *ngIf="cartLines.length"
			   (click)="formSubmit($event)">
				Continue
			</a>
			<a class="btn text text-center mb-medium"
			   (click)="closeVariations('over-budget')">
				Cancel
			</a>
		</div>
	</div>
</app-modal>

<app-modal id="timeline" heading="Requisition Timeline" size="small">
	<app-approvals-timeline [timeline]="approvalDetails?.timeline || []"></app-approvals-timeline>
	<div class="buttons-container">
		<dx-button class="btn" style="float: right;" (click)="closeModal('timeline')">Close</dx-button>
	</div>
</app-modal>