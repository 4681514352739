const baseUrl = 'https://dev-app-sherpa.azurewebsites.net';

export const environment = {
  production: false,
  api: {
    account: {
      path: baseUrl + '/api/account',
      version: null,
      debug: false,
      authPrefix: '',
      secure: true
    },
    accountAgent: {
      path: baseUrl + '/api/accountagent',
      version: null,
      debug: false,
      authPrefix: '',
      secure: true
    },
    login: {
      path: baseUrl + '/api/login',
      version: null,
      debug: false,
      authPrefix: '',
      secure: true
    },
    agent: {
      path: baseUrl + '/api/agent',
      version: null,
      debug: false,
      authPrefix: '',
      secure: true
    },
    agency: {
      path: baseUrl + '/api/agency',
      version: null,
      debug: false,
      authPrefix: '',
      secure: true
    },
    budget: {
      path: baseUrl + '/api/v1/budgetPeriod/account',
      version: null,
      debug: false,
      authPrefix: '',
      secure: true
    },
    supplier: {
      path: baseUrl + '/api/supplier',
      version: null,
      debug: false,
      authPrefix: '',
      secure: true
    },
    formulary: {
      path: baseUrl + '/api/formulary',
      version: null,
      debug: false,
      authPrefix: '',
      secure: true
    },
    catalog: {
      path: baseUrl + '/api/catalog',
      version: null,
      debug: false,
      authPrefix: '',
      secure: true
    },
    order: {
      path: baseUrl + '/api/order',
      version: null,
      debug: false,
      authPrefix: '',
      secure: true
    },
    purchaseOrder: {
      path: baseUrl + '/api/purchaseorder',
      version: null,
      debug: false,
      authPrefix: '',
      secure: true
    },
    requisition: {
      path: baseUrl + '/api/requisition',
      version: null,
      debug: false,
      authPrefix: '',
      secure: true
    },
    reporting: {
      path: baseUrl + '/api/v1/reporting',
      version: null,
      debug: false,
      authPrefix: '',
      secure: true
    },
    fulfillment: {
      path: baseUrl + '/api/fulfillment',
      version: null,
      debug: false,
      authPrefix: '',
      secure: true
    },
    ping: {
      path: baseUrl + '',
      version: null,
      debug: false,
      authPrefix: '',
      secure: true
    }
  },
  auth0: {
    domain: 'https://sherpa-dental.us.auth0.com',
    clientId: 'bM3O6BVFbd8xa0WLj1M3nRaYtIidftoX',
    audience: 'https://sherpa-dental.us.auth0.com/api/v2/',
    useRefreshTokens: true,
    scope: 'openid email profile'
  },
  google: {
    tagId: 'GTM-TB5LLC7',
  },
  appInsights: {
    instrumentationKey: 'ddaab7fa-019d-4477-b893-d6148354f04b'
  },
};
