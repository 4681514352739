import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { User } from '@auth0/auth0-angular';
import { Subscription } from 'rxjs';
import { Agent, Profile } from 'src/app/shared/models';
import { AgentService, NavigationService } from 'src/app/shared/services';
import { AccountService } from 'src/app/shared/services/account.service';
import { Feature, FeatureService } from 'src/app/shared/services/feature.service';
import { DropdownContent } from 'src/app/shared/user-type';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-platform-nav',
  templateUrl: './platform-nav.component.html',
  styleUrls: ['./platform-nav.component.scss']
})
export class PlatformNavComponent implements OnDestroy {

  @Input()
  agent: Agent | undefined = undefined;

  @Input()
  user: User | undefined;

  @Input()
  nochromeMode = false;
  @Input()
  mobileexpanded = false;

  @Input()
  profile: Profile | undefined

  private subscriptions: Subscription[] = [];
  dropdownContent: DropdownContent[] = [];

  responsiveDropdownContent: DropdownContent[] = [];

  protected activeRoute: string = "";

  constructor(
    agents: AgentService,
    public accounts: AccountService,
    private features: FeatureService,
    private navigationService: NavigationService) {

    this.navigationService.activeRoute$.subscribe(activeRoute => {
      this.activeRoute = activeRoute;
    });

    const a = this.agent ?? agents.authenticatedAgent;

    if (a) {
      this.dropdownContent = [{
        name: 'Configuration',
        claims: [],
        iconId: 'configuration',
        children: [{
          name: 'Agencies',
          routeId: 'agency',
          claims: [Feature.AgencyUpdate],
          action: () => { this.navigationService.backoffice().agency.list(); this.activeRoute = "Configuration"},
          iconId: 'agencies'
        },
        {
          name: 'Account',
          routeId: 'account',
          claims: [Feature.AccountUpdate],
          action: () => { this.navigationService.backoffice().account.list(); this.activeRoute = "Configuration"},
          iconId: 'accounts'
        }, {
          name: 'Formulary',
          routeId: 'formulary',
          claims: [Feature.AgencyUpdate],
          action: () => { this.navigationService.backoffice().formulary.list(); this.activeRoute = "Configuration"},
          iconId: 'formularies'
        }]
      }];

      this.responsiveDropdownContent = [{
        name: 'Menu',
        claims: [],
        iconId: 'menu',
        children: [
          {
            name: 'Home',
            routeId: 'dashboard',
            claims: [],
            action: () => { this.toDashboard();},
            iconId: 'home'
          },
          {
            name: 'Reports',
            routeId: 'reports',
            claims: [],
            action: () => { this.toReports();},
            iconId: 'reports'
          },
          {
          name: 'Configuration',
          claims: [],
          iconId: 'configuration',
          children: [{
            name: 'Agencies',
            routeId: 'agency',
            claims: [Feature.AgencyUpdate],
            action: () => { this.navigationService.backoffice().agency.list(); this.activeRoute = "Configuration"},
            iconId: 'agencies'
          },
          {
            name: 'Account',
            routeId: 'account',
            claims: [Feature.AccountUpdate],
            action: () => { this.navigationService.backoffice().account.list(); this.activeRoute = "Configuration"},
            iconId: 'accounts'
          }, {
            name: 'Formulary',
            routeId: 'formulary',
            claims: [Feature.AgencyUpdate],
            action: () => { this.navigationService.backoffice().formulary.list(); this.activeRoute = "Configuration"},
            iconId: 'formularies'
          }]
        }]
      }];

      if (a) {
        this.filterContent(a);
      }
    }
  }

  filterContent(a: Agent): void {
    for (var x = 0; x < this.dropdownContent.length; ++x) {
      const dc = this.dropdownContent[x];
      for (var y = 0; y < dc.children.length; ++y) {
        const cc = dc.children[y];
        if (cc.claims.length && cc.claims.filter(c => this.features.check(c, a.features)).length !== cc.claims.length) {
          dc.children.splice(y--, 1);
        }
      }
      if (!dc.children.length || (dc.claims.length && dc.claims.filter(c => this.features.check(c, a.features)).length !== dc.claims.length)) {
        this.dropdownContent.splice(x--, 1);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  toggleMenu(value: boolean): void {
    this.mobileexpanded = value;
  }

  toDashboard() {
    this.navigationService.backoffice().dashboard();
  }

  toReports() {
    this.navigationService.backoffice().reports();
  }
}
