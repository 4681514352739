import { EventEmitter, Injectable } from '@angular/core';
import { ModalComponent } from '../components/modal/modal.component';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ModalService {
    private modals: ModalComponent[] = [];
    private confirmationClose: boolean = false;
    private confirmationModalId: string | undefined;
    private triggerSubject: Subject<void> = new Subject<void>();

    private data = new BehaviorSubject("")
    currentData = this.data.asObservable();
    private confirmationEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

    setData(data: any) {
      this.data.next(data);
    }

    triggerMethodInComponentB(): void {
      this.triggerSubject.next();
    }  
    getTriggerSignal(): Observable<void> {
      return this.triggerSubject.asObservable();
    }

    setCloseConfirmationBool(bool: boolean): void {
        this.confirmationClose = bool;
    }
    
    getCloseConfirmationBool(): boolean {
        return this.confirmationClose!;
    }

    setConfirmationModalId(id: string): void {
      this.confirmationModalId = id;
    }
  
    getConfirmationModalId(): string {
      return this.confirmationModalId!;
    }

    add(modal: ModalComponent): void {
        const mo = this.modals.find(m => m.id === modal.id);
        if (mo) {
            this.modals.splice(this.modals.indexOf(mo), 1);
        }
        this.modals.push(modal);
    }
    remove(id: string): void {
        this.modals.filter(m => m.id !== id);
    }
    open(id: string): void {
        const modal = this.modals.find(m => m.id === id);
        if (modal) {
            modal.open();
        }
    }
    close(id: string): void {
        const modal = this.modals.find(m => m.id === id);
        if (modal) {
            modal.close(true);
        }
    }

    private confirmationCallback: ((confirmation: boolean) => void) | null = null;
    confirm(id: string, callback: (confirmation: boolean) => void): void {
      // Logic to open the modal with id 'confirmGoLive'
      this.open(id);
  
      // Store the callback to be used later when the user clicks Continue or Cancel
      this.confirmationCallback = callback;
    }
  
    setConfirmation(confirmation: boolean): void {
      if (this.confirmationCallback) {
        this.confirmationCallback(confirmation);
        this.confirmationCallback = null; // Reset the callback after using it
      }
    }
    
}

