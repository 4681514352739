import { Component, Input } from '@angular/core';
import { ILevelApprovers } from "../../models/approver.model";

@Component({
  selector: 'app-display-approver-list',
  templateUrl: './display-approver-list.component.html',
  styleUrls: ['./display-approver-list.component.scss']
})
export class DisplayApproverListComponent {
  @Input() levelApprovers!: ILevelApprovers[];

}
