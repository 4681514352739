import { CoreProduct, Manufacturer, ProductInfo, ProductListing, Supplier, Tag } from '.';

export class UpdateSupplierProduct {
  public id!: number;
  public name!: string;
  public supplierId!: number;
  public coreProductId!: number;
  public description: string | undefined;
  public imageUrl: string | undefined;
  public uoMs: string[] = [];
}

export class SupplierProduct extends UpdateSupplierProduct implements ProductInfo {
  constructor() {
    super();
  }
  public productName!: string;
  public id!: number;
  public description: string | undefined;
  public imageUrl: string | undefined;
  public uoMs: string[] = [];
  public urlName: string | undefined;
  public uomProducts: number[] = [];
  public sku: string | undefined;
  public active!: boolean;
  public supplierId!: number;
  public coreProduct?: CoreProduct;
  public deleted!: boolean;
  public created?: Date;
  public selected?: boolean; // for ui use
  public manufacturerName?: string;
  public manufacturerSKU?: string;
  public lastPurchaseDate?: Date;
  public lastPurchaseQuantity?: number;
  public price?: string;
  public supplier?: Supplier;

  public name!: string;
  public uom: string | undefined;
  public createdBy!: number;
  public modified?: Date;
  public modifiedBy!: number;
  public listing?: ProductListing;
  public coreProductId!: number;
  public estimatedShipDate?: Date;
  public stockQuantity?: number;
  public listingTags?: Tag[];
  public coreProductTags?: Tag[];
  public supplierProductTags?: Tag[];
  public canPurchase?: boolean;
  public recommendedProduct?: boolean;
}

