import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AccountType, Agent, Profile } from 'src/app/shared/models';
import { MonitorService, AgentService, NavigationService } from 'src/app/shared/services';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  agent?: Agent;
  working = 0;
  workingMessage = '';
  subscriptions: Subscription[] = [];

  profile = Profile;
  accountType = AccountType;
  selectedProfile: Profile | undefined;

  constructor(
    private monitor: MonitorService,
    private agents: AgentService,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute) {
    // why was asObservable added?
    this.subscriptions.push(agents.authenticated$.asObservable().subscribe((a) => {
      this.agent = a;
    }));
  }

  async ngOnInit(): Promise<any> {
    this.working = 0;

    try {
      this.working = 10;
      this.workingMessage = 'Loading dashboard';

      this.agent = this.agents.authenticatedAgent;

      this.selectedProfile = this.agents.currentProfile;

      if(this.agents.currentProfile == Profile.Unauthorized){
        this.navigationService.public().message.noAccess();
      }

      this.workingMessage = 'Dashboard loaded';
    } catch (error: any) {
      this.monitor.handleError(error, 'Error initializing');

    } finally {
      this.working = 0;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
