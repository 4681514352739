<div class="flex-row line">
    <div *ngIf="isLineUpdating" class="swapping-overlay"></div>
    <div class="fill fill-white recommendation used round-wrapper supplier-card border">
        <div class="product-info-cell" style="position: relative;">
            <div class="req-summary">
                <img class="mr-small ml-small product-image"
                [src]="requisitionLineItem.relativeImageUrl" />
                <div class="product-info">
                    <div class="flex-row no-wrap">
                        <div class="text product-name"
                            [tooltip]="requisitionLineItem.supplierProduct">
                            {{ requisitionLineItem.supplierProduct }}
                        </div>
                    </div>
                    <div style="float: left;" class="mr-small add-to-cart-area">
                        <div class="flex-row no-wrap" style="margin-bottom: -3px;">
                            <div
                                class="text text-400 rubik grey-nowrap">
                                #{{ requisitionLineItem.manufacturerPartNumber }} - {{
                                requisitionLineItem.manufacturer }}
                            </div>
                            <div class="flex-row no-wrap ml-small"
                                style="position: relative;">
                                <app-product-tag
                                    style="position: relative; top: 3px;"
                                    *ngFor="let t of requisitionLineItem.supplierProductTags"
                                    [color]="t.c" [background]="t.b"
                                    [inline]="true"
                                    [text]="t.t"></app-product-tag>
                            </div>
                        </div>
                        <div class="mt-small">
                            {{requisitionLineItem.price}}
                        </div>
                        <div class="atc mt-small flex-row flex-nowrap add-to-cart"
                            [id]="requisitionLineItem.supplierProductId" #reqLines>
                            <app-add-to-cart
                                layout="wrap" mode="update"
                                [coreProduct]="coreProduct!"
                                [showCurrentQuantity]="true"
                                [quantity]="requisitionLineItem.purchaseRequisitionLineQuantity"
                                [supplierProduct]="supplierProduct"
                                [hideRemove]="false"
                                (removed)="removeLine(requisitionLineItem.purchaseRequisitionLineId)"
                                (added)="updateLine(requisitionLineItem.purchaseRequisitionLineId)"
                                (loading)="isLineUpdating=true">
                            </app-add-to-cart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="supplier-area">
                <div class="header">
                    {{ requisitionLineItem.supplier }}
                </div>
                <div class="sku">
                    #{{ requisitionLineItem.supplierProductSKU }}
                </div>
            </div>
            <div class="line-total">
                Line Total: {{ TBDPrice ? 'TBD' : (lineTotal | currency) }}
            </div>
            <div class="alternate-product-button-container">
                <i *ngIf="requisitionLineItem.alternativeProduct" (click)="SwapSupplierProduct(requisitionLineItem.purchaseRequisitionLineId, requisitionLineItem.alternativeProduct.supplierProductId)"></i>
            </div>
            <div class="alternate-product-container" *ngIf="requisitionLineItem.alternativeProduct">
                <div class="image-container">
                    <img class="product-image"
                    [src]="requisitionLineItem.alternativeProduct.relativeImageUrl" />
                </div>
                <div class="supplier-info">
                    <div class="header">
                        {{ requisitionLineItem.alternativeProduct.supplier }}
                    </div>
                    <div class="text text-300 rubik grey-nowrap sku" style="white-space: pre-wrap">
                        #{{ requisitionLineItem.alternativeProduct.supplierProductSKU }}
                    </div>
                </div>
                <div class="product-info">
                    <div class="flex-row no-wrap">
                        <div class="text product-name-alt"
                            [tooltip]="requisitionLineItem.alternativeProduct.supplierProduct">
                            {{ requisitionLineItem.alternativeProduct.supplierProduct }}
                        </div>
                    </div>
                    <div class="manufacturer-area">
                        <div class="manufacturer">
                            #{{ requisitionLineItem.manufacturerPartNumber }} - {{ requisitionLineItem.manufacturer }}
                        </div>
                    </div>
                    <div class="mr-small">
                        <div class="flex-row no-wrap">
                            <div class="flex-row no-wrap mb-medium"
                                style="position: relative;">
                                <app-product-tag
                                    style="position: relative; top: 3px;"
                                    *ngFor="let t of requisitionLineItem.alternativeProduct.supplierProductTags"
                                    [color]="t.c" [background]="t.b"
                                    [inline]="true"
                                    [text]="t.t"></app-product-tag>
                            </div>
                        </div>
    
                        <div>
                            {{requisitionLineItem.alternativeProduct.price}}
                        </div>
                    </div>
                </div>
                <div class="supplier-product-reason" *ngIf="!originalChoice">
                    <label [innerHTML]="requisitionLineItem.alternativeSupplierProductReason"></label>
                </div>
                <div class="supplier-product-reason" *ngIf="originalChoice">
                    Original choice
                </div>
            </div>
            <div class="great-choice" *ngIf="!requisitionLineItem.alternativeProduct">
                Great Choice! 
                <span style="white-space: pre-wrap; font-weight:400; font-size: 14px">
                    No alternatives
                </span>
            </div>
            <div class="supplier-switch-container flex-row" [ngClass]="{'visible' : requisitionLineItem.topSupplierProduct1 || requisitionLineItem.topSupplierProduct2 || requisitionLineItem.topSupplierProduct3 }">
                <div class="supplier-switch flex-row" *ngIf="requisitionLineItem.topSupplierProduct1">
                    <i class="swap-product" (click)="SwapSupplierProduct(requisitionLineItem.purchaseRequisitionLineId, requisitionLineItem.topSupplierProduct1.supplierProductId)"></i>
                    <div class="flex-column supplier-label">
                        <p class="supplier-price">{{requisitionLineItem.topSupplierProduct1.price}}</p>
                        <p class="supplier-text">Switch to {{requisitionLineItem.topSupplierProduct1.supplier}}</p>
                    </div>
                </div>
    
                <div class="supplier-switch flex-row" *ngIf="requisitionLineItem.topSupplierProduct2">
                    <i class="swap-product" (click)="SwapSupplierProduct(requisitionLineItem.purchaseRequisitionLineId, requisitionLineItem.topSupplierProduct2.supplierProductId)"></i>
                    <div class="flex-column supplier-label">
                        <p class="supplier-price">{{requisitionLineItem.topSupplierProduct2.price}}</p>
                        <p class="supplier-text">Switch to {{requisitionLineItem.topSupplierProduct2.supplier}}</p>
                    </div>
                </div>
    
                <div class="supplier-switch flex-row" *ngIf="requisitionLineItem.topSupplierProduct3 && (!requisitionLineItem.topSupplierProduct1 || !requisitionLineItem.topSupplierProduct2)">
                    <i class="swap-product" (click)="SwapSupplierProduct(requisitionLineItem.purchaseRequisitionLineId, requisitionLineItem.topSupplierProduct3.supplierProductId)"></i>
                    <div class="flex-column supplier-label">
                        <div class="supplier-price"></div>
                        <p class="supplier-price">{{requisitionLineItem.topSupplierProduct3.price}}</p>
                        <p class="supplier-text">Switch to {{requisitionLineItem.topSupplierProduct3.supplier}}</p>
                    </div>
                </div>
    
            </div>
            <div class="empty-bottom-cell"></div>
        </div>
    </div>
</div>